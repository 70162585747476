import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Loader from "../../../components/shared/loaders/spinner/sppinner.component";
import Alert from "../../../components/shared/alerts/material-snack-bar/material-snack-bar.component";

//redux import
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../../redux/actions/user.actions";
import { addTypologies } from "../../../redux/actions/typologies.actions";

const style = {
  position: "absolute",
  borderRadius: "30px",
  top: "50%",
  left: "50%",
  maxHeight: "95vh",
  minWidth: "50vw",
  background: "white",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  padding: "50px",
  boxShadow: 100,
  p: 4,
};

const AddItem = ({ cancelAddItem, resetMessage }) => {
  const [newName, setnewName] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const typologyAdd = useSelector((state) => state.typologyAdd);
  const { loading: loadingAdd, error: errorAdd, addResult } = typologyAdd;
  const handleAdd = (e) => {
    e.preventDefault();
    if (newName) {
      dispatch(addTypologies(newName));
    }
  };
  useEffect(() => {
    if (!userInfo) {
      dispatch(logout());
      navigate("/login");
    } else if (userInfo.role === "USUARIO") {
      dispatch(logout());
    } else {
      resetMessage();
    }
    if (addResult) {
      cancelAddItem();
    }
  }, [navigate, userInfo, dispatch, resetMessage, addResult, cancelAddItem]);

  return (
    <div style={style}>
      {errorAdd && <Alert message={errorAdd} />}
      {loadingAdd && (
        <div className="mt-2 text-center">
          {" "}
          <Loader />
        </div>
      )}
      <h6>Ingrese el nombre de la tipología</h6>
      <input
        type="text"
        className="form-control"
        placeholder="Nombre"
        value={newName}
        onChange={(e) => {
          setnewName(e.target.value);
        }}
      />
      <hr />
      <div className="col-md-12">
        <button className="btn btn-success" onClick={handleAdd}>
          <strong>AGREGAR TIPOLOGIA</strong>
        </button>
        <button className="btn " onClick={cancelAddItem}>
          <strong>CANCELAR</strong>
        </button>
      </div>
    </div>
  );
};

export default AddItem;
