import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { useStateWithCallbackLazy } from "use-state-with-callback";

//import components
import Header from "../../layout/header/header.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import Alert from "../../components/shared/alerts/material-snack-bar/material-snack-bar.component";
import ItemEditComponent from "../../components/riskmap/item-edit/item-edit.component";
import FileEditComponent from "../../components/cuestionnaires/file-edit/file-edit.component";
//import ItemAddComponent from "../../components/cuestionnaires/add-item/add-item.component";
import FilterListComponent from "../../components/shared/filters/filter-list/filter-list.component";
import PaginationComponent from "../../components/shared/pagination/pagination.component";

//redux import
import { useDispatch, useSelector } from "react-redux";
import {
  getRiskMapsDetails,
  deleteItem,
  getMapRiskQuestionsByCondition,
  getriskmapDetailsBySearching,
  getriskmapDetailsBySorting,
} from "../../redux/actions/risk-maps.actions";
import {
  RISK_MAPS_RESPONSE_RESET,
  RISK_MAPS_DELETE_ITEM_RESET,
  RISK_MAPS_ADD_CONDUCT_RESET,
  RISK_MAPS_PLAN_ADD_RESET,
} from "../../redux/constants/risk-maps.const";

//constant
import BREADCRUM_DATA from "../../constants/breadcrumb.const";
import RISKMAPS_QUESTIONS_FILTER_OPTIONS from "../../constants/riskMapsConductsFilterOptions";

const style = {
  overflowY: "auto",
  position: "absolute",
  // borderRadius: "30px",
  top: "50%",
  left: "50%",
  maxHeight: "90vh",
  minWidth: "80vw",
  background: "white",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  padding: "40px",
  boxShadow: 100,
  p: 4,
};

const RiskMapsDetailsPage = () => {
  const myRef = useRef(null);
  const [editingId, setEditingId] = useState(null);
  const [editingFileId, setEditingFileId] = useState(null);
  const [isAddingItem, setIsAddingItem] = useState(false);
  const [message, setMessage] = useState(null);
  const [refId, setRefId] = useState(null);

  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const riskMapDetails = useSelector((state) => state.riskMapDetails);
  const {
    loading: loadingDetails,
    error: errorDetails,
    riskMap,
  } = riskMapDetails;
  const riskMapQuestionsFiltered = useSelector(
    (state) => state.riskMapQuestionsFiltered
  );
  const { questions } = riskMapQuestionsFiltered;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const riskMapResponseState = useSelector((state) => state.riskMapResponse);
  const { responseResult } = riskMapResponseState;

  const riskMapAddConduct = useSelector((state) => state.riskMapAddConduct);
  const { addResult } = riskMapAddConduct;
  const riskMapConductAddPlan = useSelector(
    (state) => state.riskMapConductAddPlan
  );
  const { responseResult: responseResultPlan } = riskMapConductAddPlan;

  /*   const questionnaryAddFile = useSelector((state) => state.questionnaryAddFile);
  const { addResult: addResultFile } = questionnaryAddFile; */

  const riskMapDeteleConduct = useSelector(
    (state) => state.riskMapDeteleConduct
  );
  const {
    loading: loadingDeleteItem,
    error: errorDeleteItem,
    deleteResult,
  } = riskMapDeteleConduct;

  const [tableDropdownOpen, setTableMenuOpen] = useState(false);
  const [tableDropdownOpenSort, setTableMenuOpenSort] = useState(false);
  const tableMenuOpen = () => {
    setTableMenuOpen(!tableDropdownOpen);
  };
  const tableSortMenuOpen = () => {
    setTableMenuOpenSort(!tableDropdownOpenSort);
  };

  const [secondTableCurrentPage, setSecondTableCurrentPage] = useState(0);
  const pageSize = 8;
  const secondTablePagesCount = questions
    ? Math.ceil(questions.length / pageSize)
    : 1;

  const setSecondTablePage = (e, index) => {
    e.preventDefault();
    setSecondTableCurrentPage(index);
  };

  const handleCambioEstado = (condition) => {
    dispatch(getMapRiskQuestionsByCondition(condition));
    setSecondTableCurrentPage(0);
  };

  const handleSorting = (condition) => {
    dispatch(getriskmapDetailsBySorting(condition));
    setSecondTableCurrentPage(0);
  };
  const [searchKey, setSearchKey] = useStateWithCallbackLazy("");
  const handleSearch = (codigo) => {
    setSearchKey(codigo, (current) => {
      dispatch(getriskmapDetailsBySearching(current.trim()));
      setSecondTableCurrentPage(0);
    });
  };

  const cancelEdit = () => {
    //e.preventDefault();
    setEditingId(null);

    return false;
  };

  const cancelEditFile = () => {
    //e.preventDefault();
    setEditingFileId(null);

    //myRef.current.reset();
    return false;
  };

  const cancelAddItem = () => {
    //e.preventDefault();
    setIsAddingItem(false);

    //myRef.current.reset();
    return false;
  };

  const resetMessage = () => {
    //e.preventDefault();
    setMessage(null);
    return false;
  };
  //console.log(myRef);
  //console.log(refId);
  if (myRef.current && refId) {
    //myRef.current.scrollIntoView();
    //myRef.current.style.backgroundColor = "rgba(50,205,50, 0.2)";
    //myRef.current.removeChild(myRef.current.children[0]);
  }

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else {
      if (responseResult) {
        //window.scrollTo(0, 0);
        setMessage("PREGUNTA RESPONDIDA  con éxito");
        //executeScroll();
      }
      if (addResult) {
        setMessage("Conducta agregada con éxito");
        //executeScroll();
      }
      if (responseResultPlan) {
        setMessage("Control y Plan de Acción agregado con éxito.");
        //executeScroll();
      }
      /* if (addResultFile) {
        setMessage("Proceso realizado exitosamente.");
        //executeScroll();
      } */

      if (deleteResult) {
        setMessage("Pregunta eliminada con éxito.");
      }

      dispatch({ type: RISK_MAPS_RESPONSE_RESET });
      /*      dispatch({ type: QUESTIONNAIRES_ADD_FILE_RESET });*/
      dispatch({ type: RISK_MAPS_ADD_CONDUCT_RESET });
      dispatch({ type: RISK_MAPS_DELETE_ITEM_RESET });
      dispatch({ type: RISK_MAPS_PLAN_ADD_RESET });
      dispatch(getRiskMapsDetails(params.id));
    }
  }, [
    navigate,
    userInfo,
    dispatch,
    params,
    responseResult,
    deleteResult,
    addResult,
    responseResultPlan,
  ]);
  const handleDelete = (e, id) => {
    e.preventDefault();
    if (window.confirm("Esta acción no se puede deshacer.")) {
      dispatch(deleteItem(id));
    }
  };
  return (
    <>
      {message && <Alert message={message} severity="success" />}
      {deleteResult && (
        <Alert message="Pregunta eliminada con éxito." severity="success" />
      )}
      {errorDeleteItem && <Alert message={errorDeleteItem} severity="error" />}
      {loadingDeleteItem && (
        <div className="text-center mb-2">
          <Loader />
        </div>
      )}
      {errorDetails ? (
        <div className="mb-3">
          <Alert message={errorDetails} />
        </div>
      ) : loadingDetails ? (
        <div className="text-center mb-2">
          <Loader />
        </div>
      ) : (
        riskMap && (
          <>
            <Header
              title={`${riskMap.name && riskMap.name}`}
              path="Detalles Mapa de Riesgo"
              pathList={BREADCRUM_DATA.riskMapDetail}
            >
              {userInfo && userInfo.role !== "EMPRESA" && (
                <>
                  {" "}
                  <Link
                    to={`/mapa-de-riesgos/agregar-conducta/${
                      riskMap.id && riskMap.id
                    }`}
                    className="btn btn-success btn-icon float-right "
                  >
                    <i className="zmdi zmdi-plus"></i>
                  </Link>{" "}
                  <Link
                    to={`/mapa-de-riesgos/resultados/${riskMap.id}`}
                    className="btn btn-info btn-icon float-right "
                  >
                    <i className="zmdi zmdi-chart"></i>
                  </Link>
                </>
              )}
            </Header>
            <div className="container-fluid">
              <div className="row clearfix">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="card project_list">
                    <div>
                      {" "}
                      <FilterListComponent
                        searchKey={searchKey}
                        handleSearch={handleSearch}
                        tableDropdownOpenSort={tableDropdownOpenSort}
                        tableSortMenuOpen={tableSortMenuOpen}
                        handleSorting={handleSorting}
                        tableDropdownOpen={tableDropdownOpen}
                        tableMenuOpen={tableMenuOpen}
                        handleCambioEstado={handleCambioEstado}
                        filterOptions={RISKMAPS_QUESTIONS_FILTER_OPTIONS}
                      />
                    </div>
                    <div className="table-responsive tableFixHead">
                      <table className="table table-hover c_table theme-color ">
                        <thead>
                          <tr>
                            <th>Tipología</th>
                            <th style={{ width: "25px" }}>Artículo</th>
                            <th style={{ maxWidth: "25px" }}>Conducta</th>

                            <th className="">Sumatoria</th>

                            <th>Promedio</th>
                            <th className="" width="150px">
                              Redondeo
                            </th>
                            <th className="" width="150px">
                              Riesgo
                            </th>
                            <th className="" width="150px">
                              Análisis y Recomendación
                            </th>

                            <th>Acción</th>
                          </tr>
                        </thead>
                        <tbody>
                          {questions &&
                            questions
                              .slice(
                                secondTableCurrentPage * pageSize,
                                (secondTableCurrentPage + 1) * pageSize
                              )
                              .map((item, index) => (
                                <>
                                  {" "}
                                  <tr
                                    /* ref={refId === item.id ? myRef : null} */
                                    key={index}
                                    className={`${
                                      parseFloat(item.round) === 0
                                        ? "notRisk"
                                        : parseFloat(item.round) < 0.99
                                        ? "minim"
                                        : parseFloat(item.round) < 1.99
                                        ? "low"
                                        : parseFloat(item.round) < 2.99
                                        ? "medium"
                                        : parseFloat(item.round) < 3.99
                                        ? "high"
                                        : "outRange"
                                    }`}
                                  >
                                    <div>
                                      <strong style={{ fontSize: "11px" }}>
                                        {item.conduct.typology &&
                                          item.conduct.typology.name}
                                      </strong>
                                    </div>
                                    <td
                                      className="pt-4"
                                      style={{ fontSize: "12px" }}
                                    >
                                      <p>{item.conduct.article}</p>
                                    </td>
                                    <div
                                      style={{
                                        minWidth: "250px",
                                        fontSize: "10px",
                                      }}
                                    >
                                      {item.conduct.name}
                                    </div>

                                    <td>{item.sum}</td>

                                    <td>{item.average}</td>
                                    <td>{item.round}</td>
                                    <td>
                                      <strong style={{ fontSize: "10px" }}>
                                        {parseFloat(item.round) === 0
                                          ? "FUERA DE RIESGO"
                                          : parseFloat(item.round) < 0.99
                                          ? "MINIMO"
                                          : parseFloat(item.round) < 1.99
                                          ? "BAJO"
                                          : parseFloat(item.round) < 2.99
                                          ? "MEDIO"
                                          : parseFloat(item.round) < 3.99
                                          ? "ALTO"
                                          : "FUERA DE RANGO!"}
                                      </strong>
                                    </td>
                                    <div>
                                      <p style={{ fontSize: "12px" }}>
                                        <strong>Análisis: </strong>{" "}
                                        {item.analysis
                                          ? item.analysis
                                          : "-----"}
                                      </p>
                                    </div>
                                    <div>
                                      <p style={{ fontSize: "12px" }}>
                                        <strong>Recomendación: </strong>
                                        {item.recommendation
                                          ? item.recommendation
                                          : "-----"}
                                      </p>
                                    </div>
                                    <td>
                                      {userInfo && userInfo.role !== "EMPRESA" && (
                                        <>
                                          {editingId === item.id ? (
                                            <div>
                                              <button
                                                onClick={(e) => {
                                                  cancelEdit(e);
                                                }}
                                                className="btn  btn-sm"
                                                title="Editando "
                                              >
                                                <i className="zmdi zmdi-assignment-o mr-2 pt-1 pb-1"></i>{" "}
                                                <strong>EDITANDO</strong>
                                              </button>
                                            </div>
                                          ) : (
                                            <>
                                              <button
                                                onClick={() => {
                                                  setEditingId(item.id);
                                                  setRefId(item.id);
                                                }}
                                                className="btn btn-warning btn-sm"
                                                title="Editar"
                                              >
                                                <i className="zmdi zmdi-edit  "></i>
                                              </button>
                                              <button
                                                onClick={(e) => {
                                                  handleDelete(e, item.id);
                                                }}
                                                className="btn btn-danger btn-sm"
                                                title="Eliminar"
                                              >
                                                <i className="zmdi zmdi-delete  "></i>
                                              </button>
                                            </>
                                          )}
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                  {editingId === item.id ? (
                                    <>
                                      <Modal
                                        open={
                                          editingId === item.id ? true : false
                                        }
                                        onClose={(e) => {
                                          cancelEdit(e);
                                          setRefId(null);
                                        }}
                                      >
                                        <div style={style}>
                                          <ItemEditComponent
                                            conductData={item}
                                            onCancel={cancelEdit}
                                            resetMessage={resetMessage}
                                            riskMapId={item.id}
                                          />
                                        </div>
                                      </Modal>
                                      {/*    <h6 className="mt-2">EDITAR</h6>
                                       */}
                                    </>
                                  ) : null}
                                  {editingFileId === item.id ? (
                                    <>
                                      <Modal
                                        open={
                                          editingFileId === item.id
                                            ? true
                                            : false
                                        }
                                        onClose={(e) => {
                                          cancelEditFile(e);
                                          setRefId(null);
                                        }}
                                      >
                                        <div style={style}>
                                          <FileEditComponent
                                            id={item.id}
                                            category={item.item.category.name}
                                            question={item.item.question}
                                            onCancel={cancelEditFile}
                                            resetMessage={resetMessage}
                                          />
                                        </div>
                                      </Modal>
                                      {/*    <h6 className="mt-2">EDITAR</h6>
                                       */}
                                    </>
                                  ) : null}
                                </>
                              ))}
                        </tbody>
                      </table>

                      {isAddingItem ? (
                        <>
                          <Modal
                            open={isAddingItem}
                            onClose={(e) => {
                              cancelAddItem(e);
                              setRefId(null);
                            }}
                          >
                            {/* <div style={style}>
                              <ItemAddComponent
                                quizId={params.id}
                                cuestionaryName={
                                  questionnary.company &&
                                  questionnary.company.name
                                }
                                onCancel={cancelAddItem}
                                resetMessage={resetMessage}
                              />
                            </div> */}
                          </Modal>
                          {/*    <h6 className="mt-2">EDITAR</h6>
                           */}
                        </>
                      ) : null}
                    </div>
                    <div className="mt-4 text-center">
                      <PaginationComponent
                        tablecurrentPage={secondTableCurrentPage}
                        setTablecurrentPage={setSecondTablePage}
                        tablepagesCount={secondTablePagesCount}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      )}
    </>
  );
};

export default RiskMapsDetailsPage;
