export const CHECKLIST_LIST_REQUEST = "CHECKLIST_LIST_REQUEST";
export const CHECKLIST_LIST_SUCCESS = "CHECKLIST_LIST_SUCCESS";
export const CHECKLIST_LIST_FAIL = "CHECKLIST_LIST_FAIL";
export const CHECKLIST_LIST_RESET = "CHECKLIST_LIST_RESET";

export const CHECKLIST_DETAILS_REQUEST = "CHECKLIST_DETAILS_REQUEST";
export const CHECKLIST_DETAILS_SUCCESS = "CHECKLIST_DETAILS_SUCCESS";
export const CHECKLIST_DETAILS_FAIL = "CHECKLIST_DETAILS_FAIL";
export const CHECKLIST_DETAILS_RESET = "CHECKLIST_DETAILS_RESET";

export const CHECKLIST_DELETE_REQUEST = "CHECKLIST_DELETE_REQUEST";
export const CHECKLIST_DELETE_SUCCESS = "CHECKLIST_DELETE_SUCCESS";
export const CHECKLIST_DELETE_FAIL = "CHECKLIST_DELETE_FAIL";
export const CHECKLIST_DELETE_RESET = "CHECKLIST_DELETE_FAIL";

export const CHECKLIST_UPDATE_REQUEST = "CHECKLIST_UPDATE_REQUEST";
export const CHECKLIST_UPDATE_SUCCESS = "CHECKLIST_UPDATE_SUCCESS";
export const CHECKLIST_UPDATE_FAIL = "CHECKLIST_UPDATE_FAIL";
export const CHECKLIST_UPDATE_RESET = "CHECKLIST_UPDATE_RESET";

export const CHECKLIST_ADD_REQUEST = "CHECKLIST_ADD_REQUEST";
export const CHECKLIST_ADD_SUCCESS = "CHECKLIST_ADD_SUCCESS";
export const CHECKLIST_ADD_FAIL = "CHECKLIST_ADD_FAIL";
export const CHECKLIST_ADD_RESET = "CHECKLIST_ADD_RESET";

export const CHECKLIST_ADD_ITEM_REQUEST = "CHECKLIST_ADD_ITEM_REQUEST";
export const CHECKLIST_ADD_ITEM_SUCCESS = "CHECKLIST_ADD_ITEM_SUCCESS";
export const CHECKLIST_ADD_ITEM_FAIL = "CHECKLIST_ADD_ITEM_FAIL";
export const CHECKLIST_ADD_ITEM_RESET = "CHECKLIST_ADD_ITEM_RESET";

export const CHECKLIST_RESPONSE_REQUEST = "CHECKLIST_RESPONSE_REQUEST";
export const CHECKLIST_RESPONSE_SUCCESS = "CHECKLIST_RESPONSE_SUCCESS";
export const CHECKLIST_RESPONSE_FAIL = "CHECKLIST_RESPONSE_FAIL";
export const CHECKLIST_RESPONSE_RESET = "CHECKLIST_RESPONSE_RESET";

export const CHECKLIST_ADD_FILE_REQUEST = "CHECKLIST_ADD_FILE_REQUEST";
export const CHECKLIST_ADD_FILE_SUCCESS = "CHECKLIST_ADD_FILE_SUCCESS";
export const CHECKLIST_ADD_FILE_FAIL = "CHECKLIST_ADD_FILE_FAIL";
export const CHECKLIST_ADD_FILE_RESET = "CHECKLIST_ADD_FILE_RESET";

export const CHECKLIST_DELETE_FILE_REQUEST = "CHECKLIST_DELETE_FILE_REQUEST";
export const CHECKLIST_DELETE_FILE_SUCCESS = "CHECKLIST_DELETE_FILE_SUCCESS";
export const CHECKLIST_DELETE_FILE_FAIL = "CHECKLIST_DELETE_FILE_FAIL";
export const CHECKLIST_DELETE_FILE_RESET = "CHECKLIST_DELETE_FILE_RESET";

export const CHECKLIST_GET_FILE_REQUEST = "CHECKLIST_GET_FILE_REQUEST";
export const CHECKLIST_GET_FILE_SUCCESS = "CHECKLIST_GET_FILE_SUCCESS";
export const CHECKLIST_GET_FILE_FAIL = "CHECKLIST_GET_FILE_FAIL";
export const CHECKLIST_GET_FILE_RESET = "CHECKLIST_GET_FILE_RESET";

export const CHECKLIST_DELETE_ITEM_REQUEST = "CHECKLIST_DELETE_ITEM_REQUEST";
export const CHECKLIST_DELETE_ITEM_SUCCESS = "CHECKLIST_DELETE_ITEM_SUCCESS";
export const CHECKLIST_DELETE_ITEM_FAIL = "CHECKLIST_DELETE_ITEM_FAIL";
export const CHECKLIST_DELETE_ITEM_RESET = "CHECKLIST_DELETE_ITEM_FAIL";

export const CHECKLIS_FILTRADA_DETAILS_GET = "CHECKLIS_FILTRADA_DETAILS_GET";
export const CHECKLIS_FILTRADA_DETAILS_RESET =
  "CHECKLIS_FILTRADA_DETAILS_RESET";
