import api from "../../api/rigori.api";

import {
  COMPANIES_DELETE_REQUEST,
  COMPANIES_DELETE_SUCCESS,
  COMPANIES_DELETE_FAIL,
  COMPANIES_DETAILS_FAIL,
  COMPANIES_DETAILS_REQUEST,
  COMPANIES_DETAILS_SUCCESS,
  COMPANIES_LIST_FAIL,
  COMPANIES_LIST_REQUEST,
  COMPANIES_LIST_SUCCESS,
  COMPANIES_ADD_FAIL,
  COMPANIES_ADD_REQUEST,
  COMPANIES_ADD_SUCCESS,
  COMPANIES_UPDATE_FAIL,
  COMPANIES_UPDATE_REQUEST,
  COMPANIES_UPDATE_SUCCESS,
  COMPANIES_DELETE_AREA_REQUEST,
  COMPANIES_DELETE_AREA_SUCCESS,
  COMPANIES_DELETE_AREA_FAIL,
} from "../constants/companies.const";

import { logout } from "./user.actions";

export const addCompanies =
  (name, ruc, address, phone, email, description, categoryId, deadlineComplaint, logo, areaIds) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: COMPANIES_ADD_REQUEST,
        });

        const {
          userLogin: { userInfo },
        } = getState();

        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
        const { data } = await api.post(
          "/companies",
          { name, ruc, address, phone, email, description, categoryId, deadlineComplaint, logo, areaIds },
          config
        );

        dispatch({
          type: COMPANIES_ADD_SUCCESS,
          payload: data,
        });
      } catch (error) {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        if (message === "Unauthorized") {
          dispatch(logout());
        }
        dispatch({
          type: COMPANIES_ADD_FAIL,
          payload: message,
        });
      }
    };

export const listCompanies = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: COMPANIES_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.get(`/companies`, config);

    dispatch({
      type: COMPANIES_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: COMPANIES_LIST_FAIL,
      payload: message,
    });
  }
};

export const updateCompany =
  (companyId, name, address, email, description, categoryId, deadlineComplaint, logo, areaIds) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: COMPANIES_UPDATE_REQUEST,
        });

        const {
          userLogin: { userInfo },
        } = getState();

        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        };

        const { data } = await api.patch(
          `/companies/${companyId}`,
          { name, address, email, description, categoryId, deadlineComplaint, logo, areaIds },
          config
        );

        dispatch({
          type: COMPANIES_UPDATE_SUCCESS,
          payload: data,
        });
      } catch (error) {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        if (message === "Unauthorized") {
          dispatch(logout());
        }
        dispatch({
          type: COMPANIES_UPDATE_FAIL,
          payload: message,
        });
      }
    };

export const deleteCompany = (companieId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: COMPANIES_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.delete(`/companies/${companieId}`, config);
    //console.log(data);

    dispatch({
      type: COMPANIES_DELETE_SUCCESS,
      payload: data,
    });
    dispatch(listCompanies());
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: COMPANIES_DELETE_FAIL,
      payload: message,
    });
  }
};

export const deleteCompanyArea = (areaId, companyId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: COMPANIES_DELETE_AREA_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    console.log("datos ", areaId, companyId);
    const { data } = await api.post("/companies/areas", { areaId, companyId }, config);

    dispatch({
      type: COMPANIES_DELETE_AREA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: COMPANIES_DELETE_AREA_FAIL,
      payload: message,
    });
  }
};

export const getCompanyDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: COMPANIES_DETAILS_REQUEST,
    });


    const { data } = await api.get(`/companies/${id}`);


    dispatch({
      type: COMPANIES_DETAILS_SUCCESS,
      payload: data,
    });
    console.log("Data", data)
  } catch (error) {
    dispatch({
      type: COMPANIES_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
