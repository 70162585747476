import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const PaginationComponent = ({
  tablecurrentPage,
  setTablecurrentPage,
  tablepagesCount,
}) => {
  return (
    <Pagination className="pagination-with-border pagination-primary">
      <PaginationItem disabled={tablecurrentPage <= 0}>
        <PaginationLink
          aria-label="anterior"
          onClick={(e) => setTablecurrentPage(e, tablecurrentPage - 1)}
          previous
          href="#top"
        />
      </PaginationItem>
      {[...Array(tablepagesCount)].map((page, i) => (
        <PaginationItem active={i === tablecurrentPage} key={i}>
          <PaginationLink
            onClick={(e) => setTablecurrentPage(e, i)}
            href="#top"
          >
            {i + 1}
          </PaginationLink>
        </PaginationItem>
      ))}
      <PaginationItem disabled={tablecurrentPage >= tablepagesCount - 1}>
        <PaginationLink
          aria-label="siguiente"
          onClick={(e) => setTablecurrentPage(e, tablecurrentPage + 1)}
          next
          href="#top"
        />
      </PaginationItem>
    </Pagination>
  );
};

export default PaginationComponent;
