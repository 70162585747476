export const LABELS_LIST_REQUEST = "LABELS_LIST_REQUEST";
export const LABELS_LIST_SUCCESS = "LABELS_LIST_SUCCESS";
export const LABELS_LIST_FAIL = "LABELS_LIST_FAIL";
export const LABELS_LIST_RESET = "LABELS_LIST_RESET";

export const LABELS_DETAILS_REQUEST = "LABELS_DETAILS_REQUEST";
export const LABELS_DETAILS_SUCCESS = "LABELS_DETAILS_SUCCESS";
export const LABELS_DETAILS_FAIL = "LABELS_DETAILS_FAIL";
export const LABELS_DETAILS_RESET = "LABELS_DETAILS_RESET";

export const LABELS_DELETE_REQUEST = "LABELS_DELETE_REQUEST";
export const LABELS_DELETE_SUCCESS = "LABELS_DELETE_SUCCESS";
export const LABELS_DELETE_FAIL = "LABELS_DELETE_FAIL";
export const LABELS_DELETE_RESET = "LABELS_DELETE_FAIL";

export const LABELS_UPDATE_REQUEST = "LABELS_UPDATE_REQUEST";
export const LABELS_UPDATE_SUCCESS = "LABELS_UPDATE_SUCCESS";
export const LABELS_UPDATE_FAIL = "LABELS_UPDATE_FAIL";
export const LABELS_UPDATE_RESET = "LABELS_UPDATE_RESET";

export const LABELS_ADD_REQUEST = "LABELS_ADD_REQUEST";
export const LABELS_ADD_SUCCESS = "LABELS_ADD_SUCCESS";
export const LABELS_ADD_FAIL = "LABELS_ADD_FAIL";
export const LABELS_ADD_RESET = "LABELS_ADD_RESET";

export const LINKS_LIST_REQUEST = "LINKS_LIST_REQUEST";
export const LINKS_LIST_SUCCESS = "LINKS_LIST_SUCCESS";
export const LINKS_LIST_FAIL = "LINKS_LIST_FAIL";
export const LINKS_LIST_RESET = "LINKS_LIST_RESET";

export const LINKS_DETAILS_REQUEST = "LINKS_DETAILS_REQUEST";
export const LINKS_DETAILS_SUCCESS = "LINKS_DETAILS_SUCCESS";
export const LINKS_DETAILS_FAIL = "LINKS_DETAILS_FAIL";
export const LINKS_DETAILS_RESET = "LINKS_DETAILS_RESET";

export const LINKS_DELETE_REQUEST = "LINKS_DELETE_REQUEST";
export const LINKS_DELETE_SUCCESS = "LINKS_DELETE_SUCCESS";
export const LINKS_DELETE_FAIL = "LINKS_DELETE_FAIL";
export const LINKS_DELETE_RESET = "LINKS_DELETE_FAIL";

export const LINKS_UPDATE_REQUEST = "LINKS_UPDATE_REQUEST";
export const LINKS_UPDATE_SUCCESS = "LINKS_UPDATE_SUCCESS";
export const LINKS_UPDATE_FAIL = "LINKS_UPDATE_FAIL";
export const LINKS_UPDATE_RESET = "LINKS_UPDATE_RESET";

export const LINKS_ADD_REQUEST = "LINKS_ADD_REQUEST";
export const LINKS_ADD_SUCCESS = "LINKS_ADD_SUCCESS";
export const LINKS_ADD_FAIL = "LINKS_ADD_FAIL";
export const LINKS_ADD_RESET = "LINKS_ADD_RESET";
