import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

//IMPORT PAGES
import CheckListDetailsPage from "../screens/checklist/CheckListDetailsPage";
import CompaniesListPage from "../screens/companies/CompaniesListPage";
import CompaniesAddPage from "../screens/companies/CompaniesAddPage";
import CompaniesUpdatePage from "../screens/companies/CompaniesUpdatePage";
import ComplaintsListPage from "../screens/complaints/ListComplaintsPage";
import ComplaintDetailPage from "../screens/complaints/ComplaintDetailsPage";
import ComplaintReportPage from "../screens/complaints/ComplaintReportPage"
import ConductListPage from "../screens/riskMaps/conducts/ConductsListPage";
import ConductAddPage from "../screens/riskMaps/conducts/ConductsAddPage";
import ConductTypeListPage from "../screens/riskMaps/conducts/ConductsTypeList";
import LabelsListPage from "../screens/searcher/labels/LabelsListPage";
import LinksListPage from "../screens/searcher/links/LinksListPage";
import LinksAddPage from "../screens/searcher/links/LinksAddPage";
import QuestionnairesListPage from "../screens/questionnaires/QuestionnairesListPage";
import QuestionnairesAddPage from "../screens/questionnaires/QuestionnairesAddPage";
import QuestionnairesDetailsPage from "../screens/questionnaires/QuestionnariesDetailsPage";
import HomePage from "../screens/HomeScreen";
import RiskMapsListPage from "../screens/riskMaps/RiskMapsListPage";
import RiskMapsDetailsPage from "../screens/riskMaps/RiskMapsDetailsPage";
import RiskMapAddConductPage from "../screens/riskMaps/RiskMapAddConductpage";
import RiskMapAdminPage from "../screens/riskMaps/RiskMapsAdminPage";
import RiskStatsPage from "../screens/riskMaps/RiskMapsResultsPage";
import SearcherPage from "../screens/searcher/SearcherPage";
import Skeleton from "../layout/skeleton/skeleton.component";
import TypologiesListPage from "../screens/riskMaps/typologies/TypologiesListPage";
import UserAddPage from "../screens/users/UserAddPage";
import UserListPage from "../screens/users/UsersListPage";
import UserUpdatePage from "../screens/users/UserUpatePage";
import UserUpdateAdminPage from "../screens/users/UserDetailsPage";
import AlertsListPage from "../screens/alerts/AlertsListPage";
import RepositoryPage from "../screens/repository/RepositoryPage";
import CategoriesListPage from "../screens/companies-categories/CategoriesListPage";
import CategoriesUpdatePage from "../screens/companies-categories/CategoriesUpdatePage";
import CategoriesAddPage from "../screens/companies-categories/CategoriesAddPage";
import RiskMapsReportPage from "../screens/riskMaps/RiskMapsReportPage";
import ComplaintsStatesListPage from "../screens/complaints-states/ComplaintStatesListPage";
import ComplaintStateAddPage from "../screens/complaints-states/ComplaintStateAddPage";
import ComplaintStateUpdatePage from "../screens/complaints-states/ComplaintStateUpdatePage";
import ComplaintActionAddPage from "../screens/complaints-action/ComplaintActionAddPage";
import ComplaintActionUpdatePage from "../screens/complaints-action/ComplaintActionUpdatePage";
import AreasListPage from "../screens/companies-area/AreaListPage";
import AreaAddPage from "../screens/companies-area/AreaAddPage";
import AreaUpdatePage from "../screens/companies-area/AreaUpdatePage";
import CompaniesAdminAreaListPage from "../screens/companies/CompaniesAdminAreaPage";
import ProtectedRoute from "./protected/protected.routes";

const RouterPage = () => {

  return (
    <Skeleton>
      <Routes>

         <Route
              path="*"
              element={<Navigate replace to={"/"} />}
            />
        {/* HOME */}
        <Route path="/" element={<ProtectedRoute roles={["AGENTE DE CUMPLIMIENTO","SUPERADMIN","EMPRESA","SUPERVISOR"]} children={<HomePage/>} />} />
        <Route path="/alertas" element={<AlertsListPage />} />
        <Route path="/buscar" element={<SearcherPage />} />
        <Route path="/checklist/:id" element={<CheckListDetailsPage />} />
        <Route path="/categorias" element={<CategoriesListPage />} />
        <Route path="/categoria/:id" element={<CategoriesUpdatePage />} />
        <Route path="/categoria/agregar" element={<CategoriesAddPage />} />
        <Route path="/estados" element={<ComplaintsStatesListPage />} />
        <Route path="/estados/:id" element={<ComplaintStateUpdatePage />} />
        <Route path="/estados/agregar" element={<ComplaintStateAddPage />} />
        <Route path="/denuncias" element={<ComplaintsListPage />} />
        <Route path="/denuncia/:id" element={<ComplaintDetailPage />} />
        <Route path="/denuncia/reporte/:id" element={<ComplaintReportPage />} />
        <Route path="/denuncia/:id/accion" element={<ComplaintActionAddPage />} />
        <Route path="/denuncia/:id/accion/:actionId" element={<ComplaintActionUpdatePage />} />
        <Route path="/empresas" element={<CompaniesListPage />} />
        <Route path="/empresa/:id" element={<CompaniesUpdatePage />} />
        <Route path="/empresa/areas/:id" element={<CompaniesAdminAreaListPage />} />
        <Route path="/empresa/agregar" element={<CompaniesAddPage />} />
        <Route path="/enlaces" element={<LinksListPage />} />
        <Route path="/enlaces/agregar" element={<LinksAddPage />} />
        <Route path="/cuestionarios" element={<QuestionnairesListPage />} />
        <Route path="/cuestionarios/:id" element={<QuestionnairesDetailsPage />} />
        <Route path="/cuestionarios/agregar" element={<QuestionnairesAddPage />} />
        <Route path="/etiquetas" element={<LabelsListPage />} />
        <Route path="/mapa-de-riesgos" element={<RiskMapsListPage />} />
        <Route path="/mapa-de-riesgos/administrar" element={<RiskMapAdminPage />} />
        <Route path="/mapa-de-riesgos/:id" element={<RiskMapsDetailsPage />} />
        <Route path="/mapa-de-riesgos/agregar-conducta/:id" element={<RiskMapAddConductPage />} />
        <Route path="/mapa-de-riesgos/resultados/:id" element={<RiskStatsPage />} />
        <Route path="/mapa-de-riesgos/tipologias" element={<TypologiesListPage />} />
        <Route path="/mapa-de-riesgos/tipo-conductas" element={<ConductTypeListPage />} />
        <Route path="/mapa-de-riesgos/conductas" element={<ConductListPage />} />
        <Route path="/mapa-de-riesgos/conductas/agregar" element={<ConductAddPage />} />
        <Route path="/mapa-de-riesgos/reporte/:id" element={<RiskMapsReportPage />} />
        <Route path="/repositorio" element={<RepositoryPage />} />
        <Route path="/usuario/actualizar" element={<UserUpdatePage />} />
        <Route path="/usuarios" element={<UserListPage />} />
        <Route path="/usuarios/:id" element={<UserUpdateAdminPage />} />
        <Route path="/usuarios/agregar" element={<UserAddPage />} />

        {/* Área */}
        <Route path="/area" element={<AreasListPage />} />
        <Route path="/area/agregar" element={<AreaAddPage />} />
        <Route path="/area/:id" element={<AreaUpdatePage />} />

      </Routes>
    </Skeleton>
  );
};

export default RouterPage;
