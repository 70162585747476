import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
//constant
import BREADCRUM_DATA from "../../constants/breadcrumb.const";
//import components
import Header from "../../layout/header/header.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";
import { getAreaCompanyDetails, updateAreaCompany } from "../../redux/actions/companies-area.actions";
import { logout } from "../../redux/actions/user.actions";


const AreaUpdatePage = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const areaUpdate = useSelector((state) => state.areaCompanyUpdate);
    const { loading, error, updateResult } = areaUpdate;
    const areaDetail = useSelector((state) => state.areaCompanyDetail);

    const {
        loading: loadingDetails,
        error: errorDetails,
        area,
    } = areaDetail;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        mode: "onChange",
        /* reValidateMode: "onChange", */
        criteriaMode: "firstError",
        shouldFocusError: true,
    });

    const onSubmit = (data) => {
        if (window.confirm("Desea actualizar la información ?")) {
            dispatch(
                updateAreaCompany(
                    params.id,
                    data.name,
                )
            );
        }
    };

    useEffect(() => {
        if (!userInfo) {
            navigate("/login");
        } else if (userInfo.role === "USUARIO") {
            dispatch(logout());
        } else {
            if (!area.name) {
                dispatch(getAreaCompanyDetails(params.id));
            } else {
                if (area.name !== "undefined") {
                    reset({
                        name: area.name,
                    });
                }
            }
        }
        if (updateResult) {
            navigate("/area");
        }
    }, [navigate, updateResult, userInfo, dispatch, params, reset, area]);


    return (
        <>
            <Header
                title="Actualizar Categoría"
                path="Actualizar Categoría"
                pathList={BREADCRUM_DATA.areaCompanyUpdate}
            >
                <button
                    className="btn btn-success btn-icon float-right"
                    onClick={handleSubmit(onSubmit)}
                >
                    <i className="zmdi zmdi-check"></i>
                </button>
            </Header>
            <div className="container-fluid">
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <h2>
                                    Actualizar <strong>información</strong>
                                </h2>
                            </div>
                            <div className="body">
                                {error && (
                                    <div className="mb-3">
                                        <Alert message={error} />
                                    </div>
                                )}
                                {loading && (
                                    <div className="text-center mb-2">
                                        <Loader />
                                    </div>
                                )}
                                {errorDetails ? (
                                    <div className="mb-3">
                                        <Alert message={errorDetails} />
                                    </div>
                                ) : loadingDetails ? (
                                    <div className="text-center mb-2">
                                        <Loader />
                                    </div>
                                ) : (
                                    <div className="row justify-content-center clearfix">
                                        <div className="col-md-7">
                                            <div className="form-group">
                                                <label className="form_label">Nombre:</label>
                                                <input
                                                    style={{ border: errors.name ? "1px solid red" : "" }}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Nombre"
                                                    {...register("name", {
                                                        required: true,
                                                        minLength: 2,
                                                    })}
                                                />
                                                {errors.name?.type === "required" && (
                                                    <span style={{ color: "red", padding: "4px 2px" }}>
                                                        El nombre es requerido.
                                                    </span>
                                                )}
                                                {errors.name?.type === "minLength" && (
                                                    <span style={{ color: "red" }}>
                                                        El nombre debe tener al menos dos caracteres.
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                       
                                        <div className="col-md-7">
                                            <button
                                                className="btn btn-success"
                                                onClick={handleSubmit(onSubmit)}
                                            >
                                                Actualizar Área
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AreaUpdatePage;