import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";

//import components
import Header from "../../layout/header/header.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";
import Snackbar from "../../components/shared/alerts/material-snack-bar/material-snack-bar.component";

//constant
import BREADCRUM_DATA from "../../constants/breadcrumb.const";

//redux

import { useDispatch, useSelector } from "react-redux";
import {
  addQuesionnaires,
  listCompaniesQuestionnaires,
  listUsersQuestionnaires,
} from "../../redux/actions/questionnaires.actions";
import { listPriorities } from "../../redux/actions/priorities.actions";
import { logout } from "../../redux/actions/user.actions";

const QuestionnairesAddPage = () => {
  const [name, setname] = useState("");
  const [userSelected, setUserSelected] = useState("");
  const [userCompanieSelected, setUserCompanieSelected] = useState("");
  const [companieSelected, setComanieSelected] = useState("");
  const [prioritySelected, setPrioritySelected] = useState("");
  const [message, setMessage] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const questionnaryAdd = useSelector((state) => state.questionnaryAdd);
  const { loading, error, addResult } = questionnaryAdd;

  const questionnaryListUsers = useSelector(
    (state) => state.questionnaryListUsers
  );
  const {
    loading: loadingUsers,
    error: errorUsers,
    users,
    companyUsers,
  } = questionnaryListUsers;
  //console.log(userSelected);

  const questionnaryListCompanies = useSelector(
    (state) => state.questionnaryListCompanies
  );
  const {
    loading: loadingCompanies,
    error: errorCompanies,
    companies,
  } = questionnaryListCompanies;

  const prioritiesList = useSelector((state) => state.prioritiesList);
  const {
    loading: loadingPriorities,
    error: errorPriorities,
    priorities,
  } = prioritiesList;

  const currentDay = new Date();
  const [selectedDay, setSelectedDay] = useState(currentDay);
  const [selectedDayCheckList, setSelectedDayCheckList] = useState(currentDay);

  const MONTHS = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Marzo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const WEEKDAYS_LONG = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];

  const modifiersStyles = {
    birthday: {
      color: "white",
      backgroundColor: "#ffc107",
    },
    thursdays: {
      color: "#ffc107",
      backgroundColor: "#fffdee",
    },
    outside: {
      backgroundColor: "white",
    },
  };

  const WEEKDAYS_SHORT = ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"];

  const handleDayClick = (daySelec) => {
    setSelectedDay(daySelec);
  };

  const handleDayClickCheckList = (daySelec) => {
    setSelectedDayCheckList(daySelec);
  };

  const handlePrintSelectedDate = (fecha) => {
    const date = new Date(fecha.toISOString().slice(0, 10) + " 12:00:00");
    return (
      date.toLocaleString("default", {
        weekday: "long",
        timeZone: "UTC",
      }) +
      ", " +
      date.getDate() +
      " de " +
      date.toLocaleString("default", { month: "long" }) +
      " de " +
      date.toLocaleString("default", { year: "numeric" })
    );
  };

  const filterUsers = (inputValue) => {
    return users.filter(
      (i) =>
        i.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        i.lastname.toLowerCase().includes(inputValue.toLowerCase()) ||
        i.email.toLowerCase().includes(inputValue.toLowerCase()) ||
        i.username.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseOptionsUsers = (inputValue) =>
    new Promise((resolve) => {
      resolve(filterUsers(inputValue));
    });

  const filterPriorities = (inputValue) => {
    return priorities.filter(
      (i) =>
        i.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        i.lastname.toLowerCase().includes(inputValue.toLowerCase()) ||
        i.email.toLowerCase().includes(inputValue.toLowerCase()) ||
        i.username.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseOptionsPriorities = (inputValue) =>
    new Promise((resolve) => {
      resolve(filterPriorities(inputValue));
    });

  const filterUsersCompanies = (inputValue) => {
    return companyUsers.filter(
      (i) =>
        i.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        i.lastname.toLowerCase().includes(inputValue.toLowerCase()) ||
        i.email.toLowerCase().includes(inputValue.toLowerCase()) ||
        i.username.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseOptionsUsersCompanies = (inputValue) =>
    new Promise((resolve) => {
      resolve(filterUsersCompanies(inputValue));
    });

  const filterCompanies = (inputValue) => {
    return companies.filter(
      (i) =>
        i.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        i.ruc.toLowerCase().includes(inputValue.toLowerCase()) ||
        i.email.toLowerCase().includes(inputValue.toLowerCase()) ||
        i.description.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseOptionsCompanies = (inputValue) =>
    new Promise((resolve) => {
      resolve(filterCompanies(inputValue));
    });

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else if (userInfo.role === "USUARIO") {
      dispatch(logout());
    } else {
      if (addResult) {
        navigate("/cuestionarios");
      }
      dispatch(listUsersQuestionnaires());
      dispatch(listCompaniesQuestionnaires());
      dispatch(listPriorities());
    }
    /* if (addResult) {
      navigate("/empresas");
    } */
  }, [navigate, userInfo, dispatch, addResult]);

  const handleSubmit = (e) => {
    window.scrollTo(0, 0);
    e.preventDefault();
    if (
      selectedDay &&
      selectedDayCheckList &&
      userSelected &&
      userCompanieSelected &&
      companieSelected &&
      name
    ) {
      dispatch(
        addQuesionnaires(
          name,
          selectedDay.toISOString().slice(0, 10),
          selectedDayCheckList.toISOString().slice(0, 10),
          userCompanieSelected,
          userSelected,
          companieSelected,
          prioritySelected
        )
      );
    } else {
      setMessage("Por favor llene todos los campos requeridos.");
    }
    //console.log(selectedDay.toISOString().slice(0, 10));
    //console.log(userSelected);
    //console.log(userCompanieSelected);
    //console.log(companieSelected);
    //console.log(name);
  };
  return (
    <>
      {" "}
      <Header
        title="Crear Cuestionario"
        path="Crear Cuestionario"
        pathList={BREADCRUM_DATA.questionnairesAdd}
      >
        {message && <Snackbar message="Por favor llene todos los campos." />}
        <button
          className="btn btn-success btn-icon float-right right_icon_toggle_btn"
          /* onClick={handleSubmit(onSubmit)} */
        >
          <i className="zmdi zmdi-check"></i>
        </button>
      </Header>
      {error && <Snackbar message={error} />}
      {loading && <Loader />}
      {errorCompanies || errorUsers || errorPriorities ? (
        <Alert message="Algo salió mal, por favor, intentelo más tarde." />
      ) : loadingUsers || loadingCompanies || loadingPriorities ? (
        <div className="text-center mt-5">
          <Loader />
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-md-12">
              <div className="card">
                <div className="header">
                  <h2>
                    Ingresar información del nuevo <strong>cuestionario</strong>
                  </h2>
                </div>
                <div className="body">
                  <h6>1. Ingresar un nombre para el cuestionario</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nombre"
                    value={name}
                    onChange={(e) => {
                      setname(e.target.value);
                    }}
                  />
                  <hr />
                  <h6>2. Seleccionar un supervisor y una empresa</h6>
                  <div className="row clearfix">
                    <div className="col-lg-4 col-md-12 mt-3">
                      <div className="form-group">
                        <label className="form_label">Supervisor:</label>
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          placeholder="Seleccione una opción"
                          loadOptions={promiseOptionsUsers}
                          getOptionLabel={(option) =>
                            `${option.name} ${option.lastname} - ${option.email}`
                          }
                          getOptionValue={(e) => e.id}
                          /*  onInputChange={(e) =>
                            setUserSelected(e.id ? e.id : "")
                          } */
                          onChange={(e) => setUserSelected(e.id ? e.id : "")}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12 mt-3">
                      <div className="form-group">
                        <label className="form_label">USUARIO EMPRESA:</label>
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          placeholder="Seleccione una opción"
                          loadOptions={promiseOptionsUsersCompanies}
                          getOptionLabel={(option) =>
                            `${option.name} ${option.lastname} - ${option.email}`
                          }
                          getOptionValue={(e) => e.id}
                          /* onInputChange={(e) =>
                            setUserCompanieSelected(e.id ? e.id : "")
                          } */
                          onChange={(e) =>
                            setUserCompanieSelected(e.id ? e.id : "")
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12 mt-3">
                      <div className="form-group">
                        <label className="form_label">EMPRESA:</label>
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          placeholder="Seleccione una opción"
                          loadOptions={promiseOptionsCompanies}
                          getOptionLabel={(option) =>
                            `${option.name} - ${option.ruc}`
                          }
                          getOptionValue={(e) => e.id}
                          /* onInputChange={(e) =>
                            setComanieSelected(e.id ? e.id : "")
                          } */
                          onChange={(e) => setComanieSelected(e.id ? e.id : "")}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <h6>3. Seleccionar una prioridad</h6>
                  <div className="row clearfix">
                    <div className="col-lg-8 col-md-12 mt-3">
                      <div className="form-group">
                        <label className="form_label">Prioridad:</label>
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          placeholder="Seleccione una opción"
                          loadOptions={promiseOptionsPriorities}
                          getOptionLabel={(option) => `${option.name}`}
                          getOptionValue={(e) => e.id}
                          /*  onInputChange={(e) =>
                            setUserSelected(e.id ? e.id : "")
                          } */
                          onChange={(e) =>
                            setPrioritySelected(e.id ? e.id : "")
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div className="row clearfix">
                    <div className="col-lg-6 col-md-12 mt-3">
                      <label className="form_label mb-2">
                        4. Fecha Límite Cuestionario:
                      </label>
                      <div className="header">
                        <h2>Fecha Seleccionada:</h2>
                        <strong>{handlePrintSelectedDate(selectedDay)}</strong>
                      </div>
                      <DayPicker
                        onDayClick={handleDayClick}
                        selectedDays={selectedDay}
                        months={MONTHS}
                        weekdaysLong={WEEKDAYS_LONG}
                        weekdaysShort={WEEKDAYS_SHORT}
                        modifiersStyles={modifiersStyles}
                        className="daypicker"
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 mt-3">
                      <label className="form_label mb-2">
                        4. Fecha Límite Checklist:
                      </label>
                      <div className="header">
                        <h2>Fecha Seleccionada:</h2>
                        <strong>
                          {handlePrintSelectedDate(selectedDayCheckList)}
                        </strong>
                      </div>
                      <DayPicker
                        onDayClick={handleDayClickCheckList}
                        selectedDays={selectedDayCheckList}
                        months={MONTHS}
                        weekdaysLong={WEEKDAYS_LONG}
                        weekdaysShort={WEEKDAYS_SHORT}
                        modifiersStyles={modifiersStyles}
                        className="daypicker"
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="col-md-12">
                    <button className="btn btn-success" onClick={handleSubmit}>
                      Crear Cuestionario
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default QuestionnairesAddPage;
