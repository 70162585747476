export const COMPANIES_LIST_REQUEST = "COMPANIES_LIST_REQUEST";
export const COMPANIES_LIST_SUCCESS = "COMPANIES_LIST_SUCCESS";
export const COMPANIES_LIST_FAIL = "COMPANIES_LIST_FAIL";
export const COMPANIES_LIST_RESET = "COMPANIES_LIST_RESET";

export const COMPANIES_DETAILS_REQUEST = "COMPANIES_DETAILS_REQUEST";
export const COMPANIES_DETAILS_SUCCESS = "COMPANIES_DETAILS_SUCCESS";
export const COMPANIES_DETAILS_FAIL = "COMPANIES_DETAILS_FAIL";
export const COMPANIES_DETAILS_RESET = "COMPANIES_DETAILS_RESET";

export const COMPANIES_DELETE_REQUEST = "COMPANIES_DELETE_REQUEST";
export const COMPANIES_DELETE_SUCCESS = "COMPANIES_DELETE_SUCCESS";
export const COMPANIES_DELETE_FAIL = "COMPANIES_DELETE_FAIL";
export const COMPANIES_DELETE_RESET = "COMPANIES_DELETE_RESET";

export const COMPANIES_UPDATE_REQUEST = "COMPANIES_UPDATE_REQUEST";
export const COMPANIES_UPDATE_SUCCESS = "COMPANIES_UPDATE_SUCCESS";
export const COMPANIES_UPDATE_FAIL = "COMPANIES_UPDATE_FAIL";
export const COMPANIES_UPDATE_RESET = "COMPANIES_UPDATE_RESET";

export const COMPANIES_ADD_REQUEST = "COMPANIES_ADD_REQUEST";
export const COMPANIES_ADD_SUCCESS = "COMPANIES_ADD_SUCCESS";
export const COMPANIES_ADD_FAIL = "COMPANIES_ADD_FAIL";
export const COMPANIES_ADD_RESET = "COMPANIES_ADD_RESET";

//Delete Area of to companies...
export const COMPANIES_DELETE_AREA_REQUEST = "COMPANIES_DELETE_AREA_REQUEST";
export const COMPANIES_DELETE_AREA_SUCCESS = "COMPANIES_DELETE_AREA_SUCCESS";
export const COMPANIES_DELETE_AREA_FAIL = "COMPANIES_DELETE_AREA_FAIL";
export const COMPANIES_DELETE_AREA_RESET = "COMPANIES_DELETE_AREA_RESET";
