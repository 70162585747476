import React from 'react';
import { Navigate } from 'react-router-dom';

/* interface ProtectedRouteProps extends Omit<RouteProps, 'component'> {
    roles: string[];
    children: React.ReactNode;
} */

const ProtectedRoute = ({ roles, children }) => {
    
    const userInfoFromStorage = localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : null;

    if (userInfoFromStorage) {
        if (userInfoFromStorage?.role === "SUPERADMIN") {
            return children;
        }else{
            return <Navigate to={"/estados"} />;
        } 
    }else{
        return <Navigate to = {"/denunciar/1"}/>
    }

};

export default ProtectedRoute;