import api from "../../api/rigori.api";

import {
  TYPOLOGIES_DELETE_REQUEST,
  TYPOLOGIES_DELETE_SUCCESS,
  TYPOLOGIES_DELETE_FAIL,
  TYPOLOGIES_LIST_FAIL,
  TYPOLOGIES_LIST_REQUEST,
  TYPOLOGIES_LIST_SUCCESS,
  TYPOLOGIES_ADD_FAIL,
  TYPOLOGIES_ADD_REQUEST,
  TYPOLOGIES_ADD_SUCCESS,
} from "../constants/typologies.const";

import { logout } from "./user.actions";

export const addTypologies = (name) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TYPOLOGIES_ADD_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await api.post("/typologies", { name }, config);

    dispatch({
      type: TYPOLOGIES_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: TYPOLOGIES_ADD_FAIL,
      payload: message,
    });
  }
};

export const listTypologies = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: TYPOLOGIES_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.get(`/typologies`, config);

    dispatch({
      type: TYPOLOGIES_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: TYPOLOGIES_LIST_FAIL,
      payload: message,
    });
  }
};

export const deleteTypology = (typologieId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TYPOLOGIES_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.delete(`/typologies/${typologieId}`, config);
    //console.log(data);

    dispatch({
      type: TYPOLOGIES_DELETE_SUCCESS,
      payload: data,
    });
    dispatch(listTypologies());
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: TYPOLOGIES_DELETE_FAIL,
      payload: message,
    });
  }
};
