import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

//import components
import Header from "../../layout/header/header.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";
import Select from "react-select";

//redux import
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/user.actions";
import {
	updateCompany,
	getCompanyDetails,
} from "../../redux/actions/companies.actions";
import { listCategoriesCompanies } from "../../redux/actions/companies-categories.actions";

//constant
import BREADCRUM_DATA from "../../constants/breadcrumb.const";
import { listAreaCompanies } from "../../redux/actions/companies-area.actions";
import DropZoneUploader from "../../components/shared/uploaders/single-uploader/dropzone-uploader";
import SelectedFilesList from "../../components/files/selected-files-list/selected-files-list";
import { useCallback } from "react";
import { addImage } from "../../redux/actions/image.actions";

const CompanyUpdatePage = () => {
	const navigate = useNavigate();
	const params = useParams();
	const dispatch = useDispatch();

	const companyUpdate = useSelector((state) => state.companyUpdate);
	const { loading, error, updateResult } = companyUpdate;
	const companyDetail = useSelector((state) => state.companyDetail);
	const {
		loading: loadingDetails,
		error: errorDetails,
		company,
	} = companyDetail;

	const categoriesList = useSelector((state) => state.categoryCompanyList);
	const {
		loading: loadingCategories,
		error: errorCategories,
		categories,
	} = categoriesList;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;
	const imageList = useSelector((state) => state.addImage)
	const { loading: loadingImage, error: errorImage, addImageResult } = imageList

	const [change, setChange] = useState(false);


	/* 	const deleteAreaCompany = useSelector((state) => state.companyAreaDelete);
		const {
			loading: loadingDeleteArea,
			error: errorAreaCompany,
			deleteAreaCompanyResult,
		} = deleteAreaCompany; */

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
		reset,
	} = useForm({
		mode: "onChange",
		/* reValidateMode: "onChange", */
		criteriaMode: "firstError",
		shouldFocusError: true,
	});

	const [uploadFiles, setUploadedFiles] = useState([]);

	const onDrop = useCallback(
		(acceptedFiles) => {
			const files = acceptedFiles.map((file) => file);
			setUploadedFiles([].concat(...uploadFiles, files));
		},
		[uploadFiles],
	);

	const handleDeleteSingleFile = (e, file) => {
		console.log("Eliminado..");
		e.preventDefault();
		setUploadedFiles(
			uploadFiles.filter(function (obj) {
				return obj.path !== file.path;
			})
		);
	};

	const onSubmit = (data) => {
		if (window.confirm("Desea actualizar la información ?")) {
			addImageResult?.url ? dispatch(
				updateCompany(
					params.id,
					data.name,
					data.address,
					data.email,
					data.description,
					data.categoryId,
					data.deadlineComplaint,
					addImageResult.url,
					data.areaIds,
				)
			) :
				dispatch(
					updateCompany(
						params.id,
						data.name,
						data.address,
						data.email,
						data.description,
						data.categoryId,
						data.deadlineComplaint,
						company.url,
						data.areaIds,
					)
				);

		}
	};


	useEffect(() => {
		if (!userInfo) {
			navigate("/login");
		} else if (userInfo.role === "USUARIO") {
			dispatch(logout());
		} else {
			if (updateResult) {
				navigate("/empresas");
			} else {

				if (!company.name) {
					dispatch(getCompanyDetails(params.id));
					dispatch(listCategoriesCompanies());
					dispatch(listAreaCompanies())
				} else {
					if (company.name !== "undefined") {
						if (uploadFiles.length !== 0) {
							dispatch(addImage(uploadFiles[0]))

						}
						reset({
							name: company.name,
							address: company.address,
							email: company.email,
							description: company.description,
							categoryId: company.category.id,
							deadlineComplaint: company.deadlineComplaint,
						});
					}
				}
			}
		}

	}, [navigate, updateResult, userInfo, dispatch, params, reset, company, uploadFiles]);
	return (
		<>
			<Header
				title="Actualizar Empresa"
				path="Actualizar Empresa"
				pathList={BREADCRUM_DATA.companyUpdate}
			>
				<button
					className="btn btn-success btn-icon float-right right_icon_toggle_btn"
					onClick={handleSubmit(onSubmit)}
				>
					<i className="zmdi zmdi-check"></i>
				</button>
			</Header>
			<div className="container-fluid">
				<div className="row clearfix">
					<div className="col-md-12">
						<div className="card">
							<div className="header">
								<h2>
									Actualizar <strong>información</strong>
								</h2>
							</div>
							<div className="body">
								{errorImage && (
									<div className="mb-3">
										<Alert message={errorImage} />
									</div>
								)}

								{error && (
									<div className="mb-3">
										<Alert message={error} />
									</div>
								)}
								{errorCategories && (
									<div className="mb-3">
										<Alert message={errorCategories} />
									</div>
								)}
								{((loading && loadingCategories) || (loadingImage)) && (
									<div className="text-center mb-2">
										<Loader />
									</div>
								)}

								{errorDetails ? (
									<div className="mb-3">
										<Alert message={errorDetails} />
									</div>
								) : loadingDetails ? (
									<div className="text-center mb-2">
										<Loader />
									</div>
								) : (
									<div className="row clearfix">
										<div className="col-lg-6 col-md-12">
											<div className="form-group">
												<label className="form_label">
													Nombre:
												</label>
												<input
													style={{
														border: errors.name
															? "1px solid red"
															: "",
													}}
													type="text"
													className="form-control"
													placeholder="Nombre"
													{...register("name", {
														required: true,
														minLength: 2,
													})}
												/>
												{errors.name?.type ===
													"required" && (
														<span
															style={{
																color: "red",
																padding: "4px 2px",
															}}
														>
															El nombre es requerido.
														</span>
													)}
												{errors.name?.type ===
													"minLength" && (
														<span
															style={{ color: "red" }}
														>
															El nombre debe tener al
															menos dos caracteres.
														</span>
													)}
											</div>
										</div>

										<div className="col-lg-6 col-md-12">
											<label className="form_label">
												Categoría de la empresa:
											</label>
											<select
												className="form-control show-tick"
												{...register("categoryId", {
													required: true,
												})}
											>
												<option value="">
													Categoría de la empresa
												</option>
												{categories &&
													categories.map(
														(category, index) => (
															<option
																key={index}
																value={
																	category.id
																}
															>
																{category.name}
															</option>
														)
													)}
											</select>
											{errors.categoryId?.type ===
												"required" && (
													<span
														style={{
															color: "red",
															padding: "4px 2px",
														}}
													>
														La categoría es requerida.
													</span>
												)}
										</div>

										<div className="col-lg-5 col-md-12">
											<div className="form-group">
												<label className="form_label">
													Dirección:
												</label>
												<input
													style={{
														border: errors.address
															? "1px solid red"
															: "",
													}}
													type="text"
													className="form-control"
													placeholder="Dirección"
													{...register("address", {
														required: true,
														minLength: 6,
													})}
												/>
												{errors.address?.type ===
													"required" && (
														<span
															style={{
																color: "red",
																padding: "4px 2px",
															}}
														>
															El nombre de usuario es
															requerido.
														</span>
													)}
												{errors.address?.type ===
													"minLength" && (
														<span
															style={{ color: "red" }}
														>
															El nombre de usuario
															debe tener al menos dos
															caracteres.
														</span>
													)}
											</div>
										</div>

										<div className="col-lg-4 col-md-12">
											<div className="form-group">
												<label className="form_label">
													Tiempo máximo de calificación de denuncias (días)
												</label>
												<input
													style={{
														border: errors.deadlineComplaint
															? "1px solid red"
															: "",
													}}
													type="text"
													className="form-control"
													{...register("deadlineComplaint", {
														required: true,
													})}
												/>
												{errors.deadlineComplaint?.type === "required" && (
													<span className="d-block" style={{ color: "red" }}>
														Este campo es requerido.
													</span>
												)}
											</div>
										</div>

										<div className="col-lg-3 col-md-12">
											<div className="form-group">
												<label className="form_label">
													Correo:
												</label>
												<input
													style={{
														border: errors.email
															? "1px solid red"
															: "",
													}}
													type="email"
													className="form-control"
													placeholder="Correo"
													{...register("email", {
														required: true,

														pattern: /\S+@\S+\.\S+/,
													})}
												/>
												{errors.email?.type ===
													"required" && (
														<span
															style={{ color: "red" }}
														>
															El correo es requerido.
														</span>
													)}
												{errors.email?.type ===
													"pattern" && (
														<span
															style={{ color: "red" }}
														>
															El valor ingresado no
															coincide con el formato
															de correo electrónico
														</span>
													)}
											</div>
										</div>


										<div className="col-lg-12 col-md-12">
											{
												company.areasAvailables && company.areasAvailables.length !== 0 ? (
													<>
														<Controller
															name="areaIds"
															rules={
																{ required: false, }
															}
															control={control}
															render={({
																field: { onChange, name }
															}) => (
																<>
																	<Select
																		name={name}
																		theme={theme => ({
																			...theme,
																			colors: {
																				...theme.colors,
																				neutral50: '#6c757d',  // Placeholder color
																			},
																		})}
																		styles={{

																			control: (base, state) => ({
																				...base,

																				border: '1px solid lightgray', // default border color
																				boxShadow: `0 0 0 1px '#E67E22'`, // no box-shadow
																			}),
																			option: (basename, state) => ({
																				...basename,
																				":hover": { backgroundColor: '#ffda9e' }
																			})
																		}}
																		placeholder="Seleccione una Área"
																		options={company.areasAvailables}
																		isMulti
																		noOptionsMessage={() => "Área no registrada..."}
																		getOptionLabel={(area) => area.name}
																		getOptionValue={(area) => String(area.id)}
																		onChange={(value) => {
																			/* if (action.removedValue) {
																				dispatch(deleteCompanyArea(action.removedValue?.id, Number(params.id)))
																				onChange(value.map((value) => value.id));
																			} else {
																			} */
																			onChange(value.map((value) => value.id));
																		}}
																	/>
																</>
															)}
														/>
														<div className='col-12'>
															{errors.areaIds?.type === "required" && <span style={{ color: "red" }}>El Área es requerido.</span>}
														</div>
													</>

												) : (
													<span><b>No tiene áreas disponibles...</b></span>
												)
											}


										</div>

										<div className="col-lg-6 col-md-12 mt-2">
											<div className="form-group">
												<label className="form_label">
													Descripción:
												</label>
												<textarea
													style={{
														border: errors.description
															? "1px solid red"
															: "",
													}}
													type="text-area"
													rows="6"
													className="form-control"
													placeholder="Descripción"
													{...register(
														"description",
														{
															required: true,

															minLength: 6,
														}
													)}
												/>
												{errors.description?.type ===
													"required" && (
														<span
															style={{ color: "red" }}
														>
															La descripción es
															requerida.
														</span>
													)}
												{errors.description?.type ===
													"minLength" && (
														<span
															style={{ color: "red" }}
														>
															La descripción debe
															tener al menos 6
															caracteres.
														</span>
													)}
											</div>
										</div>

										<div className="col-lg-6 col-md-12 mt-2">{
											!change ? (
												<>
													<div className="row">
														<div className="col-12 text-center">
															<img src={company.logo} style={{ maxHeight: "150px", maxWidth: "150px" }} className="img-thumbnail" alt="Image_Icon" />
														</div>
														<div className="col-12 text-center">
															<button className="btn btn-danger btn-md"
																onClick={() => {
																	setChange(true);
																}}
															> Eliminar</button>
														</div>
													</div>
												</>
											) : (
												<>
													<label className="form_label">Cargar Logo</label>
													<div className="mb-3 justify-content-center">
														{
															uploadFiles.length === 0 ? (<DropZoneUploader onDrop={onDrop} />) : ("")
														}
														{uploadFiles.length > 0 ? (
															<>
																<SelectedFilesList uploadFiles={uploadFiles} handleDeleteSingleFile={handleDeleteSingleFile} />
															</>
														) : null}
													</div>
												</>
											)
										}

										</div>

										<div className="col-md-12">
											<button
												disabled={errorImage}
												className="btn btn-success"
												onClick={handleSubmit(onSubmit)}
											>
												Actualizar Empresa
											</button>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CompanyUpdatePage;
