import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

//import components

import Loader from "../../shared/loaders/spinner/sppinner.component";
import Alert from "../../shared/alerts/material-snack-bar/material-snack-bar.component";
import DropZoneUploader from "../../shared/uploaders/single-uploader/dropzone-uploader";
import SelectedFilesList from "../../files/selected-files-list/selected-files-list";
import FileCardComponent from "../../files/files-card/files-card.component";
//redux import
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../../redux/actions/user.actions";
import {
  getChecklistFiles,
  addFile,
  deleteFile,
} from "../../../redux/actions/checklist.actions";
import { CHECKLIST_DELETE_FILE_RESET } from "../../../redux/constants/checklist.const";

const FileEdit = ({ id, category, question, onCancel, resetMessage }) => {
  const [uploadFiles, setUploadedFiles] = useState([]);
  const onDrop = useCallback(
    (acceptedFiles) => {
      const files = acceptedFiles.map((file) => file);
      setUploadedFiles([].concat(...uploadFiles, files));
    },
    [uploadFiles]
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const checklistListItemFiles = useSelector(
    (state) => state.checklistListItemFiles
  );
  const {
    loading: loadinGetFiles,
    error: errorGetFiles,
    files,
  } = checklistListItemFiles;
  const checklistDeteleFile = useSelector((state) => state.checklistDeteleFile);
  const {
    loading: loadinDeleteFiles,
    error: errorDaeleteFiles,
    deleteResult,
  } = checklistDeteleFile;

  const checklistAddFile = useSelector((state) => state.checklistAddFile);
  const { loading, error, addResult } = checklistAddFile;

  // console.log(uploadFiles);
  const submitHandler = (e) => {
    window.scrollTo(0, 0);
    e.preventDefault();
    dispatch(addFile(id, uploadFiles));

    /* dispatch(
              addComplaints(uploadFiles, company, subject, anonymous, description)
            ); */
  };
  const handleDeleteSingleFile = (e, file) => {
    e.preventDefault();
    setUploadedFiles(
      uploadFiles.filter(function (obj) {
        return obj.path !== file.path;
      })
    );
  };

  useEffect(() => {
    if (!userInfo) {
      dispatch(logout());
      navigate("/login");
    } else {
      resetMessage();

      dispatch({ type: CHECKLIST_DELETE_FILE_RESET });
      dispatch(getChecklistFiles(id));
    }
    if (addResult) {
      onCancel();
    }
  }, [navigate, userInfo, dispatch, onCancel, resetMessage, id, addResult]);

  const handleDelete = (e, id) => {
    e.preventDefault();
    if (window.confirm("Esta acción no se puede deshacer.")) {
      dispatch(deleteFile(id));
    }
  };
  return (
    <div className="container-fluid">
      <div className="row clearfix">
        <div className="col-md-12">
          <div className="card">
            <div className="header">
              <h2>
                <strong>Archivos</strong>
              </h2>
            </div>
            <p>
              <strong>Categoría:</strong> {category}
            </p>
            <p>
              <strong>Pregunta:</strong> {id}.- {question}
            </p>
            <div className="">
              {(loading || loadinDeleteFiles) && (
                <div className="mt-2 mb-2">
                  <Loader />
                </div>
              )}
              {(error || errorDaeleteFiles) && (
                <div className="mt-2 mb-2">
                  <Alert message={error} />
                </div>
              )}
              {deleteResult && (
                <Alert
                  message="Archivo Eliminado exitosamente"
                  severity="info"
                />
              )}
              {loadinGetFiles ? (
                <div className="mt-2 mb-2 text-center">
                  <Loader />
                </div>
              ) : errorGetFiles ? (
                <div className="mt-2 mb-2">
                  <Alert message={errorGetFiles} />
                </div>
              ) : (
                <div className="content file_manager">
                  <div className="card">
                    <h6 className="pl-2 pb-1">
                      <strong>archivos adjuntos</strong>
                    </h6>

                    <div className="row clearfix">
                      {files ? (
                        files.length > 0 ? (
                          files.map((file, index) => (
                            <div
                              className="col-lg-4 col-md-6 col-sm-12"
                              key={index}
                            >
                              <FileCardComponent
                                file={file}
                                isWithBackground={true}
                                hasDelete={
                                  userInfo
                                    ? userInfo.role !== "EMPRESA"
                                      ? true
                                      : null
                                    : null
                                }
                                isEditable={
                                  userInfo
                                    ? userInfo.role !== "EMPRESA"
                                      ? true
                                      : null
                                    : null
                                }
                                handleDelete={
                                  userInfo
                                    ? userInfo.role !== "EMPRESA"
                                      ? handleDelete
                                      : null
                                    : null
                                }
                              />
                            </div>
                          ))
                        ) : (
                          <p>
                            {" "}
                            Todavía no existen archivos adjuntos en esta
                            pregunta.
                          </p>
                        )
                      ) : (
                        "No existen archivos adjuntos en esta pregunta."
                      )}
                    </div>
                  </div>
                </div>
              )}
              {userInfo ? (
                userInfo.role !== "EMPRESA" ? (
                  <div className="row clearfix">
                    <div className="col-lg-12 col-md-12">
                      <div className="input-group mb-3">
                        <div className="form-group">
                          <label className="form_label">
                            Cargar Archivos :
                          </label>
                          <DropZoneUploader onDrop={onDrop} />

                          {uploadFiles.length > 0 ? (
                            <>
                              <SelectedFilesList
                                uploadFiles={uploadFiles}
                                handleDeleteSingleFile={handleDeleteSingleFile}
                              />
                            </>
                          ) : null}
                          <button
                            onClick={submitHandler}
                            className="btn btn-rigori btn-block waves-effect waves-light"
                          >
                            <strong>CARGAR ARCHIVOS</strong>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileEdit;
