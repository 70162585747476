import React, { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";

//import components
import Header from "../../layout/header/header.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";
import DropZoneUploader from "../../components/shared/uploaders/single-uploader/dropzone-uploader";
import SelectedFilesList from "../../components/files/selected-files-list/selected-files-list";

//import redux ;
import { useDispatch, useSelector } from "react-redux";
import { changeActiveSidebarItem } from "../../redux/actions/navigation.actions";
import { listCategoriesCompanies } from "../../redux/actions/companies-categories.actions";
import { REPOSITORY_ADD_FILE_RESET, REPOSITORY_DELETE_FILE_RESET } from "../../redux/constants/repository.const";
import { getRepositoryFiles, addFile, deleteFile } from "../../redux/actions/repository.actions";

const RepositoryPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const [message, setMessage] = useState(null);
	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;
	const filesList = useSelector((state) => state.repositoryFiles);
	const { loading, error, files } = filesList;
	const categoriesList = useSelector((state) => state.categoryCompanyList);
	const { loading: loadingCategories, error: errorCategories, categories } = categoriesList;
	const repositoryAddFile = useSelector((state) => state.repositoryAddFile);
	const { loading: loadingAddFile, error: errorAddFile, addResult } = repositoryAddFile;
	const repositoryDeteleFile = useSelector((state) => state.repositoryDeteleFile);
	const { loading: loadingDeleteFile, error: errorDeleteFile, deleteResult } = repositoryDeteleFile;
	const [uploadFiles, setUploadedFiles] = useState([]);

	const onDrop = useCallback(
		(acceptedFiles) => {
			const files = acceptedFiles.map((file) => file);
			setUploadedFiles([].concat(...uploadFiles, files));
		},
		[uploadFiles]
	);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		mode: "onChange",
		criteriaMode: "firstError",
		shouldFocusError: true,
	});

	const submitHandler = (data) => {
		dispatch(addFile(data.categoryId, uploadFiles));
	};

	const handleDeleteSingleFile = (e, file) => {
		e.preventDefault();
		setUploadedFiles(
			uploadFiles.filter(function (obj) {
				return obj.path !== file.path;
			})
		);
	};

	const handleDeleteFile = (fileId) => {
		if (window.confirm("Esta acción no se puede deshacer.")) {
			dispatch(deleteFile(fileId));
		}
	};

	useEffect(() => {
		dispatch(changeActiveSidebarItem(location.pathname));
		if (!userInfo) {
			navigate("/login");
		} else {
			if (addResult) {
				setMessage("Archivo agregado con éxito");
				setUploadedFiles([]);
				navigate("/repositorio");
				reset({
					categoryId: null,
				});
			}
			if (deleteResult) {
				setMessage("Archivo eliminado con éxito");
				navigate("/repositorio");
			}
			dispatch({ type: REPOSITORY_ADD_FILE_RESET });
			dispatch({ type: REPOSITORY_DELETE_FILE_RESET });
			if (userInfo.role !== "EMPRESA") {
				dispatch(listCategoriesCompanies());
			}
			dispatch(getRepositoryFiles());
		}
	}, [navigate, userInfo, dispatch, location, reset, addResult, deleteResult]);

	return (
		<>
			<Header title="Repositorio de Rigori" path="Repositorio de Rigori" />
			{message && (
				<div className="mb-2 mt-2">
					<Alert message={message} severity="info" />
				</div>
			)}
			{errorDeleteFile && <Alert message={errorDeleteFile} severity="error" />}
			{errorAddFile && <Alert message={errorAddFile} severity="error" />}
			{deleteResult && <Alert message="Archivo Eliminado exitosamente" severity="info" />}
			{errorCategories && (
				<div className="mb-3">
					<Alert message={errorCategories} />
				</div>
			)}
			{error ? (
				<div className="mb-2 mt-2">
					<Alert message={error} />
				</div>
			) : (loading || loadingCategories || loadingDeleteFile || loadingAddFile) ? (
				<div className="content file_manager">
					<div className="container-fluid">
						<div className="row text-center">
							<div className="col-lg-12">
								<div className="card">
									<Loader />
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className="content file_manager">
					{userInfo ? (
						userInfo.role !== "EMPRESA" ? (
							<div className="container-fluid">
								<div className="card">
									<div className="row justify-content-center clearfix">
										<div className="col-md-8 pb-3">
											<label className="form_label">Categoría de la empresa:</label>
											<select
												className="form-control show-tick"
												{...register("categoryId", {
													required: true,
												})}
											>
												<option value="">Categoría de la empresa</option>
												{categories &&
													categories.map((category, index) => (
														<option key={index} value={category.id}>
															{category.name}
														</option>
													))}
											</select>
											{errors.categoryId?.type === "required" && (
												<span
													style={{
														color: "red",
														padding: "4px 2px",
													}}
												>
													La categoría es requerida.
												</span>
											)}
										</div>

										<div className="col-md-8">
											<div className="input-group mb-3" style={{ display: "block" }}>
												<div className="form-group">
													<label className="form_label">Cargar Archivos :</label>
													<DropZoneUploader onDrop={onDrop} />
													{uploadFiles.length > 0 ? (
														<>
															<SelectedFilesList
																uploadFiles={uploadFiles}
																handleDeleteSingleFile={handleDeleteSingleFile}
															/>
															{uploadFiles.length > 5 && (
																<span
																	style={{
																		color: "red",
																		padding: "4px 2px",
																	}}
																>
																	Solo puedes cargar cinco archivos a la vez.
																</span>
															)}
														</>
													) : null}
													<button
														disabled={!uploadFiles?.length || uploadFiles.length > 5}
														onClick={handleSubmit(submitHandler)}
														className="btn btn-rigori btn-block waves-effect waves-light"
													>
														<strong>CARGAR ARCHIVOS</strong>
													</button>
												</div>
											</div>
										</div>

										<div className="col-md-8">
											<small>* Recuerda que solo puedes cargar máximo cinco archivos por categoría</small>
										</div>
									</div>
								</div>
							</div>
						) : null
					) : null}
					<div className="container-fluid">
						<div className="row clearfix">
							<div className="col-lg-12">
								<p>* Aquí puedes encontrar un repositorio de archivos ordenado por categoría de empresa</p>
								{Object.keys(files).map((item, index) => (
									<>
										<h6>{item}</h6>
										<div className="row clearfix pt-2" key={index}>
											{!files[item]?.length ? (
												<div className="col-md-12 pb-3">
													<small>No hay archivos en esta categoría</small>
												</div>
											) : (
												files[item].map((file, i) => (
													<div key={i} className="col-lg-3 col-md-4 col-sm-12">
														<div className="card">
															<div className="file">
																<div className="hover">
																	<button
																		onClick={() => handleDeleteFile(file.file.id)}
																		type="button"
																		className="btn btn-icon btn-icon-mini btn-round btn-danger"
																	>
																		<i className="zmdi zmdi-delete"></i>
																	</button>
																</div>
																<div className="icon">
																	<i className="zmdi zmdi-file-text"></i>
																</div>
																<div className="file-name">
																	<a
																		href={file.file.url}
																		target="_blank"
																		rel="noopener noreferrer"
																		className="m-b-5 text-muted"
																	>
																		{file.file.name.length > 40
																			? file.file.name.slice(0, 25) + `... ${file.file.type}`
																			: `${file.file.name}`}
																	</a>
																	<small>
																		{file.file.size / 100} KB
																		<span className="date text-muted">
																			{new Date(file.file.createdAt).toLocaleDateString()}
																		</span>
																	</small>
																</div>
															</div>
														</div>
													</div>
												))
											)}
										</div>
									</>
								))}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default RepositoryPage;
