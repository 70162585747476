import React, { useState, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

//import components
import Header from "../../layout/header/header.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";
import DropZoneUploader from "../../components/shared/uploaders/single-uploader/dropzone-uploader";
import SelectedFilesList from "../../components/files/selected-files-list/selected-files-list";

//redux
import { useDispatch, useSelector } from "react-redux";
import { addActionComplaint } from "../../redux/actions/complaints-actions.actions";
import { listStatesComplaint } from "../../redux/actions/complaints-states.actions";

const ComplaintActionAddPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const actionAdd = useSelector((state) => state.actionComplaintAdd);
	const { loading, error, addResult } = actionAdd;
	const statesList = useSelector((state) => state.statesComplaintList);
	const { loading: loadingState, error: errorState, states } = statesList;
	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const [uploadFiles, setUploadedFiles] = useState([]);
	const onDrop = useCallback(
		(acceptedFiles) => {
			const files = acceptedFiles.map((file) => file);
			setUploadedFiles([].concat(...uploadFiles, files));
		},
		[uploadFiles]
	);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onChange",
		criteriaMode: "firstError",
		shouldFocusError: true,
	});

	useEffect(() => {
		if (!userInfo) {
			navigate("/login");
		} else {
			if (addResult) {
				navigate(`/denuncia/${params.id}`);
			}
			dispatch(listStatesComplaint());
		}
	}, [navigate, addResult, userInfo, dispatch, params]);

	const handleDeleteSingleFile = (e, file) => {
		e.preventDefault();
		setUploadedFiles(
			uploadFiles.filter(function (obj) {
				return obj.path !== file.path;
			})
		);
	};

	const onSubmit = (data) => {
		dispatch(addActionComplaint(uploadFiles, data.title, data.description, params.id, data.actionStateId));
	};

	const complaintActionAdd = [
		{
			title: "Lista de denuncias",
			link: `/denuncias`,
		},
		{
			title: "Detalles",
			link: `/denuncia/${params.id}`,
		},
		{
			title: "Crear diligencia para esta denuncia",
		},
	];

	return (
		<>
			<Header title="Crear diligencia para esta denuncia" path="Crear diligencia para esta denuncia" pathList={complaintActionAdd}>
				<button className="btn btn-success btn-icon float-right" onClick={handleSubmit(onSubmit)}>
					<i className="zmdi zmdi-check"></i>
				</button>
			</Header>
			<div className="container-fluid">
				<div className="row clearfix">
					<div className="col-md-12">
						<div className="card">
							{errorState ? (
								<div className="mb-3">
									<Alert message={errorState} />
								</div>
							) : loadingState ? (
								<div className="text-center mb-2">
									<Loader />
								</div>
							) : (
								<>
									<div className="header">
										<h2>
											Ingresar información para la <strong>diligencia</strong>
										</h2>
									</div>
									<div className="body">
										{error && (
											<div className="mb-3">
												<Alert message={error} />
											</div>
										)}
										{loading && (
											<div className="text-center mb-2">
												<Loader />
											</div>
										)}
										<div className="row justify-content-center">
											<div className="col-md-7 pb-3">
												<div className="form-group pb-0">
													<label className="form_label">Estado de la diligencia </label>
													<select
														className="form-control show-tick"
														{...register("actionStateId", {
															required: true,
														})}
													>
														<option value="">Estado de la diligencia</option>
														{states &&
															states.map((state) => (
																<option key={state.id} value={state.id}>
																	{state.name}
																</option>
															))}
													</select>
													{errors.actionStateId?.type === "required" && (
														<span
															style={{
																color: "red",
																padding: "4px 2px",
															}}
														>
															El estado de la diligencia es requerido
														</span>
													)}
												</div>
											</div>
											<div className="col-md-7">
												<div className="form-group pb-0">
													<label className="form_label">Título de la diligencia: </label>
													<input
                            type="text"
                            className="form-control"
                            {...register("title", {
															required: true,
															minLength: 5,
														})}
                          />
                          {errors.title?.type === "required" && (
														<span style={{ color: "red", padding: "4px 2px" }}>El título de la diligencia es requerido.</span>
													)}
													{errors.title?.type === "minLength" && (
														<span style={{ color: "red" }}>El título debe tener al menos 5 caracteres.</span>
													)}
												</div>
											</div>
											<div className="col-md-7">
												<div className="form-group pb-0">
													<label className="form_label">Diligencias: </label>
                          <textarea
														style={{ border: errors.description ? "1px solid red" : "" }}
														type="text"
														rows={6}
														className="form-control"
														placeholder=""
														{...register("description", {
															required: true,
															minLength: 5,
														})}
													></textarea>
													{errors.description?.type === "required" && (
														<span style={{ color: "red", padding: "4px 2px" }}>Este campo es requerido.</span>
													)}
													{errors.description?.type === "minLength" && (
														<span style={{ color: "red" }}>Este campo debe tener al menos 5 caracteres.</span>
													)}
												</div>
											</div>
											<div className="col-md-7">
												<div className="form-group pb-0">
													<label className="form_label">Anexos</label>
												</div>
											</div>
											<div className="col-md-7">
												<div className="mb-3 justify-content-center">
													<DropZoneUploader onDrop={onDrop} />
													{uploadFiles.length > 0 ? (
														<>
															<SelectedFilesList uploadFiles={uploadFiles} handleDeleteSingleFile={handleDeleteSingleFile} />
														</>
													) : null}
												</div>
											</div>
											<div className="col-7">
												<button className="btn btn-success" onClick={handleSubmit(onSubmit)}>
													Crear diligencia
												</button>
											</div>
										</div>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ComplaintActionAddPage;
