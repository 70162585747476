import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useDispatch } from "react-redux";
import { changeActiveSidebarItem } from "../../redux/actions/navigation.actions";

//import components
import Header from "../../layout/header/header.component";

const RiskMapsAdminPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const handleGoTypologies = () => {
    navigate("/mapa-de-riesgos/tipologias");
  };
  const handleGoConductsType = () => {
    navigate("/mapa-de-riesgos/tipo-conductas");
  };
  const handleGoConducts = () => {
    navigate("/mapa-de-riesgos/conductas");
  };

  useEffect(() => {
    dispatch(changeActiveSidebarItem(location.pathname));
  }, [dispatch, location]);

  return (
    <>
      <Header title="Mapa de Riesgos" path="Administrar"></Header>
      <div className="container-fluid">
        <div className="row clearfix mt-5">
          <div className="col-lg-12">
            <div className="card">
              <ul className="row list-unstyled">
                <li
                  className="col-lg-4 col-md-3 col-6"
                  onClick={() => {
                    handleGoTypologies();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="body">
                    <i className="zmdi zmdi-eye col-amber"></i>
                    <h4>Tipologías</h4>
                    <span>Administrar</span>
                  </div>
                </li>
                <li
                  className="col-lg-4 col-md-3 col-6"
                  onClick={() => {
                    handleGoConductsType();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="body">
                    <i className="zmdi zmdi-thumb-up col-blue"></i>
                    <h4>Tipos de Conductas</h4>
                    <span>Administrar</span>
                  </div>
                </li>
                <li
                  className="col-lg-4 col-md-3 col-6"
                  onClick={() => {
                    handleGoConducts();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="body">
                    <i className="zmdi zmdi-comment-text col-red"></i>
                    <h4>Conductas</h4>
                    <span>Administrar</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RiskMapsAdminPage;
