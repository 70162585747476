import {
  CHANGE_ACTIVE_SIDEBAR_ITEM,
  OPEN_SIDEBAR,
  OPEN_SIDEBAR_RIGHT,
  OPEN_SIDEBAR_MOBILE,
} from "../constants/navigation.const";

/* const initialState = {
    sidebarOpened: false,
    activeItem: JSON.parse(localStorage.getItem("staticSidebar"))
      ? window.location.pathname
      : null,
  }; */

export function runtime(state = {}, action) {
  switch (action.type) {
    case OPEN_SIDEBAR:
      return { ...state, sidebarOpened: !state.sidebarOpened };
    case OPEN_SIDEBAR_RIGHT:
      return { ...state, rightSidebarOpened: !state.rightSidebarOpened };
    case OPEN_SIDEBAR_MOBILE:
      return { ...state, mobileSidebarOpened: !state.mobileSidebarOpened };
    case CHANGE_ACTIVE_SIDEBAR_ITEM:
      return {
        ...state,
        activeItem: action.activeItem,
      };
    default:
      return state;
  }
}
