import {
  COMPLAINTS_DETAILS_FAIL,
  COMPLAINTS_DETAILS_REQUEST,
  COMPLAINTS_DETAILS_RESET,
  COMPLAINTS_DETAILS_SUCCESS,
  COMPLAINTS_LIST_FAIL,
  COMPLAINTS_LIST_REQUEST,
  COMPLAINTS_LIST_RESET,
  COMPLAINTS_LIST_SUCCESS,
  COMPLAINTS_ADD_FAIL,
  COMPLAINTS_ADD_REQUEST,
  COMPLAINTS_ADD_RESET,
  COMPLAINTS_ADD_SUCCESS,
  COMPLAINTS_RATE_REQUEST,
  COMPLAINTS_RATE_SUCCESS,
  COMPLAINTS_RATE_FAIL,
  COMPLAINTS_RATE_RESET,
  COMPLAINTS_GET_EMAILS_REQUEST,
  COMPLAINTS_GET_EMAILS_SUCCESS,
  COMPLAINTS_GET_EMAILS_FAIL,
  COMPLAINTS_GET_EMAILS_RESET,
} from "../constants/complaints.const";

export const complaintAddReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPLAINTS_ADD_REQUEST:
      return { loading: true };
    case COMPLAINTS_ADD_SUCCESS:
      return { loading: false, addResult: action.payload };
    case COMPLAINTS_ADD_FAIL:
      return { loading: false, error: action.payload };
    case COMPLAINTS_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const complaintListReducer = (state = { complaints: [] }, action) => {
  switch (action.type) {
    case COMPLAINTS_LIST_REQUEST:
      return { loading: true };
    case COMPLAINTS_LIST_SUCCESS:
      return { loading: false, complaints: action.payload };
    case COMPLAINTS_LIST_FAIL:
      return { loading: false, error: action.payload };
    case COMPLAINTS_LIST_RESET:
      return { complaints: [] };
    default:
      return state;
  }
};

export const complaintDetailsReducer = (state = { complaint: {} }, action) => {
  switch (action.type) {
    case COMPLAINTS_DETAILS_REQUEST:
      return { ...state, loading: true };
    case COMPLAINTS_DETAILS_SUCCESS:
      return { loading: false, complaint: action.payload };
    case COMPLAINTS_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case COMPLAINTS_DETAILS_RESET:
      return { complaint: {} };
    default:
      return state;
  }
};

export const rateComplaintReducer = (state = { complaint: {} }, action) => {
  switch (action.type) {
    case COMPLAINTS_RATE_REQUEST:
      return { ...state, loading: true };
    case COMPLAINTS_RATE_SUCCESS:
      return { loading: false, complaint: action.payload };
    case COMPLAINTS_RATE_FAIL:
      return { loading: false, error: action.payload };
    case COMPLAINTS_RATE_RESET:
      return { complaint: {} };
    default:
      return state;
  }
};

export const listEmailsReducer = (state = { emails: [] }, action) => {
  switch (action.type) {
    case COMPLAINTS_GET_EMAILS_REQUEST:
      return { ...state, loading: true };
    case COMPLAINTS_GET_EMAILS_SUCCESS:
      return { loading: false, emails: action.payload };
    case COMPLAINTS_GET_EMAILS_FAIL:
      return { loading: false, error: action.payload };
    case COMPLAINTS_GET_EMAILS_RESET:
      return { emails: [] };
    default:
      return state;
  }
};
