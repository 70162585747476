import React, { useEffect } from "react";
import { useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

//import components
import Header from "../../layout/header/header.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";
import Select from "react-select";

//constant
import BREADCRUM_DATA from "../../constants/breadcrumb.const";

//redux
import { useDispatch, useSelector } from "react-redux";
import { listCategoriesCompanies } from "../../redux/actions/companies-categories.actions";
import { addCompanies } from "../../redux/actions/companies.actions";
import { listAreaCompanies } from "../../redux/actions/companies-area.actions";
import DropZoneUploader from "../../components/shared/uploaders/single-uploader/dropzone-uploader";
import SelectedFilesList from "../../components/files/selected-files-list/selected-files-list";
import { useCallback } from "react";
import { addImage } from "../../redux/actions/image.actions";

const CompanyAddPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const companyAdd = useSelector((state) => state.companyAdd);
	const { loading, error, addResult } = companyAdd;
	const categoriesList = useSelector((state) => state.categoryCompanyList);
	const { loading: loadingCategories, error: errorCategories, categories } = categoriesList;
	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;
	const areaList = useSelector((state) => state.areaCompanyList);
	const { loading: loadingArea, error: errorArea, area } = areaList;
	const imageList = useSelector((state) => state.addImage)
	const { loading: loadingImage, error: errorImage, addImageResult } = imageList


	const {
		register,
		handleSubmit,
		reset,
		control,
		formState: { errors },
	} = useForm({
		mode: "onChange",
		/* reValidateMode: "onChange", */
		criteriaMode: "firstError",
		shouldFocusError: true,
	});

	const [uploadFiles, setUploadedFiles] = useState([]);

	const onDrop = useCallback(
		(acceptedFiles) => {
			const files = acceptedFiles.map((file) => file);
			setUploadedFiles([].concat(...uploadFiles, files));
		},
		[uploadFiles],
	);

	const handleDeleteSingleFile = (e, file) => {
		console.log("Eliminado..");
		e.preventDefault();
		setUploadedFiles(
			uploadFiles.filter(function (obj) {
				return obj.path !== file.path;
			})
		);
	};

	const onSubmit = (data) => {
		dispatch(
			addCompanies(data.name, data.ruc, data.address, data.phone, data.email, data.description, data.categoryId, +data.deadlineComplaint, addImageResult.url ,data.areaIds)
		);
	};

	useEffect(() => {
		if (!userInfo) {
			navigate("/login");
		} else {
			if (addResult) {
				navigate("/empresas");
			}
			reset({
				deadlineComplaint: 15,
			});
			dispatch(listCategoriesCompanies());
			dispatch(listAreaCompanies())
			if (uploadFiles.length !== 0) {
				dispatch(addImage(uploadFiles[0]))

			}

		}
	}, [navigate, addResult, userInfo, dispatch, reset, uploadFiles]);

	return (
		<>
			<Header title="Crear Empresa" path="Crear Empresa" pathList={BREADCRUM_DATA.companyAdd}>
				<button className="btn btn-success btn-icon float-right right_icon_toggle_btn" onClick={handleSubmit(onSubmit)}>
					<i className="zmdi zmdi-check"></i>
				</button>
			</Header>
			<div className="container-fluid">
				<div className="row clearfix">
					<div className="col-md-12">
						<div className="card">
							<div className="header">
								<h2>
									Ingresar información de la nueva <strong>empresa</strong>
								</h2>
							</div>
							<div className="body">
								{error && (
									<div className="mb-3">
										<Alert message={error} />
									</div>
								)}
								{errorImage && (
									<div className="mb-3">
										<Alert message={errorImage} />
									</div>
								)}

								{errorCategories && (
									<div className="mb-3">
										<Alert message={errorCategories} />
									</div>
								)}
								{errorArea && (
									<div className="mb-3">
										<Alert message={errorArea} />
									</div>
								)}
								{((loading && loadingCategories) || loadingArea || loadingImage) && (
									<div className="text-center mb-2">
										<Loader />
									</div>
								)}
								<div className="row clearfix">
									<div className="col-lg-12 col-md-12">
										<label className="form_label">Cargar Logo</label>
										<div className="mb-3 justify-content-center">
											{
												uploadFiles.length === 0 ?(<DropZoneUploader onDrop={onDrop} />):("")
											}
											
											{uploadFiles.length > 0 ? (
												<>
													<SelectedFilesList uploadFiles={uploadFiles} handleDeleteSingleFile={handleDeleteSingleFile} />
												</>
											) : null}
										</div>
									</div>
									{
										uploadFiles && uploadFiles.length !== 0 ? (
											<>
												<div className="col-lg-6 col-md-12">
													<div className="form-group">
														<input
															style={{
																border: errors.name ? "1px solid red" : "",
															}}
															type="text"
															className="form-control"
															placeholder="Nombre"
															{...register("name", {
																required: true,
																minLength: 2,
															})}
														/>
														{errors.name?.type === "required" && (
															<span
																style={{
																	color: "red",
																	padding: "4px 2px",
																}}
															>
																El nombre es requerido.
															</span>
														)}
														{errors.name?.type === "minLength" && (
															<span style={{ color: "red" }}>El nombre debe tener al menos dos caracteres.</span>
														)}
													</div>
												</div>

												<div className="col-lg-6 col-md-12">
													<select
														className="form-control show-tick"
														{...register("categoryId", {
															required: true,
														})}
													>
														<option value="">Categoría de la empresa</option>
														{categories &&
															categories.map((category, index) => (
																<option key={index} value={category.id}>
																	{category.name}
																</option>
															))}
													</select>
													{errors.categoryId?.type === "required" && (
														<span
															style={{
																color: "red",
																padding: "4px 2px",
															}}
														>
															La categoría es requerida.
														</span>
													)}
												</div>

												<div className="col-lg-4 col-md-12">
													<div className="form-group">
														<input
															style={{
																border: errors.ruc ? "1px solid red" : "",
															}}
															type="text"
															className="form-control"
															placeholder="RUC"
															{...register("ruc", {
																required: true,
																minLength: 13,
															})}
														/>
														{errors.ruc?.type === "required" && (
															<span
																style={{
																	color: "red",
																	padding: "4px 2px",
																}}
															>
																El RUC es requerido.
															</span>
														)}
														{errors.ruc?.type === "minLength" && (
															<span style={{ color: "red" }}>El RUC debe tener al menos trece caracteres.</span>
														)}
													</div>
												</div>

												<div className="col-lg-4 col-md-12">
													<div className="form-group">
														<input
															style={{
																border: errors.phone ? "1px solid red" : "",
															}}
															type="tel"
															className="form-control"
															placeholder="Teléfono"
															{...register("phone", {
																required: true,

																pattern: /[0][0-9]{9}/,
															})}
														/>
														{errors.phone?.type === "required" && <span style={{ color: "red" }}>El teléfono es requerido.</span>}
														{errors.phone?.type === "pattern" && (
															<span style={{ color: "red" }}>El número debe comenzar con 0 y tener 10 dígitos.</span>
														)}
													</div>
												</div>

												<div className="col-lg-4 col-md-12">
													<div className="form-group">
														<input
															style={{
																border: errors.email ? "1px solid red" : "",
															}}
															type="email"
															className="form-control"
															placeholder="Correo"
															{...register("email", {
																required: true,

																pattern: /\S+@\S+\.\S+/,
															})}
														/>
														{errors.email?.type === "required" && <span style={{ color: "red" }}>El correo es requerido.</span>}
														{errors.email?.type === "pattern" && (
															<span style={{ color: "red" }}>El valor ingresado no coincide con el formato de correo electrónico</span>
														)}
													</div>
												</div>
												<div className="col-lg-12 col-md-12">

													<Controller
														name="areaIds"
														rules={
															{ required: true, }
														}
														control={control}
														render={({
															field: { onChange, name }
														}) => (
															<>
																<Select

																	name={name}
																	theme={theme => ({
																		...theme,
																		colors: {
																			...theme.colors,
																			neutral50: '#6c757d',  // Placeholder color
																		},
																	})}
																	styles={{

																		control: (base, state) => ({
																			...base,

																			border: '1px solid lightgray', // default border color
																			boxShadow: `0 0 0 1px '#E67E22'`, // no box-shadow
																		}),
																		option: (basename, state) => ({
																			...basename,
																			":hover": { backgroundColor: '#ffda9e' }
																		})
																	}}
																	placeholder="Seleccione una Área"
																	options={area}
																	isMulti
																	noOptionsMessage={() => "Área no registrada..."}
																	getOptionLabel={(area) => area.name}
																	getOptionValue={(area) => String(area.id)}
																	onChange={(value) => {
																		onChange(value.map((value) => value.id));
																	}}
																/>
															</>
														)}
													/>
													<div className='col-12'>
														{errors.areaIds?.type === "required" && <span style={{ color: "red" }}>El Área es requerido.</span>}
													</div>

												</div>
												<div className="col-lg-8 col-md-12 mt-3">
													<div className="form-group">
														<input
															style={{
																border: errors.address ? "1px solid red" : "",
															}}
															type="text"
															className="form-control"
															placeholder="Dirección"
															{...register("address", {
																required: true,
																minLength: 6,
															})}
														/>
														{errors.address?.type === "required" && (
															<span
																style={{
																	color: "red",
																	padding: "4px 2px",
																}}
															>
																La dirección es requerida.
															</span>
														)}
														{errors.address?.type === "minLength" && (
															<span style={{ color: "red" }}>La dirección debe tener al menos dos caracteres.</span>
														)}
													</div>
												</div>


												<div className="col-lg-4 col-md-12 mt-3">
													<div className="form-group">
														<input
															style={{
																border: errors.deadlineComplaint ? "1px solid red" : "",
															}}
															type="number"
															className="form-control"
															placeholder="Tiempo máximo de calificación de denuncias (días)"
															{...register("deadlineComplaint", {
																required: true,
															})}
														/>
														<span>Tiempo máximo de calificación de denuncias (días)</span>
														{errors.deadlineComplaint?.type === "required" && (
															<span className="d-block" style={{ color: "red" }}>
																Este campo es requerido.
															</span>
														)}
													</div>
												</div>


												<div className="col-lg-6 col-md-12">
													<div className="form-group">
														<textarea
															style={{
																border: errors.description ? "1px solid red" : "",
															}}
															type="text-area"
															rows="6"
															className="form-control"
															placeholder="Descripción"
															{...register("description", {
																required: true,

																minLength: 6,
															})}
														/>
														{errors.description?.type === "required" && <span style={{ color: "red" }}>La descripción es requerida.</span>}

														{errors.description?.type === "minLength" && (
															<span style={{ color: "red" }}>La descripción debe tener al menos 6 caracteres.</span>
														)}
													</div>
												</div>




												<div className="col-md-12">
													<button className="btn btn-success" onClick={handleSubmit(onSubmit)}>
														Guardar Empresa
													</button>
												</div>
											</>
										) : (
											""
										)
									}

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CompanyAddPage;
