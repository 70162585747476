import api from "../../api/rigori.api";

import {
  RISK_MAPS_DETAILS_FAIL,
  RISK_MAPS_DETAILS_REQUEST,
  RISK_MAPS_DETAILS_SUCCESS,
  RISK_MAPS_FILTRADA_QUESTIONS_GET,
  RISK_MAPS_LIST_FAIL,
  RISK_MAPS_LIST_REQUEST,
  RISK_MAPS_LIST_SUCCESS,
  RISK_MAPS_RESPONSE_FAIL,
  RISK_MAPS_RESPONSE_REQUEST,
  RISK_MAPS_RESPONSE_SUCCESS,
  RISK_MAPS_DELETE_ITEM_REQUEST,
  RISK_MAPS_DELETE_ITEM_SUCCESS,
  RISK_MAPS_DELETE_ITEM_FAIL,
  RISK_MAPS_STATISTICS_REQUEST,
  RISK_MAPS_STATISTICS_SUCCESS,
  RISK_MAPS_STATISTICS_FAIL,
  RISK_MAPS_ADD_CONDUCT_REQUEST,
  RISK_MAPS_ADD_CONDUCT_SUCCESS,
  RISK_MAPS_ADD_CONDUCT_FAIL,
  RISK_MAPS_FILTRADA_GET,
  RISK_MAPS_PLAN_ADD_FAIL,
  RISK_MAPS_PLAN_ADD_SUCCESS,
  RISK_MAPS_PLAN_ADD_REQUEST,
  RISK_MAPS_CONDUCTS_BY_SCALE_FAIL,
  RISK_MAPS_CONDUCTS_BY_SCALE_REQUEST,
  RISK_MAPS_CONDUCTS_BY_SCALE_SUCCESS,
  RISK_MAPS_GENERATE_REPORT_REQUEST,
  RISK_MAPS_GENERATE_REPORT_SUCCESS,
  RISK_MAPS_GENERATE_REPORT_FAIL,
} from "../constants/risk-maps.const";

import { logout } from "./user.actions";

export const listRiskMaps = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: RISK_MAPS_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.get(`/risk-maps`, config);

    dispatch({
      type: RISK_MAPS_LIST_SUCCESS,
      payload: data,
    });
    dispatch({
      type: RISK_MAPS_FILTRADA_GET,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: RISK_MAPS_LIST_FAIL,
      payload: message,
    });
  }
};

export const listRisksBySearching = (filter) => async (dispatch, getState) => {
  //const reservas = getState().reservas.reservas;
  const filterNormalized = filter.trim().toLowerCase();
  //console.log(reservas);
  var riskMapFilter;
  if (filter === "") {
    riskMapFilter = getState().riskMapsList.riskMaps;
  } else {
    riskMapFilter = getState().riskMapsList.riskMaps.filter(function (element) {
      return (
        element.name.toLowerCase().includes(filterNormalized) |
        element.user.name.toLowerCase().includes(filterNormalized) |
        element.user.lastname.toLowerCase().includes(filterNormalized) |
        element.user.email.toLowerCase().includes(filterNormalized) |
        element.company.name.toLowerCase().includes(filterNormalized) |
        element.company.ruc.toLowerCase().includes(filterNormalized) |
        element.company.email.toLowerCase().includes(filterNormalized)
      );
    });
  }

  dispatch({
    type: RISK_MAPS_FILTRADA_GET,
    payload: riskMapFilter,
  });
};

export const getRiskMapsDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RISK_MAPS_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await api.get(`/risk-maps/${id}`, config);

    dispatch({
      type: RISK_MAPS_DETAILS_SUCCESS,
      payload: data,
    });
    dispatch({
      type: RISK_MAPS_FILTRADA_QUESTIONS_GET,
      payload: data.riskMapConducts,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: RISK_MAPS_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const getMapRiskQuestionsByCondition =
  (filter) => async (dispatch, getState) => {
    //const reservas = getState().reservas.reservas;
    //console.log(filter);
    //console.log(reservas);
    var riskmapFilter;
    if (filter === "Todas") {
      riskmapFilter = getState().riskMapDetails.riskMap.riskMapConducts;
    } else if (filter === "answered") {
      riskmapFilter = getState().riskMapDetails.riskMap.riskMapConducts.filter(
        function (element) {
          return element.analysis;
        }
      );
    } else if (filter === "notAnswered") {
      riskmapFilter = getState().riskMapDetails.riskMap.riskMapConducts.filter(
        function (element) {
          return !element.analysis;
        }
      );
    } else if (filter === "highRisk") {
      riskmapFilter = getState().riskMapDetails.riskMap.riskMapConducts.filter(
        function (element) {
          if(element.scale){
            return element.scale.name === 'ALTO'
          }
          return null;
        }
      );
    } else if (filter === "mediumRisk") {
      riskmapFilter = getState().riskMapDetails.riskMap.riskMapConducts.filter(
        function (element) {
          if(element.scale){
            return element.scale.name === 'MEDIO'
          }
          return null;
        }
      );
    } else if (filter === "lowRisk") {
      riskmapFilter = getState().riskMapDetails.riskMap.riskMapConducts.filter(
        function (element) {
          if(element.scale){
            return element.scale.name === 'BAJO'
          }
          return null;
        }
      );
    } else {
      riskmapFilter = getState().riskMapDetails.riskMap.riskMapConducts;
    }

    dispatch({
      type: RISK_MAPS_FILTRADA_QUESTIONS_GET,
      payload: riskmapFilter,
    });
  };

//descending order
export const getriskmapDetailsBySorting =
  (filter) => async (dispatch, getState) => {
    var riskmapSorted;
    if (filter === "date") {
      riskmapSorted =
        getState().questionnaryDetail.riskMapDetails.riskMapConducts;
    } else if (filter === "progress") {
      riskmapSorted = getState().questionnaryListFiltered.questionnaries.sort(
        function (a, b) {
          return parseFloat(b.progress) - parseFloat(a.progress);
        }
      );
    } else if (filter === "deadline") {
      riskmapSorted = getState().questionnaryListFiltered.questionnaries.sort(
        function (a, b) {
          // console.log(Date(b.deadline));
          return new Date(a.deadline) - new Date(b.deadline);
        }
      );
    } else if (filter === "supervisor") {
      riskmapSorted = getState().questionnaryListFiltered.questionnaries.sort(
        function (a, b) {
          return b.supervisor.id - a.supervisor.id;
        }
      );
    } else if (filter === "company") {
      riskmapSorted = getState().questionnaryListFiltered.questionnaries.sort(
        function (a, b) {
          return b.company.id - a.company.id;
        }
      );
    } else if (filter === "") {
      riskmapSorted = getState().questionnaryList.questionnaries;
    } else {
      riskmapSorted = getState().questionnaryListFiltered.questionnaries;
    }

    dispatch({
      type: RISK_MAPS_FILTRADA_QUESTIONS_GET,
      payload: riskmapSorted,
    });
  };

export const getriskmapDetailsBySearching =
  (filter) => async (dispatch, getState) => {
    //const reservas = getState().reservas.reservas;
    const filterNormalized = filter.trim().toLowerCase();
    //console.log(reservas);
    var riskmapFilter;
    if (filter === "") {
      riskmapFilter = getState().riskMapDetails.riskMap.riskMapConducts;
    } else {
      riskmapFilter = getState().riskMapDetails.riskMap.riskMapConducts.filter(
        function (element) {
          return (
            (element.analysis !== null &&
              element.analysis.toLowerCase().includes(filterNormalized)) ||
            (element.recommendation !== null &&
              element.recommendation
                .toLowerCase()
                .includes(filterNormalized)) ||
            (element.scale !== null &&
              element.scale.name.toLowerCase().includes(filterNormalized)) ||
            (element.conduct !== null &&
              element.conduct.name.toLowerCase().includes(filterNormalized)) ||
            (element.conduct.type !== null &&
              element.conduct.type.name
                .toLowerCase()
                .includes(filterNormalized)) ||
            (element.conduct.typology !== null &&
              element.conduct.typology.name
                .toLowerCase()
                .includes(filterNormalized))
          );
        }
      );
    }

    dispatch({
      type: RISK_MAPS_FILTRADA_QUESTIONS_GET,
      payload: riskmapFilter,
    });
  };

export const riskMapResponse =
  (
    probability1,
    probability2,
    impact1,
    impact2,
    analysis,
    recommendation,
    riskMapConductId
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: RISK_MAPS_RESPONSE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await api.post(
        "/risk-map-conduct/answer",
        {
          probability1,
          probability2,
          impact1,
          impact2,
          analysis,
          recommendation,
          riskMapConductId,
        },
        config
      );

      dispatch({
        type: RISK_MAPS_RESPONSE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Unauthorized") {
        dispatch(logout());
      }
      dispatch({
        type: RISK_MAPS_RESPONSE_FAIL,
        payload: message,
      });
    }
  };

export const addItem =
  (typologyId, article, typeId, name, riskMapId) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: RISK_MAPS_ADD_CONDUCT_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await api.post(
        "/risk-map-conduct/add-conduct",
        { typologyId, article, typeId, name, riskMapId },
        config
      );

      dispatch({
        type: RISK_MAPS_ADD_CONDUCT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Unauthorized") {
        dispatch(logout());
      }
      dispatch({
        type: RISK_MAPS_ADD_CONDUCT_FAIL,
        payload: message,
      });
    }
  };

export const deleteItem = (conductId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RISK_MAPS_DELETE_ITEM_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.delete(
      `/risk-map-conduct/conduct/${conductId}`,
      config
    );
    //console.log(data);

    dispatch({
      type: RISK_MAPS_DELETE_ITEM_SUCCESS,
      payload: data,
    });
    // dispatch(listCompanies());
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: RISK_MAPS_DELETE_ITEM_FAIL,
      payload: message,
    });
  }
};

export const getStatisticsRiskMaps = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RISK_MAPS_STATISTICS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.get(`/risk-maps/statistics/${id}`, config);

    dispatch({
      type: RISK_MAPS_STATISTICS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: RISK_MAPS_STATISTICS_FAIL,
      payload: message,
    });
  }
};

export const getConductsByScaleType = (riskMapId, scaleId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RISK_MAPS_CONDUCTS_BY_SCALE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.get(`/risk-maps/statistics/${riskMapId}/${scaleId}`, config);

    dispatch({
      type: RISK_MAPS_CONDUCTS_BY_SCALE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: RISK_MAPS_CONDUCTS_BY_SCALE_FAIL,
      payload: message,
    });
  }
};

export const getRiskMapReport = (riskMapId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RISK_MAPS_GENERATE_REPORT_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.get(`/risk-maps/report/${riskMapId}`, config);

    dispatch({
      type: RISK_MAPS_GENERATE_REPORT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: RISK_MAPS_GENERATE_REPORT_FAIL,
      payload: message,
    });
  }
};

export const riskMapConductAddPlan =
  (
    riskMapConductId,
    existingControl,
    department,
    headControl,
    controlPerformance,
    type,
    frequency,
    valuationPerformance,
    effectivenessEvaluation,
    vulnerability,
    residualRisk,
    action,
    planResponsible,
    implementationDate,
    situation
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: RISK_MAPS_PLAN_ADD_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await api.post(
        "/risk-map-conduct/plan",
        {
          riskMapConductId,
          existingControl,
          department,
          headControl,
          controlPerformance,
          type,
          frequency,
          valuationPerformance,
          effectivenessEvaluation,
          vulnerability,
          residualRisk,
          action,
          planResponsible,
          implementationDate,
          situation,
        },
        config
      );

      dispatch({
        type: RISK_MAPS_PLAN_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Unauthorized") {
        dispatch(logout());
      }
      dispatch({
        type: RISK_MAPS_PLAN_ADD_FAIL,
        payload: message,
      });
    }
  };
