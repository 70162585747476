import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ labels, label, dataChart, backgroundColor }) => {
  // console.log(dataChart);
  const data = {
    labels: labels,
    datasets: [
      {
        label: label,
        data: dataChart,
        backgroundColor: backgroundColor,
        hoverOffset: 4,
      },
    ],
  };
  return (
    <Doughnut
      data={data}
      width={600}
      height={600}
      redraw={true}
      options={{
        responsive: true,
        maintainAspectRatio: false,
      }}
    />
  );
};

export default PieChart;
