export const AREAS_COMPANIES_LIST_FAIL = "AREAS_COMPANIES_LIST_FAIL";
export const AREAS_COMPANIES_LIST_RESET = "AREAS_COMPANIES_LIST_RESET";
export const AREAS_COMPANIES_LIST_REQUEST = "AREAS_COMPANIES_LIST_REQUEST";
export const AREAS_COMPANIES_LIST_SUCCESS = "AREAS_COMPANIES_LIST_SUCCESS";

export const AREAS_COMPANIES_DETAILS_SUCCESS = "AREAS_COMPANIES_DETAILS_SUCCESS";
export const AREAS_COMPANIES_DETAILS_FAIL = "AREAS_COMPANIES_DETAILS_FAIL";
export const AREAS_COMPANIES_DETAILS_RESET = "AREAS_COMPANIES_DETAILS_RESET";
export const AREAS_COMPANIES_DETAILS_REQUEST = "AREAS_COMPANIES_DETAILS_REQUEST";

export const AREAS_COMPANIES_ADD_REQUEST = "AREAS_COMPANIES_ADD_REQUEST";
export const AREAS_COMPANIES_ADD_SUCCESS = "AREAS_COMPANIES_ADD_SUCCESS";
export const AREAS_COMPANIES_ADD_FAIL = "AREAS_COMPANIES_ADD_FAIL";
export const AREAS_COMPANIES_ADD_RESET = "AREAS_COMPANIES_ADD_RESET";

export const AREAS_COMPANIES_DELETE_REQUEST = "AREAS_COMPANIES_DELETE_REQUEST";
export const AREAS_COMPANIES_DELETE_SUCCESS = "AREAS_COMPANIES_DELETE_SUCCESS";
export const AREAS_COMPANIES_DELETE_FAIL = "AREAS_COMPANIES_DELETE_FAIL";
export const AREAS_COMPANIES_DELETE_RESET = "AREAS_COMPANIES_DELETE_FAIL";

export const AREAS_COMPANIES_UPDATE_REQUEST = "AREAS_COMPANIES_UPDATE_REQUEST";
export const AREAS_COMPANIES_UPDATE_SUCCESS = "AREAS_COMPANIES_UPDATE_SUCCESS";
export const AREAS_COMPANIES_UPDATE_FAIL = "AREAS_COMPANIES_UPDATE_FAIL";
export const AREAS_COMPANIES_UPDATE_RESET = "AREAS_COMPANIES_UPDATE_RESET";