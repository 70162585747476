//REDUX LIBRARIES IMPORT

import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

//REDUCERS IMPORTS
import {
  alertCheckReducer,
  alertsListReducer,
} from "./reducers/alerts.reducers";

import {
  userLoginReducer,
  userRegisterReducer,
  userListReducer,
  userDeleteReducer,
  userDetailsReducer,
  userUpdateReducer,
  userUpdateAdminReducer,
} from "./reducers/user.reducers";

import { categoriesListReducer } from "./reducers/categories.reducers";
import { prioritiesListReducer } from "./reducers/priorities.reducers";

import {
  companyAddReducer,
  companyDeleteAreaReducer,
  companyDeleteReducer,
  companyDetailsReducer,
  companyListReducer,
  companyUpdateReducer,
} from "./reducers/companies.reducers";

import {
  categoryCompanyAddReducer,
  categoryCompanyDeleteReducer,
  categoryCompanyDetailsReducer,
  categoryCompanyListReducer,
  categoryCompanyUpdateReducer,
} from "./reducers/companies-categories.reducers";

import {
  complaintAddReducer,
  complaintDetailsReducer,
  complaintListReducer,
  rateComplaintReducer,
  listEmailsReducer,
} from "./reducers/complaints.reducers";

import {
  questionnaryAddReducer,
  questionnaryListReducer,
  questionnaryListCompaniesReducer,
  questionnaryListUsersReducer,
  questionnaryDetailsReducer,
  getQuestionnaryFilesReducer,
  questionnaryAddFileReducer,
  questionnaryAddItemReducer,
  questionnaryDeleteFileReducer,
  questionnaryResponseReducer,
  questionnaryDeleteItemReducer,
  questionnariesListFilterReducer,
  questionnariesDetailsFilterReducer,
} from "./reducers/questionnaires.reducers";

import {
  checklistAddFileReducer,
  checklistAddItemReducer,
  checklistDeleteFileReducer,
  checklistDeleteItemReducer,
  checklistDetailsReducer,
  checklistResponseReducer,
  getCheckListFilesReducer,
  checklistQuestionsFilterReducer,
} from "./reducers/checklist.reducers";

import {
  labelAddReducer,
  labelDeleteReducer,
  labelDetailsReducer,
  labelListReducer,
  labelUpdateReducer,
  linkAddReducer,
  linkDeleteReducer,
  linkDetailsReducer,
  linkListReducer,
  linkUpdateReducer,
} from "./reducers/searcher.reducers";

import { statisticsGeneralgetReducer } from "./reducers/statistics.reducers";

import {
  riskMapDetailsReducer,
  riskMapListReducer,
  riskMapResponseReducer,
  riskMapQuestionsFilterReducer,
  riskMapDeleteConductReducer,
  riskMapStatisticsReducer,
  riskMapConductsByScaleTypeReducer,
  riskMapAddConductReducer,
  riskmapListFilterReducer,
  riskMapConductAddPlanReducer,
  riskMapReportReducer,
} from "./reducers/risk-maps.reducers";

import {
  typologyAddReducer,
  typologyDeleteReducer,
  typologyListReducer,
} from "./reducers/typologies.reducers";

import {
  conductTypeAddReducer,
  conductTypeDeleteReducer,
  conductTypeListReducer,
  conductAddReducer,
  conductDeleteReducer,
  conductDetailsReducer,
  conductListReducer,
  conductUpdateReducer,
  conductsListFilterReducer,
} from "./reducers/conducts.reducers";

import {
  repositoryAddFileReducer,
  repostoryDeleteFileReducer,
  getRepositoryFilesReducer,
} from "./reducers/repository.reducers";

import {
  stateComplaintAddReducer,
  statesComplaintListReducer,
  stateComplaintDeleteReducer,
  stateComplaintDetailsReducer,
  stateComplaintUpdateReducer,
} from "./reducers/complaints-states.reduces";

import {
  actionComplaintAddReducer,
  actionsComplaintListReducer,
  actionComplaintDeleteReducer,
  actionComplaintDetailsReducer,
  actionComplaintUpdateReducer,
  actionDeleteFileReducer,
  actionsComplaintUserListReducer
} from "./reducers/complaints-actions.reduces";

import { runtime } from "./reducers/navigation.reducers";
import { areaCompanyAddReducer, areaCompanyDeleteReducer, areaCompanyDetailsReducer, areaCompanyListReducer, areaCompanyUpdateReducer } from "./reducers/companies-area.reducer";
import { AddImageReducer } from "./reducers/image.reducer";

const reducer = combineReducers({
  alertsList: alertsListReducer,
  alertsCheck: alertCheckReducer,
  actionComplaintAdd: actionComplaintAddReducer,
  actionsComplaintList: actionsComplaintListReducer,
  actionComplaintDelete: actionComplaintDeleteReducer,
  actionComplaintDetails: actionComplaintDetailsReducer,
  actionComplaintUpdate: actionComplaintUpdateReducer,
  actionDeleteFile: actionDeleteFileReducer,
  actionsComplaintUserList: actionsComplaintUserListReducer,
  categoriesList: categoriesListReducer,
  checklistDetail: checklistDetailsReducer,
  checklistQuestionsFiltered: checklistQuestionsFilterReducer,
  checklistResponse: checklistResponseReducer,
  checklistListItemFiles: getCheckListFilesReducer,
  checklistAddFile: checklistAddFileReducer,
  checklistAddItem: checklistAddItemReducer,
  checklistDeteleFile: checklistDeleteFileReducer,
  checklistDeteleItem: checklistDeleteItemReducer,
  
  areaCompanyList: areaCompanyListReducer,
  areaCompanyAdd: areaCompanyAddReducer,
  areaCompanyDelete: areaCompanyDeleteReducer,
  areaCompanyUpdate: areaCompanyUpdateReducer,
  areaCompanyDetail: areaCompanyDetailsReducer,
  
  categoryCompanyList: categoryCompanyListReducer,
  categoryCompanyAdd: categoryCompanyAddReducer,
  categoryCompanyDetail: categoryCompanyDetailsReducer,
  categoryCompanyDelete: categoryCompanyDeleteReducer,
  categoryCompanyUpdate: categoryCompanyUpdateReducer,
  
  companyList: companyListReducer,
  companyAdd: companyAddReducer,
  companyDetail: companyDetailsReducer,
  companyDelete: companyDeleteReducer,
  
  companyAreaDelete: companyDeleteAreaReducer,

  addImage: AddImageReducer,
  
  companyUpdate: companyUpdateReducer,
  complaintList: complaintListReducer,
  complaintAdd: complaintAddReducer,
  complaintDetail: complaintDetailsReducer,
  rateComplaint: rateComplaintReducer,
  listEmails: listEmailsReducer,
  conductAdd: conductAddReducer,
  conductDelete: conductDeleteReducer,
  conductDetails: conductDetailsReducer,
  conductUpdate: conductUpdateReducer,
  conductList: conductListReducer,
  conductListFiltered: conductsListFilterReducer,
  conductTypeList: conductTypeListReducer,
  conductTypeAdd: conductTypeAddReducer,
  conductTypeDelete: conductTypeDeleteReducer,
  labelList: labelListReducer,
  labelAdd: labelAddReducer,
  labelDetail: labelDetailsReducer,
  labelDelete: labelDeleteReducer,
  labelUpdate: labelUpdateReducer,
  linkList: linkListReducer,
  linkAdd: linkAddReducer,
  linkDetail: linkDetailsReducer,
  linkDelete: linkDeleteReducer,
  linkUpdate: linkUpdateReducer,
  navigation: runtime,
  prioritiesList: prioritiesListReducer,
  questionnaryList: questionnaryListReducer,
  questionnaryListFiltered: questionnariesListFilterReducer,
  questionnaryListUsers: questionnaryListUsersReducer,
  questionnaryListCompanies: questionnaryListCompaniesReducer,
  questionnaryDetail: questionnaryDetailsReducer,
  questionnaryDetalFiltered: questionnariesDetailsFilterReducer,
  questionnaryAdd: questionnaryAddReducer,
  questionnaryResponse: questionnaryResponseReducer,
  questionnaryListItemFiles: getQuestionnaryFilesReducer,
  questionnaryAddFile: questionnaryAddFileReducer,
  questionnaryAddItem: questionnaryAddItemReducer,
  questionnaryDeteleFile: questionnaryDeleteFileReducer,
  questionnaryDeteleItem: questionnaryDeleteItemReducer,
  repositoryFiles: getRepositoryFilesReducer,
  repositoryAddFile: repositoryAddFileReducer,
  repositoryDeteleFile: repostoryDeleteFileReducer,
  riskMapsList: riskMapListReducer,
  riskMapsListFilter: riskmapListFilterReducer,
  riskMapDetails: riskMapDetailsReducer,
  riskMapQuestionsFiltered: riskMapQuestionsFilterReducer,
  riskMapResponse: riskMapResponseReducer,
  riskMapConductAddPlan: riskMapConductAddPlanReducer,
  riskMapAddConduct: riskMapAddConductReducer,
  riskMapDeteleConduct: riskMapDeleteConductReducer,
  riskMapGetStatistics: riskMapStatisticsReducer,
  riskMapConductsByScaleType: riskMapConductsByScaleTypeReducer,
  riskMapReport: riskMapReportReducer,
  statesComplaintList: statesComplaintListReducer,
  stateComplaintAdd: stateComplaintAddReducer,
  stateComplaintDetails: stateComplaintDetailsReducer,
  stateComplaintDelete: stateComplaintDeleteReducer,
  stateComplaintUpdate: stateComplaintUpdateReducer,
  statisticsGeneral: statisticsGeneralgetReducer,
  typologyList: typologyListReducer,
  typologyDelete: typologyDeleteReducer,
  typologyAdd: typologyAddReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userDetails: userDetailsReducer,
  userUpdate: userUpdateReducer,
  userUpdateAdmin: userUpdateAdminReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  navigation: {
    sidebarOpened: true,
    rightSidebarOpened: true,
    mobileSidebarOpened: true,
    activeItem: window.location.pathname,
  },
  userLogin: {
    userInfo: userInfoFromStorage,
  },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
