import {
  ACTIONS_COMPLAINT_DELETE_FAIL,
  ACTIONS_COMPLAINT_DELETE_REQUEST,
  ACTIONS_COMPLAINT_DELETE_RESET,
  ACTIONS_COMPLAINT_DELETE_SUCCESS,
  ACTIONS_COMPLAINT_DETAILS_FAIL,
  ACTIONS_COMPLAINT_DETAILS_REQUEST,
  ACTIONS_COMPLAINT_DETAILS_RESET,
  ACTIONS_COMPLAINT_DETAILS_SUCCESS,
  ACTIONS_COMPLAINT_LIST_FAIL,
  ACTIONS_COMPLAINT_LIST_REQUEST,
  ACTIONS_COMPLAINT_LIST_RESET,
  ACTIONS_COMPLAINT_LIST_SUCCESS,
  ACTIONS_COMPLAINT_ADD_FAIL,
  ACTIONS_COMPLAINT_ADD_REQUEST,
  ACTIONS_COMPLAINT_ADD_RESET,
  ACTIONS_COMPLAINT_ADD_SUCCESS,
  ACTIONS_COMPLAINT_UPDATE_REQUEST,
  ACTIONS_COMPLAINT_UPDATE_SUCCESS,
  ACTIONS_COMPLAINT_UPDATE_FAIL,
  ACTIONS_COMPLAINT_UPDATE_RESET,
  ACTIONS_COMPLAINT_DELETE_FILE_FAIL,
  ACTIONS_COMPLAINT_DELETE_FILE_REQUEST,
  ACTIONS_COMPLAINT_DELETE_FILE_SUCCESS,
  ACTIONS_COMPLAINT_DELETE_FILE_RESET,
  ACTIONS_COMPLAINT_USER_LIST_REQUEST,
  ACTIONS_COMPLAINT_USER_LIST_RESET,
  ACTIONS_COMPLAINT_USER_LIST_SUCCESS,
  ACTIONS_COMPLAINT_USER_LIST_FAIL
} from "../constants/complaints-actions.const";

export const actionComplaintAddReducer = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS_COMPLAINT_ADD_REQUEST:
      return { loading: true };
    case ACTIONS_COMPLAINT_ADD_SUCCESS:
      return { loading: false, addResult: action.payload };
    case ACTIONS_COMPLAINT_ADD_FAIL:
      return { loading: false, error: action.payload };
    case ACTIONS_COMPLAINT_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const actionsComplaintListReducer = (state = { actions: [] }, action) => {
  switch (action.type) {
    case ACTIONS_COMPLAINT_LIST_REQUEST:
      return { loading: true };
    case ACTIONS_COMPLAINT_LIST_SUCCESS:
      return { loading: false, actions: action.payload };
    case ACTIONS_COMPLAINT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case ACTIONS_COMPLAINT_LIST_RESET:
      return { actions: [] };
    default:
      return state;
  }
};

export const actionsComplaintUserListReducer = (state = { actions: [] }, action) => {
  switch (action.type) {
    case ACTIONS_COMPLAINT_USER_LIST_REQUEST:
      return { loading: true };
    case ACTIONS_COMPLAINT_USER_LIST_SUCCESS:
      return { loading: false, actions: action.payload };
    case ACTIONS_COMPLAINT_USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case ACTIONS_COMPLAINT_USER_LIST_RESET:
      return { actions: [] };
    default:
      return state;
  }
};

export const actionComplaintDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS_COMPLAINT_DELETE_REQUEST:
      return { loading: true };
    case ACTIONS_COMPLAINT_DELETE_SUCCESS:
      return { loading: false, deleteResult: action.payload };
    case ACTIONS_COMPLAINT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case ACTIONS_COMPLAINT_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const actionComplaintDetailsReducer = (state = { action: {} }, action) => {
  switch (action.type) {
    case ACTIONS_COMPLAINT_DETAILS_REQUEST:
      return { ...state, loading: true };
    case ACTIONS_COMPLAINT_DETAILS_SUCCESS:
      return { loading: false, action: action.payload };
    case ACTIONS_COMPLAINT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case ACTIONS_COMPLAINT_DETAILS_RESET:
      return { action: {} };
    default:
      return state;
  }
};

export const actionComplaintUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS_COMPLAINT_UPDATE_REQUEST:
      return { loading: true };
    case ACTIONS_COMPLAINT_UPDATE_SUCCESS:
      return { loading: false, updateResult: action.payload };
    case ACTIONS_COMPLAINT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ACTIONS_COMPLAINT_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const actionDeleteFileReducer = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS_COMPLAINT_DELETE_FILE_REQUEST:
      return { loading: true };
    case ACTIONS_COMPLAINT_DELETE_FILE_SUCCESS:
      return { loading: false, deleteResult: action.payload };
    case ACTIONS_COMPLAINT_DELETE_FILE_FAIL:
      return { loading: false, error: action.payload };
    case ACTIONS_COMPLAINT_DELETE_FILE_RESET:
      return {};
    default:
      return state;
  }
};
