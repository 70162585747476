import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Modal from "@mui/material/Modal";

//import components
import Header from "../../../layout/header/header.component";
import Loader from "../../../components/shared/loaders/spinner/sppinner.component";
import Alert from "../../../components/shared/alerts/material-snack-bar/material-snack-bar.component";
import ListTagsComponent from "../../../components/shared/tags/tags.component";
import AddLabelComponent from "../../../components/labels/add-item.component";
//redux import
import { useDispatch, useSelector } from "react-redux";
import { changeActiveSidebarItem } from "../../../redux/actions/navigation.actions";
import { logout } from "../../../redux/actions/user.actions";
import {
  listLabels,
  deleteLabel,
} from "../../../redux/actions/searcher.actions";

import {
  LABELS_DELETE_RESET,
  LABELS_ADD_RESET,
  LABELS_UPDATE_RESET,
} from "../../../redux/constants/searcher.const";

const LabelsListPage = () => {
  const [message, setMessage] = useState(null);
  const [isAddingItem, setIsAddingItem] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const labelList = useSelector((state) => state.labelList);
  const { loading, error, labels } = labelList;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const labelAdd = useSelector((state) => state.labelAdd);
  const { addResult } = labelAdd;

  const labelDelete = useSelector((state) => state.labelDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    deleteLabelResult,
  } = labelDelete;

  const cancelAddItem = () => {
    //e.preventDefault();
    setIsAddingItem(false);

    //myRef.current.reset();
    return false;
  };
  useEffect(() => {
    dispatch(changeActiveSidebarItem(location.pathname));
    if (!userInfo) {
      navigate("/login");
    } else {
      if (userInfo.role !== "EMPRESA") {
        if (addResult) {
          setMessage("Etiqueta agregada con éxito");
          setIsAddingItem(false);

          //executeScroll();
        }

        dispatch({ type: LABELS_ADD_RESET });
        dispatch({ type: LABELS_DELETE_RESET });
        dispatch({ type: LABELS_UPDATE_RESET });

        dispatch(listLabels());
      } else {
        dispatch(logout());
        navigate("/login");
      }
    }
  }, [navigate, userInfo, dispatch, params, addResult, location]);

  const handleDelete = (e, id) => {
    e.preventDefault();
    if (window.confirm("Esta acción no se puede deshacer.")) {
      dispatch(deleteLabel(id));
    }
  };

  const resetMessage = () => {
    //e.preventDefault();
    setMessage(null);
    return false;
  };
  return (
    <>
      {" "}
      {deleteLabelResult && (
        <Alert message="Etiqueta eliminada exitosamente" severity="info" />
      )}
      {message && <Alert message={message} severity="success" />}
      {/* {addResult && (
        <Alert message="Etiqueta agregada exitosamente." severity="success" />
      )} */}
      {errorDelete && <Alert message={errorDelete} />}
      <Header
        title={`Etiquetas`}
        path="Administrar Etiquetas"
        //pathList={BREADCRUM_DATA.questionnairyDetail}
      >
        {userInfo && userInfo.role !== "EMPRESA" && (
          <button
            onClick={() => {
              setIsAddingItem(true);
            }}
            className="btn btn-success btn-icon float-right "
          >
            <i className="zmdi zmdi-plus"></i>
          </button>
        )}
      </Header>
      <div className="container-fluid">
        <div className="row clearfix">
          <div className="col-md-12 col-sm-12 col-xs-12">
            {loadingDelete && (
              <div className="mt-2 text-center">
                {" "}
                <Loader />
              </div>
            )}

            {loading ? (
              <div className="mt-2 text-center">
                {" "}
                <Loader />
              </div>
            ) : error ? (
              <div className="mt-2 text-center">
                <Alert message={error} />
                <h6>{error}</h6>
              </div>
            ) : labels ? (
              labels.length > 0 ? (
                <div className="mt-2">
                  <div className="mb-4">
                    <h6>Lista de Etiquetas</h6>
                  </div>
                  <ListTagsComponent data={labels} onDelete={handleDelete} />
                </div>
              ) : (
                <div className="mt-2 text-center">
                  <h6>Todavía no existen etiquetas agregadas.</h6>
                </div>
              )
            ) : null}
          </div>
          {isAddingItem ? (
            <>
              <Modal
                open={isAddingItem}
                onClose={(e) => {
                  cancelAddItem(e);
                }}
              >
                <AddLabelComponent
                  cancelAddItem={cancelAddItem}
                  resetMessage={resetMessage}
                />
              </Modal>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default LabelsListPage;
