import {
  RISK_MAPS_DETAILS_FAIL,
  RISK_MAPS_DETAILS_REQUEST,
  RISK_MAPS_DETAILS_RESET,
  RISK_MAPS_DETAILS_SUCCESS,
  RISK_MAPS_LIST_FAIL,
  RISK_MAPS_LIST_REQUEST,
  RISK_MAPS_LIST_RESET,
  RISK_MAPS_LIST_SUCCESS,
  RISK_MAPS_RESPONSE_REQUEST,
  RISK_MAPS_RESPONSE_SUCCESS,
  RISK_MAPS_RESPONSE_FAIL,
  RISK_MAPS_RESPONSE_RESET,
  RISK_MAPS_FILTRADA_QUESTIONS_GET,
  RISK_MAPS_FILTRADA_QUESTIONS_RESET,
  RISK_MAPS_DELETE_ITEM_REQUEST,
  RISK_MAPS_DELETE_ITEM_SUCCESS,
  RISK_MAPS_DELETE_ITEM_RESET,
  RISK_MAPS_STATISTICS_REQUEST,
  RISK_MAPS_STATISTICS_SUCCESS,
  RISK_MAPS_STATISTICS_FAIL,
  RISK_MAPS_STATISTICS_RESET,
  RISK_MAPS_ADD_CONDUCT_REQUEST,
  RISK_MAPS_ADD_CONDUCT_SUCCESS,
  RISK_MAPS_ADD_CONDUCT_FAIL,
  RISK_MAPS_ADD_CONDUCT_RESET,
  RISK_MAPS_FILTRADA_GET,
  RISK_MAPS_FILTRADA_RESET,
  RISK_MAPS_PLAN_ADD_REQUEST,
  RISK_MAPS_PLAN_ADD_SUCCESS,
  RISK_MAPS_PLAN_ADD_FAIL,
  RISK_MAPS_PLAN_ADD_RESET,
  RISK_MAPS_CONDUCTS_BY_SCALE_FAIL,
  RISK_MAPS_CONDUCTS_BY_SCALE_REQUEST,
  RISK_MAPS_CONDUCTS_BY_SCALE_SUCCESS,
  RISK_MAPS_CONDUCTS_BY_SCALE_RESET,
  RISK_MAPS_GENERATE_REPORT_REQUEST,
  RISK_MAPS_GENERATE_REPORT_SUCCESS,
  RISK_MAPS_GENERATE_REPORT_FAIL,
  RISK_MAPS_GENERATE_REPORT_RESET,
} from "../constants/risk-maps.const";

export const riskMapListReducer = (state = { riskMaps: [] }, action) => {
  switch (action.type) {
    case RISK_MAPS_LIST_REQUEST:
      return { loading: true };
    case RISK_MAPS_LIST_SUCCESS:
      return { loading: false, riskMaps: action.payload };
    case RISK_MAPS_LIST_FAIL:
      return { loading: false, error: action.payload };
    case RISK_MAPS_LIST_RESET:
      return { riskMaps: [] };
    default:
      return state;
  }
};

export const riskmapListFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case RISK_MAPS_FILTRADA_GET:
      return {
        riskMaps: action.payload,
      };
    case RISK_MAPS_FILTRADA_RESET:
      return {};

    default:
      return state;
  }
};

export const riskMapDetailsReducer = (state = { riskMap: {} }, action) => {
  switch (action.type) {
    case RISK_MAPS_DETAILS_REQUEST:
      return { ...state, loading: true };
    case RISK_MAPS_DETAILS_SUCCESS:
      return { loading: false, riskMap: action.payload };
    case RISK_MAPS_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case RISK_MAPS_DETAILS_RESET:
      return { riskMap: {} };
    default:
      return state;
  }
};

export const riskMapQuestionsFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case RISK_MAPS_FILTRADA_QUESTIONS_GET:
      return {
        questions: action.payload,
      };
    case RISK_MAPS_FILTRADA_QUESTIONS_RESET:
      return {};

    default:
      return state;
  }
};

export const riskMapResponseReducer = (state = {}, action) => {
  switch (action.type) {
    case RISK_MAPS_RESPONSE_REQUEST:
      return { loading: true };
    case RISK_MAPS_RESPONSE_SUCCESS:
      return { loading: false, responseResult: action.payload };
    case RISK_MAPS_RESPONSE_FAIL:
      return { loading: false, error: action.payload };
    case RISK_MAPS_RESPONSE_RESET:
      return {};
    default:
      return state;
  }
};

export const riskMapConductAddPlanReducer = (state = {}, action) => {
  switch (action.type) {
    case RISK_MAPS_PLAN_ADD_REQUEST:
      return { loading: true };
    case RISK_MAPS_PLAN_ADD_SUCCESS:
      return { loading: false, responseResult: action.payload };
    case RISK_MAPS_PLAN_ADD_FAIL:
      return { loading: false, error: action.payload };
    case RISK_MAPS_PLAN_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const riskMapAddConductReducer = (state = {}, action) => {
  switch (action.type) {
    case RISK_MAPS_ADD_CONDUCT_REQUEST:
      return { loading: true };
    case RISK_MAPS_ADD_CONDUCT_SUCCESS:
      return { loading: false, addResult: action.payload };
    case RISK_MAPS_ADD_CONDUCT_FAIL:
      return { loading: false, error: action.payload };
    case RISK_MAPS_ADD_CONDUCT_RESET:
      return {};
    default:
      return state;
  }
};

export const riskMapDeleteConductReducer = (state = {}, action) => {
  switch (action.type) {
    case RISK_MAPS_DELETE_ITEM_REQUEST:
      return { loading: true };
    case RISK_MAPS_DELETE_ITEM_SUCCESS:
      return { loading: false, deleteResult: action.payload };
    case RISK_MAPS_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case RISK_MAPS_DELETE_ITEM_RESET:
      return {};
    default:
      return state;
  }
};

export const riskMapStatisticsReducer = (state = {}, action) => {
  switch (action.type) {
    case RISK_MAPS_STATISTICS_REQUEST:
      return { loading: true };
    case RISK_MAPS_STATISTICS_SUCCESS:
      return { loading: false, stats: action.payload };
    case RISK_MAPS_STATISTICS_FAIL:
      return { loading: false, error: action.payload };
    case RISK_MAPS_STATISTICS_RESET:
      return {};
    default:
      return state;
  }
};

export const riskMapConductsByScaleTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case RISK_MAPS_CONDUCTS_BY_SCALE_REQUEST:
      return { loading: true };
    case RISK_MAPS_CONDUCTS_BY_SCALE_SUCCESS:
      return { loading: false, conducts: action.payload };
    case RISK_MAPS_CONDUCTS_BY_SCALE_FAIL:
      return { loading: false, error: action.payload };
    case RISK_MAPS_CONDUCTS_BY_SCALE_RESET:
      return {};
    default:
      return state;
  }
};

export const riskMapReportReducer = (state = {}, action) => {
  switch (action.type) {
    case RISK_MAPS_GENERATE_REPORT_REQUEST:
      return { loading: true };
    case RISK_MAPS_GENERATE_REPORT_SUCCESS:
      return { loading: false, report: action.payload };
    case RISK_MAPS_GENERATE_REPORT_FAIL:
      return { loading: false, error: action.payload };
    case RISK_MAPS_GENERATE_REPORT_RESET:
      return {};
    default:
      return state;
  }
};