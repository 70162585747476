import { AREAS_COMPANIES_ADD_FAIL, AREAS_COMPANIES_ADD_REQUEST, AREAS_COMPANIES_ADD_RESET, AREAS_COMPANIES_ADD_SUCCESS, AREAS_COMPANIES_DELETE_FAIL, AREAS_COMPANIES_DELETE_REQUEST, AREAS_COMPANIES_DELETE_RESET, AREAS_COMPANIES_DELETE_SUCCESS, AREAS_COMPANIES_DETAILS_FAIL, AREAS_COMPANIES_DETAILS_REQUEST, AREAS_COMPANIES_DETAILS_RESET, AREAS_COMPANIES_DETAILS_SUCCESS, AREAS_COMPANIES_LIST_FAIL, AREAS_COMPANIES_LIST_REQUEST, AREAS_COMPANIES_LIST_RESET, AREAS_COMPANIES_LIST_SUCCESS, AREAS_COMPANIES_UPDATE_FAIL, AREAS_COMPANIES_UPDATE_REQUEST, AREAS_COMPANIES_UPDATE_RESET, AREAS_COMPANIES_UPDATE_SUCCESS } from "../constants/companies-area.const";

export const areaCompanyAddReducer = (state = {}, action) => {
  switch (action.type) {
    case AREAS_COMPANIES_ADD_REQUEST:
      return { loading: true };
    case AREAS_COMPANIES_ADD_SUCCESS:
      return { loading: false, addResult: action.payload };
    case AREAS_COMPANIES_ADD_FAIL:
      return { loading: false, error: action.payload };
    case AREAS_COMPANIES_ADD_RESET:
      return {};
    default:
      return state;
  }
};


export const areaCompanyListReducer = (state = { area: [] }, action) => {
  switch (action.type) {
    case AREAS_COMPANIES_LIST_REQUEST:
      return { loading: true };
    case AREAS_COMPANIES_LIST_SUCCESS:
      return { loading: false, area: action.payload };
    case AREAS_COMPANIES_LIST_FAIL:
      return { loading: false, error: action.payload };
    case AREAS_COMPANIES_LIST_RESET:
      return { area: [] };
    default:
      return state;
  }
};

export const areaCompanyDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case AREAS_COMPANIES_DELETE_REQUEST:
      return { loading: true };
    case AREAS_COMPANIES_DELETE_SUCCESS:
      return { loading: false, deleteCompanyResult: action.payload };
    case AREAS_COMPANIES_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case AREAS_COMPANIES_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const areaCompanyUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case AREAS_COMPANIES_UPDATE_REQUEST:
      return { loading: true };
    case AREAS_COMPANIES_UPDATE_SUCCESS:
      return { loading: false, updateResult: action.payload };
    case AREAS_COMPANIES_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case AREAS_COMPANIES_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const areaCompanyDetailsReducer = (state = { area: {} }, action) => {
  switch (action.type) {
    case AREAS_COMPANIES_DETAILS_REQUEST:
      return { ...state, loading: true };
    case AREAS_COMPANIES_DETAILS_SUCCESS:
      return { loading: false, area: action.payload };
    case AREAS_COMPANIES_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case AREAS_COMPANIES_DETAILS_RESET:
      return { area: {} };
    default:
      return state;
  }
};
