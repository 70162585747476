import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

//import components
import Header from "../../layout/header/header.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";

//constant
import BREADCRUM_DATA from "../../constants/breadcrumb.const";

//redux
import { useDispatch, useSelector } from "react-redux";
import { addUser, logout } from "../../redux/actions/user.actions";

const UserAddPage = () => {
  const [message, setMessage] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, register: registerResult } = userRegister;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    mode: "onChange",
    /* reValidateMode: "onChange", */
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  const onSubmit = (data) => {
    if (data.password !== data.confirmPassword) {
      setMessage("Las contraseñas no son iguales");
    } else {
      dispatch(
        addUser(
          data.name,
          data.lastname,
          data.email,
          data.phone,
          data.username,
          data.password,
          data.role
        )
      );
    }
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else if (userInfo.role === "USUARIO") {
      dispatch(logout());
    }
    if (registerResult) {
      navigate("/usuarios");
    }
  }, [navigate, registerResult, userInfo, dispatch]);

  return (
    <>
      <Header
        title="Crear Usuario"
        path="Crear Usuario"
        pathList={BREADCRUM_DATA.userAdd}
      >
        <button
          className="btn btn-success btn-icon float-right right_icon_toggle_btn"
          type="button"
        >
          <i className="zmdi zmdi-check"></i>
        </button>
      </Header>
      <div className="container-fluid">
        <div className="row clearfix">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h2>
                  Ingresar información del nuevo <strong>usuario</strong>
                </h2>
              </div>
              <div className="body">
                {message && (
                  <div className="mb-3">
                    <Alert message={message} />
                  </div>
                )}
                {error && (
                  <div className="mb-3">
                    <Alert message={error} />
                  </div>
                )}
                {loading && (
                  <div className="text-center mb-2">
                    <Loader />
                  </div>
                )}
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <input
                        style={{ border: errors.name ? "1px solid red" : "" }}
                        type="text"
                        className="form-control"
                        placeholder="Nombre"
                        {...register("name", {
                          required: true,
                          minLength: 2,
                        })}
                      />
                      {errors.name?.type === "required" && (
                        <span style={{ color: "red", padding: "4px 2px" }}>
                          El nombre es requerido.
                        </span>
                      )}
                      {errors.name?.type === "minLength" && (
                        <span style={{ color: "red" }}>
                          El nombre debe tener al menos dos caracteres.
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <input
                        style={{
                          border: errors.lastname ? "1px solid red" : "",
                        }}
                        type="text"
                        className="form-control"
                        placeholder="Apellido"
                        {...register("lastname", {
                          required: true,
                          minLength: 2,
                        })}
                      />
                      {errors.lastname?.type === "required" && (
                        <span style={{ color: "red", padding: "4px 2px" }}>
                          El apellido es requerido.
                        </span>
                      )}
                      {errors.lastname?.type === "minLength" && (
                        <span style={{ color: "red" }}>
                          El apellido debe tener al menos dos caracteres.
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      <input
                        style={{
                          border: errors.phone ? "1px solid red" : "",
                        }}
                        type="tel"
                        className="form-control"
                        placeholder="Teléfono"
                        {...register("phone", {
                          required: true,

                          pattern: /[0][0-9]{9}/,
                        })}
                      />
                      {errors.phone?.type === "required" && (
                        <span style={{ color: "red" }}>
                          El teléfono es requerido.
                        </span>
                      )}
                      {errors.phone?.type === "pattern" && (
                        <span style={{ color: "red" }}>
                          El número debe comenzar con 0 y tener 10 dígitos.
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      <input
                        style={{
                          border: errors.email ? "1px solid red" : "",
                        }}
                        type="email"
                        className="form-control"
                        placeholder="Correo"
                        {...register("email", {
                          required: true,

                          pattern: /\S+@\S+\.\S+/,
                        })}
                      />
                      {errors.email?.type === "required" && (
                        <span style={{ color: "red" }}>
                          El correo es requerido.
                        </span>
                      )}
                      {errors.email?.type === "pattern" && (
                        <span style={{ color: "red" }}>
                          El valor ingresado no coincide con el formato de
                          correo electrónico
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      <input
                        style={{
                          border: errors.username ? "1px solid red" : "",
                        }}
                        type="text"
                        className="form-control"
                        placeholder="Nombre Usuario"
                        {...register("username", {
                          required: true,
                          minLength: 3,
                        })}
                      />
                      {errors.username?.type === "required" && (
                        <span style={{ color: "red", padding: "4px 2px" }}>
                          El nombre de usuario es requerido.
                        </span>
                      )}
                      {errors.username?.type === "minLength" && (
                        <span style={{ color: "red" }}>
                          El nombre de usuario debe tener al menos dos
                          caracteres.
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <input
                        style={{
                          border: errors.password ? "1px solid red" : "",
                        }}
                        type="password"
                        className="form-control"
                        placeholder="Contraseña"
                        {...register("password", {
                          required: true,

                          minLength: 6,
                        })}
                      />
                      {errors.password?.type === "required" && (
                        <span style={{ color: "red" }}>
                          La contraseña es requerida.
                        </span>
                      )}
                      {errors.password?.type === "minLength" && (
                        <span style={{ color: "red" }}>
                          La contraseña debe tener al menos 6 caracteres.
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <input
                        style={{
                          border: errors.confirmPassword ? "1px solid red" : "",
                        }}
                        {...register("confirmPassword", {
                          required: true,

                          minLength: 6,
                        })}
                        type="password"
                        className="form-control"
                        placeholder="Repetir Contraseña"
                      />
                      {errors.confirmPassword?.type === "required" && (
                        <span style={{ color: "red" }}>
                          La contraseña es requerida.
                        </span>
                      )}
                      {errors.confirmPassword?.type === "minLength" && (
                        <span style={{ color: "red" }}>
                          La contraseña debe tener al menos 6 caracteres.
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <h5>
                      <strong>Rol</strong>
                    </h5>
                    <div className="radio">
                      <input
                        id="procheck1"
                        {...register("role")}
                        type="radio"
                        value="EMPRESA"
                      />
                      <label for="procheck1">Empresa</label>
                    </div>
                    <div className="radio">
                      <input
                        id="procheck2"
                        {...register("role")}
                        type="radio"
                        value="SUPERVISOR"
                      />

                      <label for="procheck2">Supervisor</label>
                    </div>
                    <div className="radio">
                      <input
                        id="procheck3"
                        {...register("role")}
                        type="radio"
                        value="SUPERADMIN"
                      />

                      <label for="procheck3">Súper Admin</label>
                    </div>
                    <div className="radio">
                      <input
                        id="procheck4"
                        {...register("role")}
                        type="radio"
                        value="AGENTE DE CUMPLIMIENTO"
                      />

                      <label for="procheck4">Agente de Cumplimiento</label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button
                      className="btn btn-success"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Guardar Usuario
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserAddPage;
