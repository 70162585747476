import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";

//import components
import Header from "../../layout/header/header.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";
import Snackbar from "../../components/shared/alerts/material-snack-bar/material-snack-bar.component";

//constant
import BREADCRUM_DATA from "../../constants/breadcrumb.const";

//redux

import { useDispatch, useSelector } from "react-redux";
import { addItem } from "../../redux/actions/risk-maps.actions";
import { listConductType } from "../../redux/actions/conducts.actions";
import { listTypologies } from "../../redux/actions/typologies.actions";

import { logout } from "../../redux/actions/user.actions";

const RiskMapAddConductPage = () => {
  const params = useParams();
  // console.log(params);
  const [name, setname] = useState("");
  const [typologySelected, setTypologySelected] = useState("");
  const [typeSelected, setTypeSelected] = useState("");
  const [article, setArticle] = useState("");
  const [message, setMessage] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const riskMapAddConduct = useSelector((state) => state.riskMapAddConduct);
  const { loading, error, addResult } = riskMapAddConduct;

  const conductTypeList = useSelector((state) => state.conductTypeList);
  const {
    loading: loadingTypes,
    error: errorTypes,
    conducts_type,
  } = conductTypeList;
  //console.log(userSelected);

  const typologyList = useSelector((state) => state.typologyList);
  const {
    loading: loadingTypologies,
    error: errorTypologies,
    typologies,
  } = typologyList;

  const filterUTypologies = (inputValue) => {
    return typologies.filter((i) =>
      i.name.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseOptionsTypologies = (inputValue) =>
    new Promise((resolve) => {
      resolve(filterUTypologies(inputValue));
    });

  const filterTypes = (inputValue) => {
    return conducts_type.filter((i) =>
      i.name.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseOptionsTypes = (inputValue) =>
    new Promise((resolve) => {
      resolve(filterTypes(inputValue));
    });

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else if (userInfo.role === "USUARIO") {
      dispatch(logout());
    } else {
      if (addResult) {
        navigate(-1);
      }
      dispatch(listConductType());
      dispatch(listTypologies());
    }
    /* if (addResult) {
      navigate("/empresas");
    } */
  }, [navigate, userInfo, dispatch, addResult]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (typeSelected && typologySelected && article && name) {
      dispatch(
        addItem(typologySelected, article, typeSelected, name, params.id)
      );
    } else {
      setMessage("Por favor llene todos los campos requeridos.");
    }
    //console.log(selectedDay.toISOString().slice(0, 10));
    //console.log(userSelected);
    //console.log(userCompanieSelected);
    //console.log(companieSelected);
    //console.log(name);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <button
        className="btn mt-3 btn-icon float-left right_icon_toggle_btn"
        onClick={(e) => {
          handleBack();
        }}
      >
        <i className="zmdi zmdi-arrow-left"></i>
      </button>
      <Header
        title="Agregar Conducta"
        path="Agregar Conducta"
        pathList={BREADCRUM_DATA.riskMapAddConduct}
      >
        {message && <Snackbar message="Por favor llene todos los campos." />}
        <button
          className="btn btn-success btn-icon float-right right_icon_toggle_btn"
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          <i className="zmdi zmdi-check"></i>
        </button>
      </Header>
      {error && <Snackbar message={error} />}
      {loading && <Loader />}
      {errorTypes || errorTypologies ? (
        <Alert message="Algo salió mal, por favor, intentelo más tarde." />
      ) : loadingTypes || loadingTypologies ? (
        <div className="text-center mt-5">
          <Loader />
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-md-12">
              <div className="card">
                <div className="header">
                  <h2>
                    Ingresar información de la nueva <strong>conducta</strong>
                  </h2>
                </div>
                <div className="body">
                  <div className="row clearfix">
                    <div className="col-lg-8 col-md-12 mt-3">
                      <div className="form-group">
                        <label className="form_label">1. Artículo:</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Artículo"
                          value={article}
                          onChange={(e) => {
                            setArticle(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <hr />
                  <h6>2. Seleccionar una tipología y un tipo</h6>
                  <div className="row clearfix">
                    <div className="col-lg-4 col-md-12 mt-3">
                      <div className="form-group">
                        <label className="form_label">Tipología:</label>
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          placeholder="Seleccione una opción"
                          loadOptions={promiseOptionsTypologies}
                          getOptionLabel={(option) => `${option.name}`}
                          getOptionValue={(e) => e.id}
                          /*  onInputChange={(e) =>
                            setUserSelected(e.id ? e.id : "")
                          } */
                          onChange={(e) =>
                            setTypologySelected(e.id ? e.id : "")
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12 mt-3">
                      <div className="form-group">
                        <label className="form_label">Tipo de Conducta:</label>
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          placeholder="Seleccione una opción"
                          loadOptions={promiseOptionsTypes}
                          getOptionLabel={(option) => `${option.name}`}
                          getOptionValue={(e) => e.id}
                          /* onInputChange={(e) =>
                            setUserCompanieSelected(e.id ? e.id : "")
                          } */
                          onChange={(e) => setTypeSelected(e.id ? e.id : "")}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <h6>3. Ingresar un nombre para la conducta.</h6>
                  <textarea
                    rows={2}
                    type="text"
                    className="form-control"
                    placeholder="Nombre"
                    value={name}
                    onChange={(e) => {
                      setname(e.target.value);
                    }}
                  />
                  <div className="col-md-12 mt-3">
                    <button className="btn btn-success" onClick={handleSubmit}>
                      <strong style={{ fontSize: "14px" }}>
                        Crear Conducta
                      </strong>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RiskMapAddConductPage;
