import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

//import components
import Header from "../../layout/header/header.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";

//redux import
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/user.actions";
import {
  updateCategoryCompany,
  getCategoryCompanyDetails,
} from "../../redux/actions/companies-categories.actions";

//constant
import BREADCRUM_DATA from "../../constants/breadcrumb.const";

const CategoriesUpdatePage = () => {

  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  
  const categoryUpdate = useSelector((state) => state.categoryCompanyUpdate);
  const { loading, error, updateResult } = categoryUpdate;
  const categoryDetail = useSelector((state) => state.categoryCompanyDetail);
  
  const {
    loading: loadingDetails,
    error: errorDetails,
    category,
  } = categoryDetail;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const {
    register,
    handleSubmit,

    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    /* reValidateMode: "onChange", */
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  const onSubmit = (data) => {
    if (window.confirm("Desea actualizar la información ?")) {
      dispatch(
        updateCategoryCompany(
          params.id,
          data.name,
          data.description
        )
      );
    }
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else if (userInfo.role === "USUARIO") {
      dispatch(logout());
    } else {
      if (!category.name) {
        dispatch(getCategoryCompanyDetails(params.id));
      } else {
        if (category.name !== "undefined") {
          reset({
            name: category.name,
            address: category.address,
            email: category.email,
            description: category.description,
          });
        }
      }
    }
    if (updateResult) {
      navigate("/categorias");
    }
  }, [navigate, updateResult, userInfo, dispatch, params, reset, category]);

  return (
    <>
      <Header
        title="Actualizar Categoría"
        path="Actualizar Categoría"
        pathList={BREADCRUM_DATA.categoryCompanyUpdate}
      >
        <button
          className="btn btn-success btn-icon float-right"
          onClick={handleSubmit(onSubmit)}
        >
          <i className="zmdi zmdi-check"></i>
        </button>
      </Header>
      <div className="container-fluid">
        <div className="row clearfix">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h2>
                  Actualizar <strong>información</strong>
                </h2>
              </div>
              <div className="body">
                {error && (
                  <div className="mb-3">
                    <Alert message={error} />
                  </div>
                )}
                {loading && (
                  <div className="text-center mb-2">
                    <Loader />
                  </div>
                )}
                {errorDetails ? (
                  <div className="mb-3">
                    <Alert message={errorDetails} />
                  </div>
                ) : loadingDetails ? (
                  <div className="text-center mb-2">
                    <Loader />
                  </div>
                ) : (
                  <div className="row justify-content-center clearfix">
                    <div className="col-md-7">
                      <div className="form-group">
                        <label className="form_label">Nombre:</label>
                        <input
                          style={{ border: errors.name ? "1px solid red" : "" }}
                          type="text"
                          className="form-control"
                          placeholder="Nombre"
                          {...register("name", {
                            required: true,
                            minLength: 2,
                          })}
                        />
                        {errors.name?.type === "required" && (
                          <span style={{ color: "red", padding: "4px 2px" }}>
                            El nombre es requerido.
                          </span>
                        )}
                        {errors.name?.type === "minLength" && (
                          <span style={{ color: "red" }}>
                            El nombre debe tener al menos dos caracteres.
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="form-group">
                        <label className="form_label">Descripción:</label>
                        <textarea
                          style={{
                            border: errors.description ? "1px solid red" : "",
                          }}
                          type="text-area"
                          rows="6"
                          className="form-control"
                          placeholder="Descripción"
                          {...register("description", {
                            required: true,

                            minLength: 6,
                          })}
                        />
                        {errors.description?.type === "required" && (
                          <span style={{ color: "red" }}>
                            La descripción es requerida.
                          </span>
                        )}
                        {errors.description?.type === "minLength" && (
                          <span style={{ color: "red" }}>
                            La descripción debe tener al menos 6 caracteres.
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-7">
                      <button
                        className="btn btn-success"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Actualizar Categoría
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CategoriesUpdatePage