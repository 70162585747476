import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

//import components

import Loader from "../../shared/loaders/spinner/sppinner.component";
import Alert from "../../shared/alerts/material-snack-bar/material-snack-bar.component";

//redux import
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../../redux/actions/user.actions";
import { questionnaryResponse } from "../../../redux/actions/questionnaires.actions";

//import styles from "./item-edit.module.scss";

const EditItem = ({
  id,
  category,
  question,
  state,
  answer,
  onCancel,
  resetMessage,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const questionnaryResponseState = useSelector(
    (state) => state.questionnaryResponse
  );
  const { loading, error, responseResult } = questionnaryResponseState;

  const {
    register,
    handleSubmit,

    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    /* reValidateMode: "onChange", */
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  const onSubmit = (data) => {
    //console.log(data);

    const stateId = data.state === "1" ? 1 : 2;
    dispatch(questionnaryResponse(id, stateId, data.answer, ""));

    //setMessage(true);
  };

  useEffect(() => {
    if (!userInfo) {
      dispatch(logout());
      navigate("/login");
    } else {
      resetMessage();
      if (state || answer) {
        reset({
          state: state ? (state.id === 1 ? "1" : "2") : null,
          answer: answer ? answer : null,
        });
      }
    }
    if (responseResult) {
      onCancel();
    }
  }, [
    navigate,
    userInfo,
    dispatch,
    reset,
    answer,
    state,
    responseResult,
    onCancel,
    resetMessage,
  ]);
  return (
    <div className="container-fluid">
      <div className="row clearfix">
        <div className="col-md-12">
          <div className="card">
            <div className="header">
              <h2>
                Responder <strong>Pregunta</strong>
              </h2>
            </div>
            <p>
              <strong>Categoría:</strong> {category}
            </p>
            <p>
              <strong>Pregunta:</strong> {id}.- {question}
            </p>
            <div className="body">
              {loading && (
                <div className="mt-2 mb-2">
                  <Loader />
                </div>
              )}
              {error && (
                <div className="mt-2 mb-2">
                  <Alert message={error} />
                </div>
              )}
              <div className="row clearfix">
                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label className="form_label">
                      Desarrolle su respuesta:
                    </label>
                    <textarea
                      rows="6"
                      style={{ border: errors.name ? "1px solid red" : "" }}
                      type="text"
                      className="form-control"
                      placeholder="Ingrese aquí su respuesta."
                      {...register("answer")}
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <label className="form_label">Respuesta:</label>

                  <div className="radio">
                    <input
                      id="procheck13"
                      {...register("state")}
                      type="radio"
                      value={1}
                    />
                    <label for="procheck13">Si</label>
                  </div>
                  <div className="radio">
                    <input
                      id="procheck2"
                      {...register("state")}
                      type="radio"
                      value={2}
                    />

                    <label for="procheck2">No</label>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12 mt-4">
                  <button
                    onClick={handleSubmit(onSubmit)}
                    className="btn btn-success btn-sm"
                    title="Guardar"
                  >
                    <i className="zmdi zmdi-check mr-2 pt-1 pb-1"></i>{" "}
                    <strong>GUARDAR CAMBIOS</strong>
                  </button>
                  <br />
                  <button
                    onClick={onCancel}
                    className="btn btn-error btn-sm"
                    style={{ minWidth: "162px" }}
                    title="Cancelar"
                  >
                    <i className="zmdi zmdi-check mr-2 pt-1 pb-1"></i>{" "}
                    <strong>CANCELAR</strong>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditItem;
