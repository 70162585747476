import {
  REPOSITORY_GET_FILES_REQUEST,
  REPOSITORY_GET_FILES_SUCCESS,
  REPOSITORY_GET_FILES_FAIL,
  REPOSITORY_GET_FILES_RESET,
  REPOSITORY_ADD_FILE_REQUEST,
  REPOSITORY_ADD_FILE_SUCCESS,
  REPOSITORY_ADD_FILE_FAIL,
  REPOSITORY_ADD_FILE_RESET,
  REPOSITORY_DELETE_FILE_REQUEST,
  REPOSITORY_DELETE_FILE_SUCCESS,
  REPOSITORY_DELETE_FILE_FAIL,
  REPOSITORY_DELETE_FILE_RESET,
} from "../constants/repository.const";

export const getRepositoryFilesReducer = (state = { files: {} }, action) => {
  switch (action.type) {
    case REPOSITORY_GET_FILES_REQUEST:
      return { ...state, loading: true };
    case REPOSITORY_GET_FILES_SUCCESS:
      return { loading: false, files: action.payload };
    case REPOSITORY_GET_FILES_FAIL:
      return { loading: false, error: action.payload };
    case REPOSITORY_GET_FILES_RESET:
      return { files: {} };
    default:
      return state;
  }
};

export const repositoryAddFileReducer = (state = {}, action) => {
  switch (action.type) {
    case REPOSITORY_ADD_FILE_REQUEST:
      return { loading: true };
    case REPOSITORY_ADD_FILE_SUCCESS:
      return { loading: false, addResult: action.payload };
    case REPOSITORY_ADD_FILE_FAIL:
      return { loading: false, error: action.payload };
    case REPOSITORY_ADD_FILE_RESET:
      return {};
    default:
      return state;
  }
};

export const repostoryDeleteFileReducer = (state = {}, action) => {
  switch (action.type) {
    case REPOSITORY_DELETE_FILE_REQUEST:
      return { loading: true };
    case REPOSITORY_DELETE_FILE_SUCCESS:
      return { loading: false, deleteResult: action.payload };
    case REPOSITORY_DELETE_FILE_FAIL:
      return { loading: false, error: action.payload };
    case REPOSITORY_DELETE_FILE_RESET:
      return {};
    default:
      return state;
  }
};