import axios from "axios";

/* const apiUrl =
  process.env.NODE_ENV === "production"
    ? "https://lawyers-test.herokuapp.com/api/"
    : "https://lawyers-test.herokuapp.com/api/"; */
/*  const apiUrl ="http://localhost:8383/api/" */
/*     const apiUrl ="https://rigori-eiteck.dev.deaego.com/api/" */
const apiUrl = "https://rigori-prod.up.railway.app/api/";
/*  const apiUrl ="https://rigori-server-app-production.up.railway.app/api/" */
const url = apiUrl;

const api = axios.create( {
  baseURL: url,
} );

export default api;
