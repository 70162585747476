import api from "../../api/rigori.api";
import {
  CATEGORIES_LIST_FAIL,
  CATEGORIES_LIST_REQUEST,
  CATEGORIES_LIST_SUCCESS,
} from "../constants/categories.const";

import { logout } from "./user.actions";

export const listCategories = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CATEGORIES_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.get(`/categories`, config);

    dispatch({
      type: CATEGORIES_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: CATEGORIES_LIST_FAIL,
      payload: message,
    });
  }
};
