import api from "../../api/rigori.api";

import {
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_ADMIN_FAIL,
  USER_UPDATE_ADMIN_REQUEST,
  USER_UPDATE_ADMIN_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
} from "../constants/user.const";

import { LINKS_LIST_RESET } from "../constants/searcher.const";

export const login = (username, password) => async (dispatch) => {
  //DISPATCH RESETS

  ////////
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await api.post(
      "/auth/signin",
      { username, password },
      config
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    //set user to localStorage
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: LINKS_LIST_RESET });
  /*   dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_DETAILS_RESET });

  dispatch({ type: USER_LIST_RESET });
  dispatch({ type: RESERVA_PLACE_RESET });
  dispatch({ type: RESERVA_DETAILS_RESET });
  dispatch({ type: RESERVA_LIST_MY_RESET });
  dispatch({ type: RESERVA_ADD_CODIGO_RESET });
  dispatch({ type: RESERVA_DELETE_RESET });
  dispatch({ type: RECARGA_TRANSFERENCIA_RESULT_RESET });
  dispatch({ type: RECARGA_PLACE_RESET });
  dispatch({ type: RESERVA_DELETE_USER });
  dispatch({ type: RESERVA_DELETE_COURT });
  dispatch({ type: RESERVA_DELETE_SCHEDULE }); */
};

export const addUser =
  (name, lastname, email, phone, username, password, role) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await api.post(
        "/auth/signup",
        { name, lastname, email, phone, username, password, role },
        config
      );

      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Unauthorized") {
        dispatch(logout());
      }
      dispatch({
        type: USER_REGISTER_FAIL,
        payload: message,
      });
    }
  };

export const listUsers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.get(`/users`, config);

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: USER_LIST_FAIL,
      payload: message,
    });
  }
};

export const updateUser =
  (userId, name, lastname, email, phone, username) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await api.patch(
        `/users/${userId}`,
        { name, lastname, email, phone, username },
        config
      );

      dispatch({
        type: USER_UPDATE_SUCCESS,
        payload: data,
      });
      dispatch(getUserDetails(userId));
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Unauthorized") {
        dispatch(logout());
      }
      dispatch({
        type: USER_UPDATE_FAIL,
        payload: message,
      });
    }
  };

export const updateUserAdmin =
  (userId, name, lastname, email, phone, username, role) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_UPDATE_ADMIN_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await api.patch(
        `/users/superadmin/${userId}`,
        { name, lastname, email, phone, username, role },
        config
      );

      dispatch({
        type: USER_UPDATE_ADMIN_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Unauthorized") {
        dispatch(logout());
      }
      dispatch({
        type: USER_UPDATE_ADMIN_FAIL,
        payload: message,
      });
    }
  };

export const deleteUser = (userId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.delete(`/users/${userId}`, config);
    //console.log(data);

    dispatch({
      type: USER_DELETE_SUCCESS,
      payload: data,
    });
    dispatch(listUsers());
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: USER_DELETE_FAIL,
      payload: message,
    });
  }
};

export const getUserDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await api.get(`/users/${id}`, config);

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
