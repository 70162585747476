import api from "../../api/rigori.api";

import {
  CHECKLIST_ADD_FILE_FAIL,
  CHECKLIST_ADD_FILE_REQUEST,
  CHECKLIST_ADD_FILE_SUCCESS,
  CHECKLIST_ADD_ITEM_FAIL,
  CHECKLIST_ADD_ITEM_REQUEST,
  CHECKLIST_ADD_ITEM_SUCCESS,
  CHECKLIST_DELETE_FILE_FAIL,
  CHECKLIST_DELETE_FILE_REQUEST,
  CHECKLIST_DELETE_FILE_SUCCESS,
  CHECKLIST_DETAILS_FAIL,
  CHECKLIST_DETAILS_REQUEST,
  CHECKLIST_DETAILS_SUCCESS,
  CHECKLIST_GET_FILE_FAIL,
  CHECKLIST_GET_FILE_REQUEST,
  CHECKLIST_GET_FILE_SUCCESS,
  CHECKLIST_LIST_FAIL,
  CHECKLIST_LIST_REQUEST,
  CHECKLIST_LIST_SUCCESS,
  CHECKLIST_RESPONSE_FAIL,
  CHECKLIST_RESPONSE_REQUEST,
  CHECKLIST_RESPONSE_SUCCESS,
  CHECKLIST_DELETE_ITEM_REQUEST,
  CHECKLIST_DELETE_ITEM_SUCCESS,
  CHECKLIST_DELETE_ITEM_FAIL,
  CHECKLIS_FILTRADA_DETAILS_GET,
} from "../constants/checklist.const";

import { logout } from "./user.actions";

export const listQuestionnaries = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CHECKLIST_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.get(`/quizzes`, config);

    dispatch({
      type: CHECKLIST_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: CHECKLIST_LIST_FAIL,
      payload: message,
    });
  }
};

export const getChecklistDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CHECKLIST_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await api.get(`/checklists/${id}`, config);

    dispatch({
      type: CHECKLIST_DETAILS_SUCCESS,
      payload: data,
    });
    dispatch({
      type: CHECKLIS_FILTRADA_DETAILS_GET,
      payload: data.checklistItems,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: CHECKLIST_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const getCheckListQuestionsByCondition =
  (filter) => async (dispatch, getState) => {
    //const reservas = getState().reservas.reservas;
    //console.log(filter);
    //console.log(reservas);
    var checklistFilter;
    if (filter === "Todas") {
      checklistFilter = getState().checklistDetail.checklist.checklistItems;
    } else if (filter === "answered") {
      checklistFilter =
        getState().checklistDetail.checklist.checklistItems.filter(function (
          element
        ) {
          return element.state;
        });
    } else if (filter === "notAnswered") {
      checklistFilter =
        getState().checklistDetail.checklist.checklistItems.filter(function (
          element
        ) {
          return !element.state;
        });
    } else if (filter === "perform") {
      checklistFilter =
        getState().checklistDetail.checklist.checklistItems.filter(function (
          element
        ) {
          return element.state && element.state.id === 1;
        });
    } else if (filter === "notPerform") {
      //console.log("dsfsdsfd");
      checklistFilter =
        getState().checklistDetail.checklist.checklistItems.filter(function (
          element
        ) {
          return element.state && element.state.id === 2;
        });
    } else {
      checklistFilter = getState().checklistDetail.checklist.checklistItems;
    }

    dispatch({
      type: CHECKLIS_FILTRADA_DETAILS_GET,
      payload: checklistFilter,
    });
  };

//descending order
export const getCheckListQuestionsBySorting =
  (filter) => async (dispatch, getState) => {
    var questionnairesSorted;
    if (filter === "date") {
      questionnairesSorted = getState().questionnaryListFiltered.questionnaries;
    } else if (filter === "progress") {
      questionnairesSorted =
        getState().questionnaryListFiltered.questionnaries.sort(function (
          a,
          b
        ) {
          return parseFloat(b.progress) - parseFloat(a.progress);
        });
    } else if (filter === "deadline") {
      questionnairesSorted =
        getState().questionnaryListFiltered.questionnaries.sort(function (
          a,
          b
        ) {
          // console.log(Date(b.deadline));
          return new Date(a.deadline) - new Date(b.deadline);
        });
    } else if (filter === "supervisor") {
      questionnairesSorted =
        getState().questionnaryListFiltered.questionnaries.sort(function (
          a,
          b
        ) {
          return b.supervisor.id - a.supervisor.id;
        });
    } else if (filter === "company") {
      questionnairesSorted =
        getState().questionnaryListFiltered.questionnaries.sort(function (
          a,
          b
        ) {
          return b.company.id - a.company.id;
        });
    } else if (filter === "") {
      questionnairesSorted = getState().questionnaryList.questionnaries;
    } else {
      questionnairesSorted = getState().questionnaryListFiltered.questionnaries;
    }

    dispatch({
      type: CHECKLIS_FILTRADA_DETAILS_GET,
      payload: questionnairesSorted,
    });
  };

export const getCheckListQuestionsBySearching =
  (filter) => async (dispatch, getState) => {
    //const reservas = getState().reservas.reservas;
    const filterNormalized = filter.trim().toLowerCase();
    //console.log(reservas);
    var checklistFilter;
    if (filter === "") {
      checklistFilter = getState().checklistDetail.checklist.checklistItems;
    } else {
      checklistFilter =
        getState().checklistDetail.checklist.checklistItems.filter(function (
          element
        ) {
          return (
            (element.item.category.name &&
              element.item.category.name
                .toLowerCase()
                .includes(filterNormalized)) ||
            (element.item.category.description !== null &&
              element.item.category.description
                .toLowerCase()
                .includes(filterNormalized)) ||
            (element.item.question !== null &&
              element.item.question.toLowerCase().includes(filterNormalized)) ||
            (element.note !== null &&
              element.note.toLowerCase().includes(filterNormalized))
          );
        });
    }

    dispatch({
      type: CHECKLIS_FILTRADA_DETAILS_GET,
      payload: checklistFilter,
    });
  };

export const getChecklistFiles = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CHECKLIST_GET_FILE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await api.get(`/checklists-items/files/${id}`, config);

    dispatch({
      type: CHECKLIST_GET_FILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: CHECKLIST_GET_FILE_FAIL,
      payload: message,
    });
  }
};

export const addItem =
  (question, categoryId, checklistId) => async (dispatch, getState) => {
    // console.log(categoryId);
    try {
      dispatch({
        type: CHECKLIST_ADD_ITEM_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await api.post(
        "/checklists-items/add-item",
        { question, categoryId, checklistId },
        config
      );

      dispatch({
        type: CHECKLIST_ADD_ITEM_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Unauthorized") {
        dispatch(logout());
      }
      dispatch({
        type: CHECKLIST_ADD_ITEM_FAIL,
        payload: message,
      });
    }
  };

export const checklistResponse =
  (checklistItemId, stateId, note) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CHECKLIST_RESPONSE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await api.post(
        "/checklists-items/answer",
        { checklistItemId, stateId, note },
        config
      );

      dispatch({
        type: CHECKLIST_RESPONSE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Unauthorized") {
        dispatch(logout());
      }
      dispatch({
        type: CHECKLIST_RESPONSE_FAIL,
        payload: message,
      });
    }
  };

export const addFile = (id, files) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CHECKLIST_ADD_FILE_REQUEST,
    });
    let formData = new FormData();

    /* files.forEach((element) => {
        formData.append("files", element);
      }); */
    const {
      userLogin: { userInfo },
    } = getState();
    for (let i = 0; i < files.length; i++) {
      //console.log(files[i]);
      formData.append("file", files[i]);
      //console.log(formData);
    }
    //console.log(files);
    //formData.append("file", files[0]);
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    const { data } = await api.patch(
      `/checklists-items/add-file/${id}`,
      formData,
      config
    );
    dispatch({
      type: CHECKLIST_ADD_FILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    /* if (message === "Unauthorized") {
        dispatch(logout());
      } */
    dispatch({
      type: CHECKLIST_ADD_FILE_FAIL,
      payload: message,
    });
  }
};
export const deleteFile = (fileId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CHECKLIST_DELETE_FILE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.delete(`/checklists-items/${fileId}`, config);
    //console.log(data);

    dispatch({
      type: CHECKLIST_DELETE_FILE_SUCCESS,
      payload: data,
    });
    // dispatch(listCompanies());
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: CHECKLIST_DELETE_FILE_FAIL,
      payload: message,
    });
  }
};

export const deleteItem = (itemId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CHECKLIST_DELETE_ITEM_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.delete(
      `/checklists-items/item/${itemId}`,
      config
    );
    //console.log(data);

    dispatch({
      type: CHECKLIST_DELETE_ITEM_SUCCESS,
      payload: data,
    });
    // dispatch(listCompanies());
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: CHECKLIST_DELETE_ITEM_FAIL,
      payload: message,
    });
  }
};
