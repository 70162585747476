import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";

//import components
import Header from "../../layout/header/header.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";
import FileCardComponent from "../../components/files/files-card/files-card.component";

//redux import
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/user.actions";
import { getComplaintDetails, rateComplaint } from "../../redux/actions/complaints.actions";
import { listActionsComplaint, deleteActionComplaint } from "../../redux/actions/complaints-actions.actions";

import {
	ACTIONS_COMPLAINT_ADD_RESET,
	ACTIONS_COMPLAINT_UPDATE_RESET,
	ACTIONS_COMPLAINT_DETAILS_RESET,
	ACTIONS_COMPLAINT_DELETE_FILE_RESET,
	ACTIONS_COMPLAINT_DELETE_RESET,
} from "../../redux/constants/complaints-actions.const";

//constant
import BREADCRUM_DATA from "../../constants/breadcrumb.const";

const ComplaintDetailsPage = () => {
	const navigate = useNavigate();
	const params = useParams();
	const dispatch = useDispatch();
	const complaintDetail = useSelector((state) => state.complaintDetail);
	const { loading: loadingDetails, error: errorDetails, complaint } = complaintDetail;
	const actionsList = useSelector((state) => state.actionsComplaintList);
	const { loading: loadingActions, actions } = actionsList;
	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;
	const actionDelete = useSelector((state) => state.actionComplaintDelete);
	const { loading: loadingDelete, error: errorDelete } = actionDelete;
	const rateComplaintResult = useSelector((state) => state.rateComplaint);
	const { loading: loadingRateComplaint, error: errorRateComplaint } = rateComplaintResult;

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		mode: "onChange",
		/* reValidateMode: "onChange", */
		criteriaMode: "firstError",
		shouldFocusError: true,
	});

	const handleDeleteAction = (e, actionId) => {
		e.preventDefault();
		if (window.confirm("Esta acción no se puede deshacer.")) {
			dispatch(deleteActionComplaint(params.id, actionId));
		}
	};

	const onSubmit = (data) => dispatch(rateComplaint(params.id, data.complaintRate));

	useEffect(() => {
		if (!userInfo) {
			navigate("/login");
		} else if (userInfo.role === "USUARIO") {
			dispatch(logout());
		} else {
			dispatch({ type: ACTIONS_COMPLAINT_ADD_RESET });
			dispatch({ type: ACTIONS_COMPLAINT_UPDATE_RESET });
			dispatch({ type: ACTIONS_COMPLAINT_DELETE_RESET });
			dispatch({ type: ACTIONS_COMPLAINT_DETAILS_RESET });
			dispatch({ type: ACTIONS_COMPLAINT_DELETE_FILE_RESET });
			if (!complaint.description) {
				if (params.id) {
					dispatch(getComplaintDetails(params.id));
				}
			} else {
				if (complaint.description !== "undefined") {
					reset({
						complaintRate: complaint.complaintRate,
					});
				}
			}
			dispatch(listActionsComplaint(params.id));
		}
	}, [navigate, userInfo, dispatch, params, reset, complaint]);

	return (
		<>
			<Header title="Detalles Denuncia" path="Detalles Denuncia" pathList={BREADCRUM_DATA.complaintDetail}>
				<Link to={`/denuncia/reporte/${params.id}`} style={{ height: "2.375rem" }} className="btn btn-danger float-right">
					<i className="zmdi zmdi-collection-pdf pt-1"></i> Descargar reporte
				</Link>
			</Header>
			<div className="container-fluid">
				<div className="row clearfix">
					<div className="col-md-12">
						{errorRateComplaint && (
							<div className="mb-2 mt-2">
								<Alert message={errorRateComplaint} />
							</div>
						)}
						{errorDelete && (
							<div className="mb-2 mt-2">
								<Alert message={errorDelete} />
							</div>
						)}
						{errorDetails ? (
							<div className="mb-3">
								<Alert message={errorDetails} />
							</div>
						) : loadingDetails || loadingActions || loadingDelete || loadingRateComplaint ? (
							<div className="text-center mb-2">
								<Loader />
							</div>
						) : (
							<>
								<div className="card">
									<div className="header">
										<h2 style={{ textTransform: "none" }}>
											DETALLES DE LA <strong> DENUNCIA</strong>
										</h2>
									</div>
									<div className="body">
										<div className="row clearfix">
											<div className="col-12">
												<div className="form-group">
													<label className="form_label">Número de expediente</label>
													<p>{complaint && complaint.code}</p>
												</div>
											</div>
											{
												complaint.areaComplainant ? (
													<>
														<div className="col-12 col-md-3">
															<div className="form-group">
																<label className="form_label">Tipo de denuncia</label>
																<p>{complaint.anonymous ? "Anónima" : "Confidencial"}</p>
															</div>
														</div>
														<div className="col-12 col-md-3">
															<div className="form-group">
																<label className="form_label">Denunciante</label>
																<p>{complaint.internalComplainant ? "Interno" : "Externo"}</p>
															</div>
														</div>
														<div className="col-12 col-md-3">
															<div className="form-group">
																<label className="form_label">Área de denunciante</label>
																<p>{complaint.areaComplainant.name}</p>
															</div>
														</div>
														<div className="col-12 col-md-3">
															<div className="form-group">
																<label className="form_label">Fecha de denuncia</label>
																<p>{new Date(complaint.createdAt).toLocaleString()}</p>
															</div>
														</div>
													</>
												) : (
													<>
														<div className="col-12 col-md-4">
															<div className="form-group">
																<label className="form_label">Tipo de denuncia</label>
																<p>{complaint.anonymous ? "Anónima" : "Confidencial"}</p>
															</div>
														</div>
														<div className="col-12 col-md-4">
															<div className="form-group">
																<label className="form_label">Denunciante</label>
																<p>{complaint.internalComplainant ? "Interno" : "Externo"}</p>
															</div>
														</div>
														<div className="col-12 col-md-4">
															<div className="form-group">
																<label className="form_label">Fecha de denuncia</label>
																<p>{new Date(complaint.createdAt).toLocaleString()}</p>
															</div>
														</div>
													</>
												)
											}

											{!complaint.anonymous && (
												<div className="col-lg-6 col-md-12">
													<div className="form-group">
														<label className="form_label">Nombre:</label>
														<p>{complaint.name}</p>
													</div>
												</div>
											)}
											{!complaint.anonymous && (
												<div className="col-lg-6 col-md-12">
													<div className="form-group">
														<label className="form_label">Apellido:</label>
														<p>{complaint.lastname}</p>
													</div>
												</div>
											)}
											{!complaint.anonymous && (
												<div className="col-lg-6 col-md-12">
													<div className="form-group">
														<label className="form_label">Teléfono:</label>
														<p>
															<p>{complaint.phone}</p>
														</p>
													</div>
												</div>
											)}
											{!complaint.anonymous && (
												<div className="col-lg-6 col-md-12">
													<div className="form-group">
														<label className="form_label">Correo:</label>
														<p>{complaint.email}</p>
													</div>
												</div>
											)}
											<div className="col-12 col-md-6">
												<div className="form-group">
													<label className="form_label">Empresa:</label>
													<p>{complaint.company ? complaint.company.name : "-"}</p>
												</div>
											</div>
											<div className="col-lg-6 col-md-12">
												<div className="form-group">
													<label className="form_label">Conducta denunciada:</label>
													<p>{complaint.conduct ? complaint.conduct : "-"}</p>
												</div>
											</div>
											{complaint.denounceds && !!complaint.denounceds?.length && (
												<div className="col-12 pt-3">
													<div className="form-group">
														<label className="form_label">Identificación de denunciad@s: </label>
													</div>
												</div>
											)}
											{complaint.denounceds &&
												complaint.denounceds.map((denounced) => (
													<>
														<div className="col-12 col-md-4">
															<div className="form-group mb-0">
																<label className="form_label">Nombre: </label>{" "}
																<span>
																	{denounced.denouncedName ? denounced.denouncedName : "-"}{" "}
																	{denounced.denouncedLastname ? denounced.denouncedLastname : "-"}
																</span>
															</div>
														</div>
														<div className="col-12 col-md-4">
															<div className="form-group mb-0">
																<label className="form_label">Cargo: </label>{" "}
																<span>{denounced.denouncedJob ? denounced.denouncedJob : "-"}</span>
															</div>
														</div>
														{
															denounced.area ? (
																<>
																<div className="col-12 col-md-4">
															<div className="form-group mb-0">
																<label className="form_label">Área: </label>{" "}
																<span>{denounced.area.name}</span>
															</div>
														</div>
																</>
															):("")
														}
													
														<div className="col-12 col-md-4"></div>
													</>
												))}
											<div className="col-12 py-4">
												<div className="form-group">
													<label className="form_label">Descripción de los hechos:</label>
													<p>{complaint.description}</p>
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form_label">Calificación de denuncia</label>
													<textarea
														style={{ border: errors.complaintRate ? "1px solid red" : "" }}
														type="text"
														rows={4}
														className="form-control"
														{...register("complaintRate", {
															required: true,
															minLength: 5,
														})}
													></textarea>
													{errors.complaintRate?.type === "required" && (
														<span style={{ color: "red", padding: "4px 2px" }}>Este campo es requerido.</span>
													)}
													{errors.complaintRate?.type === "minLength" && (
														<span style={{ color: "red" }}>Este campo debe tener al menos 5 caracteres.</span>
													)}
												</div>
												<button
													className={`btn btn-${!complaint.complaintRate ? "info" : "warning"} float-right`}
													onClick={handleSubmit(onSubmit)}
												>
													{!complaint.complaintRate ? "Calificar esta denuncia" : "Actualizar calificación de denuncia"}
												</button>
											</div>
										</div>
									</div>
								</div>
								<div className="content file_manager">
									<div className="card">
										<div className="header">
											<h2>archivos adjuntos</h2>
										</div>
										<div className="row">
											{complaint.files ? (
												complaint.files.length > 0 ? (
													<>
														{complaint.files.map((file, index) => (
															<div className="col-lg-3 col-md-4 col-sm-12" key={index}>
																<FileCardComponent file={file} />
															</div>
														))}
													</>
												) : (
													<div className="col-12 text-center">
														<h6> No existen archivos adjuntos en esta denuncia.</h6>
													</div>
												)
											) : (
												<div className="col-12 text-center">
													<h6> No existen archivos adjuntos en esta denuncia.</h6>
												</div>
											)}
										</div>
									</div>
								</div>
								<div className="card">
									<div className="header">
										<h2>
											Diligencias de la <strong>denuncia</strong>
										</h2>
									</div>
									<div className="body pt-0" style={{ backgroundColor: "transparent" }}>
										{!actions?.length ? (
											<div className="row text-center pt-3">
												<div className="col-12">
													<h6>No hay diligencias para esta denuncia</h6>
													<Link to={`/denuncia/${complaint.id}/accion`} className="btn btn-success" type="button">
														Crear nueva diligencia
													</Link>
												</div>
											</div>
										) : (
											<div className="row clearfix">
												<div className="col-12 pb-4">
													<Link to={`/denuncia/${complaint.id}/accion`} className="btn btn-success float-right" type="button">
														Crear nueva diligencia
													</Link>
												</div>
												<div className="col-sm-12 pt-3">
													<ul className="cbp_tmtimeline">
														{actions.map((action, index) => (
															<li key={action.id}>
																<div className="cbp_tmicon bg-info">{index + 1}</div>
																<div className="cbp_tmlabel pb-2">
																	<div className="cbp_tmtime">
																		<span>{new Date(action.createdAt).toLocaleString()}</span>
																	</div>
																	<h6>estado: {action.actionState.name}</h6>
																	{action.title && (
																		<p>
																			<strong>Título de la diligencia:</strong> {action.title}
																		</p>
																	)}
																	<p>{action.description}</p>
																	{!!action.files?.length && (
																		<div className="table-responsive">
																			<table className="table table-hover mb-0 c_table">
																				<tbody>
																					{action.files.map((file) => (
																						<tr key={file.id}>
																							<td>
																								<span>
																									<a href={file.url} target="_blank" rel="noopener noreferrer">
																										<i className="zmdi zmdi-file-text w25 text-amber"></i> {file.name}
																									</a>
																								</span>
																							</td>
																						</tr>
																					))}
																				</tbody>
																			</table>
																		</div>
																	)}
																	<div className="text-right">
																		<Link to={`/denuncia/${complaint.id}/accion/${action.id}`} className="btn btn-warning" type="button">
																			Actualizar
																		</Link>
																		<button
																			className="btn btn-danger"
																			type="button"
																			onClick={(e) => {
																				handleDeleteAction(e, action.id);
																			}}
																		>
																			Eliminar
																		</button>
																	</div>
																</div>
															</li>
														))}
													</ul>
												</div>
											</div>
										)}
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default ComplaintDetailsPage;
