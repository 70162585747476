import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "@mui/material/Modal";
//import components
import Header from "../../../layout/header/header.component";
import Loader from "../../../components/shared/loaders/spinner/sppinner.component";
import SkeletonLoader from "../../../components/shared/loaders/skeleton-loader/skeleton-loader.component";
import Alert from "../../../components/shared/alerts/material-alert/alert.component";
import PaginationComponent from "../../../components/shared/pagination/pagination.component";
import ItemAddComponent from "../../../components/conducts/add-type-item/add-type-item.component";
//import redux ;
import { useDispatch, useSelector } from "react-redux";
import {
  listConductType,
  deleteConductType as deleteConductTypeAction,
} from "../../../redux/actions/conducts.actions";
import { changeActiveSidebarItem } from "../../../redux/actions/navigation.actions";
import {
  CONDUCTS_TYPE_ADD_RESET,
  CONDUCTS_TYPE_DELETE_RESET,
  CONDUCTS_TYPE_LIST_RESET,
} from "../../../redux/constants/conducts.const";

import BREADCRUM_DATA from "../../../constants/breadcrumb.const";

const TypologiesListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState(null);
  const [isAddingItem, setIsAddingItem] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const conductTypeList = useSelector((state) => state.conductTypeList);
  const { loading, error, conducts_type } = conductTypeList;
  const conductTypeDelete = useSelector((state) => state.conductTypeDelete);
  const conductTypeAdd = useSelector((state) => state.conductTypeAdd);
  const { addResult } = conductTypeAdd;
  const {
    loading: loadingDelete,
    error: errorDelete,
    deleteConductTypeResult,
  } = conductTypeDelete;

  const [secondTableCurrentPage, setSecondTableCurrentPage] = useState(0);
  const pageSize = 10;
  const secondTablePagesCount = conducts_type
    ? Math.ceil(conducts_type.length / pageSize)
    : 1;

  const setSecondTablePage = (e, index) => {
    e.preventDefault();
    setSecondTableCurrentPage(index);
  };
  const cancelAddItem = () => {
    //e.preventDefault();
    setIsAddingItem(false);

    //myRef.current.reset();
    return false;
  };
  const resetMessage = () => {
    //e.preventDefault();
    setMessage(null);
    return false;
  };
  useEffect(() => {
    dispatch(changeActiveSidebarItem(location.pathname));
    if (!userInfo) {
      navigate("/login");
    } else {
      if (addResult) {
        setMessage("Tipo de conducta creada con éxito");
      }

      dispatch({ type: CONDUCTS_TYPE_ADD_RESET });
      dispatch({ type: CONDUCTS_TYPE_DELETE_RESET });
      dispatch(listConductType());

      //to clean up the suscription
      return function cleanList() {
        dispatch({ type: CONDUCTS_TYPE_LIST_RESET });
      };
    }
  }, [navigate, userInfo, dispatch, addResult, location]);
  const handleDelete = (e, id) => {
    e.preventDefault();
    if (window.confirm("Esta acción no se puede deshacer.")) {
      dispatch(deleteConductTypeAction(id));
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <button
        onClick={() => {
          handleGoBack();
        }}
        title="Regresar"
        className="btn mt-3   btn-icon float-left"
      >
        <i className="zmdi zmdi-arrow-left"></i>
      </button>
      <Header
        title="Tipos de Conductas"
        path="Lista de Tipos de Conductas"
        pathList={BREADCRUM_DATA.conductTypesList}
      >
        {userInfo && userInfo.role !== "EMPRESA" && (
          <>
            {" "}
            <button
              onClick={() => {
                setIsAddingItem(true);
              }}
              className="btn btn-success btn-icon float-right "
            >
              <i className="zmdi zmdi-plus"></i>
            </button>{" "}
          </>
        )}
      </Header>

      {errorDelete && (
        <div className="mb-2 mt-2">
          <Alert message={errorDelete} />
        </div>
      )}
      {loadingDelete && (
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card text-center">
                <Loader />
              </div>
            </div>
          </div>
        </div>
      )}
      {deleteConductTypeResult && (
        <div className="mb-2 mt-2">
          <Alert
            message="Tipo de Conducta eliminada exitosamente"
            severity="info"
          />
        </div>
      )}
      {message && (
        <div className="mb-2 mt-2">
          <Alert message={message} severity="info" />
        </div>
      )}
      {error ? (
        <div className="mb-2 mt-2">
          <Alert message={error} />
        </div>
      ) : loading ? (
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <SkeletonLoader />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-hover c_table theme-color">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Nombre</th>

                        <th>Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      {conducts_type ? (
                        conducts_type.length === 0 ? (
                          <h4 className="text-center">
                            No existen tipos de conducta registrados.
                          </h4>
                        ) : (
                          conducts_type
                            .slice(
                              secondTableCurrentPage * pageSize,
                              (secondTableCurrentPage + 1) * pageSize
                            )
                            .map((type, index) => (
                              <>
                                <tr>
                                  <td>{type.id}</td>
                                  <div className="mt-3">{type.name}</div>

                                  <td>
                                    <button
                                      className="btn btn-danger btn-sm"
                                      title="Eliminar"
                                      onClick={(e) => {
                                        handleDelete(e, type.id);
                                      }}
                                    >
                                      <i className="zmdi zmdi-delete"></i>
                                    </button>
                                  </td>
                                </tr>
                              </>
                            ))
                        )
                      ) : null}
                    </tbody>
                  </table>

                  <div className="mt-4 text-center">
                    <PaginationComponent
                      tablecurrentPage={secondTableCurrentPage}
                      setTablecurrentPage={setSecondTablePage}
                      tablepagesCount={secondTablePagesCount}
                    />
                  </div>
                  {isAddingItem ? (
                    <>
                      <Modal
                        open={isAddingItem}
                        onClose={(e) => {
                          cancelAddItem(e);
                        }}
                      >
                        <ItemAddComponent
                          cancelAddItem={cancelAddItem}
                          resetMessage={resetMessage}
                        />
                      </Modal>
                      {/*    <h6 className="mt-2">EDITAR</h6>
                       */}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TypologiesListPage;
