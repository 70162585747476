import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
//import components

import Loader from "../../shared/loaders/spinner/sppinner.component";
import Alert from "../../shared/alerts/material-snack-bar/material-snack-bar.component";
import { riskMapConductAddPlan as riskMapConductAddPlanAction } from "../../../redux/actions/risk-maps.actions";

//redux import
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../../redux/actions/user.actions";

const AddPlan = ({ conductData, resetMessage, onCancel, riskMapId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const riskMapConductAddPlan = useSelector(
    (state) => state.riskMapConductAddPlan
  );
  const { loading, error, responseResult } = riskMapConductAddPlan;

  const currentDay = conductData.plan
    ? new Date(conductData.plan.implementationDate)
    : new Date();
  const [selectedDay, setSelectedDay] = useState(currentDay);

  const MONTHS = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Marzo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const WEEKDAYS_LONG = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];

  const modifiersStyles = {
    birthday: {
      color: "white",
      backgroundColor: "#ffc107",
    },
    thursdays: {
      color: "#ffc107",
      backgroundColor: "#fffdee",
    },
    outside: {
      backgroundColor: "white",
    },
  };

  const WEEKDAYS_SHORT = ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"];

  const handleDayClick = (daySelec) => {
    setSelectedDay(daySelec);
  };

  const handlePrintSelectedDate = (fecha) => {
    const date = new Date(fecha.toISOString().slice(0, 10) + " 12:00:00");
    return (
      date.toLocaleString("default", {
        weekday: "long",
        timeZone: "UTC",
      }) +
      ", " +
      date.getDate() +
      " de " +
      date.toLocaleString("default", { month: "long" }) +
      " de " +
      date.toLocaleString("default", { year: "numeric" })
    );
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    /* reValidateMode: "onChange", */
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  const onSubmit = (data) => {
    // console.log(data);
    window.scrollTo(0, 0);
    dispatch(
      riskMapConductAddPlanAction(
        riskMapId,
        data.existingControl,
        data.department,
        data.headControl,
        data.controlPerformance,
        data.type,
        parseInt(data.frequency),
        data.valuationPerformance,
        data.effectivenessEvaluation,
        data.vulnerability,
        data.residualRisk,
        data.action,
        data.planResponsible,
        selectedDay.toISOString().slice(0, 10),
        data.situation
      )
    );

    //setMessage(true);
  };

  useEffect(() => {
    if (!userInfo) {
      dispatch(logout());
      navigate("/login");
    } else {
      resetMessage();

      reset({
        existingControl: conductData.plan
          ? conductData.plan.existingControl
          : null,
        department: conductData.plan ? conductData.plan.department : null,
        headControl: conductData.plan ? conductData.plan.headControl : null,
        controlPerformance: conductData.plan
          ? conductData.plan.controlPerformance
          : null,
        type: conductData.plan ? conductData.plan.type : null,
        frequency: conductData.plan ? conductData.plan.frequency : null,
        valuationPerformance: conductData.plan
          ? conductData.plan.valuationPerformance
          : null,
        effectivenessEvaluation: conductData.plan
          ? conductData.plan.effectivenessEvaluation
          : null,
        vulnerability: conductData.plan ? conductData.plan.vulnerability : null,
        residualRisk: conductData.plan ? conductData.plan.residualRisk : null,
        action: conductData.plan ? conductData.plan.action : null,
        planResponsible: conductData.plan
          ? conductData.plan.planResponsible
          : null,

        situation: conductData.plan ? conductData.plan.situation : null,
      });
    }
    if (responseResult) {
      onCancel();
    }
  }, [
    navigate,
    userInfo,
    dispatch,
    responseResult,
    onCancel,
    resetMessage,
    conductData,
    reset,
  ]);
  return (
    <>
      {loading && (
        <div className="mt-2 mb-2">
          <Loader />
        </div>
      )}
      {error && (
        <div className="mt-2 mb-2">
          <Alert message={error} />
        </div>
      )}
      <div className="row">
        <div className="col-lg-12 col-md-12 col-xs-12 mt-3 text-center">
          <h6>IDENTIFICACIÓN CONTROLES</h6>
        </div>
        <div className="col-lg-3 col-md-6 col-xs-12 mt-3">
          <label className="form_label">Control Existente:</label>
          <textarea
            rows={2}
            type="number"
            style={{
              border: errors.name ? "1px solid red" : "",
            }}
            className="form-control"
            placeholder="Ingrese aquí su respuesta."
            {...register("existingControl")}
          />
        </div>

        <div className="col-lg-3 col-md-6 col-xs-12 mt-3">
          <label className="form_label">Dep. Responsable:</label>
          <textarea
            rows={2}
            type="number"
            style={{
              border: errors.name ? "1px solid red" : "",
            }}
            className="form-control"
            placeholder="Ingrese aquí su respuesta."
            {...register("department")}
          />
        </div>
        <div className="col-lg-3 col-md-6 col-xs-12 mt-3">
          <label className="form_label">Responsable del control:</label>
          <textarea
            rows={2}
            type="number"
            style={{
              border: errors.name ? "1px solid red" : "",
            }}
            className="form-control"
            placeholder="Ingrese aquí su respuesta."
            {...register("headControl")}
          />
        </div>
        <div className="col-lg-3 col-md-6 col-xs-12 mt-3">
          <label className="form_label">Realización:</label>
          <textarea
            rows={2}
            type="number"
            style={{
              border: errors.name ? "1px solid red" : "",
            }}
            className="form-control"
            placeholder="Ingrese aquí su respuesta."
            {...register("controlPerformance")}
          />
        </div>

        <div className="col-lg-3 col-md-6 col-xs-12 mt-3">
          <label className="form_label">Tipo:</label>
          <input
            style={{
              border: errors.name ? "1px solid red" : "",
            }}
            className="form-control"
            placeholder="Ingrese aquí su respuesta."
            {...register("type")}
          />
        </div>
        <div className="col-lg-3 col-md-6 col-xs-12 mt-3">
          <label className="form_label">Frecuencia(En meses):</label>
          <input
            type="number"
            style={{
              border: errors.name ? "1px solid red" : "",
            }}
            className="form-control"
            placeholder="Ingrese aquí su respuesta."
            {...register("frequency")}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-xs-12 mt-5 text-center">
          <h6>VALORACION CONTROLES</h6>
        </div>
        <div className="col-lg-3 col-md-6 col-xs-12 mt-3">
          <label className="form_label">Realizacion:</label>
          <input
            style={{
              border: errors.name ? "1px solid red" : "",
            }}
            className="form-control"
            placeholder="Ingrese aquí su respuesta."
            {...register("valuationPerformance")}
          />
        </div>
        <div className="col-lg-3 col-md-6 col-xs-12 mt-3">
          <label className="form_label">Evaluacion de Controles:</label>
          <input
            style={{
              border: errors.name ? "1px solid red" : "",
            }}
            className="form-control"
            placeholder="Ingrese aquí su respuesta."
            {...register("effectivenessEvaluation")}
          />
        </div>
        <div className="col-lg-3 col-md-6 col-xs-12 mt-3">
          <label className="form_label">Vulnerabilidad:</label>
          <input
            style={{
              border: errors.name ? "1px solid red" : "",
            }}
            className="form-control"
            placeholder="Ingrese aquí su respuesta."
            {...register("vulnerability")}
          />
        </div>
        <div className="col-lg-3 col-md-6 col-xs-12 mt-3">
          <label className="form_label">Riesgo Residual:</label>
          <input
            style={{
              border: errors.name ? "1px solid red" : "",
            }}
            className="form-control"
            placeholder="Ingrese aquí su respuesta."
            {...register("residualRisk")}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-xs-12 mt-5 text-center">
          <h6>PLAN DE ACCION</h6>
        </div>
        <div className="col-lg-4 col-md-6 col-xs-12 mt-3">
          <label className="form_label">Accion:</label>
          <input
            style={{
              border: errors.name ? "1px solid red" : "",
            }}
            className="form-control"
            placeholder="Ingrese aquí su respuesta."
            {...register("action")}
          />
        </div>
        <div className="col-lg-4 col-md-6 col-xs-12 mt-3">
          <label className="form_label">Responsable:</label>
          <input
            style={{
              border: errors.name ? "1px solid red" : "",
            }}
            className="form-control"
            placeholder="Ingrese aquí su respuesta."
            {...register("planResponsible")}
          />
        </div>

        <div className="col-lg-4 col-md-6 col-xs-12 mt-3">
          <label className="form_label">Situacion:</label>
          <input
            style={{
              border: errors.name ? "1px solid red" : "",
            }}
            className="form-control"
            placeholder="Ingrese aquí su respuesta."
            {...register("situation")}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-xs-12 mt-3">
          <label className="form_label">Fecha Implementacion:</label>

          <DayPicker
            onDayClick={handleDayClick}
            selectedDays={selectedDay}
            months={MONTHS}
            weekdaysLong={WEEKDAYS_LONG}
            weekdaysShort={WEEKDAYS_SHORT}
            modifiersStyles={modifiersStyles}
            className="daypicker"
          />
        </div>
        <div className="col-lg-3 text-center col-md-6 mt-5">
          <div className="header">
            <h2>Fecha Seleccionada:</h2>
            <strong>{handlePrintSelectedDate(selectedDay)}</strong>
          </div>
        </div>
        <div className="col-lg-3 text-center col-md-6 mt-5">
          <button
            onClick={handleSubmit(onSubmit)}
            className="btn btn-success btn-sm"
            title="Guardar"
          >
            <i className="zmdi zmdi-check mr-2 pt-1 pb-1"></i>{" "}
            <strong>GUARDAR CAMBIOS</strong>
          </button>
          <br />
          <button
            onClick={onCancel}
            className="btn btn-error btn-sm"
            style={{ minWidth: "162px" }}
            title="Cancelar"
          >
            <i className="zmdi zmdi-check mr-2 pt-1 pb-1"></i>{" "}
            <strong>CANCELAR</strong>
          </button>
        </div>
      </div>
    </>
  );
};

export default AddPlan;
