import { IMAGE_ADD_FAIL, IMAGE_ADD_REQUEST, IMAGE_ADD_RESET, IMAGE_ADD_SUCCESS } from "../constants/image.const";

export const AddImageReducer = (state = {}, action) => {
    switch (action.type) {
      case IMAGE_ADD_REQUEST:
        return { loading: true };
      case IMAGE_ADD_SUCCESS:
        return { loading: false, addImageResult: action.payload };
      case IMAGE_ADD_FAIL:
        return { loading: false, error: action.payload };
      case IMAGE_ADD_RESET:
        return {};
      default:
        return state;
    }
  };