import {
  STATES_COMPLAINT_DELETE_FAIL,
  STATES_COMPLAINT_DELETE_REQUEST,
  STATES_COMPLAINT_DELETE_RESET,
  STATES_COMPLAINT_DELETE_SUCCESS,
  STATES_COMPLAINT_DETAILS_FAIL,
  STATES_COMPLAINT_DETAILS_REQUEST,
  STATES_COMPLAINT_DETAILS_RESET,
  STATES_COMPLAINT_DETAILS_SUCCESS,
  STATES_COMPLAINT_LIST_FAIL,
  STATES_COMPLAINT_LIST_REQUEST,
  STATES_COMPLAINT_LIST_RESET,
  STATES_COMPLAINT_LIST_SUCCESS,
  STATES_COMPLAINT_ADD_FAIL,
  STATES_COMPLAINT_ADD_REQUEST,
  STATES_COMPLAINT_ADD_RESET,
  STATES_COMPLAINT_ADD_SUCCESS,
  STATES_COMPLAINT_UPDATE_REQUEST,
  STATES_COMPLAINT_UPDATE_SUCCESS,
  STATES_COMPLAINT_UPDATE_FAIL,
  STATES_COMPLAINT_UPDATE_RESET,
} from "../constants/complaints-states.const";

export const stateComplaintAddReducer = (state = {}, action) => {
  switch (action.type) {
    case STATES_COMPLAINT_ADD_REQUEST:
      return { loading: true };
    case STATES_COMPLAINT_ADD_SUCCESS:
      return { loading: false, addResult: action.payload };
    case STATES_COMPLAINT_ADD_FAIL:
      return { loading: false, error: action.payload };
    case STATES_COMPLAINT_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const statesComplaintListReducer = (state = { states: [] }, action) => {
  switch (action.type) {
    case STATES_COMPLAINT_LIST_REQUEST:
      return { loading: true };
    case STATES_COMPLAINT_LIST_SUCCESS:
      return { loading: false, states: action.payload };
    case STATES_COMPLAINT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case STATES_COMPLAINT_LIST_RESET:
      return { states: [] };
    default:
      return state;
  }
};

export const stateComplaintDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case STATES_COMPLAINT_DELETE_REQUEST:
      return { loading: true };
    case STATES_COMPLAINT_DELETE_SUCCESS:
      return { loading: false, deleteStateResult: action.payload };
    case STATES_COMPLAINT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case STATES_COMPLAINT_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const stateComplaintDetailsReducer = (state = { state: {} }, action) => {
  switch (action.type) {
    case STATES_COMPLAINT_DETAILS_REQUEST:
      return { ...state, loading: true };
    case STATES_COMPLAINT_DETAILS_SUCCESS:
      return { loading: false, state: action.payload };
    case STATES_COMPLAINT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case STATES_COMPLAINT_DETAILS_RESET:
      return { state: {} };
    default:
      return state;
  }
};

export const stateComplaintUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case STATES_COMPLAINT_UPDATE_REQUEST:
      return { loading: true };
    case STATES_COMPLAINT_UPDATE_SUCCESS:
      return { loading: false, updateResult: action.payload };
    case STATES_COMPLAINT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case STATES_COMPLAINT_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
