export const QUESTIONNAIRES_LIST_REQUEST = "QUESTIONNAIRES_LIST_REQUEST";
export const QUESTIONNAIRES_LIST_SUCCESS = "QUESTIONNAIRES_LIST_SUCCESS";
export const QUESTIONNAIRES_LIST_FAIL = "QUESTIONNAIRES_LIST_FAIL";
export const QUESTIONNAIRES_LIST_RESET = "QUESTIONNAIRES_LIST_RESET";

export const QUESTIONNAIRES_DETAILS_REQUEST = "QUESTIONNAIRES_DETAILS_REQUEST";
export const QUESTIONNAIRES_DETAILS_SUCCESS = "QUESTIONNAIRES_DETAILS_SUCCESS";
export const QUESTIONNAIRES_DETAILS_FAIL = "QUESTIONNAIRES_DETAILS_FAIL";
export const QUESTIONNAIRES_DETAILS_RESET = "QUESTIONNAIRES_DETAILS_RESET";

export const QUESTIONNAIRES_DELETE_REQUEST = "QUESTIONNAIRES_DELETE_REQUEST";
export const QUESTIONNAIRES_DELETE_SUCCESS = "QUESTIONNAIRES_DELETE_SUCCESS";
export const QUESTIONNAIRES_DELETE_FAIL = "QUESTIONNAIRES_DELETE_FAIL";
export const QUESTIONNAIRES_DELETE_RESET = "QUESTIONNAIRES_DELETE_FAIL";

export const QUESTIONNAIRES_UPDATE_REQUEST = "QUESTIONNAIRES_UPDATE_REQUEST";
export const QUESTIONNAIRES_UPDATE_SUCCESS = "QUESTIONNAIRES_UPDATE_SUCCESS";
export const QUESTIONNAIRES_UPDATE_FAIL = "QUESTIONNAIRES_UPDATE_FAIL";
export const QUESTIONNAIRES_UPDATE_RESET = "QUESTIONNAIRES_UPDATE_RESET";

export const QUESTIONNAIRES_ADD_REQUEST = "QUESTIONNAIRES_ADD_REQUEST";
export const QUESTIONNAIRES_ADD_SUCCESS = "QUESTIONNAIRES_ADD_SUCCESS";
export const QUESTIONNAIRES_ADD_FAIL = "QUESTIONNAIRES_ADD_FAIL";
export const QUESTIONNAIRES_ADD_RESET = "QUESTIONNAIRES_ADD_RESET";

export const QUESTIONNAIRES_GET_USERS_REQUEST =
  "QUESTIONNAIRES_GET_USERS_REQUEST";
export const QUESTIONNAIRES_GET_USERS_SUCCESS =
  "QUESTIONNAIRES_GET_USER_SUCCESS";
export const QUESTIONNAIRES_GET_USERS_FAIL = "QUESTIONNAIRES_GET_USER_FAIL";
export const QUESTIONNAIRES_GET_USERS_RESET = "QUESTIONNAIRES_GET_USER_RESET";

export const QUESTIONNAIRES_GET_COMPANIES_REQUEST =
  "QUESTIONNAIRES_GET_COMPANIES_REQUEST";
export const QUESTIONNAIRES_GET_COMPANIES_SUCCESS =
  "QUESTIONNAIRES_GET_COMPANIES_SUCCESS";
export const QUESTIONNAIRES_GET_COMPANIES_FAIL =
  "QUESTIONNAIRES_GET_COMPANIES_FAIL";
export const QUESTIONNAIRES_GET_COMPANIES_RESET =
  "QUESTIONNAIRES_GET_COMPANIES_RESET";

export const QUESTIONNAIRES_ADD_ITEM_REQUEST =
  "QUESTIONNAIRES_ADD_ITEM_REQUEST";
export const QUESTIONNAIRES_ADD_ITEM_SUCCESS =
  "QUESTIONNAIRES_ADD_ITEM_SUCCESS";
export const QUESTIONNAIRES_ADD_ITEM_FAIL = "QUESTIONNAIRES_ADD_ITEM_FAIL";
export const QUESTIONNAIRES_ADD_ITEM_RESET = "QUESTIONNAIRES_ADD_ITEM_RESET";

export const QUESTIONNAIRES_RESPONSE_REQUEST =
  "QUESTIONNAIRES_RESPONSE_REQUEST";
export const QUESTIONNAIRES_RESPONSE_SUCCESS =
  "QUESTIONNAIRES_RESPONSE_SUCCESS";
export const QUESTIONNAIRES_RESPONSE_FAIL = "QUESTIONNAIRES_RESPONSE_FAIL";
export const QUESTIONNAIRES_RESPONSE_RESET = "QUESTIONNAIRES_RESPONSE_RESET";

export const QUESTIONNAIRES_ADD_FILE_REQUEST =
  "QUESTIONNAIRES_ADD_FILE_REQUEST";
export const QUESTIONNAIRES_ADD_FILE_SUCCESS =
  "QUESTIONNAIRES_ADD_FILE_SUCCESS";
export const QUESTIONNAIRES_ADD_FILE_FAIL = "QUESTIONNAIRES_ADD_FILE_FAIL";
export const QUESTIONNAIRES_ADD_FILE_RESET = "QUESTIONNAIRES_ADD_FILE_RESET";

export const QUESTIONNAIRES_DELETE_FILE_REQUEST =
  "QUESTIONNAIRES_DELETE_FILE_REQUEST";
export const QUESTIONNAIRES_DELETE_FILE_SUCCESS =
  "QUESTIONNAIRES_DELETE_FILE_SUCCESS";
export const QUESTIONNAIRES_DELETE_FILE_FAIL =
  "QUESTIONNAIRES_DELETE_FILE_FAIL";
export const QUESTIONNAIRES_DELETE_FILE_RESET =
  "QUESTIONNAIRES_DELETE_FILE_RESET";

export const QUESTIONNAIRES_GET_FILE_REQUEST =
  "QUESTIONNAIRES_GET_FILE_REQUEST";
export const QUESTIONNAIRES_GET_FILE_SUCCESS =
  "QUESTIONNAIRES_GET_FILE_SUCCESS";
export const QUESTIONNAIRES_GET_FILE_FAIL = "QUESTIONNAIRES_GET_FILE_FAIL";
export const QUESTIONNAIRES_GET_FILE_RESET = "QUESTIONNAIRES_GET_FILE_RESET";

export const QUESTIONNAIRES_DELETE_ITEM_REQUEST =
  "QUESTIONNAIRES_DELETE_ITEM_REQUEST";
export const QUESTIONNAIRES_DELETE_ITEM_SUCCESS =
  "QUESTIONNAIRES_DELETE_ITEM_SUCCESS";
export const QUESTIONNAIRES_DELETE_ITEM_FAIL =
  "QUESTIONNAIRES_DELETE_ITEM_FAIL";
export const QUESTIONNAIRES_DELETE_ITEM_RESET =
  "QUESTIONNAIRES_DELETE_ITEM_FAIL";

export const QUESTIONNAIRES_FILTRADA_GET = "QUESTIONNAIRES_FILTRADA_GET";
export const QUESTIONNAIRES_FILTRADA_RESET = "QUESTIONNAIRES_FILTRADA_RESET";

export const QUESTIONNAIRES_FILTRADA_DETAILS_GET =
  "QUESTIONNAIRES_FILTRADA_DETAILS_GET";
export const QUESTIONNAIRES_FILTRADA_DETAILS_RESET =
  "QUESTIONNAIRES_FILTRADA_DETAILS_RESET";
