import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

//import components
import Header from "../../../layout/header/header.component";
import Loader from "../../../components/shared/loaders/spinner/sppinner.component";
import SkeletonLoader from "../../../components/shared/loaders/skeleton-loader/skeleton-loader.component";
import Alert from "../../../components/shared/alerts/material-alert/alert.component";

//import redux ;
import { useDispatch, useSelector } from "react-redux";
import {
  listLinks,
  deleteLink as deleteLinkAction,
} from "../../../redux/actions/searcher.actions";
import { changeActiveSidebarItem } from "../../../redux/actions/navigation.actions";
import {
  LINKS_ADD_RESET,
  LINKS_DELETE_RESET,
  LINKS_UPDATE_RESET,
  LINKS_DETAILS_RESET,
  LINKS_LIST_RESET,
} from "../../../redux/constants/searcher.const";

const LinksListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState(null);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const linkList = useSelector((state) => state.linkList);
  const { loading, error, links } = linkList;
  const linkDelete = useSelector((state) => state.linkDelete);
  const linkAdd = useSelector((state) => state.linkAdd);
  const { addResult } = linkAdd;
  const linkUpdate = useSelector((state) => state.linkUpdate);
  const { updateResult } = linkUpdate;
  const {
    loading: loadingDelete,
    error: errorDelete,
    deleteLinkResult,
  } = linkDelete;

  useEffect(() => {
    dispatch(changeActiveSidebarItem(location.pathname));
    if (!userInfo) {
      navigate("/login");
    } else {
      if (addResult) {
        setMessage("Enlace creado con éxito");
      }
      if (updateResult) {
        setMessage("Enlace actualizado con éxito");
      }
      dispatch({ type: LINKS_ADD_RESET });
      dispatch({ type: LINKS_UPDATE_RESET });
      dispatch({ type: LINKS_DELETE_RESET });
      dispatch({ type: LINKS_DETAILS_RESET });
      dispatch(listLinks());

      //to clean up the suscription
      return function cleanList() {
        dispatch({ type: LINKS_LIST_RESET });
      };
    }
  }, [navigate, userInfo, dispatch, addResult, updateResult, location]);

  /*  useEffect(() => {
    return function cleanList() {
      console.log("dssdsdsddss");
    };
  }); */

  const handleDelete = (e, id) => {
    e.preventDefault();
    if (window.confirm("Esta acción no se puede deshacer.")) {
      dispatch(deleteLinkAction(id));
    }
  };
  return (
    <>
      <Header title="Lista de Enlaces" path="Lista de Enlaces">
        <Link
          to="/enlaces/agregar"
          className="btn btn-success btn-icon float-right "
        >
          <i className="zmdi zmdi-plus"></i>
        </Link>
      </Header>
      {errorDelete && (
        <div className="mb-2 mt-2">
          <Alert message={errorDelete} />
        </div>
      )}
      {loadingDelete && (
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card text-center">
                <Loader />
              </div>
            </div>
          </div>
        </div>
      )}
      {deleteLinkResult && (
        <div className="mb-2 mt-2">
          <Alert message="Enlace eliminado exitosamente" severity="info" />
        </div>
      )}
      {message && (
        <div className="mb-2 mt-2">
          <Alert message={message} severity="info" />
        </div>
      )}
      {error ? (
        <div className="mb-2 mt-2">
          <Alert message={error} />
        </div>
      ) : loading ? (
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <SkeletonLoader />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-hover c_table theme-color">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Nombre</th>
                        <th>Descripción</th>
                        <th>Url</th>
                        <th>Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      {links ? (
                        links.length === 0 ? (
                          <tr className="text-center">
                            <td colSpan={5}>
                              No existen enlaces registrados.
                            </td>
                          </tr>
                        ) : (
                          links.map((link, index) => (
                            <tr key={index}>
                              <td>{link.id}</td>
                              <td>
                                <strong>
                                  {link.name.length > 35
                                    ? link.name.slice(0, 35) + "..."
                                    : link.name}
                                </strong>
                              </td>
                              <div>
                                <p>
                                  {link.description.length > 100
                                    ? link.description.slice(0, 100) + "..."
                                    : link.description}
                                </p>{" "}
                              </div>
                              <td>{link.url} </td>

                              <td>
                                <Link
                                  to={`/empresa/${link.id}`}
                                  className="btn btn-warning btn-sm"
                                  title="Editar"
                                >
                                  <i className="zmdi zmdi-edit"></i>
                                </Link>
                                <button
                                  className="btn btn-danger btn-sm"
                                  title="Eliminar"
                                  onClick={(e) => {
                                    handleDelete(e, link.id);
                                  }}
                                >
                                  <i className="zmdi zmdi-delete"></i>
                                </button>
                              </td>
                            </tr>
                          ))
                        )
                      ) : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LinksListPage;
