import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";

//import components
import Header from "../../layout/header/header.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";

//redux import
import { useDispatch, useSelector } from "react-redux";
import { changeActiveSidebarItem } from "../../redux/actions/navigation.actions";
import {
  updateUserAdmin,
  logout,
  getUserDetails,
} from "../../redux/actions/user.actions";

//constant
import BREADCRUM_DATA from "../../constants/breadcrumb.const";

const UserDetailsPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const userUpdateAdmin = useSelector((state) => state.userUpdateAdmin);
  const { loading, error, userUpdateResult } = userUpdateAdmin;
  const userDetails = useSelector((state) => state.userDetails);
  const { loading: loadingDetails, error: errorDetails, user } = userDetails;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const {
    register,
    handleSubmit,

    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    /* reValidateMode: "onChange", */
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  const onSubmit = (data) => {
    if (window.confirm("Desea actualizar la información ?")) {
      dispatch(
        updateUserAdmin(
          params.id,
          data.name,
          data.lastname,
          data.email,
          data.phone,
          data.username,
          data.role
        )
      );
    }
  };

  useEffect(() => {
    dispatch(changeActiveSidebarItem(location.pathname));
    if (!userInfo) {
      navigate("/login");
    } else if (userInfo.role === "USUARIO") {
      dispatch(logout());
    } else {
      if (!user.name) {
        dispatch(getUserDetails(params.id));
      } else {
        if (user.name !== "undefined") {
          reset({
            name: user.name,
            lastname: user.lastname,
            email: user.email,
            username: user.username,
            phone: user.phone,
            role: user.role ? user.role.name : "NO DEFINIDO",
          });
        }
      }
    }
    if (userUpdateResult) {
      navigate("/usuarios");
    }
  }, [
    navigate,
    userUpdateResult,
    userInfo,
    dispatch,
    params,
    reset,
    user,
    location,
  ]);
  return (
    <>
      <Header
        title="Actualizar Usuario"
        path="Actualizar Usuario"
        pathList={BREADCRUM_DATA.userUpdate}
      >
        <button
          className="btn btn-success btn-icon float-right right_icon_toggle_btn"
          type="button"
        >
          <i className="zmdi zmdi-check"></i>
        </button>
      </Header>
      <div className="container-fluid">
        <div className="row clearfix">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h2>
                  Actualizar <strong>información</strong>
                </h2>
              </div>
              <div className="body">
                {error && (
                  <div className="mb-3">
                    <Alert message={error} />
                  </div>
                )}
                {loading && (
                  <div className="text-center mb-2">
                    <Loader />
                  </div>
                )}
                {errorDetails ? (
                  <div className="mb-3">
                    <Alert message={errorDetails} />
                  </div>
                ) : loadingDetails ? (
                  <div className="text-center mb-2">
                    <Loader />
                  </div>
                ) : (
                  <div className="row clearfix">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <label className="form_label">Nombre:</label>
                        <input
                          style={{ border: errors.name ? "1px solid red" : "" }}
                          type="text"
                          className="form-control"
                          placeholder="Nombre"
                          {...register("name", {
                            required: true,
                            minLength: 2,
                          })}
                        />
                        {errors.name?.type === "required" && (
                          <span style={{ color: "red", padding: "4px 2px" }}>
                            El nombre es requerido.
                          </span>
                        )}
                        {errors.name?.type === "minLength" && (
                          <span style={{ color: "red" }}>
                            El nombre debe tener al menos dos caracteres.
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <label className="form_label">Apellido:</label>
                        <input
                          style={{
                            border: errors.lastname ? "1px solid red" : "",
                          }}
                          type="text"
                          className="form-control"
                          placeholder="Apellido"
                          {...register("lastname", {
                            required: true,
                            minLength: 2,
                          })}
                        />
                        {errors.lastname?.type === "required" && (
                          <span style={{ color: "red", padding: "4px 2px" }}>
                            El apellido es requerido.
                          </span>
                        )}
                        {errors.lastname?.type === "minLength" && (
                          <span style={{ color: "red" }}>
                            El apellido debe tener al menos dos caracteres.
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group">
                        <label className="form_label">Teléfono:</label>
                        <input
                          style={{
                            border: errors.phone ? "1px solid red" : "",
                          }}
                          type="tel"
                          className="form-control"
                          placeholder="Teléfono"
                          {...register("phone", {
                            required: true,

                            pattern: /[0][0-9]{9}/,
                          })}
                        />
                        {errors.phone?.type === "required" && (
                          <span style={{ color: "red" }}>
                            El teléfono es requerido.
                          </span>
                        )}
                        {errors.phone?.type === "pattern" && (
                          <span style={{ color: "red" }}>
                            El número debe comenzar con 0 y tener 10 dígitos.
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group">
                        <label className="form_label">Correo:</label>
                        <input
                          style={{
                            border: errors.email ? "1px solid red" : "",
                          }}
                          type="email"
                          className="form-control"
                          placeholder="Correo"
                          {...register("email", {
                            required: true,

                            pattern: /\S+@\S+\.\S+/,
                          })}
                        />
                        {errors.email?.type === "required" && (
                          <span style={{ color: "red" }}>
                            El correo es requerido.
                          </span>
                        )}
                        {errors.email?.type === "pattern" && (
                          <span style={{ color: "red" }}>
                            El valor ingresado no coincide con el formato de
                            correo electrónico
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group">
                        <label className="form_label">Nombre Usuario:</label>
                        <input
                          style={{
                            border: errors.username ? "1px solid red" : "",
                          }}
                          type="text"
                          className="form-control"
                          placeholder="Nombre Usuario"
                          {...register("username", {
                            required: true,
                            minLength: 3,
                          })}
                        />
                        {errors.username?.type === "required" && (
                          <span style={{ color: "red", padding: "4px 2px" }}>
                            El nombre de usuario es requerido.
                          </span>
                        )}
                        {errors.username?.type === "minLength" && (
                          <span style={{ color: "red" }}>
                            El nombre de usuario debe tener al menos dos
                            caracteres.
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <h5>
                        <strong>Rol</strong>
                      </h5>
                      <div className="radio">
                        <input
                          id="procheck1"
                          {...register("role")}
                          type="radio"
                          value="EMPRESA"
                        />
                        <label for="procheck1">Empresa</label>
                      </div>
                      <div className="radio">
                        <input
                          id="procheck2"
                          {...register("role")}
                          type="radio"
                          value="SUPERVISOR"
                        />

                        <label for="procheck2">Supervisor</label>
                      </div>
                      <div className="radio">
                        <input
                          id="procheck3"
                          {...register("role")}
                          type="radio"
                          value="SUPERADMIN"
                        />

                        <label for="procheck3">Súper Admin</label>
                      </div>
                      <div className="radio">
                      <input
                        id="procheck4"
                        {...register("role")}
                        type="radio"
                        value="AGENTE DE CUMPLIMIENTO"
                      />

                      <label for="procheck4">Agente de Cumplimiento</label>
                    </div>
                    </div>
                    <div className="col-md-12">
                      <button
                        className="btn btn-success"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Actualizar Usuario
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetailsPage;
