import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

//import components
import Header from "../../layout/header/header.component";
//import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import SkeletonLoader from "../../components/shared/loaders/skeleton-loader/skeleton-loader.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";

//import redux ;
import { useDispatch, useSelector } from "react-redux";

import { listComplaints } from "../../redux/actions/complaints.actions";
import { changeActiveSidebarItem } from "../../redux/actions/navigation.actions";
import { COMPLAINTS_ADD_RESET, COMPLAINTS_DETAILS_RESET } from "../../redux/constants/complaints.const";

import { ACTIONS_COMPLAINT_LIST_RESET } from "../../redux/constants/complaints-actions.const";

const ListComplaintsPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	//const [message, setMessage] = useState(null);
	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;
	const complaintList = useSelector((state) => state.complaintList);
	const { loading, error, complaints } = complaintList;

	useEffect(() => {
		dispatch(changeActiveSidebarItem(location.pathname));
		if (!userInfo) {
			navigate("/login");
		} else {
			dispatch({ type: COMPLAINTS_ADD_RESET });
			dispatch({ type: COMPLAINTS_DETAILS_RESET });
			dispatch({ type: ACTIONS_COMPLAINT_LIST_RESET });
			dispatch(listComplaints());
		}
	}, [navigate, userInfo, dispatch, location]);
	return (
		<>
			<Header title="Lista de Denuncias" path="Lista de Denuncias"></Header>
			{/*  {message && (
        <div className="mb-2 mt-2">
          <Alert message={message} severity="info" />
        </div>
      )} */}
			{error ? (
				<div className="mb-2 mt-2">
					<Alert message={error} />
				</div>
			) : loading ? (
				<div className="container-fluid">
					<div className="row clearfix">
						<div className="col-lg-12">
							<div className="card">
								<SkeletonLoader />
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className="container-fluid">
					<div className="row clearfix">
						<div className="col-lg-12">
							<div className="card">
								<div className="table-responsive">
									<table className="table table-hover c_table theme-color">
										<thead>
											<tr>
												<th>#</th>
												<th>Nombre</th>
												<th>Correo</th>
												
												<th>Empresa</th>
												<th>Conducta</th>
												
												<td>Motivo</td>
												<th>Ver Detalles</th>
											</tr>
										</thead>
										<tbody>
											{complaints.length === 0 ? (
												<tr className="text-center">
													<td colSpan={9}>
                            No existen denuncias registradas
                          </td>
												</tr>
											) : (
												complaints.map((complaint, index) => (
													<tr key={index}>
														<td>{complaint.id}</td>
														<td>
															<strong>{complaint.anonymous ? "Anónim@" : complaint.name+ ' '+ complaint.lastname}</strong>
														</td>

														<td>{complaint.anonymous ? "Anónim@" : complaint.email}</td>
														
														<td>{complaint.company ? complaint.company.name : "-"}</td>
														<td>{complaint.conduct.length >= 15 ? complaint.conduct.slice(0, 15) + "...." : complaint.conduct}</td>
														
														<td>
															{complaint.description.length >= 15 ? complaint.description.slice(0, 15) + "...." : complaint.description}
														</td>

														<td>
															<Link to={`/denuncia/${complaint.id}`} className="btn btn-info btn-sm" title="Detalles">
																<i className="zmdi zmdi-eye"></i>
															</Link>
                              <Link to={`/denuncia/reporte/${complaint.id}`} className="btn btn-danger btn-sm" title="Imprimir reporte">
																<i className="zmdi zmdi-collection-pdf"></i>
															</Link>
														</td>
													</tr>
												))
											)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ListComplaintsPage;
