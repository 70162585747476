import {
  TYPOLOGIES_DELETE_FAIL,
  TYPOLOGIES_DELETE_REQUEST,
  TYPOLOGIES_DELETE_RESET,
  TYPOLOGIES_DELETE_SUCCESS,
  TYPOLOGIES_LIST_FAIL,
  TYPOLOGIES_LIST_REQUEST,
  TYPOLOGIES_LIST_RESET,
  TYPOLOGIES_LIST_SUCCESS,
  TYPOLOGIES_ADD_FAIL,
  TYPOLOGIES_ADD_REQUEST,
  TYPOLOGIES_ADD_RESET,
  TYPOLOGIES_ADD_SUCCESS,
} from "../constants/typologies.const";

export const typologyAddReducer = (state = {}, action) => {
  switch (action.type) {
    case TYPOLOGIES_ADD_REQUEST:
      return { loading: true };
    case TYPOLOGIES_ADD_SUCCESS:
      return { loading: false, addResult: action.payload };
    case TYPOLOGIES_ADD_FAIL:
      return { loading: false, error: action.payload };
    case TYPOLOGIES_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const typologyListReducer = (state = { typologies: [] }, action) => {
  switch (action.type) {
    case TYPOLOGIES_LIST_REQUEST:
      return { loading: true };
    case TYPOLOGIES_LIST_SUCCESS:
      return { loading: false, typologies: action.payload };
    case TYPOLOGIES_LIST_FAIL:
      return { loading: false, error: action.payload };
    case TYPOLOGIES_LIST_RESET:
      return { typologies: [] };
    default:
      return state;
  }
};

export const typologyDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TYPOLOGIES_DELETE_REQUEST:
      return { loading: true };
    case TYPOLOGIES_DELETE_SUCCESS:
      return { loading: false, deleteTypologyResult: action.payload };
    case TYPOLOGIES_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case TYPOLOGIES_DELETE_RESET:
      return {};
    default:
      return state;
  }
};
