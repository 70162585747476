import React, { useState } from "react";

//IMPORT EXTERNAL LIBRARIES
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";

//STYLES
import styles from "./alert.module.scss";

// severity:
// error, warning, info, success
const MaterialAlert = ({ message, severity = "error" }) => {
  const [open, setOpen] = useState(true);
  return (
    <div>
      <Collapse in={open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon
                /* fontSize="24px" */ className={styles.r_alert_icon}
              />
            </IconButton>
          }
          severity={severity}
          className={styles.r_alert}
        >
          {message}
        </Alert>
      </Collapse>
    </div>
  );
};

export default MaterialAlert;
