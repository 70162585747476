import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
//import components

import Loader from "../../shared/loaders/spinner/sppinner.component";
import Alert from "../../shared/alerts/material-snack-bar/material-snack-bar.component";

//redux import
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../../redux/actions/user.actions";
import { addItem } from "../../../redux/actions/questionnaires.actions";
import { listCategories } from "../../../redux/actions/categories.actions";

const AddItem = ({ cuestionaryName, quizId, onCancel, resetMessage }) => {
  const [question, setQuestion] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const categoriesList = useSelector((state) => state.categoriesList);
  const {
    loading: loadinCategories,
    error: errorCategories,
    categories,
  } = categoriesList;
  console.log(categories);
  const questionnaryAddItem = useSelector((state) => state.questionnaryAddItem);
  const { loading, error, addResult } = questionnaryAddItem;

  const submitHandler = (e) => {
    window.scrollTo(0, 0);
    e.preventDefault();
    dispatch(addItem(question, categoryId, quizId));
  };

  const filterCategories = (inputValue) => {
    return categories.filter((i) =>
      i.name.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  const promiseOptionsCategories = (inputValue) =>
    new Promise((resolve) => {
      resolve(filterCategories(inputValue));
    });
  useEffect(() => {
    if (!userInfo) {
      dispatch(logout());
      navigate("/login");
    } else if (userInfo.role === "USUARIO") {
      dispatch(logout());
    } else {
      resetMessage();
    }
    if (addResult) {
      onCancel();
    }
    dispatch(listCategories());
  }, [navigate, userInfo, dispatch, onCancel, resetMessage, addResult]);
  return (
    <div className="container-fluid">
      <div className="row clearfix">
        <div className="col-md-12">
          <div className="card">
            <div className="header">
              <h2>
                <strong>Cuestionario- {cuestionaryName}</strong>
              </h2>
            </div>

            <div className="body">
              {loading && (
                <div className="mt-2 mb-2">
                  <Loader />
                </div>
              )}
              {error && (
                <div className="mt-2 mb-2">
                  <Alert message={error} />
                </div>
              )}
              {loadinCategories ? (
                <div className="mt-2 mb-2 text-center">
                  <Loader />
                </div>
              ) : errorCategories ? (
                <div className="mt-2 mb-2">
                  <Alert message={errorCategories} />
                </div>
              ) : (
                <div className="row clearfix">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="header">
                        <h2>
                          Ingresar información de la nueva{" "}
                          <strong>pregunta</strong>
                        </h2>
                      </div>
                      <div className="body">
                        <label className="form_label">
                          1. ingrese la pregunta:
                        </label>
                        <textarea
                          rows={4}
                          type="text"
                          className="form-control"
                          placeholder="Pregunta"
                          value={question}
                          onChange={(e) => {
                            setQuestion(e.target.value);
                          }}
                        />
                        <hr />

                        <div className="row clearfix">
                          <div className="col-lg-12 col-md-12 mt-3">
                            <div className="form-group">
                              <label className="form_label">
                                2. Seleccione una Categoría:
                              </label>
                              <AsyncSelect
                                cacheOptions
                                defaultOptions
                                placeholder="Seleccione una opción"
                                loadOptions={promiseOptionsCategories}
                                getOptionLabel={(option) =>
                                  `${option.name} - ${
                                    option.descripction
                                      ? option.descripction
                                      : ""
                                  }`
                                }
                                getOptionValue={(e) => e.id}
                                /*  onInputChange={(e) =>
                            setUserSelected(e.id ? e.id : "")
                          } */
                                onChange={(e) =>
                                  setCategoryId(e.id ? e.id : "")
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <hr />
                        <div className="col-md-12">
                          <button
                            className="btn btn-success"
                            onClick={submitHandler}
                          >
                            <strong>AGREGAR PREGUNTA</strong>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddItem;
