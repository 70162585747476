const RISKMAPS_SCALES_OPTIONS = [
	{
		name: "MINIMO",
		id: 2,
	},
	{
		name: "BAJO",
		id: 3,
	},
	{
		name: "MEDIO",
		id: 4,
	},
	{
		name: "ALTO",
		id: 5,
	},
];

export default RISKMAPS_SCALES_OPTIONS;
