import React from "react";

const ProfileCard = ({ name, lastname, email, username, phone, role }) => {
  return (
    <div className="card mcard_3">
      <div className="body">
        <h4 className="m-t-10">
          {name} {lastname}
        </h4>
        <small className="text-muted">Nombre de Usuario: </small>
        <p>{username}</p>
        <hr />
        <small className="text-muted">Correo: </small>
        <p>{email}</p>
        <hr />
        <small className="text-muted">Teléfono: </small>
        <p>{phone}</p>
        <hr />
        <small className="text-muted">Rol: </small>
        <p>{role}</p>
      </div>
    </div>
  );
};

export default ProfileCard;
