import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import styles from "./dropzone-uploader.module.scss";

const DropZoneUploader = ({ onDrop }) => {
  const {
    // acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });
  const baseStyle = {
    flex: 1,

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFocused, isDragAccept, isDragReject]
  );
  return (
    <div {...getRootProps({ style })} className={styles.dropzone}>
      <input {...getInputProps()} />
      <i className="zmdi zmdi-cloud-upload"></i>
      <p>
        Arrastre y suelte algunos archivos aquí, o haga clic para seleccionar
        archivos
      </p>
    </div>
  );
};

export default DropZoneUploader;
