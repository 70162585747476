import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useStateWithCallbackLazy } from "use-state-with-callback";

//import components
import Header from "../../layout/header/header.component";
//import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import SkeletonLoader from "../../components/shared/loaders/skeleton-loader/skeleton-loader.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";
import FilterListComponent from "../../components/shared/filters/filter-list/filter-list.component";
import PaginationComponent from "../../components/shared/pagination/pagination.component";

//import redux ;
import { useDispatch, useSelector } from "react-redux";
import { changeActiveSidebarItem } from "../../redux/actions/navigation.actions";
import {
  listQuestionnaries,
  listQuestionnairesByCondition,
  listQuestionnairesBySorting,
  listQuestionnairesBySearching,
} from "../../redux/actions/questionnaires.actions";
import {
  QUESTIONNAIRES_ADD_RESET,
  QUESTIONNAIRES_UPDATE_RESET,
  QUESTIONNAIRES_DELETE_RESET,
  QUESTIONNAIRES_DETAILS_RESET,
} from "../../redux/constants/questionnaires.const";

//const
import QUESTIONNAIRES_FILTER_OPTIONS from "../../constants/questionnairesFilteroptions.const";

const QuestionnairesListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState(null);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const questionnaryAdd = useSelector((state) => state.questionnaryAdd);
  const { addResult } = questionnaryAdd;
  const questionnaryList = useSelector((state) => state.questionnaryList);
  const { loading, error } = questionnaryList;
  const questionnaryListFiltered = useSelector(
    (state) => state.questionnaryListFiltered
  );
  const { questionnaries } = questionnaryListFiltered;

  const [tableDropdownOpen, setTableMenuOpen] = useState(false);
  const [tableDropdownOpenSort, setTableMenuOpenSort] = useState(false);
  const tableMenuOpen = () => {
    setTableMenuOpen(!tableDropdownOpen);
  };
  const tableSortMenuOpen = () => {
    setTableMenuOpenSort(!tableDropdownOpenSort);
  };

  const [secondTableCurrentPage, setSecondTableCurrentPage] = useState(0);
  const pageSize = 10;
  const secondTablePagesCount = questionnaries
    ? Math.ceil(questionnaries.length / pageSize)
    : 1;

  const setSecondTablePage = (e, index) => {
    e.preventDefault();
    setSecondTableCurrentPage(index);
  };

  const handleCambioEstado = (condition) => {
    dispatch(listQuestionnairesByCondition(condition));
    setSecondTableCurrentPage(0);
  };

  const handleSorting = (condition) => {
    dispatch(listQuestionnairesBySorting(condition));
    setSecondTableCurrentPage(0);
  };
  const [searchKey, setSearchKey] = useStateWithCallbackLazy("");
  const handleSearch = (codigo) => {
    setSearchKey(codigo, (current) => {
      dispatch(listQuestionnairesBySearching(current.trim()));
      setSecondTableCurrentPage(0);
    });
  };

  useEffect(() => {
    dispatch(changeActiveSidebarItem(location.pathname));
    if (!userInfo) {
      navigate("/login");
    } else {
      if (addResult) {
        setMessage("CUESTIONARIO creado con éxito");
      }
      dispatch({ type: QUESTIONNAIRES_DETAILS_RESET });
      dispatch({ type: QUESTIONNAIRES_UPDATE_RESET });
      dispatch({ type: QUESTIONNAIRES_ADD_RESET });
      dispatch({ type: QUESTIONNAIRES_DELETE_RESET });
      /*  dispatch({ type: QUESTIONNAIRES_DELETE_ITEM_RESET }); */
      dispatch(listQuestionnaries());
    }
  }, [navigate, userInfo, dispatch, location, addResult]);
  return (
    <>
      <Header title="Lista de Cuestionarios" path="Lista de Cuestionarios">
        {userInfo ? (
          userInfo.role !== "EMPRESA" ? (
            <Link
              to="/cuestionarios/agregar"
              className="btn btn-success btn-icon float-right "
            >
              <i className="zmdi zmdi-plus"></i>
            </Link>
          ) : null
        ) : null}
      </Header>
      {message && (
        <div className="mb-2 mt-2">
          <Alert message={message} severity="info" />
        </div>
      )}
      {error ? (
        <div className="mb-2 mt-2">
          <Alert message={error} />
        </div>
      ) : loading ? (
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <SkeletonLoader />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="card project_list">
                <div className="table-responsive">
                  <FilterListComponent
                    searchKey={searchKey}
                    handleSearch={handleSearch}
                    tableDropdownOpenSort={tableDropdownOpenSort}
                    tableSortMenuOpen={tableSortMenuOpen}
                    handleSorting={handleSorting}
                    tableDropdownOpen={tableDropdownOpen}
                    tableMenuOpen={tableMenuOpen}
                    handleCambioEstado={handleCambioEstado}
                    filterOptions={QUESTIONNAIRES_FILTER_OPTIONS}
                  />

                  {questionnaries && questionnaries.length === 0 ? (
                    <h5 className="text-center">
                      No se ha encontrado cuestionarios.
                    </h5>
                  ) : (
                    <>
                      <table className="table table-hover c_table theme-color">
                        <thead>
                          <tr>
                            <th>Asignado</th>
                            <th>Nombre</th>
                            {/* <th className="hidden-md-down">Equipo</th> */}
                            <th className="hidden-md-down" width="150px">
                              Progreso
                            </th>
                            <th>Prioridad</th>
                            <th>Vence</th>
                            <th>Acción</th>
                          </tr>
                        </thead>

                        <tbody>
                          {questionnaries && questionnaries.length === 0 ? (
                            <h5 className="text-center">
                              No se ha encontrado cuestionarios.
                            </h5>
                          ) : (
                            questionnaries &&
                            questionnaries
                              .slice(
                                secondTableCurrentPage * pageSize,
                                (secondTableCurrentPage + 1) * pageSize
                              )
                              .map((questionnary, index) => (
                                <tr key={index}>
                                  <td>
                                    <Link
                                      className="single-user-name"
                                      to={`/usuarios/${questionnary.supervisor.id}`}
                                    >
                                      {questionnary.supervisor.name}{" "}
                                      {questionnary.supervisor.lastname}
                                    </Link>
                                    <br />
                                    <small>
                                      {questionnary.supervisor.role.name}
                                    </small>
                                  </td>
                                  <td>
                                    <strong>{questionnary.company.name}</strong>
                                    <br />
                                    <small>{questionnary.company.email}</small>
                                  </td>

                                  <td className="hidden-md-down">
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "700",
                                      }}
                                    >
                                      {questionnary.progress + " %"}
                                    </div>
                                    <div className="progress">
                                      <div
                                        className="progress-bar l-blue"
                                        role="progressbar"
                                        aria-valuenow="45"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{
                                          width:
                                            questionnary.progress === "0.00"
                                              ? "1%"
                                              : questionnary.progress + "%",
                                        }}
                                      ></div>
                                    </div>
                                  </td>
                                  <td>
                                    {questionnary.priority ? (
                                      <span
                                        className={`badge badge-${
                                          questionnary.priority.id === 1
                                            ? "danger"
                                            : questionnary.priority.id === 2
                                            ? "warning"
                                            : "info"
                                        }`}
                                      >
                                        {questionnary.priority.name}
                                      </span>
                                    ) : (
                                      "--"
                                    )}
                                  </td>
                                  <td>
                                    {new Date(questionnary.deadline).toLocaleDateString()}
                                    {/* {new Date(
                                      questionnary.deadline + " 12:00:00"
                                    ).getDate()}{" "}
                                    {new Date(
                                      questionnary.deadline + " 12:00:00"
                                    ).toLocaleString("default", {
                                      month: "short",
                                    })}{" "}
                                    {new Date(
                                      questionnary.deadline + " 12:00:00"
                                    ).toLocaleString("default", {
                                      year: "numeric",
                                    })} */}
                                  </td>
                                  <td>
                                    <div className="icon-container">
                                      {userInfo &&
                                      userInfo.role === "EMPRESA" ? (
                                        <>
                                          {" "}
                                          <Link
                                            to={`/cuestionarios/${questionnary.id}`}
                                            className="btn btn-warning btn-sm"
                                            title="Editar"
                                          >
                                            <i className="zmdi zmdi-edit"></i>
                                          </Link>
                                        </>
                                      ) : (
                                        <>
                                          <Link
                                            to={`/cuestionarios/${questionnary.id}`}
                                            className="btn btn-info btn-sm"
                                            title="Ver Cuestionario"
                                          >
                                            <i className="zmdi zmdi-eye"></i>
                                          </Link>
                                          {questionnary.checklist && (
                                            <Link
                                              to={`/checklist/${questionnary.checklist.id}`}
                                              className="btn btn-warning btn-sm"
                                              title="Editar Checklist"
                                            >
                                              <i className="zmdi zmdi-edit"></i>
                                            </Link>
                                          )}

                                          <button
                                            className="btn btn-danger btn-sm"
                                            title="Eliminar"
                                            onClick={(e) => {}}
                                          >
                                            <i className="zmdi zmdi-delete"></i>
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))
                          )}
                        </tbody>
                      </table>
                      <div className="mt-4 text-center">
                        <PaginationComponent
                          tablecurrentPage={secondTableCurrentPage}
                          setTablecurrentPage={setSecondTablePage}
                          tablepagesCount={secondTablePagesCount}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default QuestionnairesListPage;
