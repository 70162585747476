import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../../layout/header/header.component";
import { deleteAreaCompany, listAreaCompanies } from "../../redux/actions/companies-area.actions";
import { changeActiveSidebarItem } from "../../redux/actions/navigation.actions";
import { AREAS_COMPANIES_ADD_RESET, AREAS_COMPANIES_DELETE_RESET, AREAS_COMPANIES_DETAILS_RESET, AREAS_COMPANIES_UPDATE_RESET } from "../../redux/constants/companies-area.const";
import Alert from "../../components/shared/alerts/material-alert/alert.component";
import SkeletonLoader from "../../components/shared/loaders/skeleton-loader/skeleton-loader.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";
const AreasListPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const areaList = useSelector((state) => state.areaCompanyList);
    const { loading, error, area } = areaList;
    const areaDelete = useSelector((state) => state.categoryCompanyDelete);
    const {
        loading: loadingDelete,
        error: errorDelete,
        deleteCompanyResult,
    } = areaDelete;

    useEffect(() => {
        dispatch(changeActiveSidebarItem(location.pathname));
        if (!userInfo) {
            navigate("/login");
        } else {
            dispatch({ type: AREAS_COMPANIES_ADD_RESET });
            dispatch({ type: AREAS_COMPANIES_DELETE_RESET });
            dispatch({ type: AREAS_COMPANIES_DETAILS_RESET });
            dispatch({ type: AREAS_COMPANIES_UPDATE_RESET });
            dispatch(listAreaCompanies())
        }
    }, [userInfo, navigate, dispatch, location])

    const handleDelete = (e, id) => {
        e.preventDefault();
        if (window.confirm("Esta acción no se puede deshacer.")) {
            dispatch(deleteAreaCompany(id));
        }
    };

    return (
        <>
            <Header
                title="Lista de Áreas de la Empresa"
                path="Lista de Áreas de la Empresa"
            >
                <Link
                    to="/area/agregar"
                    className="btn btn-success btn-icon float-right "
                >
                    <i className="zmdi zmdi-plus"></i>
                </Link>
            </Header>
            {errorDelete && (
                <div className="mb-2 mt-2">
                    <Alert message={errorDelete} />
                </div>
            )}
            {loadingDelete && (
                <div className="container-fluid">
                    <div className="row clearfix">
                        <div className="col-lg-12">
                            <div className="card text-center">
                                <Loader />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {deleteCompanyResult && (
                <div className="mb-2 mt-2">
                    <Alert message="Categoría eliminada exitosamente" severity="info" />
                </div>
            )}
            {error ? (
                <div className="mb-2 mt-2">
                    <Alert message={error} />
                </div>
            ) : (
                <>
                    {
                        loading ? (
                            <div className="container-fluid">
                                <div className="row clearfix">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <SkeletonLoader />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="container-fluid">
                                <div className="row clearfix">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="table-responsive">
                                                <table className="table table-hover c_table theme-color">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Nombre</th>
                                                            <th>Acción</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            !area?.length ? (
                                                                <tr className="text-center">
                                                                    <td>No existen áreas de empresas registradas.</td>
                                                                </tr>
                                                            ) : (
                                                                area.map((area, index) => (
                                                                    <tr key={index}>
                                                                        <td>{area.id}</td>
                                                                        <td>
                                                                            <strong>{area.name}</strong>
                                                                        </td>
                                                                        <td>
                                                                            <Link
                                                                                to={`/area/${area.id}`}
                                                                                className="btn btn-warning btn-sm"
                                                                                title="Editar"
                                                                            >
                                                                                <i className="zmdi zmdi-edit"></i>
                                                                            </Link>
                                                                            <button
                                                                                className="btn btn-danger btn-sm"
                                                                                title="Eliminar"
                                                                                onClick={(e) => {
                                                                                    handleDelete(e, area.id);
                                                                                }}
                                                                            >
                                                                                <i className="zmdi zmdi-delete"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </>
            )
            }
        </>
    )
}

export default AreasListPage;