import {
  LABELS_DELETE_FAIL,
  LABELS_DELETE_REQUEST,
  LABELS_DELETE_RESET,
  LABELS_DELETE_SUCCESS,
  LABELS_DETAILS_FAIL,
  LABELS_DETAILS_REQUEST,
  LABELS_DETAILS_RESET,
  LABELS_DETAILS_SUCCESS,
  LABELS_LIST_FAIL,
  LABELS_LIST_REQUEST,
  LABELS_LIST_RESET,
  LABELS_LIST_SUCCESS,
  LABELS_ADD_FAIL,
  LABELS_ADD_REQUEST,
  LABELS_ADD_RESET,
  LABELS_ADD_SUCCESS,
  LABELS_UPDATE_REQUEST,
  LABELS_UPDATE_SUCCESS,
  LABELS_UPDATE_FAIL,
  LABELS_UPDATE_RESET,
  LINKS_ADD_REQUEST,
  LINKS_ADD_SUCCESS,
  LINKS_ADD_FAIL,
  LINKS_ADD_RESET,
  LINKS_DELETE_REQUEST,
  LINKS_DELETE_SUCCESS,
  LINKS_DELETE_FAIL,
  LINKS_DELETE_RESET,
  LINKS_DETAILS_REQUEST,
  LINKS_DETAILS_SUCCESS,
  LINKS_DETAILS_FAIL,
  LINKS_DETAILS_RESET,
  LINKS_UPDATE_REQUEST,
  LINKS_UPDATE_SUCCESS,
  LINKS_UPDATE_FAIL,
  LINKS_UPDATE_RESET,
  LINKS_LIST_REQUEST,
  LINKS_LIST_SUCCESS,
  LINKS_LIST_FAIL,
  LINKS_LIST_RESET,
} from "../constants/searcher.const";

export const labelAddReducer = (state = {}, action) => {
  switch (action.type) {
    case LABELS_ADD_REQUEST:
      return { loading: true };
    case LABELS_ADD_SUCCESS:
      return { loading: false, addResult: action.payload };
    case LABELS_ADD_FAIL:
      return { loading: false, error: action.payload };
    case LABELS_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const linkAddReducer = (state = {}, action) => {
  switch (action.type) {
    case LINKS_ADD_REQUEST:
      return { loading: true };
    case LINKS_ADD_SUCCESS:
      return { loading: false, addResult: action.payload };
    case LINKS_ADD_FAIL:
      return { loading: false, error: action.payload };
    case LINKS_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const labelListReducer = (state = { labels: [] }, action) => {
  switch (action.type) {
    case LABELS_LIST_REQUEST:
      return { loading: true };
    case LABELS_LIST_SUCCESS:
      return { loading: false, labels: action.payload };
    case LABELS_LIST_FAIL:
      return { loading: false, error: action.payload };
    case LABELS_LIST_RESET:
      return { labels: [] };
    default:
      return state;
  }
};

export const linkListReducer = (state = {}, action) => {
  switch (action.type) {
    case LINKS_LIST_REQUEST:
      return { loading: true };
    case LINKS_LIST_SUCCESS:
      return { loading: false, links: action.payload };
    case LINKS_LIST_FAIL:
      return { loading: false, error: action.payload };
    case LINKS_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const labelDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case LABELS_DELETE_REQUEST:
      return { loading: true };
    case LABELS_DELETE_SUCCESS:
      return { loading: false, deleteLabelResult: action.payload };
    case LABELS_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case LABELS_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const linkDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case LINKS_DELETE_REQUEST:
      return { loading: true };
    case LINKS_DELETE_SUCCESS:
      return { loading: false, deleteLinkResult: action.payload };
    case LINKS_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case LINKS_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const labelDetailsReducer = (state = { label: {} }, action) => {
  switch (action.type) {
    case LABELS_DETAILS_REQUEST:
      return { ...state, loading: true };
    case LABELS_DETAILS_SUCCESS:
      return { loading: false, label: action.payload };
    case LABELS_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case LABELS_DETAILS_RESET:
      return { label: {} };
    default:
      return state;
  }
};

export const linkDetailsReducer = (state = { link: {} }, action) => {
  switch (action.type) {
    case LINKS_DETAILS_REQUEST:
      return { ...state, loading: true };
    case LINKS_DETAILS_SUCCESS:
      return { loading: false, link: action.payload };
    case LINKS_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case LINKS_DETAILS_RESET:
      return { link: {} };
    default:
      return state;
  }
};

export const labelUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case LABELS_UPDATE_REQUEST:
      return { loading: true };
    case LABELS_UPDATE_SUCCESS:
      return { loading: false, updateResult: action.payload };
    case LABELS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case LABELS_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const linkUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case LINKS_UPDATE_REQUEST:
      return { loading: true };
    case LINKS_UPDATE_SUCCESS:
      return { loading: false, updateResult: action.payload };
    case LINKS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case LINKS_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
