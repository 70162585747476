import api from "../../api/rigori.api";

import {
  QUESTIONNAIRES_ADD_FAIL,
  QUESTIONNAIRES_ADD_FILE_FAIL,
  QUESTIONNAIRES_ADD_FILE_REQUEST,
  QUESTIONNAIRES_ADD_FILE_SUCCESS,
  QUESTIONNAIRES_ADD_ITEM_FAIL,
  QUESTIONNAIRES_ADD_ITEM_REQUEST,
  QUESTIONNAIRES_ADD_ITEM_SUCCESS,
  QUESTIONNAIRES_ADD_REQUEST,
  QUESTIONNAIRES_ADD_SUCCESS,
  QUESTIONNAIRES_DELETE_FILE_FAIL,
  QUESTIONNAIRES_DELETE_FILE_REQUEST,
  QUESTIONNAIRES_DELETE_FILE_SUCCESS,
  QUESTIONNAIRES_DETAILS_FAIL,
  QUESTIONNAIRES_DETAILS_REQUEST,
  QUESTIONNAIRES_DETAILS_SUCCESS,
  QUESTIONNAIRES_GET_COMPANIES_FAIL,
  QUESTIONNAIRES_GET_COMPANIES_REQUEST,
  QUESTIONNAIRES_GET_COMPANIES_SUCCESS,
  QUESTIONNAIRES_GET_FILE_FAIL,
  QUESTIONNAIRES_GET_FILE_REQUEST,
  QUESTIONNAIRES_GET_FILE_SUCCESS,
  QUESTIONNAIRES_GET_USERS_FAIL,
  QUESTIONNAIRES_GET_USERS_REQUEST,
  QUESTIONNAIRES_GET_USERS_SUCCESS,
  QUESTIONNAIRES_LIST_FAIL,
  QUESTIONNAIRES_LIST_REQUEST,
  QUESTIONNAIRES_LIST_SUCCESS,
  QUESTIONNAIRES_RESPONSE_FAIL,
  QUESTIONNAIRES_RESPONSE_REQUEST,
  QUESTIONNAIRES_RESPONSE_SUCCESS,
  QUESTIONNAIRES_DELETE_ITEM_REQUEST,
  QUESTIONNAIRES_DELETE_ITEM_SUCCESS,
  QUESTIONNAIRES_DELETE_ITEM_FAIL,
  QUESTIONNAIRES_FILTRADA_GET,
  QUESTIONNAIRES_FILTRADA_DETAILS_GET,
} from "../constants/questionnaires.const";

import { logout } from "./user.actions";

export const addQuesionnaires =
  (
    name,
    deadline,
    checklistDeadline,
    userCompanyId,
    supervisorId,
    companyId,
    priorityId
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: QUESTIONNAIRES_ADD_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await api.post(
        "/quizzes",
        {
          name,
          deadline,
          checklistDeadline,
          userCompanyId,
          supervisorId,
          companyId,
          priorityId,
        },
        config
      );

      dispatch({
        type: QUESTIONNAIRES_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Unauthorized") {
        dispatch(logout());
      }
      dispatch({
        type: QUESTIONNAIRES_ADD_FAIL,
        payload: message,
      });
    }
  };

export const listQuestionnaries = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: QUESTIONNAIRES_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.get(`/quizzes`, config);

    dispatch({
      type: QUESTIONNAIRES_LIST_SUCCESS,
      payload: data,
    });
    dispatch({
      type: QUESTIONNAIRES_FILTRADA_GET,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: QUESTIONNAIRES_LIST_FAIL,
      payload: message,
    });
  }
};
export const listQuestionnairesByCondition =
  (filter) => async (dispatch, getState) => {
    //const reservas = getState().reservas.reservas;
    //console.log(filter);
    //console.log(reservas);
    var questionnairesFilter;
    if (filter === "Todas") {
      questionnairesFilter = getState().questionnaryList.questionnaries;
    } else if (filter === "Completados") {
      questionnairesFilter = getState().questionnaryList.questionnaries.filter(
        function (element) {
          return element.progress === "100";
        }
      );
    } else if (filter === "No Completados") {
      questionnairesFilter = getState().questionnaryList.questionnaries.filter(
        function (element) {
          return element.progress !== "100";
        }
      );
    } else if (filter === "Prioridad alta") {
      questionnairesFilter = getState().questionnaryList.questionnaries.filter(
        function (element) {
          return element.priority.name === "ALTA";
        }
      );
    } else if (filter === "Prioridad baja") {
      questionnairesFilter = getState().questionnaryList.questionnaries.filter(
        function (element) {
          return element.priority.name === "BAJA";
        }
      );
    } else if (filter === "Prioridad media") {
      questionnairesFilter = getState().questionnaryList.questionnaries.filter(
        function (element) {
          return element.priority.name === "MEDIA";
        }
      );
    } else {
      questionnairesFilter = getState().questionnaryList.questionnaries;
    }

    dispatch({
      type: QUESTIONNAIRES_FILTRADA_GET,
      payload: questionnairesFilter,
    });
  };

//descending order
export const listQuestionnairesBySorting =
  (filter) => async (dispatch, getState) => {
    var questionnairesSorted;
    if (filter === "date") {
      questionnairesSorted = getState().questionnaryListFiltered.questionnaries;
    } else if (filter === "progress") {
      questionnairesSorted =
        getState().questionnaryListFiltered.questionnaries.sort(function (
          a,
          b
        ) {
          return parseFloat(b.progress) - parseFloat(a.progress);
        });
    } else if (filter === "deadline") {
      questionnairesSorted =
        getState().questionnaryListFiltered.questionnaries.sort(function (
          a,
          b
        ) {
          console.log(Date(b.deadline));
          return new Date(a.deadline) - new Date(b.deadline);
        });
    } else if (filter === "supervisor") {
      questionnairesSorted =
        getState().questionnaryListFiltered.questionnaries.sort(function (
          a,
          b
        ) {
          return b.supervisor.id - a.supervisor.id;
        });
    } else if (filter === "company") {
      questionnairesSorted =
        getState().questionnaryListFiltered.questionnaries.sort(function (
          a,
          b
        ) {
          return b.company.id - a.company.id;
        });
    } else if (filter === "") {
      questionnairesSorted = getState().questionnaryList.questionnaries;
    } else {
      questionnairesSorted = getState().questionnaryListFiltered.questionnaries;
    }

    dispatch({
      type: QUESTIONNAIRES_FILTRADA_GET,
      payload: questionnairesSorted,
    });
  };

export const listQuestionnairesBySearching =
  (filter) => async (dispatch, getState) => {
    //const reservas = getState().reservas.reservas;
    const filterNormalized = filter.trim().toLowerCase();
    //console.log(reservas);
    var questionnairesFilter;
    if (filter === "") {
      questionnairesFilter = getState().questionnaryList.questionnaries;
    } else {
      questionnairesFilter = getState().questionnaryList.questionnaries.filter(
        function (element) {
          return (
            element.name.toLowerCase().includes(filterNormalized) |
            element.supervisor.name.toLowerCase().includes(filterNormalized) |
            element.supervisor.lastname
              .toLowerCase()
              .includes(filterNormalized) |
            element.supervisor.email.toLowerCase().includes(filterNormalized) |
            element.company.name.toLowerCase().includes(filterNormalized) |
            element.company.ruc.toLowerCase().includes(filterNormalized) |
            element.company.email.toLowerCase().includes(filterNormalized)
          );
        }
      );
    }

    dispatch({
      type: QUESTIONNAIRES_FILTRADA_GET,
      payload: questionnairesFilter,
    });
  };

//LIST USERS FOR CREATE CUESTIONARIE
export const listUsersQuestionnaires = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: QUESTIONNAIRES_GET_USERS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.get(`/users`, config);

    dispatch({
      type: QUESTIONNAIRES_GET_USERS_SUCCESS,
      payload: {
        users: data.filter((item) => {
          return item.role.id !== 2;
        }),
        usersCompany: data.filter((item) => {
          return item.role.id === 2;
        }),
      },
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: QUESTIONNAIRES_GET_USERS_FAIL,
      payload: message,
    });
  }
};
//LIST COMPANIES FOR CREATE CUESTIONARIE
export const listCompaniesQuestionnaires = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: QUESTIONNAIRES_GET_COMPANIES_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.get(`/companies`, config);

    dispatch({
      type: QUESTIONNAIRES_GET_COMPANIES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: QUESTIONNAIRES_GET_COMPANIES_FAIL,
      payload: message,
    });
  }
};

export const getQuestionnairesDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: QUESTIONNAIRES_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await api.get(`/quizzes/${id}`, config);

    dispatch({
      type: QUESTIONNAIRES_DETAILS_SUCCESS,
      payload: data,
    });
    dispatch({
      type: QUESTIONNAIRES_FILTRADA_DETAILS_GET,
      payload: data.quizItems,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: QUESTIONNAIRES_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const getQuestionnairesDetailsByCondition =
  (filter) => async (dispatch, getState) => {
    //const reservas = getState().reservas.reservas;
    //console.log(filter);
    //console.log(reservas);
    var questionnairesFilter;
    if (filter === "Todas") {
      questionnairesFilter =
        getState().questionnaryDetail.questionnary.quizItems;
    } else if (filter === "answered") {
      questionnairesFilter =
        getState().questionnaryDetail.questionnary.quizItems.filter(function (
          element
        ) {
          return element.state;
        });
    } else if (filter === "notAnswered") {
      questionnairesFilter =
        getState().questionnaryDetail.questionnary.quizItems.filter(function (
          element
        ) {
          return !element.state;
        });
    } else if (filter === "perform") {
      questionnairesFilter =
        getState().questionnaryDetail.questionnary.quizItems.filter(function (
          element
        ) {
          return element.state && element.state.id === 1;
        });
    } else if (filter === "notPerform") {
      questionnairesFilter =
        getState().questionnaryDetail.questionnary.quizItems.filter(function (
          element
        ) {
          return element.state && element.state.id === 2;
        });
    } else {
      questionnairesFilter =
        getState().questionnaryDetail.questionnary.quizItems;
    }

    dispatch({
      type: QUESTIONNAIRES_FILTRADA_DETAILS_GET,
      payload: questionnairesFilter,
    });
  };

//descending order
export const getQuestionnairesDetailsBySorting =
  (filter) => async (dispatch, getState) => {
    var questionnairesSorted;
    if (filter === "date") {
      questionnairesSorted = getState().questionnaryListFiltered.questionnaries;
    } else if (filter === "progress") {
      questionnairesSorted =
        getState().questionnaryListFiltered.questionnaries.sort(function (
          a,
          b
        ) {
          return parseFloat(b.progress) - parseFloat(a.progress);
        });
    } else if (filter === "deadline") {
      questionnairesSorted =
        getState().questionnaryListFiltered.questionnaries.sort(function (
          a,
          b
        ) {
          // console.log(Date(b.deadline));
          return new Date(a.deadline) - new Date(b.deadline);
        });
    } else if (filter === "supervisor") {
      questionnairesSorted =
        getState().questionnaryListFiltered.questionnaries.sort(function (
          a,
          b
        ) {
          return b.supervisor.id - a.supervisor.id;
        });
    } else if (filter === "company") {
      questionnairesSorted =
        getState().questionnaryListFiltered.questionnaries.sort(function (
          a,
          b
        ) {
          return b.company.id - a.company.id;
        });
    } else if (filter === "") {
      questionnairesSorted = getState().questionnaryList.questionnaries;
    } else {
      questionnairesSorted = getState().questionnaryListFiltered.questionnaries;
    }

    dispatch({
      type: QUESTIONNAIRES_FILTRADA_GET,
      payload: questionnairesSorted,
    });
  };

export const getQuestionnairesDetailsBySearching =
  (filter) => async (dispatch, getState) => {
    //const reservas = getState().reservas.reservas;
    const filterNormalized = filter.trim().toLowerCase();
    //console.log(reservas);
    var questionnairesFilter;
    if (filter === "") {
      questionnairesFilter =
        getState().questionnaryDetail.questionnary.quizItems;
    } else {
      questionnairesFilter =
        getState().questionnaryDetail.questionnary.quizItems.filter(function (
          element
        ) {
          return (
            (element.item.category.name &&
              element.item.category.name
                .toLowerCase()
                .includes(filterNormalized)) ||
            (element.item.category.description !== null &&
              element.item.category.description
                .toLowerCase()
                .includes(filterNormalized)) ||
            (element.item.question !== null &&
              element.item.question.toLowerCase().includes(filterNormalized)) ||
            (element.answer !== null &&
              element.answer.toLowerCase().includes(filterNormalized))
          );
        });
    }

    dispatch({
      type: QUESTIONNAIRES_FILTRADA_DETAILS_GET,
      payload: questionnairesFilter,
    });
  };

export const getQuestionnairesFiles = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: QUESTIONNAIRES_GET_FILE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await api.get(`/quizzes-items/${id}`, config);

    dispatch({
      type: QUESTIONNAIRES_GET_FILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: QUESTIONNAIRES_GET_FILE_FAIL,
      payload: message,
    });
  }
};

export const addItem =
  (question, categoryId, quizId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: QUESTIONNAIRES_ADD_ITEM_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await api.post(
        "/quizzes-items/add-item",
        { question, categoryId, quizId },
        config
      );

      dispatch({
        type: QUESTIONNAIRES_ADD_ITEM_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Unauthorized") {
        dispatch(logout());
      }
      dispatch({
        type: QUESTIONNAIRES_ADD_ITEM_FAIL,
        payload: message,
      });
    }
  };

export const questionnaryResponse =
  (quizItemId, stateId, answer, note) => async (dispatch, getState) => {
    try {
      dispatch({
        type: QUESTIONNAIRES_RESPONSE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await api.post(
        "/quizzes-items/answer",
        { quizItemId, stateId, answer, note },
        config
      );

      dispatch({
        type: QUESTIONNAIRES_RESPONSE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Unauthorized") {
        dispatch(logout());
      }
      dispatch({
        type: QUESTIONNAIRES_RESPONSE_FAIL,
        payload: message,
      });
    }
  };

export const addFile = (id, files) => async (dispatch, getState) => {
  try {
    dispatch({
      type: QUESTIONNAIRES_ADD_FILE_REQUEST,
    });
    let formData = new FormData();

    /* files.forEach((element) => {
        formData.append("files", element);
      }); */
    const {
      userLogin: { userInfo },
    } = getState();
    for (let i = 0; i < files.length; i++) {
      //console.log(files[i]);
      formData.append("file", files[i]);
      //console.log(formData);
    }
    //console.log(files);
    //formData.append("file", files[0]);
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    const { data } = await api.post(
      `/quizzes-items/add-file/${id}`,
      formData,
      config
    );
    dispatch({
      type: QUESTIONNAIRES_ADD_FILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    /* if (message === "Unauthorized") {
        dispatch(logout());
      } */
    dispatch({
      type: QUESTIONNAIRES_ADD_FILE_FAIL,
      payload: message,
    });
  }
};
export const deleteFile = (fileId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: QUESTIONNAIRES_DELETE_FILE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.delete(`/quizzes-items/${fileId}`, config);
    //console.log(data);

    dispatch({
      type: QUESTIONNAIRES_DELETE_FILE_SUCCESS,
      payload: data,
    });
    // dispatch(listCompanies());
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: QUESTIONNAIRES_DELETE_FILE_FAIL,
      payload: message,
    });
  }
};

export const deleteItem = (itemId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: QUESTIONNAIRES_DELETE_ITEM_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.delete(`/quizzes-items/item/${itemId}`, config);
    //console.log(data);

    dispatch({
      type: QUESTIONNAIRES_DELETE_ITEM_SUCCESS,
      payload: data,
    });
    // dispatch(listCompanies());
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: QUESTIONNAIRES_DELETE_ITEM_FAIL,
      payload: message,
    });
  }
};
