const CHECKLIST_QUESTIONS_FILTER_OPTIONS = {
  filter: [
    {
      title: "Todas",
      action: "Todas",
    },
    {
      title: "Respondidas",
      action: "answered",
    },
    {
      title: "No Respondidas",
      action: "notAnswered",
    },
    {
      title: "Cumple",
      action: "perform",
    },
    {
      title: "No Cumple",
      action: "notPerform",
    },
  ],
  sort: [],
};

export default CHECKLIST_QUESTIONS_FILTER_OPTIONS;
