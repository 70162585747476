import React, { useEffect, useState } from "react";
import { useNavigate, Link, NavLink } from "react-router-dom";

//redux import
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/actions/user.actions";
import { openSidebar } from "../../redux/actions/navigation.actions";
import { UnmountClosed } from "react-collapse";

//images import
import logo from "../../assets/images/rigoriLogo.png";

//styles
import styles from "./left-side-bar.module.scss";

const LeftSideBar = () => {
	const navigate = useNavigate();
	const [isCollapse, setIsCollapse] = useState(false);
	const [isCollapseRiskMap, setIsCollapseRiskMap] = useState(false);
	const [isCollapseComplaints, setIsCollapseComplaints] = useState(false);
	const dispatch = useDispatch();
	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;
	const navigation = useSelector((state) => state.navigation);
	const { activeItem, mobileSidebarOpened } = navigation;

	const logoutHandler = () => {
		if (window.confirm("Cerar Sesión ?")) {
			dispatch(logout());
		}
	};

	useEffect(() => {
		if (!userInfo) {
			navigate("/login");
		}
	}, [navigate, userInfo]);

	return (
		<>
			{/* <!-- Left Sidebar --> */}
			<aside id="leftsidebar" className={`sidebar ${mobileSidebarOpened === false ? "open" : ""}`}>
				<div className="navbar-brand">
					<button className="btn-menu" type="button" onClick={() => dispatch(openSidebar())}>
						<i className="zmdi zmdi-menu"></i>
					</button>
					<Link to="/">
						<img src={logo} width="30" alt="Aero" />
						<span className="m-l-10">Rigori</span>
					</Link>
				</div>

				<div className="menu">
					<ul className="list">

						{userInfo && (
						<div className="scrollable" >
								<li>
									<div className="user-info p-2">
										{/* <Link className="image" to="/usuario/actualizar">
											<img src={defaultUser} alt="User" />
										</Link> */}

										<div className="detail">
											<Link to="/usuario/actualizar" className="py-0">
												<h4>{userInfo.username}</h4>
											</Link>
											<small className={styles.role}>{userInfo.role}</small>
										</div>
									</div>
								</li>

								{/* Panel Administrativo */}
								{userInfo.role === "SUPERADMIN" && (
									<li>
										<Link to="/" className={`${activeItem === "/" ? styles.navbar_item_active : ""}`}>
											<i className="zmdi zmdi-home"></i>
											<span>Panel Administrativo</span>
										</Link>
									</li>
								)}
								{/* USUARIOS */}
								{userInfo.role === "SUPERADMIN" && (
									<li>
										<Link to="/usuarios" className={`${activeItem.includes("usuario") ? styles.navbar_item_active : ""}`}>
											<i className="zmdi zmdi-account"></i>
											<span>Usuarios</span>
										</Link>
									</li>
								)}
								{/* Empresas - Categorías */}
								{userInfo.role === "SUPERADMIN" && (
									<li>
										<Link className={`${activeItem.includes("categorias") ? styles.navbar_item_active : ""}`} to="/categorias">
											<i className="zmdi zmdi-bookmark"></i>
											<span>Empresas - Categorías</span>
										</Link>
									</li>
								)}
								{userInfo.role === "SUPERADMIN" && (
									<li>
										<Link className={`${activeItem.includes("area") ? styles.navbar_item_active : ""}`} to="/area">
											<i className="zmdi zmdi-city-alt"></i>
											<span>Empresas - Áreas</span>
										</Link>
									</li>
								)}
								{/* Empresas */}
								{(userInfo.role === "SUPERADMIN" || userInfo.role === "SUPERVISOR") && (
									<li>
										<Link className={`${activeItem.includes("empresa") ? styles.navbar_item_active : ""}`} to="/empresas">
											<i className="zmdi zmdi-folder"></i>
											<span>Empresas</span>
										</Link>
									</li>
								)}
								{/* Mis Cuestionarios */}
								{userInfo.role === "EMPRESA" && (
									<li>
										<Link className={`${activeItem.includes("cuestionario") ? styles.navbar_item_active : ""}`} to="/cuestionarios">
											<i className="zmdi zmdi-check-square"></i>
											<span>Mis cuestionarios</span>
										</Link>
									</li>
								)}
								{/* Cuestionarios */}
								{(userInfo.role === "SUPERADMIN" || userInfo.role === "SUPERVISOR") && (
									<li>
										<Link className={`${activeItem.includes("cuestionario") ? styles.navbar_item_active : ""}`} to="/cuestionarios">
											<i className="zmdi zmdi-assignment-o"></i>
											<span>Cuestionarios</span>
										</Link>
									</li>
								)}
								{/* Mapa de Riesgos */}
								{(userInfo.role === "SUPERADMIN" || userInfo.role === "SUPERVISOR") && (
									<li>
										{" "}
										<a
											href={false}
											className={`menu-toggle ${styles.collapseTitle}`}
											onClick={() => {
												setIsCollapseRiskMap(!isCollapseRiskMap);
											}}
										>
											<i className="zmdi zmdi-chart-donut"></i>
											<span>Mapa de Riesgos</span>
										</a>
										<UnmountClosed isOpened={isCollapseRiskMap}>
											<ul className={styles.collapseList}>
												<li>
													<Link
														to="/mapa-de-riesgos/administrar"
														className={`${activeItem.includes("administrar") ||
															activeItem.includes("tipologias") ||
															activeItem.includes("tipo-conductas") ||
															activeItem.includes("conductas")
															? styles.navbar_item_active
															: ""
															}`}
													>
														<i className="zmdi  zmdi-assignment-alert"></i>
														<span>Administración</span>
													</Link>
												</li>

												<li>
													<Link
														to="/mapa-de-riesgos"
														className={`${activeItem.includes("mapa") &&
															!activeItem.includes("administrar") &&
															!activeItem.includes("tipologias") &&
															!activeItem.includes("tipo-conductas") &&
															!activeItem.includes("conductas")
															? styles.navbar_item_active
															: ""
															}`}
													>
														<i className="zmdi zmdi-case"></i>
														<span>Riesgos, Controles, Acciones</span>
													</Link>
												</li>
												{/* <li>
													<Link
														to="/buscar"
														className={`${
															activeItem.includes("buscar")
																? styles.navbar_item_active
																: ""
														}`}
													>
														<i className="zmdi zmdi-chart"></i>
														<span>Resultados</span>
													</Link>
												</li> */}
											</ul>
										</UnmountClosed>
									</li>
								)}
								{/* Denuncias */}
								{(userInfo.role === "SUPERADMIN" || userInfo.role === "AGENTE DE CUMPLIMIENTO") && (
									<li>
										{/* <li>
										<Link
											className={`${activeItem.includes("denuncia") ? styles.navbar_item_active : ""}`}
											to="/denuncias"
										>
											<i className="zmdi zmdi-help"></i>
											<span>Denuncias</span>
										</Link>
									</li> */}{" "}
										<a
											href={false}
											className={`menu-toggle ${styles.collapseTitle}`}
											onClick={() => {
												setIsCollapseComplaints(!isCollapseComplaints);
											}}
										>
											<i className="zmdi zmdi-help"></i>
											<span>Denuncias</span>
										</a>
										<UnmountClosed isOpened={isCollapseComplaints}>
											<ul className={styles.collapseList}>
												<li>
													<Link to="/estados" className={`${activeItem.includes("estados") ? styles.navbar_item_active : ""}`}>
														<i className="zmdi zmdi-settings"></i>
														<span>Gestión de estados</span>
													</Link>
												</li>
												<li>
													<Link to="/denuncias" className={`${activeItem.includes("denuncias") ? styles.navbar_item_active : ""}`}>
														<i className="zmdi zmdi-info"></i>
														<span>Ver denuncias</span>
													</Link>
												</li>
											</ul>
										</UnmountClosed>
									</li>
								)}
								{/* Buscador (todas las opciones) */}
								{(userInfo.role === "SUPERADMIN" || userInfo.role === "SUPERVISOR") && (
									<li>
										{" "}
										<a
											href={false}
											className={`menu-toggle ${styles.collapseTitle}`}
											onClick={() => {
												setIsCollapse(!isCollapse);
											}}
										>
											<i className="zmdi zmdi-search-in-page"></i>
											<span>Buscador</span>
										</a>
										<UnmountClosed isOpened={isCollapse}>
											<ul className={styles.collapseList}>
												<li>
													<Link to="/etiquetas" className={`${activeItem.includes("etiqueta") ? styles.navbar_item_active : ""}`}>
														<i className="zmdi zmdi-label"></i>
														<span>Etiquetas</span>
													</Link>
												</li>
												<li>
													<Link to="/enlaces" className={`${activeItem.includes("enlace") ? styles.navbar_item_active : ""}`}>
														<i className="zmdi zmdi-link"></i>
														<span>Enlaces</span>
													</Link>
												</li>
												<li>
													<Link to="/buscar" className={`${activeItem.includes("buscar") ? styles.navbar_item_active : ""}`}>
														<i className="zmdi zmdi-search-in-page"></i>
														<span>Buscar</span>
													</Link>
												</li>
											</ul>
										</UnmountClosed>
									</li>
								)}
								{/* Buscador (solo buscar) */}
								{userInfo.role === "EMPRESA" && (
									<li>
										<Link to="/buscar" className={`${activeItem.includes("buscar") ? styles.navbar_item_active : ""}`}>
											<i className="zmdi zmdi-search-in-page"></i>
											<span>Buscador</span>
										</Link>
									</li>
								)}
								{/* Alertas */}
								{(userInfo.role === "SUPERADMIN" || userInfo.role === "SUPERVISOR" || userInfo.role === "EMPRESA") && (
									<li>
										<Link className={`${activeItem.includes("alertas") ? styles.navbar_item_active : ""}`} to="/alertas">
											<i className="zmdi zmdi-notifications"></i>
											<span>Alertas</span>
										</Link>
									</li>
								)}
								{/* Repositorio */}
								{(userInfo.role === "SUPERADMIN" || userInfo.role === "SUPERVISOR" || userInfo.role === "EMPRESA") && (
									<li>
										<Link className={`${activeItem.includes("repositorio") ? styles.navbar_item_active : ""}`} to="/repositorio">
											<i className="zmdi zmdi-folder-special"></i>
											<span>Repositorio</span>
										</Link>
									</li>
								)}
								<li className="d-md-none">
									<NavLink to="#" onClick={logoutHandler} className="">
										<i className="zmdi zmdi-power"></i>
										<span>Cerrar Sesión</span>
									</NavLink>
								</li>


							</div>
						)}
					</ul>
				</div>
			</aside>
		</>
	);
};

export default LeftSideBar;
