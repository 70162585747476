import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

//import components
import Header from "../../layout/header/header.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import SkeletonLoader from "../../components/shared/loaders/skeleton-loader/skeleton-loader.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";

//import redux ;
import { useDispatch, useSelector } from "react-redux";
import {
  listCompanies,
  deleteCompany as deleteCompanyAction,
} from "../../redux/actions/companies.actions";
import { changeActiveSidebarItem } from "../../redux/actions/navigation.actions";
import {
  COMPANIES_ADD_RESET,
  COMPANIES_DELETE_RESET,
  COMPANIES_UPDATE_RESET,
  COMPANIES_DETAILS_RESET,
  COMPANIES_DELETE_AREA_RESET,
} from "../../redux/constants/companies.const";

const BusinessListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState(null);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const companyList = useSelector((state) => state.companyList);
  const { loading, error, companies } = companyList;
  const companyDelete = useSelector((state) => state.companyDelete);
  const companyAdd = useSelector((state) => state.companyAdd);
  const { addResult } = companyAdd;
  const companyUpdate = useSelector((state) => state.companyUpdate);
  const { updateResult } = companyUpdate;
  const {
    loading: loadingDelete,
    error: errorDelete,
    deleteCompanyResult,
  } = companyDelete;

  useEffect(() => {
    dispatch(changeActiveSidebarItem(location.pathname));
    if (!userInfo) {
      navigate("/login");
    } else {
      if (addResult) {
        setMessage("EMPRESA creada con éxito");
      }
      if (updateResult) {
        setMessage("Empresa actualizada con éxito");
      }
      dispatch({ type: COMPANIES_ADD_RESET });
      dispatch({ type: COMPANIES_UPDATE_RESET });
      dispatch({ type: COMPANIES_DELETE_RESET });
      dispatch({ type: COMPANIES_DETAILS_RESET });
      dispatch({ type: COMPANIES_DELETE_AREA_RESET });
      dispatch(listCompanies());
    }
  }, [navigate, userInfo, dispatch, addResult, updateResult, location]);

  const handleDelete = (e, id) => {
    e.preventDefault();
    if (window.confirm("Esta acción no se puede deshacer.")) {
      dispatch(deleteCompanyAction(id));
    }
  };

  return (
    <>
      <Header title="Lista de Empresas" path="Lista de Empresas">
        <Link
          to="/empresa/agregar"
          className="btn btn-success btn-icon float-right "
        >
          <i className="zmdi zmdi-plus"></i>
        </Link>
      </Header>
      {errorDelete && (
        <div className="mb-2 mt-2">
          <Alert message={errorDelete} />
        </div>
      )}
      {loadingDelete && (
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card text-center">
                <Loader />
              </div>
            </div>
          </div>
        </div>
      )}
      {deleteCompanyResult && (
        <div className="mb-2 mt-2">
          <Alert message="Empresa eliminada exitosamente" severity="info" />
        </div>
      )}
      {message && (
        <div className="mb-2 mt-2">
          <Alert message={message} severity="info" />
        </div>
      )}
      {error ? (
        <div className="mb-2 mt-2">
          <Alert message={error} />
        </div>
      ) : loading ? (
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <SkeletonLoader />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-hover c_table theme-color">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Nombre</th>
                        <th>RUC</th>
                        <th>Dirección</th>
                        <th>Correo</th>
                        <th>Teléfono</th>
                        <th>Categoría</th>
                        <th>Canal</th>
                        <th>Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      {companies.length === 0 ? (
                        <h4 className="text-center">
                          No existen empresas registradas.
                        </h4>
                      ) : (
                        companies.map((companie, index) => (
                          <tr key={index}>
                            <td>{index +1 }</td>
                            <td>
                              <strong>{companie.name}</strong>
                            </td>
                            <td>{companie.ruc} </td>
                            <td>{companie.address} </td>
                            <td>{companie.email} </td>
                            <td>{companie.phone}</td>
                            <td>{companie.category.name}</td>
                            <td>
                              <Link
                                target={"_blank"}
                                to={`/denunciar/${companie.id}`}
                                className="btn btn-success btn-sm"
                                title="Denunciar"
                              >
                                <i className="zmdi zmdi-file-text"></i>
                              </Link>
                              <Link
                                target={"_blank"}
                                to={`/revisar-denuncia/${companie.id}`}
                                className="btn btn-primary btn-sm"
                                title="Revisar"
                              >
                                <i class="zmdi zmdi-eye"></i>
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={`/empresa/${companie.id}`}
                                className="btn btn-warning btn-sm"
                                title="Editar"
                              >
                                <i className="zmdi zmdi-edit"></i>
                              </Link>
                              <Link
                                to={`/empresa/areas/${companie.id}`}
                                className="btn btn-info btn-sm"
                                title="Administrar Áreas"
                              >
                                <i className="zmdi zmdi-city-alt"></i>
                              </Link>
                              <button
                                className="btn btn-danger btn-sm"
                                title="Eliminar"
                                onClick={(e) => {
                                  handleDelete(e, companie.id);
                                }}
                              >
                                <i className="zmdi zmdi-delete"></i>
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

    </>
  );
};

export default BusinessListPage;
