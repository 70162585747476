import {
  CATEGORIES_COMPANIES_DELETE_FAIL,
  CATEGORIES_COMPANIES_DELETE_REQUEST,
  CATEGORIES_COMPANIES_DELETE_RESET,
  CATEGORIES_COMPANIES_DELETE_SUCCESS,
  CATEGORIES_COMPANIES_DETAILS_FAIL,
  CATEGORIES_COMPANIES_DETAILS_REQUEST,
  CATEGORIES_COMPANIES_DETAILS_RESET,
  CATEGORIES_COMPANIES_DETAILS_SUCCESS,
  CATEGORIES_COMPANIES_LIST_FAIL,
  CATEGORIES_COMPANIES_LIST_REQUEST,
  CATEGORIES_COMPANIES_LIST_RESET,
  CATEGORIES_COMPANIES_LIST_SUCCESS,
  CATEGORIES_COMPANIES_ADD_FAIL,
  CATEGORIES_COMPANIES_ADD_REQUEST,
  CATEGORIES_COMPANIES_ADD_RESET,
  CATEGORIES_COMPANIES_ADD_SUCCESS,
  CATEGORIES_COMPANIES_UPDATE_REQUEST,
  CATEGORIES_COMPANIES_UPDATE_SUCCESS,
  CATEGORIES_COMPANIES_UPDATE_FAIL,
  CATEGORIES_COMPANIES_UPDATE_RESET,
} from "../constants/companies-categories.const";

export const categoryCompanyAddReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORIES_COMPANIES_ADD_REQUEST:
      return { loading: true };
    case CATEGORIES_COMPANIES_ADD_SUCCESS:
      return { loading: false, addResult: action.payload };
    case CATEGORIES_COMPANIES_ADD_FAIL:
      return { loading: false, error: action.payload };
    case CATEGORIES_COMPANIES_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const categoryCompanyListReducer = (state = { categories: [] }, action) => {
  switch (action.type) {
    case CATEGORIES_COMPANIES_LIST_REQUEST:
      return { loading: true };
    case CATEGORIES_COMPANIES_LIST_SUCCESS:
      return { loading: false, categories: action.payload };
    case CATEGORIES_COMPANIES_LIST_FAIL:
      return { loading: false, error: action.payload };
    case CATEGORIES_COMPANIES_LIST_RESET:
      return { categories: [] };
    default:
      return state;
  }
};

export const categoryCompanyDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORIES_COMPANIES_DELETE_REQUEST:
      return { loading: true };
    case CATEGORIES_COMPANIES_DELETE_SUCCESS:
      return { loading: false, deleteCompanyResult: action.payload };
    case CATEGORIES_COMPANIES_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case CATEGORIES_COMPANIES_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const categoryCompanyDetailsReducer = (state = { category: {} }, action) => {
  switch (action.type) {
    case CATEGORIES_COMPANIES_DETAILS_REQUEST:
      return { ...state, loading: true };
    case CATEGORIES_COMPANIES_DETAILS_SUCCESS:
      return { loading: false, category: action.payload };
    case CATEGORIES_COMPANIES_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case CATEGORIES_COMPANIES_DETAILS_RESET:
      return { category: {} };
    default:
      return state;
  }
};

export const categoryCompanyUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORIES_COMPANIES_UPDATE_REQUEST:
      return { loading: true };
    case CATEGORIES_COMPANIES_UPDATE_SUCCESS:
      return { loading: false, updateResult: action.payload };
    case CATEGORIES_COMPANIES_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case CATEGORIES_COMPANIES_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
