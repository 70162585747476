import React, { useState, useEffect } from "react";

//NAVIGATION
import { useNavigate } from "react-router-dom";

// REDUX IMPORT
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/actions/user.actions";

//COMPONENTS IMPORT
import Alert from "../components/shared/alerts/material-alert/alert.component";
import Loader from "../components/shared/loaders/spinner/sppinner.component";

const LoginScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [message, setMessage] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (userInfo) {
      navigate("/");
    }
  }, [navigate, userInfo]);

  const submitHandler = (e) => {
    if (password && email) {
      e.preventDefault();

      dispatch(login(email, password));
    } else {
      setMessage("Por favor llene los campos respectivos.");
    }
  };

  const handleSeePassword = (e) => {
    e.preventDefault();
    setIsPasswordVisible(!isPasswordVisible);
  };
  return (
    <div className="authentication mt-5 pt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-12">
            <form className="card auth_form" onSubmit={submitHandler}>
              <div className="header">
                <img
                  className="logo"
                  src="assets/images/rigoriLogo.png"
                  alt="Rigori"
                />
                <h5>Ingresar</h5>
                <p className="r-auth-description">
                  Bienvenid@ al panel administrativo de
                  <b> Rigori</b>, por favor ingrese sus credenciales para
                  iniciar sesión.
                </p>
                {error && <Alert message={error} />}
                {message && <Alert message={message} />}
                {loading && <Loader />}
              </div>
              <div className="body">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    placeholder="Nombre de usuario o Correo"
                    required
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i className="zmdi zmdi-account-circle"></i>
                    </span>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    className="form-control"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    placeholder="Contraseña"
                    required
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <button
                        className="forgot"
                        title="Ver"
                        onClick={handleSeePassword}
                      >
                        <i className="zmdi zmdi-lock"></i>
                      </button>
                    </span>
                  </div>
                </div>

                <button
                  onClick={submitHandler}
                  className="btn btn-rigori btn-block waves-effect waves-light"
                >
                  INGRESAR
                </button>
                {/* <div className="signin_with mt-3">
                  <p className="mb-0">or Sign Up using</p>
                  <button className="btn btn-primary btn-icon btn-icon-mini btn-round facebook">
                    <i className="zmdi zmdi-facebook"></i>
                  </button>
                  <button className="btn btn-primary btn-icon btn-icon-mini btn-round twitter">
                    <i className="zmdi zmdi-twitter"></i>
                  </button>
                  <button className="btn btn-primary btn-icon btn-icon-mini btn-round google">
                    <i className="zmdi zmdi-google-plus"></i>
                  </button>
                </div> */}
              </div>
            </form>
            <div className="copyright text-center">
              &copy;
              {new Date().getFullYear()},{" "}
              <span>
                <a
                  href="https://eiteck.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Eiteck
                </a>
              </span>
              {" & "}
              <span>
                <a
                  href="https://www.rigori.com.ec/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Rigori
                </a>
              </span>
            </div>
          </div>
          <div className="col-lg-8 col-sm-12">
            <div className="card">
              <img src="assets/images/signin.svg" alt="Ingresar" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
