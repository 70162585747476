import {
  CHECKLIST_DETAILS_FAIL,
  CHECKLIST_DETAILS_REQUEST,
  CHECKLIST_DETAILS_RESET,
  CHECKLIST_DETAILS_SUCCESS,
  CHECKLIST_GET_FILE_REQUEST,
  CHECKLIST_GET_FILE_SUCCESS,
  CHECKLIST_GET_FILE_FAIL,
  CHECKLIST_GET_FILE_RESET,
  CHECKLIST_ADD_ITEM_REQUEST,
  CHECKLIST_ADD_ITEM_SUCCESS,
  CHECKLIST_ADD_ITEM_FAIL,
  CHECKLIST_ADD_ITEM_RESET,
  CHECKLIST_RESPONSE_REQUEST,
  CHECKLIST_RESPONSE_SUCCESS,
  CHECKLIST_RESPONSE_FAIL,
  CHECKLIST_RESPONSE_RESET,
  CHECKLIST_ADD_FILE_REQUEST,
  CHECKLIST_ADD_FILE_SUCCESS,
  CHECKLIST_ADD_FILE_FAIL,
  CHECKLIST_ADD_FILE_RESET,
  CHECKLIST_DELETE_FILE_REQUEST,
  CHECKLIST_DELETE_FILE_SUCCESS,
  CHECKLIST_DELETE_FILE_FAIL,
  CHECKLIST_DELETE_FILE_RESET,
  CHECKLIST_DELETE_ITEM_REQUEST,
  CHECKLIST_DELETE_ITEM_SUCCESS,
  CHECKLIST_DELETE_ITEM_FAIL,
  CHECKLIST_DELETE_ITEM_RESET,
  CHECKLIS_FILTRADA_DETAILS_GET,
  CHECKLIS_FILTRADA_DETAILS_RESET,
} from "../constants/checklist.const";

export const checklistDetailsReducer = (state = { checklist: {} }, action) => {
  switch (action.type) {
    case CHECKLIST_DETAILS_REQUEST:
      return { ...state, loading: true };
    case CHECKLIST_DETAILS_SUCCESS:
      return { loading: false, checklist: action.payload };
    case CHECKLIST_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case CHECKLIST_DETAILS_RESET:
      return { checklist: {} };
    default:
      return state;
  }
};

export const checklistQuestionsFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECKLIS_FILTRADA_DETAILS_GET:
      return {
        questions: action.payload,
      };
    case CHECKLIS_FILTRADA_DETAILS_RESET:
      return {};

    default:
      return state;
  }
};

export const getCheckListFilesReducer = (state = { files: {} }, action) => {
  switch (action.type) {
    case CHECKLIST_GET_FILE_REQUEST:
      return { ...state, loading: true };
    case CHECKLIST_GET_FILE_SUCCESS:
      return { loading: false, files: action.payload };
    case CHECKLIST_GET_FILE_FAIL:
      return { loading: false, error: action.payload };
    case CHECKLIST_GET_FILE_RESET:
      return { files: {} };
    default:
      return state;
  }
};

export const checklistAddItemReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECKLIST_ADD_ITEM_REQUEST:
      return { loading: true };
    case CHECKLIST_ADD_ITEM_SUCCESS:
      return { loading: false, addResult: action.payload };
    case CHECKLIST_ADD_ITEM_FAIL:
      return { loading: false, error: action.payload };
    case CHECKLIST_ADD_ITEM_RESET:
      return {};
    default:
      return state;
  }
};

export const checklistResponseReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECKLIST_RESPONSE_REQUEST:
      return { loading: true };
    case CHECKLIST_RESPONSE_SUCCESS:
      return { loading: false, responseResult: action.payload };
    case CHECKLIST_RESPONSE_FAIL:
      return { loading: false, error: action.payload };
    case CHECKLIST_RESPONSE_RESET:
      return {};
    default:
      return state;
  }
};

export const checklistAddFileReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECKLIST_ADD_FILE_REQUEST:
      return { loading: true };
    case CHECKLIST_ADD_FILE_SUCCESS:
      return { loading: false, addResult: action.payload };
    case CHECKLIST_ADD_FILE_FAIL:
      return { loading: false, error: action.payload };
    case CHECKLIST_ADD_FILE_RESET:
      return {};
    default:
      return state;
  }
};

export const checklistDeleteFileReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECKLIST_DELETE_FILE_REQUEST:
      return { loading: true };
    case CHECKLIST_DELETE_FILE_SUCCESS:
      return { loading: false, deleteResult: action.payload };
    case CHECKLIST_DELETE_FILE_FAIL:
      return { loading: false, error: action.payload };
    case CHECKLIST_DELETE_FILE_RESET:
      return {};
    default:
      return state;
  }
};

export const checklistDeleteItemReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECKLIST_DELETE_ITEM_REQUEST:
      return { loading: true };
    case CHECKLIST_DELETE_ITEM_SUCCESS:
      return { loading: false, deleteResult: action.payload };
    case CHECKLIST_DELETE_ITEM_FAIL:
      return { loading: false, error: action.payload };
    case CHECKLIST_DELETE_ITEM_RESET:
      return {};
    default:
      return state;
  }
};
