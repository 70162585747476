export const CONDUCTS_LIST_REQUEST = "CONDUCTS_LIST_REQUEST";
export const CONDUCTS_LIST_SUCCESS = "CONDUCTS_LIST_SUCCESS";
export const CONDUCTS_LIST_FAIL = "CONDUCTS_LIST_FAIL";
export const CONDUCTS_LIST_RESET = "CONDUCTS_LIST_RESET";

export const CONDUCTS_DETAILS_REQUEST = "CONDUCTS_DETAILS_REQUEST";
export const CONDUCTS_DETAILS_SUCCESS = "CONDUCTS_DETAILS_SUCCESS";
export const CONDUCTS_DETAILS_FAIL = "CONDUCTS_DETAILS_FAIL";
export const CONDUCTS_DETAILS_RESET = "CONDUCTS_DETAILS_FAIL";

export const CONDUCTS_UPDATE_REQUEST = "CONDUCTS_UPDATE_REQUEST";
export const CONDUCTS_UPDATE_SUCCESS = "CONDUCTS_UPDATE_SUCCESS";
export const CONDUCTS_UPDATE_FAIL = "CONDUCTS_UPDATE_FAIL";
export const CONDUCTS_UPDATE_RESET = "CONDUCTS_UPDATE_RESET";

export const CONDUCTS_DELETE_REQUEST = "CONDUCTS_DELETE_REQUEST";
export const CONDUCTS_DELETE_SUCCESS = "CONDUCTS_DELETE_SUCCESS";
export const CONDUCTS_DELETE_FAIL = "CONDUCTS_DELETE_FAIL";
export const CONDUCTS_DELETE_RESET = "CONDUCTS_DELETE_FAIL";

export const CONDUCTS_ADD_REQUEST = "CONDUCTS_ADD_REQUEST";
export const CONDUCTS_ADD_SUCCESS = "CONDUCTS_ADD_SUCCESS";
export const CONDUCTS_ADD_FAIL = "CONDUCTS_ADD_FAIL";
export const CONDUCTS_ADD_RESET = "CONDUCTS_ADD_RESET";

export const CONDUCTS_FILTRADA_GET = "CONDUCTS_FILTRADA_GET";
export const CONDUCTS_FILTRADA_RESET = "CONDUCTS_FILTRADA_RESET";

export const CONDUCTS_TYPE_LIST_REQUEST = "CONDUCTS_TYPE_LIST_REQUEST";
export const CONDUCTS_TYPE_LIST_SUCCESS = "CONDUCTS_TYPE_LIST_SUCCESS";
export const CONDUCTS_TYPE_LIST_FAIL = "CONDUCTS_TYPE_LIST_FAIL";
export const CONDUCTS_TYPE_LIST_RESET = "CONDUCTS_TYPE_LIST_RESET";

export const CONDUCTS_TYPE_DELETE_REQUEST = "CONDUCTS_TYPE_DELETE_REQUEST";
export const CONDUCTS_TYPE_DELETE_SUCCESS = "CONDUCTS_TYPE_DELETE_SUCCESS";
export const CONDUCTS_TYPE_DELETE_FAIL = "CONDUCTS_TYPE_DELETE_FAIL";
export const CONDUCTS_TYPE_DELETE_RESET = "CONDUCTS_TYPE_DELETE_FAIL";

export const CONDUCTS_TYPE_ADD_REQUEST = "CONDUCTS_TYPE_ADD_REQUEST";
export const CONDUCTS_TYPE_ADD_SUCCESS = "CONDUCTS_TYPE_ADD_SUCCESS";
export const CONDUCTS_TYPE_ADD_FAIL = "CONDUCTS_TYPE_ADD_FAIL";
export const CONDUCTS_TYPE_ADD_RESET = "CONDUCTS_TYPE_ADD_RESET";

export const CONDUCTS_TYPE_FILTRADA_GET = "CONDUCTS_TYPE_FILTRADA_GET";
export const CONDUCTS_TYPE_FILTRADA_RESET = "CONDUCTS_TYPE_FILTRADA_RESET";
