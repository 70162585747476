import api from "../../api/rigori.api";

import {
  CATEGORIES_COMPANIES_DELETE_REQUEST,
  CATEGORIES_COMPANIES_DELETE_SUCCESS,
  CATEGORIES_COMPANIES_DELETE_FAIL,
  CATEGORIES_COMPANIES_DETAILS_FAIL,
  CATEGORIES_COMPANIES_DETAILS_REQUEST,
  CATEGORIES_COMPANIES_DETAILS_SUCCESS,
  CATEGORIES_COMPANIES_LIST_FAIL,
  CATEGORIES_COMPANIES_LIST_REQUEST,
  CATEGORIES_COMPANIES_LIST_SUCCESS,
  CATEGORIES_COMPANIES_ADD_FAIL,
  CATEGORIES_COMPANIES_ADD_REQUEST,
  CATEGORIES_COMPANIES_ADD_SUCCESS,
  CATEGORIES_COMPANIES_UPDATE_FAIL,
  CATEGORIES_COMPANIES_UPDATE_REQUEST,
  CATEGORIES_COMPANIES_UPDATE_SUCCESS,
} from "../constants/companies-categories.const";

import { logout } from "./user.actions";

export const addCategoryCompany = (name, description) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CATEGORIES_COMPANIES_ADD_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await api.post(
        "/categories-companies",
        { name, description },
        config
      );

      dispatch({
        type: CATEGORIES_COMPANIES_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Unauthorized") {
        dispatch(logout());
      }
      dispatch({
        type: CATEGORIES_COMPANIES_ADD_FAIL,
        payload: message,
      });
    }
  };

export const listCategoriesCompanies = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CATEGORIES_COMPANIES_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.get(`/categories-companies`, config);

    dispatch({
      type: CATEGORIES_COMPANIES_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: CATEGORIES_COMPANIES_LIST_FAIL,
      payload: message,
    });
  }
};

export const updateCategoryCompany = (categoryId, name, description) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CATEGORIES_COMPANIES_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await api.patch(
        `/categories-companies/${categoryId}`,
        { name, description },
        config
      );

      dispatch({
        type: CATEGORIES_COMPANIES_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Unauthorized") {
        dispatch(logout());
      }
      dispatch({
        type: CATEGORIES_COMPANIES_UPDATE_FAIL,
        payload: message,
      });
    }
  };

export const deleteCategoryCompany = (companieId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CATEGORIES_COMPANIES_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.delete(`/categories-companies/${companieId}`, config);

    dispatch({
      type: CATEGORIES_COMPANIES_DELETE_SUCCESS,
      payload: data,
    });
    dispatch(listCategoriesCompanies());
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: CATEGORIES_COMPANIES_DELETE_FAIL,
      payload: message,
    });
  }
};

export const getCategoryCompanyDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CATEGORIES_COMPANIES_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await api.get(`/categories-companies/${id}`, config);

    dispatch({
      type: CATEGORIES_COMPANIES_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CATEGORIES_COMPANIES_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
