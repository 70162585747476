import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

//import components

import Loader from "../../shared/loaders/spinner/sppinner.component";
import Alert from "../../shared/alerts/material-snack-bar/material-snack-bar.component";
import AddPlanComponent from "../../../components/riskmap/add-plan/add-plan.component";
//redux import
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../../redux/actions/user.actions";
import { riskMapResponse } from "../../../redux/actions/risk-maps.actions";

//import styles from "./item-edit.module.scss";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EditItem = ({ conductData, resetMessage, onCancel, riskMapId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const riskMapResponseState = useSelector((state) => state.riskMapResponse);
  const { loading, error, responseResult } = riskMapResponseState;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    /* reValidateMode: "onChange", */
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  const onSubmit = (data) => {
    //console.log(data);
    //console.log(riskMapId);
    dispatch(
      riskMapResponse(
        parseInt(data.probability1),
        parseInt(data.probability2),
        parseInt(data.impact1),
        parseInt(data.impact2),
        data.analysis,
        data.recommendation,
        riskMapId
      )
    );

    //setMessage(true);
  };

  useEffect(() => {
    if (!userInfo) {
      dispatch(logout());
      navigate("/login");
    } else {
      resetMessage();

      reset({
        probability1: conductData.probability1,
        probability2: conductData.probability2,
        impact1: conductData.impact1,
        impact2: conductData.impact2,
        analysis: conductData.analysis,
        recommendation: conductData.recommendation,
      });
    }
    if (responseResult) {
      onCancel();
    }
  }, [
    navigate,
    userInfo,
    dispatch,
    responseResult,
    onCancel,
    resetMessage,
    conductData,
    reset,
  ]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="container-fluid">
      <div className="row clearfix">
        <div className="col-md-12">
          <div className="card">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="header">
                  <h2>
                    Mapa de <strong>Riesgos</strong>
                  </h2>
                </div>{" "}
                <p>
                  <strong>Artículo:</strong> {conductData.conduct.article}
                </p>
                <p>
                  <strong>Tipología:</strong>{" "}
                  {conductData.conduct.typology
                    ? conductData.conduct.typology.name
                    : "----"}
                </p>
                <p>
                  <strong>Conducta:</strong> {conductData.conduct.name}
                </p>
                <p>
                  <strong>Riesgo:</strong>{" "}
                  <strong>
                    {parseFloat(conductData.round) === 0
                      ? "FUERA DE RIESGO"
                      : parseFloat(conductData.round) < 0.99
                      ? "MINIMO"
                      : parseFloat(conductData.round) < 1.99
                      ? "BAJO"
                      : parseFloat(conductData.round) < 2.99
                      ? "MEDIO"
                      : parseFloat(conductData.round) < 3.99
                      ? "ALTO"
                      : "FUERA DE RANGO!"}
                  </strong>
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="header">
                  <h2>
                    Controles y <strong>Plan de Acción</strong>
                  </h2>
                </div>
                {conductData.plan &&
                conductData.plan.existingControl &&
                conductData.plan.existingControl &&
                conductData.plan.frequency ? (
                  <p>
                    <strong>Control Existente:</strong>{" "}
                    {conductData.plan.existingControl}
                    {". - "}
                    <strong style={{ fontSize: "12px" }}>Responsable: </strong>
                    {conductData.plan.headControl}
                    {" .- "}{" "}
                    <strong style={{ fontSize: "12px" }}>Frecuencia: </strong>{" "}
                    {conductData.plan.frequency} meses.
                  </p>
                ) : (
                  <p>
                    <strong>Control Existente:</strong>Todavía no existe un
                    control.
                  </p>
                )}

                {conductData.plan &&
                conductData.plan.vulnerability &&
                conductData.plan.residualRisk &&
                conductData.plan.frequency ? (
                  <p>
                    <strong>Valoración de Controles:</strong> {". - "}
                    <strong style={{ fontSize: "12px" }}>
                      Vulnerabilidad: :{" "}
                    </strong>
                    {conductData.plan.vulnerability}
                    {" .- "}{" "}
                    <strong style={{ fontSize: "12px" }}>
                      Riesgo Resiudal:{" "}
                    </strong>{" "}
                    {conductData.plan.residualRisk}.
                  </p>
                ) : (
                  <p>
                    <strong>Valoración de Controles:</strong>Todavía no existe
                    una valoración.
                  </p>
                )}

                {conductData.plan &&
                conductData.plan.planResponsible &&
                conductData.plan.implementationDate ? (
                  <p>
                    <strong>Plan de Acción:</strong>{" "}
                    <strong style={{ fontSize: "12px" }}>Responsable: </strong>
                    {conductData.plan.planResponsible}
                    {" .- "}{" "}
                    <strong style={{ fontSize: "12px" }}>
                      Fecha implementación:{" "}
                    </strong>{" "}
                    {conductData.plan.implementationDate} .
                  </p>
                ) : (
                  <p>
                    <strong>Plan de Acción:</strong>Todavía no existe un plan de
                    Acción.
                  </p>
                )}
              </div>
            </div>

            <div className="body">
              {loading && (
                <div className="mt-2 mb-2">
                  <Loader />
                </div>
              )}
              {error && (
                <div className="mt-2 mb-2">
                  <Alert message={error} />
                </div>
              )}
              <div className="row clearfix">
                <div className="col-lg-12 col-md-12">
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                      >
                        <Tab label="Riesgos" {...a11yProps(0)} />
                        <Tab
                          label="Controles y Plan de Acción"
                          {...a11yProps(1)}
                        />
                      </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                      <div className="row">
                        <div className="col-12">
                          <small><b>Recuerde:</b> los valores de riesgo deben ser ingresados en una escala de 1 a 4</small>
                        </div>
                        <div className="col-lg-3 col-md-6 col-xs-12 mt-3">
                          <label className="form_label">
                            Riesgo por Actividad 70%:
                          </label>
                          <input
                            type="number"
                            style={{
                              border: errors.name ? "1px solid red" : "",
                            }}
                            className="form-control"
                            placeholder="Ingrese aquí su respuesta."
                            {...register("probability1")}
                          />
                        </div>

                        <div className="col-lg-3 col-md-6 col-xs-12 mt-3">
                          <label className="form_label">
                            Riesgo por Antecedente 30%:
                          </label>
                          <input
                            type="number"
                            style={{
                              border: errors.name ? "1px solid red" : "",
                            }}
                            className="form-control"
                            placeholder="Ingrese aquí su respuesta."
                            {...register("probability2")}
                          />
                        </div>
                        <div className="col-lg-3 col-md-6 col-xs-12 mt-3">
                          <label className="form_label">
                            Riesgo penal y económico 40%:
                          </label>
                          <input
                            type="number"
                            style={{
                              border: errors.name ? "1px solid red" : "",
                            }}
                            className="form-control"
                            placeholder="Ingrese aquí su respuesta."
                            {...register("impact1")}
                          />
                        </div>
                        <div className="col-lg-3 col-md-6 col-xs-12 mt-3">
                          <label className="form_label">
                            Riesgo reputacional 60%:
                          </label>
                          <input
                            type="number"
                            style={{
                              border: errors.name ? "1px solid red" : "",
                            }}
                            className="form-control"
                            placeholder="Ingrese aquí su respuesta."
                            {...register("impact2")}
                          />
                        </div>

                        <div className="col-lg-4 col-md-6 col-xs-12 mt-3">
                          <label className="form_label">Análisis:</label>
                          <textarea
                            rows={4}
                            style={{
                              border: errors.name ? "1px solid red" : "",
                            }}
                            className="form-control"
                            placeholder="Ingrese aquí su respuesta."
                            {...register("analysis")}
                          />
                        </div>
                        <div className="col-lg-4 col-md-6 col-xs-12 mt-3">
                          <label className="form_label">Recomendación:</label>
                          <textarea
                            rows={4}
                            style={{
                              border: errors.name ? "1px solid red" : "",
                            }}
                            className="form-control"
                            placeholder="Ingrese aquí su respuesta."
                            {...register("recommendation")}
                          />
                        </div>
                        <div className="col-lg-4 col-md-6 mt-5">
                          <button
                            onClick={handleSubmit(onSubmit)}
                            className="btn btn-success btn-sm"
                            title="Guardar"
                          >
                            <i className="zmdi zmdi-check mr-2 pt-1 pb-1"></i>{" "}
                            <strong>GUARDAR CAMBIOS</strong>
                          </button>
                          <br />
                          <button
                            onClick={onCancel}
                            className="btn btn-error btn-sm"
                            style={{ minWidth: "162px" }}
                            title="Cancelar"
                          >
                            <i className="zmdi zmdi-check mr-2 pt-1 pb-1"></i>{" "}
                            <strong>CANCELAR</strong>
                          </button>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <AddPlanComponent
                        conductData={conductData}
                        resetMessage={resetMessage}
                        onCancel={onCancel}
                        riskMapId={riskMapId}
                      />
                    </TabPanel>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditItem;
