import {
  CONDUCTS_TYPE_DELETE_FAIL,
  CONDUCTS_TYPE_DELETE_REQUEST,
  CONDUCTS_TYPE_DELETE_RESET,
  CONDUCTS_TYPE_DELETE_SUCCESS,
  CONDUCTS_TYPE_LIST_FAIL,
  CONDUCTS_TYPE_LIST_REQUEST,
  CONDUCTS_TYPE_LIST_RESET,
  CONDUCTS_TYPE_LIST_SUCCESS,
  CONDUCTS_TYPE_ADD_FAIL,
  CONDUCTS_TYPE_ADD_REQUEST,
  CONDUCTS_TYPE_ADD_RESET,
  CONDUCTS_TYPE_ADD_SUCCESS,
  CONDUCTS_ADD_REQUEST,
  CONDUCTS_ADD_SUCCESS,
  CONDUCTS_ADD_FAIL,
  CONDUCTS_ADD_RESET,
  CONDUCTS_LIST_REQUEST,
  CONDUCTS_LIST_SUCCESS,
  CONDUCTS_LIST_FAIL,
  CONDUCTS_LIST_RESET,
  CONDUCTS_DETAILS_REQUEST,
  CONDUCTS_DETAILS_SUCCESS,
  CONDUCTS_DETAILS_FAIL,
  CONDUCTS_DETAILS_RESET,
  CONDUCTS_FILTRADA_GET,
  CONDUCTS_FILTRADA_RESET,
  CONDUCTS_UPDATE_REQUEST,
  CONDUCTS_UPDATE_SUCCESS,
  CONDUCTS_UPDATE_FAIL,
  CONDUCTS_UPDATE_RESET,
  CONDUCTS_DELETE_RESET,
  CONDUCTS_DELETE_FAIL,
  CONDUCTS_DELETE_SUCCESS,
  CONDUCTS_DELETE_REQUEST,
} from "../constants/conducts.const";

export const conductAddReducer = (state = {}, action) => {
  switch (action.type) {
    case CONDUCTS_ADD_REQUEST:
      return { loading: true };
    case CONDUCTS_ADD_SUCCESS:
      return { loading: false, addResult: action.payload };
    case CONDUCTS_ADD_FAIL:
      return { loading: false, error: action.payload };
    case CONDUCTS_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const conductListReducer = (state = { conducts: [] }, action) => {
  switch (action.type) {
    case CONDUCTS_LIST_REQUEST:
      return { loading: true };
    case CONDUCTS_LIST_SUCCESS:
      return { loading: false, conducts: action.payload };
    case CONDUCTS_LIST_FAIL:
      return { loading: false, error: action.payload };
    case CONDUCTS_LIST_RESET:
      return { conducts: [] };
    default:
      return state;
  }
};

export const conductDetailsReducer = (state = { conduct: {} }, action) => {
  switch (action.type) {
    case CONDUCTS_DETAILS_REQUEST:
      return { ...state, loading: true };
    case CONDUCTS_DETAILS_SUCCESS:
      return { loading: false, conduct: action.payload };
    case CONDUCTS_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case CONDUCTS_DETAILS_RESET:
      return { conduct: {} };
    default:
      return state;
  }
};

export const conductsListFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case CONDUCTS_FILTRADA_GET:
      return {
        conducts: action.payload,
      };
    case CONDUCTS_FILTRADA_RESET:
      return {};

    default:
      return state;
  }
};

export const conductUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case CONDUCTS_UPDATE_REQUEST:
      return { loading: true };
    case CONDUCTS_UPDATE_SUCCESS:
      return { loading: false, updateResult: action.payload };
    case CONDUCTS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case CONDUCTS_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const conductDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CONDUCTS_DELETE_REQUEST:
      return { loading: true };
    case CONDUCTS_DELETE_SUCCESS:
      return { loading: false, deleteConductResult: action.payload };
    case CONDUCTS_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case CONDUCTS_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const conductTypeAddReducer = (state = {}, action) => {
  switch (action.type) {
    case CONDUCTS_TYPE_ADD_REQUEST:
      return { loading: true };
    case CONDUCTS_TYPE_ADD_SUCCESS:
      return { loading: false, addResult: action.payload };
    case CONDUCTS_TYPE_ADD_FAIL:
      return { loading: false, error: action.payload };
    case CONDUCTS_TYPE_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const conductTypeListReducer = (
  state = { conducts_type: [] },
  action
) => {
  switch (action.type) {
    case CONDUCTS_TYPE_LIST_REQUEST:
      return { loading: true };
    case CONDUCTS_TYPE_LIST_SUCCESS:
      return { loading: false, conducts_type: action.payload };
    case CONDUCTS_TYPE_LIST_FAIL:
      return { loading: false, error: action.payload };
    case CONDUCTS_TYPE_LIST_RESET:
      return { conducts_type: [] };
    default:
      return state;
  }
};

export const conductTypeDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CONDUCTS_TYPE_DELETE_REQUEST:
      return { loading: true };
    case CONDUCTS_TYPE_DELETE_SUCCESS:
      return { loading: false, deleteConductTypeResult: action.payload };
    case CONDUCTS_TYPE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case CONDUCTS_TYPE_DELETE_RESET:
      return {};
    default:
      return state;
  }
};
