import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Modal from "@mui/material/Modal";
import styles from "./card-risk-map.module.scss";
import RISKMAPS_SCALES_OPTIONS from "../../../../constants/riskMapsScales";

//import components
import Loader from "../../../../components/shared/loaders/spinner/sppinner.component";

//redux import
import { useDispatch, useSelector } from "react-redux";
import { getConductsByScaleType } from "../../../../redux/actions/risk-maps.actions";

const style = {
	overflowY: "auto",
	position: "absolute",
	//borderRadius: "30px",
	top: "50%",
	left: "50%",
	maxHeight: "95vh",
	minWidth: "80vw",
	background: "white",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	padding: "25px 50px",
	boxShadow: 100,
	p: 4,
};

/* 
bg: "bg-red", bg-green, bg-blue, bg-amber
iconName: copy, info, trending-up
*/
const CardRiskMap = ({ name, number, porcentage, total }) => {
	//console.log(porcentage);

	const [scale, setScale] = useState(null);
	const params = useParams();
	const dispatch = useDispatch();

	const riskMapConductsByScaleType = useSelector(
		(state) => state.riskMapConductsByScaleType
	);

	const showInfo = () => {
		if (handleClick()) {
			const scale = RISKMAPS_SCALES_OPTIONS.find(
				(scale) => scale.name === name
			);
			setScale(scale);
			dispatch(getConductsByScaleType(params.id, scale.id));
		}
	};

	const { loading, conducts } = riskMapConductsByScaleType;

	const cancelEdit = () => {
		//e.preventDefault();
		setScale(null);
		return false;
	};

	const handleClick = () =>
		RISKMAPS_SCALES_OPTIONS.some((scale) => scale.name === name);

	return (
		<>
			<div
				onClick={showInfo}
				className={`card info-box-2 ${handleClick() && styles.pointer}`}
			>
				<div
					className={`body ${
						name === "FUERA DE RIESGO"
							? styles.notRisk
							: name === "MINIMO"
							? styles.minim
							: name === "BAJO"
							? styles.low
							: name === "MEDIO"
							? styles.medium
							: name === "ALTO"
							? styles.high
							: name === "FUERA DE RANGO"
							? styles.outRange
							: name === "TOTAL"
							? styles.total
							: styles.notAnswered
					}`}
				>
					<div className="content col-12">
						<div className={styles.text}>{name}</div>
						{porcentage !== undefined ? (
							<div className="icon col-12">
								<strong className={styles.porcentage}>
									{porcentage + " %"}
								</strong>
							</div>
						) : (
							<div className="icon col-12">
								<strong className={styles.porcentage}>
									100 %
								</strong>
							</div>
						)}

						<div className={` ${styles.cardnumber}`}>
							{number} / {total}
						</div>
					</div>
				</div>
			</div>
			{scale ? (
				<>
					<Modal
						open={scale ? true : false}
						onClose={(e) => {
							cancelEdit(e);
						}}
					>
						<div style={style}>
							{loading ? (
								<div className="text-center mt-4">
									<Loader />
								</div>
							) : conducts && conducts?.length ? (
								<>
									<div class={`header`}>
										<h5 class={`${styles.textCapitalize}`}>
											Riesgo {scale.name}
										</h5>
									</div>
									<div className="row clearfix">
										<div className="col-md-12 col-sm-12 col-xs-12">
											<div className="project_list">
												<div className="table-responsive">
													<table className="table table-hover c_table">
														<thead>
															<tr>
                              <th style={{ width: "10%" }}>Tipología</th>
                              <th style={{ width: "25px" }}>Artículo</th>
                              <th style={{ maxWidth: "25px" }}>Conducta</th>
                              <th className="">Sumatoria</th>
                              <th>Promedio</th>
                              <th className="" width="150px">
                                Redondeo
                              </th>
                              <th className="" width="150px">
                                Análisis y Recomendación
                              </th>
															</tr>
														</thead>
														<tbody>
															{conducts.map(
																(
																	item,
																	index
																) => (
																	<tr key={index}>
																		<div>
                                      <strong style={{ fontSize: "11px", verticalAlign: 'center' }}>
                                        {item.conduct.typology &&
                                          item.conduct.typology.name}
                                      </strong>
                                    </div>
																		<td
                                      style={{ fontSize: "12px" }}>
                                      {item.conduct.article}
																		</td>
																		<div style={{
                                        minWidth: "250px",
                                        fontSize: "10px",
                                      }}>
                                      {item.conduct.name}
																		</div>
																		<td>{item.sum}</td>
                                    <td>{item.average}</td>
                                    <td>{item.round}</td>
																		<div>
                                      <p style={{ fontSize: "12px" }}>
                                        <strong>Análisis: </strong>{" "}
                                        {item.analysis
                                          ? item.analysis
                                          : "-----"}
                                      </p>
                                    </div>
                                    <div>
                                      <p style={{ fontSize: "12px" }}>
                                        <strong>Recomendación: </strong>
                                        {item.recommendation
                                          ? item.recommendation
                                          : "-----"}
                                      </p>
                                    </div>
																	</tr>
																)
															)}
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</>
							) : (
								<div class={`header`} style={{textAlign: 'center'}}>
									<h6>No hay registros de en esta escala</h6>
								</div>
							)}
						</div>
					</Modal>
				</>
			) : null}
		</>
	);
};

export default CardRiskMap;
