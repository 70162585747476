import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//import components
import Header from "../../layout/header/header.component";
import SkeletonLoader from "../../components/shared/loaders/skeleton-loader/skeleton-loader.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";

//import redux ;
import { useDispatch, useSelector } from "react-redux";
import { listAlerts } from "../../redux/actions/alerts.actions";
import { changeActiveSidebarItem } from "../../redux/actions/navigation.actions";

const AlertsListPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;
	const alertsList = useSelector((state) => state.alertsList);
	const { loading, error, alerts } = alertsList;

	useEffect(() => {
		dispatch(changeActiveSidebarItem(location.pathname));
		if (!userInfo) {
			navigate("/login");
		} else {
			dispatch(listAlerts());
		}
	}, [navigate, userInfo, dispatch, location]);

	return (
		<>
			<Header title="Lista de Alertas" path="Lista de Alertas" />
			{error ? (
				<div className="mb-2 mt-2">
					<Alert message={error} />
				</div>
			) : loading ? (
				<div className="container-fluid">
					<div className="row clearfix">
						<div className="col-lg-12">
							<div className="card">
								<SkeletonLoader />
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className="container-fluid">
					<div className="row clearfix">
						<div className="col-md-12 col-sm-12 col-xs-12">
							<h5>Cuestionarios</h5>
							<div className="card project_list">
								<div className="table-responsive">
									<table className="table table-hover c_table">
										<thead>
											<tr>
												<th>#</th>
												<th>Nombre</th>
												<th>Fecha límite</th>
												<th>Creado por</th>
												<th>Supervisor</th>
												<th>Estado</th>
											</tr>
										</thead>
										<tbody>
											{alerts &&
											alerts.quizzes &&
											alerts.quizzes.length === 0 ? (
												<p className="text-center">
													No existen alertas
													registradas.
												</p>
											) : (
												alerts &&
												alerts.quizzes &&
												alerts.quizzes.map(
													(alert, index) => (
														<tr key={index}>
															<td>
																<strong>
																	{alert.id}
																</strong>
															</td>
															<td>
																{/* <Link to={`/alertas/${alert.name}`}/> */}
																{alert.name}
															</td>
															<td>
																{new Date(
																	alert.deadline
																).toLocaleDateString()}
															</td>
															<td>{`${alert.createdBy.name} ${alert.createdBy.lastname}`}</td>
															<td>{`${alert.supervisor.name} ${alert.supervisor.lastname}`}</td>
															<td>
																<span
																	className={`badge ${
																		alert
																			.priority
																			.name ===
																		"ALTA"
																			? "badge-warning"
																			: alert
																					.priority
																					.name ===
																			  "MEDIA"
																			? "badge-info"
																			: "badge-danger"
																	}`}
																>
																	{
																		alert
																			.priority
																			.name
																	}
																</span>
															</td>
														</tr>
													)
												)
											)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div className="col-md-12 col-sm-12 col-xs-12">
							<h5>Checklists</h5>
							<div className="card project_list">
								<div className="table-responsive">
									<table className="table table-hover c_table">
										<thead>
											<tr>
												<th>#</th>
												<th>Nombre</th>
												<th>Fecha límite</th>
												<th>Creado por</th>
												<th>Supervisor</th>
											</tr>
										</thead>
										<tbody>
											{alerts &&
											alerts.checklists &&
											alerts.checklists.length === 0 ? (
												<p className="text-center">
													No existen alertas
													registradas.
												</p>
											) : (
												alerts &&
												alerts.checklists &&
												alerts.checklists.map(
													(checklist, index) => (
														<tr key={index}>
															<td>
																<strong>
																	{
																		checklist.id
																	}
																</strong>
															</td>
															<td>
																{/* <Link to={`/alertas/${alert.name}`}/> */}
																{checklist.name}
															</td>
															<td>
																{new Date(
																	checklist.deadline
																).toLocaleDateString()}
															</td>
															<td>{`${checklist.createdBy.name} ${checklist.createdBy.lastname}`}</td>
															<td>{`${checklist.supervisor.name} ${checklist.supervisor.lastname}`}</td>
														</tr>
													)
												)
											)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default AlertsListPage;
