import api from "../../api/rigori.api";

import {
	COMPLAINTS_DETAILS_FAIL,
	COMPLAINTS_DETAILS_REQUEST,
	COMPLAINTS_DETAILS_SUCCESS,
	COMPLAINTS_LIST_FAIL,
	COMPLAINTS_LIST_REQUEST,
	COMPLAINTS_LIST_SUCCESS,
	COMPLAINTS_ADD_FAIL,
	COMPLAINTS_ADD_REQUEST,
	COMPLAINTS_ADD_SUCCESS,
  COMPLAINTS_RATE_REQUEST,
  COMPLAINTS_RATE_SUCCESS,
  COMPLAINTS_RATE_FAIL,
  COMPLAINTS_GET_EMAILS_REQUEST,
  COMPLAINTS_GET_EMAILS_SUCCESS,
  COMPLAINTS_GET_EMAILS_FAIL
} from "../constants/complaints.const";

import { logout } from "./user.actions";

export const addComplaints =
	(
		files,
		anonymous,
		name,
		lastname,
		email,
		phone,
		companyName,
		internalComplainant,
		idAreaComplainant,
		conduct,
		description,
		wantedKnowProgress,
		companyId,
    denounceds
	) =>
	async (dispatch) => {
		try {
			dispatch({ type: COMPLAINTS_ADD_REQUEST });
			let formData = new FormData();
			for (let i = 0; i < files.length; i++) formData.append("file", files[i]);
			const config = { headers: { "Content-Type": "multipart/form-data" } };
			formData.append(
				"request",
				JSON.stringify({
					anonymous,
					name,
					lastname,
					email,
					phone,
					companyName,
					internalComplainant,
					idAreaComplainant,
					conduct,
					description,
					wantedKnowProgress,
          denounceds,
				})
			);
			const { data } = await api.post(
				"/complaints",
				formData,
        { params: { companyId } },
				config
			);
			dispatch({
				type: COMPLAINTS_ADD_SUCCESS,
				payload: data,
			});
		} catch (error) {
			const message = error.response && error.response.data.message ? error.response.data.message : error.message;
			dispatch({
				type: COMPLAINTS_ADD_FAIL,
				payload: message,
			});
		}
	};

export const listComplaints = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: COMPLAINTS_LIST_REQUEST,
		});

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await api.get(`/complaints`, config);

		dispatch({
			type: COMPLAINTS_LIST_SUCCESS,
			payload: data,
		});
	} catch (error) {
		const message = error.response && error.response.data.message ? error.response.data.message : error.message;
		if (message === "Unauthorized") {
			dispatch(logout());
		}
		dispatch({
			type: COMPLAINTS_LIST_FAIL,
			payload: message,
		});
	}
};

export const getComplaintDetails = (id) => async (dispatch, getState) => {
	try {
		dispatch({
			type: COMPLAINTS_DETAILS_REQUEST,
		});

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${userInfo.token}`,
			},
		};
		const { data } = await api.get(`/complaints/${id}`, config);

		dispatch({
			type: COMPLAINTS_DETAILS_SUCCESS,
			payload: data,
		});
	} catch (error) {
		const message = error.response && error.response.data.message ? error.response.data.message : error.message;
		if (message === "Unauthorized") {
			dispatch(logout());
		}
		dispatch({
			type: COMPLAINTS_DETAILS_FAIL,
			payload: message,
		});
	}
};

export const rateComplaint = (complaintId, complaintRate) => async (dispatch, getState) => {
	try {
		dispatch({
			type: COMPLAINTS_RATE_REQUEST,
		});

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${userInfo.token}`,
			},
		};
		const { data } = await api.patch(`/complaints/${complaintId}`, { complaintRate }, config);
		dispatch({
			type: COMPLAINTS_RATE_SUCCESS,
			payload: data,
		});
    dispatch(getComplaintDetails(complaintId));
	} catch (error) {
		const message = error.response && error.response.data.message ? error.response.data.message : error.message;
		if (message === "Unauthorized") {
			dispatch(logout());
		}
		dispatch({
			type: COMPLAINTS_RATE_FAIL,
			payload: message,
		});
	}
};

export const getListEmails = () => async (dispatch) => {
	try {
		dispatch({
			type: COMPLAINTS_GET_EMAILS_REQUEST,
		});
		const { data } = await api.post(`/complaints/contact-emails`);
		dispatch({
			type: COMPLAINTS_GET_EMAILS_SUCCESS,
			payload: data,
		});
	} catch (error) {
		const message = error.response && error.response.data.message ? error.response.data.message : error.message;
		dispatch({
			type: COMPLAINTS_GET_EMAILS_FAIL,
			payload: message,
		});
	}
};