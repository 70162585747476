import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

//PAGES IMPORT
import LoginPage from "./screens/LoginScreen";
import DashboardRouter from "./router/Router";
import CreateComplaintPage from "./screens/complaints/CreateComplaintPage";
import ComplaintUserDetailsPage from "./screens/complaints/ComplaintUserDetailsPage";
import "./styles/app.scss";

function App() {
  return (
    <div className="">
      <Router>
        <Routes>
          <Route />
          <Route path="/login" element={<LoginPage />} />
          <Route
                path="/gestion"
                element={<Navigate replace to={"/login"}/>}/>
          
          <Route path="/denunciar/:id" element={<CreateComplaintPage />} />
          <Route path="/revisar-denuncia/:id" element={<ComplaintUserDetailsPage />} />
          <Route path="*" element={<DashboardRouter />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
