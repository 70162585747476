import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
//import components
import Header from "../../layout/header/header.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";

//constant
import BREADCRUM_DATA from "../../constants/breadcrumb.const";
import { useEffect } from "react";
import { deleteCompanyArea, getCompanyDetails } from "../../redux/actions/companies.actions";
import { COMPANIES_DELETE_AREA_RESET } from "../../redux/constants/companies.const";

const CompaniesAdminAreaListPage = () => {

    const dispatch = useDispatch();
    const params = useParams();

    const companyDetail = useSelector((state) => state.companyDetail);
    const {
        loading: loadingDetails,
        error: errorDetails,
        company,
    } = companyDetail;

    const deleteAreaCompany = useSelector((state) => state.companyAreaDelete);
    const {
        deleteAreaCompanyResult,
    } = deleteAreaCompany;

    useEffect(() => {
        dispatch(getCompanyDetails(params.id));
        if (deleteAreaCompanyResult) {
            dispatch({ type: COMPANIES_DELETE_AREA_RESET });
            dispatch(getCompanyDetails(params.id));
        }
    }, [dispatch, deleteAreaCompanyResult, params.id])

    const handleDelete = (e, id) => {
        e.preventDefault();
        if (window.confirm("Esta acción no se puede deshacer.")) {
            dispatch(deleteCompanyArea(id, Number(params.id)))
        }
    };

    return (
        <>
            <Header title="Administrar Áreas de la Empresa" path="Administrar Áreas de la Empresa" pathList={BREADCRUM_DATA.companyAdminAreas}>
                {/* <button className="btn btn-success btn-icon float-right right_icon_toggle_btn" onClick={handleSubmit(onSubmit)}>
					<i className="zmdi zmdi-check"></i>
				</button> */}
            </Header>
           
            {errorDetails ? (
                <div className="mb-3">
                    <Alert message={errorDetails} />
                </div>
            ) : loadingDetails ? (
                <div className="text-center mb-2">
                    <Loader />
                </div>
            ) : (
                <>
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="table-responsive">
                                        <table className="table table-hover c_table theme-color">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Nombre</th>
                                                    <th>Acción</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                     company.areasInUse && company.areasInUse.length === 0 ? (
                                                        <h4 className="text-center">
                                                            No existen áreas registradas.
                                                        </h4>
                                                    ) : (
                                                        company.areasInUse && company.areasInUse.map(
                                                            (area, key) => (
                                                                <tr key={key}>
                                                                    <td>{key + 1}</td>
                                                                    <td>
                                                                        {area.name}
                                                                    </td>
                                                                    <button
                                                                        className="btn btn-danger btn-sm"
                                                                        title="Eliminar Área"
                                                                        onClick={(e) => {
                                                                            handleDelete(e, area.id);
                                                                        }}
                                                                    >
                                                                        <i className="zmdi zmdi-delete"></i>
                                                                    </button>
                                                                </tr>
                                                            )
                                                        )
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-12">
										<Link className="btn btn-success"  to={`/empresas`} title="Regresar">
                                        <i className="zmdi zmdi-arrow-left"></i> Regresar
										</Link>
									</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
            }
        </>
    )
}

export default CompaniesAdminAreaListPage;