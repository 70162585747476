import React from "react";
import styles from "./tags.module.scss";
const TagsComponent = ({ data, onDelete }) => {
  return (
    <ul className={styles.tags}>
      {data.map((item, index) => (
        <li
          key={index}
          onClick={(e) => {
            onDelete(e, item.id);
          }}
        >
          <div className={styles.tag}>
            {item.name} <i className={`zmdi zmdi-close ${styles.icon}`}></i>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default TagsComponent;
