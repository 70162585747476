import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PDFViewer, Document, Page, View, Text, StyleSheet } from "@react-pdf/renderer";

//import components
import Header from "../../layout/header/header.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";

//redux import
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/user.actions";
import { getComplaintDetails } from "../../redux/actions/complaints.actions";
import { listActionsComplaint } from "../../redux/actions/complaints-actions.actions";

const BORDER_COLOR = "#bfbfbf";
const BORDER_STYLE = "none";

const styles = StyleSheet.create({
	body: {
		paddingVertical: 20,
		paddingHorizontal: 40,
	},
	table: {
		display: "table",
		width: "auto",
		borderStyle: BORDER_STYLE,
		borderColor: BORDER_COLOR,
		borderWidth: 0,
		borderRightWidth: 0,
		borderBottomWidth: 0,
	},
	tableRow: {
		margin: "auto",
		flexDirection: "row",
	},
	tableHeader30: {
		width: "30%",
		borderWidth: 0,
		borderLeftWidth: 0,
		borderTopWidth: 0,
	},
	tableHeader50: {
		width: "50%",
		borderWidth: 0,
		borderLeftWidth: 0,
		borderTopWidth: 0,
	},
	tableHeader70: {
		width: "70%",
		borderWidth: 0,
		borderLeftWidth: 0,
		borderTopWidth: 0,
	},
	tableHeader100: {
		width: "100%",
		borderWidth: 0,
		borderLeftWidth: 0,
		borderTopWidth: 0,
	},
	tableCellHeader: {
		margin: 5,
		fontSize: 10,
	},
	tableCell: {
		margin: 5,
		fontSize: 10,
	},
});

const ComplaintReportPage = () => {
	const navigate = useNavigate();
	const params = useParams();
	const dispatch = useDispatch();
	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;
	const riskMapReport = [
		{
			title: "Lista de denuncias",
			link: "/denuncias",
		},
		{
			title: "Detalle",
			link: `/denuncia/${params.id}`,
		},
		{
			title: "Reporte de denuncia",
		},
	];

	const complaintDetail = useSelector((state) => state.complaintDetail);
	const { loading: loadingDetails, error: errorDetails, complaint } = complaintDetail;
	const actionsList = useSelector((state) => state.actionsComplaintList);
	const { loading: loadingActions, actions } = actionsList;

	console.log(actions);

	useEffect(() => {
		if (!userInfo) {
			navigate("/login");
		} else if (userInfo.role === "USUARIO") {
			dispatch(logout());
		} else {
			if (params.id) {
				dispatch(getComplaintDetails(params.id));
				dispatch(listActionsComplaint(params.id));
			}
		}
	}, [navigate, userInfo, dispatch, params]);

	return (
		<>
			<Header title="Reporte de denuncia" path="Reporte de denuncia" pathList={riskMapReport}></Header>
			<div className="container-fluid" style={{ minHeight: "83vh" }}>
				<div className="row clearfix">
					<div className="col-12">
						{loadingDetails || loadingActions ? (
							<div className="text-center mt-4">
								<Loader />
							</div>
						) : errorDetails ? (
							<Alert message={errorDetails} />
						) : (
							complaint && (
								<PDFViewer style={{ width: "100%", height: "82vh" }}>
									<Document>
										<Page size={`A4`} style={styles.body}>
											{/* Detalles generales */}
											<View style={styles.table}>
												{/* Date */}
												<View style={{ ...styles.tableRow, borderWidth: 1 }}>
													<View style={styles.tableHeader50}>
														<Text style={styles.tableCellHeader}>Reporte de denuncia</Text>
													</View>
													<View style={styles.tableHeader50}>
														<Text style={styles.tableCellHeader}>
															Creación de denuncia: {new Date(complaint.createdAt).toLocaleString()}
														</Text>
													</View>
												</View>
												{/* Company Name */}
												<View style={{ ...styles.tableRow, paddingTop: 20 }}>
													<View style={styles.tableHeader50}>
														<Text style={styles.tableCellHeader}>Nombre de la empresa</Text>
													</View>
													<View style={styles.tableHeader50}>
														<Text style={styles.tableCellHeader}>{complaint.company && complaint.company.name}</Text>
													</View>
												</View>
												{/* Company RUC */}
												<View style={styles.tableRow}>
													<View style={styles.tableHeader50}>
														<Text style={styles.tableCellHeader}>Identificación de la compañía</Text>
													</View>
													<View style={styles.tableHeader50}>
														<Text style={styles.tableCellHeader}>{complaint.company && complaint.company.ruc}</Text>
													</View>
												</View>
												{/* Número de expediente */}
												<View style={styles.tableRow}>
													<View style={styles.tableHeader50}>
														<Text style={styles.tableCellHeader}>Número de expediente</Text>
													</View>
													<View style={styles.tableHeader50}>
														<Text style={styles.tableCellHeader}>{complaint.code}</Text>
													</View>
												</View>
												<View style={styles.tableRow}>
													<View style={styles.tableHeader50}>
														<Text style={styles.tableCellHeader}>Denunciante</Text>
													</View>
													<View style={styles.tableHeader50}>
														<Text style={styles.tableCellHeader}>{complaint.internalComplainant ? "Interno" : "Externo"}</Text>
													</View>
												</View>
												{/* Tipo de denuncia */}
												<View style={styles.tableRow}>
													<View style={styles.tableHeader50}>
														<Text style={styles.tableCellHeader}>Tipo de denuncia</Text>
													</View>
													<View style={styles.tableHeader50}>
														<Text style={styles.tableCellHeader}>{complaint.anonymous ? "Anónima" : "Confidencial"}</Text>
													</View>
												</View>
											</View>
											{/* Datos del denunciante */}
											{!complaint.anonymous && (
												<View style={{ ...styles.table, paddingTop: 20 }}>
													<View style={{ ...styles.tableRow, borderWidth: 1 }}>
														<View style={styles.tableHeader100}>
															<Text style={{ ...styles.tableCellHeader, textAlign: "center", textTransform: "uppercase" }}>
																IDENTIFICACIÓN DEL DENUNCIANTE
															</Text>
														</View>
													</View>
													<View style={{ ...styles.tableRow, paddingTop: 10 }}>
														<View style={styles.tableHeader50}>
															<Text style={styles.tableCellHeader}>Nombre</Text>
														</View>
														<View style={styles.tableHeader50}>
															<Text style={styles.tableCellHeader}>
																{complaint.name} {complaint.lastname}
															</Text>
														</View>
													</View>
													<View style={{ ...styles.tableRow }}>
														<View style={styles.tableHeader50}>
															<Text style={styles.tableCellHeader}>Email</Text>
														</View>
														<View style={styles.tableHeader50}>
															<Text style={styles.tableCellHeader}>{complaint.email}</Text>
														</View>
													</View>
													<View style={{ ...styles.tableRow }}>
														<View style={styles.tableHeader50}>
															<Text style={styles.tableCellHeader}>Teléfono</Text>
														</View>
														<View style={styles.tableHeader50}>
															<Text style={styles.tableCellHeader}>{complaint.phone}</Text>
														</View>
													</View>
												</View>
											)}
											{/* Datos del denunciado */}
											<View style={{ ...styles.table, paddingTop: 20 }}>
												{complaint.denounceds && !!complaint.denounceds?.length && (
													<View style={{ ...styles.tableRow, borderWidth: 1 }}>
														<View style={styles.tableHeader100}>
															<Text style={{ ...styles.tableCellHeader, textAlign: "center", textTransform: "uppercase" }}>
																IDENTIFICACIÓN DE DENUNCIAD@S
															</Text>
														</View>
													</View>
												)}
												{complaint.denounceds &&
													complaint.denounceds.map((denounced) => (
														<>
															<View style={{ ...styles.tableRow, paddingTop: 10 }}>
																<View style={styles.tableHeader30}>
																	<Text style={styles.tableCellHeader}>
																		{denounced.denouncedName ? denounced.denouncedName : "-"}{" "}
																		{denounced.denouncedLastname ? denounced.denouncedLastname : "-"} ({denounced.denouncedJob})
																	</Text>
																</View>
																{
																	denounced.area && (
																		<View style={styles.tableHeader30}>
																			<Text style={styles.tableCellHeader}>
																				Área: {denounced.area ? denounced.area.name : "-"}{" "}
																			</Text>
																		</View>
																	)
																}
															</View>
														</>
													))}
											</View>
											{/* Detalles de la denucnia */}
											<View style={{ ...styles.table, paddingTop: 20 }}>
												<View style={{ ...styles.tableRow, borderWidth: 1 }}>
													<View style={styles.tableHeader100}>
														<Text style={{ ...styles.tableCellHeader, textAlign: "center", textTransform: "uppercase" }}>
															Detalles de la denuncia
														</Text>
													</View>
												</View>
												<View style={{ ...styles.tableRow, paddingTop: 10 }}>
													<View style={styles.tableHeader30}>
														<Text style={styles.tableCellHeader}>Conducta denunciada</Text>
													</View>
													<View style={styles.tableHeader70}>
														<Text style={styles.tableCellHeader}>{complaint.conduct}</Text>
													</View>
												</View>
												{
													complaint.areaComplainant && (

														<View style={{ ...styles.tableRow }}>
															<View style={styles.tableHeader30}>
																<Text style={styles.tableCellHeader}>Área del denunciante</Text>
															</View>
															<View style={styles.tableHeader70}>
																<Text style={styles.tableCellHeader}>{complaint.areaComplainant.name}</Text>
															</View>
														</View>
													)
												}
												<View style={{ ...styles.tableRow }}>
													<View style={styles.tableHeader30}>
														<Text style={styles.tableCellHeader}>Descripción de los hechos</Text>
													</View>
													<View style={styles.tableHeader70}>
														<Text style={styles.tableCellHeader}>{complaint.description}</Text>
													</View>
												</View>
												<View style={{ ...styles.tableRow }}>
													<View style={styles.tableHeader30}>
														<Text style={styles.tableCellHeader}>Calificación de la denuncia</Text>
													</View>
													<View style={styles.tableHeader70}>
														<Text style={styles.tableCellHeader}>
															{complaint.complaintRate ? complaint.complaintRate : "Esta denuncia aún no ha sido calificada"}
														</Text>
													</View>
												</View>
											</View>
											{/* Acciones de la denuncia */}
											<View style={{ ...styles.table, paddingTop: 20 }}>
												{actions && !!actions?.length && (
													<View style={{ ...styles.tableRow, borderTopWidth: 1, borderRightWidth: 1, borderLeftWidth: 1 }}>
														<View style={styles.tableHeader100}>
															<Text style={{ ...styles.tableCellHeader, textAlign: "center", textTransform: "uppercase" }}>
																Diligencias de la denuncia
															</Text>
														</View>
													</View>
												)}

												{actions.map((action) => (
													<View style={{ ...styles.tableRow, borderWidth: 1 }}>
														<View style={{ ...styles.tableHeader30, borderRightWidth: 1 }}>
															<Text style={styles.tableCellHeader}>{new Date(action.createdAt).toLocaleString()}</Text>
														</View>
														<View style={styles.tableHeader70}>
															<Text style={styles.tableCellHeader}>Estado: {action.actionState.name}</Text>
															<Text style={styles.tableCellHeader}>Título de la diligencia: {action.title}</Text>
															<Text style={styles.tableCellHeader}>{action.description}</Text>
														</View>
													</View>
												))}
											</View>
										</Page>
									</Document>
								</PDFViewer>
							)
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default ComplaintReportPage;
