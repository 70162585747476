import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

//import components
import Header from "../../layout/header/header.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";

//constant
import BREADCRUM_DATA from "../../constants/breadcrumb.const";

//redux
import { useDispatch, useSelector } from "react-redux";
import { addCategoryCompany } from "../../redux/actions/companies-categories.actions";
import { logout } from "../../redux/actions/user.actions";

const CategoriesAddPage = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categoryAdd = useSelector((state) => state.categoryCompanyAdd);
  const { loading, error, addResult } = categoryAdd;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else if (userInfo.role === "USUARIO") {
      dispatch(logout());
    }
    if (addResult) {
      navigate("/categorias");
    }
  }, [navigate, addResult, userInfo, dispatch]);

  const onSubmit = (data) => {
    dispatch(
      addCategoryCompany(
        data.name,
        data.description
      )
    );
  };

  return (
    <>
      <Header
        title="Crear Categoría"
        path="Crear Categoría"
        pathList={BREADCRUM_DATA.categoryCompanyAdd}
      >
        <button
          className="btn btn-success btn-icon float-right right_icon_toggle_btn"
          onClick={handleSubmit(onSubmit)}
        >
          <i className="zmdi zmdi-check"></i>
        </button>
      </Header>
      <div className="container-fluid">
        <div className="row clearfix">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h2>
                  Ingresar información de la nueva <strong>categoría de empresa</strong>
                </h2>
              </div>
              <div className="body">
                {error && (
                  <div className="mb-3">
                    <Alert message={error} />
                  </div>
                )}
                {loading && (
                  <div className="text-center mb-2">
                    <Loader />
                  </div>
                )}
                <div className="row justify-content-center clearfix">
                  <div className="col-md-7">
                    <div className="form-group">
                      <input
                        style={{ border: errors.name ? "1px solid red" : "" }}
                        type="text"
                        className="form-control"
                        placeholder="Nombre"
                        {...register("name", {
                          required: true,
                          minLength: 2,
                        })}
                      />
                      {errors.name?.type === "required" && (
                        <span style={{ color: "red", padding: "4px 2px" }}>
                          El nombre es requerido.
                        </span>
                      )}
                      {errors.name?.type === "minLength" && (
                        <span style={{ color: "red" }}>
                          El nombre debe tener al menos dos caracteres.
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="form-group">
                      <textarea
                        style={{
                          border: errors.description ? "1px solid red" : "",
                        }}
                        type="text-area"
                        rows="6"
                        className="form-control"
                        placeholder="Descripción"
                        {...register("description", {
                          required: true,

                          minLength: 6,
                        })}
                      />
                      {errors.description?.type === "required" && (
                        <span style={{ color: "red" }}>
                          La descripción es requerida.
                        </span>
                      )}
                      {errors.description?.type === "minLength" && (
                        <span style={{ color: "red" }}>
                          La descripción debe tener al menos 6 caracteres.
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-7">
                    <button
                      className="btn btn-success"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Guardar Categoría
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CategoriesAddPage;