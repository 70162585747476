import api from "../../api/rigori.api";
import { IMAGE_ADD_FAIL, IMAGE_ADD_REQUEST, IMAGE_ADD_SUCCESS } from "../constants/image.const";
import { logout } from "./user.actions";

export const addImage = (file) =>

  async (dispatch, getState) => {
    const f = new FormData();
    f.append("file",file)
    console.log("Archivo Modificado: ", f)
    try {

      dispatch({
        type: IMAGE_ADD_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          'content-type': 'multipart/form-data',
        },
      };
      const { data } = await api.post(
        "/files/image",
        f,
        config
      );
      dispatch({
        type: IMAGE_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Unauthorized") {
        dispatch(logout());
      }
      dispatch({
        type: IMAGE_ADD_FAIL,
        payload: message,
      });
    }
  }