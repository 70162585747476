import React from "react";
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";

const FilterList = ({
  searchKey,
  handleSearch,
  tableDropdownOpenSort,
  tableSortMenuOpen,
  handleSorting,
  tableDropdownOpen,
  tableMenuOpen,
  handleCambioEstado,
  filterOptions,
}) => {
  return (
    <div className="card">
      <div className="body">
        <div className="row">
          <div className="col-lg-10 col-md-10 col-sm-12">
            <form>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Buscar..."
                  value={searchKey}
                  onChange={(e) => {
                    handleSearch(e.target.value);
                  }}
                />
                {/*   <button
                className=" buttonSearcher" onClick={handleSearch} 
              >
                <i className="zmdi zmdi-search"></i>
              </button> */}
              </div>
            </form>
          </div>
          <div className="col-md-1 col-sm-6">
            <div>
              <Dropdown
                className="d-none d-sm-block"
                nav
                isOpen={tableDropdownOpenSort}
                toggle={() => tableSortMenuOpen()}
              >
                <DropdownToggle nav>
                  <i className="zmdi zmdi-sort-asc"></i>
                </DropdownToggle>

                <DropdownMenu style={{ marginRight: "20px" }}>
                  <DropdownItem>
                    <div style={{ fontWeight: "700" }}>Ordenar por:</div>
                  </DropdownItem>
                  {filterOptions.sort.map((item, index) => (
                    <DropdownItem
                      key={index}
                      className="dropdownItem"
                      onClick={() => handleSorting(item.action)}
                    >
                      <div>{item.title}</div>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <div className="col-md-1 col-sm-6">
            <div>
              <Dropdown
                className="d-none d-sm-block"
                nav
                isOpen={tableDropdownOpen}
                toggle={() => tableMenuOpen()}
              >
                <DropdownToggle nav>
                  <i className="zmdi zmdi-filter-list"></i>{" "}
                </DropdownToggle>
                <DropdownMenu style={{ marginRight: "5px" }}>
                  <DropdownItem>
                    <div style={{ fontWeight: "700" }}>Filtrar por:</div>
                  </DropdownItem>
                  {filterOptions.filter.map((item, index) => (
                    <DropdownItem
                      className="dropdownItem"
                      key={index}
                      onClick={() => handleCambioEstado(item.action)}
                    >
                      <div>{item.title}</div>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterList;
