import api from "../../api/rigori.api";
import { AREAS_COMPANIES_ADD_FAIL, AREAS_COMPANIES_ADD_REQUEST, AREAS_COMPANIES_ADD_SUCCESS, AREAS_COMPANIES_DELETE_FAIL, AREAS_COMPANIES_DELETE_REQUEST, AREAS_COMPANIES_DELETE_SUCCESS, AREAS_COMPANIES_DETAILS_FAIL, AREAS_COMPANIES_DETAILS_REQUEST, AREAS_COMPANIES_DETAILS_SUCCESS, AREAS_COMPANIES_LIST_FAIL, AREAS_COMPANIES_LIST_REQUEST, AREAS_COMPANIES_LIST_SUCCESS, AREAS_COMPANIES_UPDATE_FAIL, AREAS_COMPANIES_UPDATE_REQUEST, AREAS_COMPANIES_UPDATE_SUCCESS } from "../constants/companies-area.const";
import { logout } from "./user.actions";


export const addAreaCompany = (name) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: AREAS_COMPANIES_ADD_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await api.post(
        "/area",
        { name },
        config
      );

      dispatch({
        type: AREAS_COMPANIES_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Unauthorized") {
        dispatch(logout());
      }
      dispatch({
        type: AREAS_COMPANIES_ADD_FAIL,
        payload: message,
      });
    }
  };

  export const listAreaCompanies = () => async (dispatch, getState) => {
    try {
      dispatch({
        type: AREAS_COMPANIES_LIST_REQUEST,
      });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await api.get(`/area`, config);
  
      dispatch({
        type: AREAS_COMPANIES_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Unauthorized") {
        dispatch(logout());
      }
      dispatch({
        type: AREAS_COMPANIES_LIST_FAIL,
        payload: message,
      });
    }
  };

  export const updateAreaCompany = (categoryId, name) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: AREAS_COMPANIES_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await api.patch(
        `/area/${categoryId}`,
        { name },
        config
      );

      dispatch({
        type: AREAS_COMPANIES_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Unauthorized") {
        dispatch(logout());
      }
      dispatch({
        type: AREAS_COMPANIES_UPDATE_FAIL,
        payload: message,
      });
    }
  };

  export const deleteAreaCompany = (areaId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: AREAS_COMPANIES_DELETE_REQUEST,
      });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await api.delete(`/area/${areaId}`, config);
  
      dispatch({
        type: AREAS_COMPANIES_DELETE_SUCCESS,
        payload: data,
      });
      dispatch(listAreaCompanies());
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Unauthorized") {
        dispatch(logout());
      }
      dispatch({
        type: AREAS_COMPANIES_DELETE_FAIL,
        payload: message,
      });
    }
  };

  export const getAreaCompanyDetails = (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: AREAS_COMPANIES_DETAILS_REQUEST,
      });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await api.get(`/area/${id}`, config);
  
      dispatch({
        type: AREAS_COMPANIES_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: AREAS_COMPANIES_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };