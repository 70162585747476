import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

//import components
import Header from "../../layout/header/header.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";

//constant
import BREADCRUM_DATA from "../../constants/breadcrumb.const";

//redux
import { useDispatch, useSelector } from "react-redux";
import { addStateComplaint } from "../../redux/actions/complaints-states.actions";
import { logout } from "../../redux/actions/user.actions";

const ComplaintStateAddPage = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stateAdd = useSelector((state) => state.stateComplaintAdd);
  const { loading, error, addResult } = stateAdd;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else if (userInfo.role === "USUARIO") {
      dispatch(logout());
    }
    if (addResult) {
      navigate("/estados");
    }
  }, [navigate, addResult, userInfo, dispatch]);

  const onSubmit = (data) => dispatch(addStateComplaint(data.name));

  return (
    <>
      <Header
        title="Crear estado para denuncia"
        path="Crear estado para denuncia"
        pathList={BREADCRUM_DATA.complaintStateAdd}
      >
        <button
          className="btn btn-success btn-icon float-right right_icon_toggle_btn"
          onClick={handleSubmit(onSubmit)}
        >
          <i className="zmdi zmdi-check"></i>
        </button>
      </Header>
      <div className="container-fluid">
        <div className="row clearfix">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <h2>
                  Ingresar información del nuevo <strong>estado de denuncia</strong>
                </h2>
              </div>
              <div className="body">
                {error && (
                  <div className="mb-3">
                    <Alert message={error} />
                  </div>
                )}
                {loading && (
                  <div className="text-center mb-2">
                    <Loader />
                  </div>
                )}
                <div className="row justify-content-center clearfix">
                  <div className="col-md-7">
                    <div className="form-group">
                      <input
                        style={{ border: errors.name ? "1px solid red" : "" }}
                        type="text"
                        className="form-control"
                        placeholder="Nombre"
                        {...register("name", {
                          required: true,
                          minLength: 2,
                        })}
                      />
                      {errors.name?.type === "required" && (
                        <span style={{ color: "red", padding: "4px 2px" }}>
                          El nombre es requerido.
                        </span>
                      )}
                      {errors.name?.type === "minLength" && (
                        <span style={{ color: "red" }}>
                          El nombre debe tener al menos dos caracteres.
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-7">
                    <button
                      className="btn btn-success"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Guardar estado de denuncia
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ComplaintStateAddPage;