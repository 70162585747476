import React from "react";
import styles from "./selected-files.module.scss";

const SelectedFilesList = ({ uploadFiles, handleDeleteSingleFile }) => {
  return (
    <div className="body todo_list">
      <h6>Archivos Seleccionados</h6>
      <ul className="list-group">
        {uploadFiles.map((file, id) => (
          <li
            key={id}
            className={`list-group-item d-flex justify-content-between align-items-center ${styles.file_item} `}
          >
            {file.type.includes("sheet") ? (
              <i className="zmdi zmdi-chart pr-2"></i>
            ) : file.type.includes("document") ? (
              <i className="zmdi zmdi-file-text pr-2"></i>
            ) : file.type.includes("pdf") ? (
              <i className="zmdi zmdi-collection-pdf pr-2"></i>
            ) : file.type.includes("jpeg") | file.type.includes("png") ? (
              <i className="zmdi zmdi-collection-image pr-2"></i>
            ) : (
              <i className="zmdi zmdi-file pr-2"></i>
            )}

            <p>
              {" "}
              {file.path.length > 40
                ? file.path.slice(0, 40) + "..."
                : file.path}{" "}
              - {file.size / 100} KB
            </p>

            <span
              className={`badge badge-success badge-pil`}
              onClick={(e) => {
                handleDeleteSingleFile(e, file);
              }}
            >
              x
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SelectedFilesList;
