import {
  PRIORITIES_LIST_FAIL,
  PRIORITIES_LIST_REQUEST,
  PRIORITIES_LIST_RESET,
  PRIORITIES_LIST_SUCCESS,
} from "../constants/priotities.const";

export const prioritiesListReducer = (state = { priorities: [] }, action) => {
  switch (action.type) {
    case PRIORITIES_LIST_REQUEST:
      return { loading: true };
    case PRIORITIES_LIST_SUCCESS:
      return { loading: false, priorities: action.payload };
    case PRIORITIES_LIST_FAIL:
      return { loading: false, error: action.payload };
    case PRIORITIES_LIST_RESET:
      return { priorities: [] };
    default:
      return state;
  }
};
