import {
  STATISCTIS_GENERAL_GET_FAIL,
  STATISCTIS_GENERAL_GET_REQUEST,
  STATISCTIS_GENERAL_GET_SUCCESS,
  STATISCTIS_GENERAL_GET_RESET,
} from "../constants/statistics.const";

export const statisticsGeneralgetReducer = (state = {}, action) => {
  switch (action.type) {
    case STATISCTIS_GENERAL_GET_REQUEST:
      return { loading: true };
    case STATISCTIS_GENERAL_GET_SUCCESS:
      return { loading: false, statistics: action.payload };
    case STATISCTIS_GENERAL_GET_FAIL:
      return { loading: false, error: action.payload };
    case STATISCTIS_GENERAL_GET_RESET:
      return {};
    default:
      return state;
  }
};
