export const REPOSITORY_ADD_FILE_REQUEST = "REPOSITORY_ADD_FILE_REQUEST";
export const REPOSITORY_ADD_FILE_SUCCESS = "REPOSITORY_ADD_FILE_SUCCESS";
export const REPOSITORY_ADD_FILE_FAIL = "REPOSITORY_ADD_FILE_FAIL";
export const REPOSITORY_ADD_FILE_RESET = "REPOSITORY_ADD_FILE_RESET";

export const REPOSITORY_DELETE_FILE_REQUEST = "REPOSITORY_DELETE_FILE_REQUEST";
export const REPOSITORY_DELETE_FILE_SUCCESS = "REPOSITORY_DELETE_FILE_SUCCESS";
export const REPOSITORY_DELETE_FILE_FAIL = "REPOSITORY_DELETE_FILE_FAIL";
export const REPOSITORY_DELETE_FILE_RESET = "REPOSITORY_DELETE_FILE_RESET";

export const REPOSITORY_GET_FILES_REQUEST = "REPOSITORY_GET_FILES_REQUEST";
export const REPOSITORY_GET_FILES_SUCCESS = "REPOSITORY_GET_FILES_SUCCESS";
export const REPOSITORY_GET_FILES_FAIL = "REPOSITORY_GET_FILES_FAIL";
export const REPOSITORY_GET_FILES_RESET = "REPOSITORY_GET_FILES_RESET";