import React, { useState } from "react";
import { Link } from "react-router-dom";

//import redux ;
import { useDispatch, useSelector } from "react-redux";
import { listLinks } from "../../redux/actions/searcher.actions";

//import components
import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";
const SearcherPopUp = () => {
  const dispatch = useDispatch();

  const [keysearch, setKeySearch] = useState("");
  const linkList = useSelector((state) => state.linkList);
  const { loading, error, links } = linkList;
  const handleSearch = (e) => {
    e.preventDefault();

    if (keysearch) {
      dispatch(listLinks(keysearch));
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="body">
            <form>
              <div className="input-group">
                <button className=" buttonSearcher" onClick={handleSearch}>
                  <i className="zmdi zmdi-search"></i>
                </button>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Buscar..."
                  value={keysearch}
                  onChange={(e) => {
                    setKeySearch(e.target.value);
                  }}
                />
              </div>
            </form>
          </div>
        </div>
        {loading ? (
          <div className="text-center mt-3">
            <Loader />
          </div>
        ) : error ? (
          <Alert message={error} />
        ) : links ? (
          links.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-hover mb-0 c_table search_page">
                <tbody>
                  {links.slice(0, 4).map((link, index) => (
                    <tr key={index}>
                      <td className="max_ellipsis">
                        <h5 className="title">
                          <a
                            href={link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {link.name}
                          </a>
                        </h5>
                        <a
                          className="link"
                          href={link.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {link.url}
                        </a>
                        <span className="details">{link.description}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {links.length > 4 && (
                <li className="footer">
                  <Link to="/buscar">Ver Todo</Link>
                </li>
              )}
            </div>
          ) : (
            <div className="text-center">
              <h6>
                No existen resultados para su búsqueda, por favor inténtelo
                nuevamente
              </h6>
            </div>
          )
        ) : null}
      </div>
    </div>
  );
};

export default SearcherPopUp;
