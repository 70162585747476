import api from "../../api/rigori.api";

import {
	ACTIONS_COMPLAINT_DELETE_REQUEST,
	ACTIONS_COMPLAINT_DELETE_SUCCESS,
	ACTIONS_COMPLAINT_DELETE_FAIL,
	ACTIONS_COMPLAINT_DETAILS_FAIL,
	ACTIONS_COMPLAINT_DETAILS_REQUEST,
	ACTIONS_COMPLAINT_DETAILS_SUCCESS,
	ACTIONS_COMPLAINT_LIST_FAIL,
	ACTIONS_COMPLAINT_LIST_REQUEST,
	ACTIONS_COMPLAINT_LIST_SUCCESS,
	ACTIONS_COMPLAINT_ADD_FAIL,
	ACTIONS_COMPLAINT_ADD_REQUEST,
	ACTIONS_COMPLAINT_ADD_SUCCESS,
	ACTIONS_COMPLAINT_UPDATE_FAIL,
	ACTIONS_COMPLAINT_UPDATE_REQUEST,
	ACTIONS_COMPLAINT_UPDATE_SUCCESS,
	ACTIONS_COMPLAINT_DELETE_FILE_REQUEST,
	ACTIONS_COMPLAINT_DELETE_FILE_SUCCESS,
	ACTIONS_COMPLAINT_DELETE_FILE_FAIL,
	ACTIONS_COMPLAINT_USER_LIST_REQUEST,
	ACTIONS_COMPLAINT_USER_LIST_SUCCESS,
	ACTIONS_COMPLAINT_USER_LIST_FAIL,
} from "../constants/complaints-actions.const";

import { logout } from "./user.actions";

export const addActionComplaint = (files, title, description, complaintId, actionStateId) => async (dispatch, getState) => {
	try {
		dispatch({
			type: ACTIONS_COMPLAINT_ADD_REQUEST,
		});
		let formData = new FormData();
		for (let i = 0; i < files.length; i++) formData.append("files", files[i]);
		const {
			userLogin: { userInfo },
		} = getState();
		const config = { headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${userInfo.token}` } };
		formData.append(
			"request",
			JSON.stringify({
				title,
				description,
				complaintId,
				actionStateId,
			})
		);
		const { data } = await api.post("/actions", formData, config);
		dispatch({
			type: ACTIONS_COMPLAINT_ADD_SUCCESS,
			payload: data,
		});
	} catch (error) {
		const message = error.response && error.response.data.message ? error.response.data.message : error.message;
		if (message === "Unauthorized") {
			dispatch(logout());
		}
		dispatch({
			type: ACTIONS_COMPLAINT_ADD_FAIL,
			payload: message,
		});
	}
};

export const listActionsComplaint = (complaintId) => async (dispatch, getState) => {
	try {
		dispatch({
			type: ACTIONS_COMPLAINT_LIST_REQUEST,
		});
		const {
			userLogin: { userInfo },
		} = getState();
		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};
		const { data } = await api.get(`/actions/complaint/${complaintId}`, config);
		dispatch({
			type: ACTIONS_COMPLAINT_LIST_SUCCESS,
			payload: data,
		});
	} catch (error) {
		const message = error.response && error.response.data.message ? error.response.data.message : error.message;
		if (message === "Unauthorized") {
			dispatch(logout());
		}
		dispatch({
			type: ACTIONS_COMPLAINT_LIST_FAIL,
			payload: message,
		});
	}
};

export const listActionsComplaintUser = (complaintCode) => async (dispatch, getState) => {
	try {
		dispatch({
			type: ACTIONS_COMPLAINT_USER_LIST_REQUEST,
		});
		const { data } = await api.get(`/actions/complaint-code/${complaintCode}`);
		dispatch({
			type: ACTIONS_COMPLAINT_USER_LIST_SUCCESS,
			payload: data,
		});
	} catch (error) {
		const message = error.response && error.response.data.message ? error.response.data.message : error.message;
		dispatch({
			type: ACTIONS_COMPLAINT_USER_LIST_FAIL,
			payload: message,
		});
	}
};

export const updateActionComplaint = (actionId, files, title, description, complaintId, actionStateId) => async (dispatch, getState) => {
	try {
		dispatch({
			type: ACTIONS_COMPLAINT_UPDATE_REQUEST,
		});
		let formData = new FormData();
		for (let i = 0; i < files.length; i++) formData.append("files", files[i]);
		const {
			userLogin: { userInfo },
		} = getState();
		const config = { headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${userInfo.token}` } };
		formData.append(
			"request",
			JSON.stringify({
				title,
				description,
				complaintId,
				actionStateId,
			})
		);
		const { data } = await api.patch(`/actions/${actionId}`, formData, config);
		dispatch({
			type: ACTIONS_COMPLAINT_UPDATE_SUCCESS,
			payload: data,
		});
	} catch (error) {
		const message = error.response && error.response.data.message ? error.response.data.message : error.message;
		if (message === "Unauthorized") {
			dispatch(logout());
		}
		dispatch({
			type: ACTIONS_COMPLAINT_UPDATE_FAIL,
			payload: message,
		});
	}
};

export const deleteActionComplaint = (complaintId, actionId) => async (dispatch, getState) => {
	try {
		dispatch({
			type: ACTIONS_COMPLAINT_DELETE_REQUEST,
		});

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await api.delete(`/actions/${actionId}`, config);

		dispatch({
			type: ACTIONS_COMPLAINT_DELETE_SUCCESS,
			payload: data,
		});
		dispatch(listActionsComplaint(complaintId));
	} catch (error) {
		const message = error.response && error.response.data.message ? error.response.data.message : error.message;
		if (message === "Unauthorized") {
			dispatch(logout());
		}
		dispatch({
			type: ACTIONS_COMPLAINT_DELETE_FAIL,
			payload: message,
		});
	}
};

export const getActionComplaintDetails = (id) => async (dispatch, getState) => {
	try {
		dispatch({
			type: ACTIONS_COMPLAINT_DETAILS_REQUEST,
		});

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${userInfo.token}`,
			},
		};
		const { data } = await api.get(`/actions/${id}`, config);

		dispatch({
			type: ACTIONS_COMPLAINT_DETAILS_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: ACTIONS_COMPLAINT_DETAILS_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		});
	}
};

export const deleteActionFile = (actionId, fileId) => async (dispatch, getState) => {
	try {
		dispatch({
			type: ACTIONS_COMPLAINT_DELETE_FILE_REQUEST,
		});

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await api.delete(`/actions/file/${actionId}/${fileId}`, config);

		dispatch({
			type: ACTIONS_COMPLAINT_DELETE_FILE_SUCCESS,
			payload: data,
		});
		dispatch(getActionComplaintDetails(actionId));
	} catch (error) {
		const message = error.response && error.response.data.message ? error.response.data.message : error.message;
		if (message === "Unauthorized") {
			dispatch(logout());
		}
		dispatch({
			type: ACTIONS_COMPLAINT_DELETE_FILE_FAIL,
			payload: message,
		});
	}
};
