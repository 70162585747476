import {
  CHANGE_ACTIVE_SIDEBAR_ITEM,
  OPEN_SIDEBAR_RIGHT,
  OPEN_SIDEBAR_MOBILE,
  OPEN_SIDEBAR,
} from "../constants/navigation.const";

export function openSidebar() {
  return {
    type: OPEN_SIDEBAR,
  };
}

export function openSidebarRight() {
  return {
    type: OPEN_SIDEBAR_RIGHT,
  };
}

export function openSidebarMobile() {
  return {
    type: OPEN_SIDEBAR_MOBILE,
  };
}

export function changeActiveSidebarItem(activeItem) {
  return {
    type: CHANGE_ACTIVE_SIDEBAR_ITEM,
    activeItem,
  };
}
