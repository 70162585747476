import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { useStateWithCallbackLazy } from "use-state-with-callback";

//import components
import Header from "../../layout/header/header.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import Alert from "../../components/shared/alerts/material-snack-bar/material-snack-bar.component";
import ItemEditComponent from "../../components/cuestionnaires/item-edit/item-edit.component";
import FileEditComponent from "../../components/cuestionnaires/file-edit/file-edit.component";
import ItemAddComponent from "../../components/cuestionnaires/add-item/add-item.component";
import FilterListComponent from "../../components/shared/filters/filter-list/filter-list.component";
import PaginationComponent from "../../components/shared/pagination/pagination.component";

//redux import
import { useDispatch, useSelector } from "react-redux";
import {
  getQuestionnairesDetails,
  deleteItem,
  getQuestionnairesDetailsByCondition,
  getQuestionnairesDetailsBySearching,
  getQuestionnairesDetailsBySorting,
} from "../../redux/actions/questionnaires.actions";
import {
  QUESTIONNAIRES_RESPONSE_RESET,
  QUESTIONNAIRES_ADD_FILE_RESET,
  QUESTIONNAIRES_ADD_ITEM_RESET,
  QUESTIONNAIRES_DELETE_ITEM_RESET,
} from "../../redux/constants/questionnaires.const";

//constant
import BREADCRUM_DATA from "../../constants/breadcrumb.const";
import QUESTIONNAIRES_QUESTIONS_FILTER_OPTIONS from "../../constants/questionnairesQuestionsFilterOptions.cons";

const style = {
  overflowY: "scroll",
  position: "absolute",
  borderRadius: "30px",
  top: "50%",
  left: "50%",
  maxHeight: "95vh",
  minWidth: "80vw",
  background: "white",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  padding: "50px",
  boxShadow: 100,
  p: 4,
};

const QuestionnariesDetailsPage = () => {
  const myRef = useRef(null);
  const [editingId, setEditingId] = useState(null);
  const [editingFileId, setEditingFileId] = useState(null);
  const [isAddingItem, setIsAddingItem] = useState(false);
  const [message, setMessage] = useState(null);
  const [refId, setRefId] = useState(null);

  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const questionnaryDetail = useSelector((state) => state.questionnaryDetail);
  const {
    loading: loadingDetails,
    error: errorDetails,
    questionnary,
  } = questionnaryDetail;
  const questionnaryDetalFiltered = useSelector(
    (state) => state.questionnaryDetalFiltered
  );
  const { questions } = questionnaryDetalFiltered;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const questionnaryResponseState = useSelector(
    (state) => state.questionnaryResponse
  );
  const { responseResult } = questionnaryResponseState;

  const questionnaryAddItem = useSelector((state) => state.questionnaryAddItem);
  const { addResult } = questionnaryAddItem;

  const questionnaryAddFile = useSelector((state) => state.questionnaryAddFile);
  const { addResult: addResultFile } = questionnaryAddFile;

  const questionnaryDeteleItem = useSelector(
    (state) => state.questionnaryDeteleItem
  );
  const {
    loading: loadingDeleteItem,
    error: errorDeleteItem,
    deleteResult,
  } = questionnaryDeteleItem;

  let diff = null;

  if (questionnary) {
    const oneDay = 24 * 60 * 60 * 1000;
    const today = new Date();
    const deadlineDate = new Date(questionnary.deadline + " 12:00:00");
    diff = Math.round(Math.abs((today - deadlineDate) / oneDay));
  }

  const [tableDropdownOpen, setTableMenuOpen] = useState(false);
  const [tableDropdownOpenSort, setTableMenuOpenSort] = useState(false);
  const tableMenuOpen = () => {
    setTableMenuOpen(!tableDropdownOpen);
  };
  const tableSortMenuOpen = () => {
    setTableMenuOpenSort(!tableDropdownOpenSort);
  };

  const [secondTableCurrentPage, setSecondTableCurrentPage] = useState(0);
  const pageSize = 10;
  const secondTablePagesCount = questions
    ? Math.ceil(questions.length / pageSize)
    : 1;

  const setSecondTablePage = (e, index) => {
    e.preventDefault();
    setSecondTableCurrentPage(index);
  };

  const handleCambioEstado = (condition) => {
    dispatch(getQuestionnairesDetailsByCondition(condition));
    setSecondTableCurrentPage(0);
  };

  const handleSorting = (condition) => {
    dispatch(getQuestionnairesDetailsBySorting(condition));
    setSecondTableCurrentPage(0);
  };
  const [searchKey, setSearchKey] = useStateWithCallbackLazy("");
  const handleSearch = (codigo) => {
    setSearchKey(codigo, (current) => {
      dispatch(getQuestionnairesDetailsBySearching(current.trim()));
      setSecondTableCurrentPage(0);
    });
  };

  const cancelEdit = () => {
    //e.preventDefault();
    setEditingId(null);

    return false;
  };

  const cancelEditFile = () => {
    //e.preventDefault();
    setEditingFileId(null);

    //myRef.current.reset();
    return false;
  };

  const cancelAddItem = () => {
    //e.preventDefault();
    setIsAddingItem(false);

    //myRef.current.reset();
    return false;
  };

  const resetMessage = () => {
    //e.preventDefault();
    setMessage(null);
    return false;
  };
  //console.log(myRef);
  //console.log(refId);
  if (myRef.current && refId) {
    //myRef.current.scrollIntoView();
    //myRef.current.style.backgroundColor = "rgba(50,205,50, 0.2)";
    //myRef.current.removeChild(myRef.current.children[0]);
  }

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else {
      if (responseResult) {
        //window.scrollTo(0, 0);
        setMessage("PREGUNTA RESPONDIDA  con éxito");
        //executeScroll();
      }
      if (addResult) {
        setMessage("PREGUNTA AGREGADA con éxito");
        //executeScroll();
      }
      if (addResultFile) {
        setMessage("Proceso realizado exitosamente.");
        //executeScroll();
      }

      if (deleteResult) {
        setMessage("Pregunta eliminada con éxito.");
      }

      dispatch({ type: QUESTIONNAIRES_RESPONSE_RESET });
      dispatch({ type: QUESTIONNAIRES_ADD_FILE_RESET });
      dispatch({ type: QUESTIONNAIRES_ADD_ITEM_RESET });
      dispatch({ type: QUESTIONNAIRES_DELETE_ITEM_RESET });
      dispatch(getQuestionnairesDetails(params.id));
    }
  }, [
    navigate,
    userInfo,
    dispatch,
    params,
    responseResult,
    addResult,
    addResultFile,
    deleteResult,
  ]);
  const handleDelete = (e, id) => {
    e.preventDefault();
    if (window.confirm("Esta acción no se puede deshacer.")) {
      dispatch(deleteItem(id));
    }
  };
  return (
    <>
      {message && <Alert message={message} severity="success" />}
      {deleteResult && (
        <Alert message="Pregunta eliminada con éxito." severity="success" />
      )}
      {errorDeleteItem && <Alert message={errorDeleteItem} severity="error" />}
      {loadingDeleteItem && (
        <div className="text-center mb-2">
          <Loader />
        </div>
      )}
      {errorDetails ? (
        <div className="mb-3">
          <Alert message={errorDetails} />
        </div>
      ) : loadingDetails ? (
        <div className="text-center mb-2">
          <Loader />
        </div>
      ) : (
        questionnary && (
          <>
            <Header
              title={`Cuestionario - ${
                questionnary.company && questionnary.company.name
              }`}
              path="Detalles Cuestionario"
              pathList={BREADCRUM_DATA.questionnairyDetail}
            >
              {userInfo && userInfo.role !== "EMPRESA" && (
                <button
                  onClick={() => {
                    setIsAddingItem(true);
                  }}
                  className="btn btn-success btn-icon float-right "
                >
                  <i className="zmdi zmdi-plus"></i>
                </button>
              )}
            </Header>
            <div className="container-fluid">
              <div className="row clearfix">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="card project_list">
                    <div className="row mb-2 mt-2">
                      <div className="col-md-4 col-sm-12 col-xs-12">
                        <h6>
                          PROGRESO ACTUAL:{" "}
                          <span style={{ fontWeight: "900", color: "#c55b25" }}>
                            {questionnary.progress} %
                          </span>{" "}
                        </h6>
                      </div>
                      <div className="col-md-4 col-sm-12 col-xs-12">
                        <h6>
                          Vence:{" "}
                          <span style={{ fontWeight: "900", color: "#c55b25" }}>
                            {new Date(
                              questionnary.deadline + " 12:00:00"
                            ).getDate()}{" "}
                            {new Date(
                              questionnary.deadline + " 12:00:00"
                            ).toLocaleString("default", {
                              month: "short",
                            })}{" "}
                            {new Date(
                              questionnary.deadline + " 12:00:00"
                            ).toLocaleString("default", {
                              year: "numeric",
                            })}
                          </span>{" "}
                        </h6>
                      </div>
                      <div className="col-md-4 col-sm-12 col-xs-12">
                        <h6>
                          Días Restantes:{" "}
                          <span
                            style={{
                              fontWeight: "900",
                              color: diff
                                ? diff <= 6
                                  ? "red"
                                  : diff <= 14 || diff > 6
                                  ? "orange"
                                  : "green"
                                : "",
                            }}
                          >
                            {diff} días
                          </span>{" "}
                        </h6>
                      </div>
                    </div>
                    <div>
                      {" "}
                      <FilterListComponent
                        searchKey={searchKey}
                        handleSearch={handleSearch}
                        tableDropdownOpenSort={tableDropdownOpenSort}
                        tableSortMenuOpen={tableSortMenuOpen}
                        handleSorting={handleSorting}
                        tableDropdownOpen={tableDropdownOpen}
                        tableMenuOpen={tableMenuOpen}
                        handleCambioEstado={handleCambioEstado}
                        filterOptions={QUESTIONNAIRES_QUESTIONS_FILTER_OPTIONS}
                      />
                    </div>
                    <div className="table-responsive tableFixHead">
                      <table className="table table-hover c_table theme-color ">
                        <thead>
                          <tr>
                            <th style={{ width: "25px" }}>Id</th>
                            <th style={{ maxWidth: "25px" }}>Descripción</th>

                            <th className="">Respuesta</th>

                            <th>Desarrolle su Respuesta</th>
                            <th className="" width="150px">
                              Anexos
                            </th>
                            <th>Acción</th>
                          </tr>
                        </thead>
                        <tbody>
                          {questions &&
                            questions
                              .slice(
                                secondTableCurrentPage * pageSize,
                                (secondTableCurrentPage + 1) * pageSize
                              )
                              .map((item, index) => (
                                <>
                                  {" "}
                                  <tr
                                    /* ref={refId === item.id ? myRef : null} */
                                    key={index}
                                    className={`${
                                      editingId === item.id ||
                                      editingFileId === item.id
                                        ? "rowEditing"
                                        : "rowInactive"
                                    } ${item.state ? "rowAnswered" : ""}`}
                                  >
                                    <td>
                                      <strong>{item.id}</strong>
                                    </td>
                                    <div className="pt-4">
                                      <p>
                                        <strong>Categoría:</strong>{" "}
                                        {item.item.category.name} -{" "}
                                        {item.item.category.description}
                                      </p>
                                      <p>
                                        <strong>Pregunta:</strong>{" "}
                                        {item.item.question}
                                      </p>
                                    </div>

                                    <td>
                                      <div className="form-group">
                                        <div className="radio inlineblock m-r-20">
                                          <input
                                            /*  disabled={
                                            editingId === item.id ? false : true
                                          } */
                                            type="radio"
                                            className="with-gap"
                                            checked={
                                              item.state
                                                ? item.state.id === 1
                                                  ? true
                                                  : false
                                                : null
                                            }
                                          />
                                          <label for="male">Si</label>
                                        </div>
                                        <div className="radio inlineblock">
                                          <input
                                            /* disabled={
                                            editingId === item.id ? false : true
                                          } */
                                            type="radio"
                                            className="with-gap"
                                            checked={
                                              item.state
                                                ? item.state.id === 1
                                                  ? false
                                                  : true
                                                : null
                                            }
                                          />
                                          <label for="Female">No</label>
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="form-group">
                                        <div className="form-line">
                                          <textarea
                                            disabled={
                                              editingId === item.id
                                                ? false
                                                : true
                                            }
                                            rows="4"
                                            className="form-control no-resize"
                                            placeholder="Todavía no existe una respuesta"
                                            value={
                                              item.answer ? item.answer : ""
                                            }
                                          ></textarea>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <button
                                        className="btn  r-btn btn-anexos"
                                        onClick={() => {
                                          setEditingFileId(item.id);
                                          setRefId(item.id);
                                        }}
                                      >
                                        <i className="zmdi zmdi-folder-outline mr-2"></i>
                                        <strong>Anexos</strong>
                                      </button>
                                    </td>
                                    <td>
                                      {userInfo &&
                                      userInfo.role === "EMPRESA" ? (
                                        <>
                                          {editingId === item.id ? (
                                            <div>
                                              <button
                                                onClick={(e) => {
                                                  cancelEdit(e);
                                                }}
                                                className="btn  btn-sm"
                                                title="Editando "
                                              >
                                                <i className="zmdi zmdi-assignment-o mr-2 pt-1 pb-1"></i>{" "}
                                                <strong>EDITANDO</strong>
                                              </button>
                                            </div>
                                          ) : (
                                            <button
                                              onClick={() => {
                                                setEditingId(item.id);
                                                setRefId(item.id);
                                              }}
                                              className="btn btn-warning btn-sm"
                                              title="Editar"
                                            >
                                              <i className="zmdi zmdi-edit pt-1 pb-1 mr-2"></i>
                                              <strong>EDITAR </strong>
                                            </button>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <button
                                            className="btn btn-danger btn-sm"
                                            title="Eliminar"
                                            onClick={(e) => {
                                              handleDelete(e, item.id);
                                            }}
                                          >
                                            <i className="zmdi zmdi-delete"></i>
                                          </button>
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                  {editingId === item.id ? (
                                    <>
                                      <Modal
                                        open={
                                          editingId === item.id ? true : false
                                        }
                                        onClose={(e) => {
                                          cancelEdit(e);
                                          setRefId(null);
                                        }}
                                      >
                                        <div style={style}>
                                          <ItemEditComponent
                                            id={item.id}
                                            category={
                                              item.item.category.name +
                                              " " +
                                              item.item.category.description
                                            }
                                            question={item.item.question}
                                            state={item.state}
                                            answer={item.answer}
                                            onCancel={cancelEdit}
                                            resetMessage={resetMessage}
                                          />
                                        </div>
                                      </Modal>
                                      {/*    <h6 className="mt-2">EDITAR</h6>
                                       */}
                                    </>
                                  ) : null}
                                  {editingFileId === item.id ? (
                                    <>
                                      <Modal
                                        open={
                                          editingFileId === item.id
                                            ? true
                                            : false
                                        }
                                        onClose={(e) => {
                                          cancelEditFile(e);
                                          setRefId(null);
                                        }}
                                      >
                                        <div style={style}>
                                          <FileEditComponent
                                            id={item.id}
                                            category={item.item.category.name}
                                            question={item.item.question}
                                            onCancel={cancelEditFile}
                                            resetMessage={resetMessage}
                                          />
                                        </div>
                                      </Modal>
                                      {/*    <h6 className="mt-2">EDITAR</h6>
                                       */}
                                    </>
                                  ) : null}
                                </>
                              ))}
                        </tbody>
                      </table>

                      {isAddingItem ? (
                        <>
                          <Modal
                            open={isAddingItem}
                            onClose={(e) => {
                              cancelAddItem(e);
                              setRefId(null);
                            }}
                          >
                            <div style={style}>
                              <ItemAddComponent
                                quizId={params.id}
                                cuestionaryName={
                                  questionnary.company &&
                                  questionnary.company.name
                                }
                                onCancel={cancelAddItem}
                                resetMessage={resetMessage}
                              />
                            </div>
                          </Modal>
                          {/*    <h6 className="mt-2">EDITAR</h6>
                           */}
                        </>
                      ) : null}
                    </div>
                    <div className="mt-4 text-center">
                      <PaginationComponent
                        tablecurrentPage={secondTableCurrentPage}
                        setTablecurrentPage={setSecondTablePage}
                        tablepagesCount={secondTablePagesCount}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      )}
    </>
  );
};

export default QuestionnariesDetailsPage;
