import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

//import components
import Header from "../../layout/header/header.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import SkeletonLoader from "../../components/shared/loaders/skeleton-loader/skeleton-loader.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";

//import redux ;
import { useDispatch, useSelector } from "react-redux";
import { listStatesComplaint, deleteStateComplaint } from "../../redux/actions/complaints-states.actions";
import { changeActiveSidebarItem } from "../../redux/actions/navigation.actions";
import {
  STATES_COMPLAINT_ADD_RESET,
  STATES_COMPLAINT_DELETE_RESET,
  STATES_COMPLAINT_UPDATE_RESET,
  STATES_COMPLAINT_DETAILS_RESET,
} from "../../redux/constants/complaints-states.const";

const ComplaintsStatesListPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [message, setMessage] = useState(null);
	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;
	const statesList = useSelector((state) => state.statesComplaintList);
	const { loading, error, states } = statesList;

  const stateDelete = useSelector((state) => state.stateComplaintDelete);
  const stateAdd = useSelector((state) => state.stateComplaintAdd);
  const { addResult } = stateAdd;
  const stateUpdate = useSelector((state) => state.stateComplaintUpdate);
  const { updateResult } = stateUpdate;

  const {
    loading: loadingDelete,
    error: errorDelete,
    deleteCompanyResult,
  } = stateDelete;

	useEffect(() => {
		dispatch(changeActiveSidebarItem(location.pathname));
		if (!userInfo) {
			navigate("/login");
		} else {
			if (addResult) {
        setMessage("Estado creado con éxito");
      }
      if (updateResult) {
        setMessage("Estado actualizado con éxito");
      }
      dispatch({ type: STATES_COMPLAINT_ADD_RESET });
      dispatch({ type: STATES_COMPLAINT_UPDATE_RESET });
      dispatch({ type: STATES_COMPLAINT_DELETE_RESET });
      dispatch({ type: STATES_COMPLAINT_DETAILS_RESET });
			dispatch(listStatesComplaint());
		}
	}, [navigate, userInfo, dispatch, addResult, updateResult, location]);

  const handleDelete = (e, id) => {
    e.preventDefault();
    if (window.confirm("Esta acción no se puede deshacer.")) {
      dispatch(deleteStateComplaint(id));
    }
  };

	return (
		<>
			<Header
				title="Estados para acciones de una denuncia"
				path="Estados para acciones de una denuncia"
			>
				<Link
					to="/estados/agregar"
					className="btn btn-success btn-icon float-right "
				>
					<i className="zmdi zmdi-plus"></i>
				</Link>
			</Header>
      {errorDelete && (
        <div className="mb-2 mt-2">
          <Alert message={errorDelete} />
        </div>
      )}
      {loadingDelete && (
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card text-center">
                <Loader />
              </div>
            </div>
          </div>
        </div>
      )}
      {deleteCompanyResult && (
        <div className="mb-2 mt-2">
          <Alert message="Estado eliminado exitosamente" severity="info" />
        </div>
      )}
      {message && (
        <div className="mb-2 mt-2">
          <Alert message={message} severity="info" />
        </div>
      )}
			{error ? (
				<div className="mb-2 mt-2">
					<Alert message={error} />
				</div>
			) : loading ? (
				<div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <SkeletonLoader />
              </div>
            </div>
          </div>
        </div>
			) : (
				<div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-hover c_table theme-color">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Nombre</th>
                        <th>Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!states?.length ? (
                        <tr className="text-center">
                          <td>No existen estados de denuncias registrados.</td>
                        </tr>
                      ) : (
                        states.map((state, index) => (
                          <tr key={index}>
                            <td>{state.id}</td>
                            <td>
                              <strong>{state.name}</strong>
                            </td>
                            <td>
                              <Link
                                to={`/estados/${state.id}`}
                                className="btn btn-warning btn-sm"
                                title="Editar"
                              >
                                <i className="zmdi zmdi-edit"></i>
                              </Link>
                              <button
                                className="btn btn-danger btn-sm"
                                title="Eliminar"
                                onClick={(e) => {
                                  handleDelete(e, state.id);
                                }}
                              >
                                <i className="zmdi zmdi-delete"></i>
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
			)}
		</>
	);
};

export default ComplaintsStatesListPage;
