import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

//components
import Loader from "../../../shared/loaders/spinner/sppinner.component";

//import redux ;
import { useDispatch, useSelector } from "react-redux";

import {
  listAlerts,
  // alertsCheck,
} from "../../../../redux/actions/alerts.actions";
//styles
// import styles from "./alert-togle.module.scss";

const AlertTogle = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const alertsList = useSelector((state) => state.alertsList);
  const { loading, error, alerts } = alertsList;

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else {
      dispatch(listAlerts());
    }
  }, [navigate, dispatch, userInfo]);
  return (
    <>
      <li className="body">
        <ul className="menu list-unstyled">
          {loading ? (
            <Loader />
          ) : error ? (
            <p>Error</p>
          ) : (
            alerts &&
            alerts.quizzes &&
            alerts.quizzes.map((item, index) => (
              <li key={index}>
                <Link to="/">
                  <div className="icon-circle bg-amber">
                    <i className="zmdi zmdi-assignment-o"></i>
                  </div>
                  <div></div>
                  <div className="menu-info">
                    <h4>{item.name}</h4>
                    <p>
                      <i className="zmdi zmdi-time"></i> Fecha límite {" "}
                      {new Date(item.deadline).toLocaleDateString()}
                    </p>
                  </div>
                </Link>
              </li>
            ))
          )}
        </ul>
      </li>
    </>
  );
};

export default AlertTogle;
