import React from "react";
import { Link } from "react-router-dom";
import styles from "./statistics-card-v2.module.scss";

/* 
bg: "bg-red", bg-green, bg-blue, bg-amber
iconName: copy, info, trending-up
*/
const StatisticCard = ({ bg, color = "#fff", name, number, iconName = "copy", link = "/" }) => {
  return (
    <Link to={link} className="card info-box-2" style={{ "cursor": "pointer" }} title={`Ver ${name}`}>
      <div className={`body`} style={{ "backgroundColor": bg, "color": color }}>
        <div className="icon col-12">
          <i className={`zmdi zmdi-${iconName}`} style={{ "fontSize": "45px", "color": color }}></i>
        </div>
        <div className="content col-12">
          <div className={styles.text}>{name}</div>
          <div className={` ${styles.cardnumber}`}>{number}</div>
        </div>
      </div>
    </Link>
  );
};

export default StatisticCard;
