import api from "../../api/rigori.api";

import {
  STATES_COMPLAINT_DELETE_REQUEST,
  STATES_COMPLAINT_DELETE_SUCCESS,
  STATES_COMPLAINT_DELETE_FAIL,
  STATES_COMPLAINT_DETAILS_FAIL,
  STATES_COMPLAINT_DETAILS_REQUEST,
  STATES_COMPLAINT_DETAILS_SUCCESS,
  STATES_COMPLAINT_LIST_FAIL,
  STATES_COMPLAINT_LIST_REQUEST,
  STATES_COMPLAINT_LIST_SUCCESS,
  STATES_COMPLAINT_ADD_FAIL,
  STATES_COMPLAINT_ADD_REQUEST,
  STATES_COMPLAINT_ADD_SUCCESS,
  STATES_COMPLAINT_UPDATE_FAIL,
  STATES_COMPLAINT_UPDATE_REQUEST,
  STATES_COMPLAINT_UPDATE_SUCCESS,
} from "../constants/complaints-states.const";

import { logout } from "./user.actions";

export const addStateComplaint = (name) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: STATES_COMPLAINT_ADD_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await api.post(
        "/action-states",
        { name },
        config
      );

      dispatch({
        type: STATES_COMPLAINT_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Unauthorized") {
        dispatch(logout());
      }
      dispatch({
        type: STATES_COMPLAINT_ADD_FAIL,
        payload: message,
      });
    }
  };

export const listStatesComplaint = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: STATES_COMPLAINT_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.get(`/action-states`, config);

    dispatch({
      type: STATES_COMPLAINT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: STATES_COMPLAINT_LIST_FAIL,
      payload: message,
    });
  }
};

export const updateStateComplaint = (stateId, name) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: STATES_COMPLAINT_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await api.patch(
        `/action-states/${stateId}`,
        { name },
        config
      );

      dispatch({
        type: STATES_COMPLAINT_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Unauthorized") {
        dispatch(logout());
      }
      dispatch({
        type: STATES_COMPLAINT_UPDATE_FAIL,
        payload: message,
      });
    }
  };

export const deleteStateComplaint = (stateId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: STATES_COMPLAINT_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.delete(`/action-states/${stateId}`, config);

    dispatch({
      type: STATES_COMPLAINT_DELETE_SUCCESS,
      payload: data,
    });
    dispatch(listStatesComplaint());
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: STATES_COMPLAINT_DELETE_FAIL,
      payload: message,
    });
  }
};

export const getStateComplaintDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: STATES_COMPLAINT_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await api.get(`/action-states/${id}`, config);

    dispatch({
      type: STATES_COMPLAINT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STATES_COMPLAINT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
