import {
  COMPANIES_DELETE_FAIL,
  COMPANIES_DELETE_REQUEST,
  COMPANIES_DELETE_RESET,
  COMPANIES_DELETE_SUCCESS,
  COMPANIES_DETAILS_FAIL,
  COMPANIES_DETAILS_REQUEST,
  COMPANIES_DETAILS_RESET,
  COMPANIES_DETAILS_SUCCESS,
  COMPANIES_LIST_FAIL,
  COMPANIES_LIST_REQUEST,
  COMPANIES_LIST_RESET,
  COMPANIES_LIST_SUCCESS,
  COMPANIES_ADD_FAIL,
  COMPANIES_ADD_REQUEST,
  COMPANIES_ADD_RESET,
  COMPANIES_ADD_SUCCESS,
  COMPANIES_UPDATE_REQUEST,
  COMPANIES_UPDATE_SUCCESS,
  COMPANIES_UPDATE_FAIL,
  COMPANIES_UPDATE_RESET,
  COMPANIES_DELETE_AREA_REQUEST,
  COMPANIES_DELETE_AREA_SUCCESS,
  COMPANIES_DELETE_AREA_FAIL,
  COMPANIES_DELETE_AREA_RESET,
} from "../constants/companies.const";

export const companyAddReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPANIES_ADD_REQUEST:
      return { loading: true };
    case COMPANIES_ADD_SUCCESS:
      return { loading: false, addResult: action.payload };
    case COMPANIES_ADD_FAIL:
      return { loading: false, error: action.payload };
    case COMPANIES_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const companyListReducer = (state = { companies: [] }, action) => {
  switch (action.type) {
    case COMPANIES_LIST_REQUEST:
      return { loading: true };
    case COMPANIES_LIST_SUCCESS:
      return { loading: false, companies: action.payload };
    case COMPANIES_LIST_FAIL:
      return { loading: false, error: action.payload };
    case COMPANIES_LIST_RESET:
      return { companies: [] };
    default:
      return state;
  }
};

export const companyDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPANIES_DELETE_REQUEST:
      return { loading: true };
    case COMPANIES_DELETE_SUCCESS:
      return { loading: false, deleteCompanyResult: action.payload };
    case COMPANIES_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case COMPANIES_DELETE_RESET:
      return {};
    default:
      return state;
  }
};
export const companyDeleteAreaReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPANIES_DELETE_AREA_REQUEST:
      return { loading: true };
    case COMPANIES_DELETE_AREA_SUCCESS:
      return { loading: false, deleteAreaCompanyResult: action.payload };
    case COMPANIES_DELETE_AREA_FAIL:
      return { loading: false, error: action.payload };
    case COMPANIES_DELETE_AREA_RESET:
      return {};
    default:
      return state;
  }
};

export const companyDetailsReducer = (state = { company: {} }, action) => {
  switch (action.type) {
    case COMPANIES_DETAILS_REQUEST:
      return { ...state, loading: true };
    case COMPANIES_DETAILS_SUCCESS:
      return { loading: false, company: action.payload };
    case COMPANIES_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case COMPANIES_DETAILS_RESET:
      return { company: {} };
    default:
      return state;
  }
};

export const companyUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPANIES_UPDATE_REQUEST:
      return { loading: true };
    case COMPANIES_UPDATE_SUCCESS:
      return { loading: false, updateResult: action.payload };
    case COMPANIES_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case COMPANIES_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
