import api from "../../api/rigori.api";

import {
  REPOSITORY_ADD_FILE_FAIL,
  REPOSITORY_ADD_FILE_REQUEST,
  REPOSITORY_ADD_FILE_SUCCESS,
  REPOSITORY_DELETE_FILE_FAIL,
  REPOSITORY_DELETE_FILE_REQUEST,
  REPOSITORY_DELETE_FILE_SUCCESS,
  REPOSITORY_GET_FILES_FAIL,
  REPOSITORY_GET_FILES_REQUEST,
  REPOSITORY_GET_FILES_SUCCESS,
} from "../constants/repository.const";

import { logout } from "./user.actions";

export const getRepositoryFiles = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: REPOSITORY_GET_FILES_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.get(`/repository`, config);

    dispatch({
      type: REPOSITORY_GET_FILES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: REPOSITORY_GET_FILES_FAIL,
      payload: message,
    });
  }
};

export const addFile = (companyCategoryId, files) => async (dispatch, getState) => {
  try {
    dispatch({
      type: REPOSITORY_ADD_FILE_REQUEST,
    });

    let formData = new FormData();
    
    const {
      userLogin: { userInfo },
    } = getState();
    
    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }
    
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    const { data } = await api.post(
      `/repository/${companyCategoryId}`,
      formData,
      config
    );

    dispatch({
      type: REPOSITORY_ADD_FILE_SUCCESS,
      payload: data,
    });

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: REPOSITORY_ADD_FILE_FAIL,
      payload: message,
    });
  }
};

export const deleteFile = (fileId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: REPOSITORY_DELETE_FILE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.delete(`/repository/${fileId}`, config);

    dispatch({
      type: REPOSITORY_DELETE_FILE_SUCCESS,
      payload: data,
    });
    
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: REPOSITORY_DELETE_FILE_FAIL,
      payload: message,
    });
  }
};