import {
  ALERTS_LIST_FAIL,
  ALERTS_LIST_REQUEST,
  ALERTS_LIST_SUCCESS,
  ALERTS_CHECK_REQUEST,
  ALERTS_CHECK_SUCCESS,
  ALERTS_CHECK_FAIL,
  ALERTS_LIST_RESET,
  ALERTS_CHECK_RESET,
} from "../constants/alerts.const";

export const alertCheckReducer = (state = {}, action) => {
  switch (action.type) {
    case ALERTS_CHECK_REQUEST:
      return { loading: true };
    case ALERTS_CHECK_SUCCESS:
      return { loading: false, checkResult: action.payload };
    case ALERTS_CHECK_FAIL:
      return { loading: false, error: action.payload };
    case ALERTS_CHECK_RESET:
      return {};
    default:
      return state;
  }
};

export const alertsListReducer = (state = { alerts: {} }, action) => {
  switch (action.type) {
    case ALERTS_LIST_REQUEST:
      return { loading: true };
    case ALERTS_LIST_SUCCESS:
      return { loading: false, alerts: action.payload };
    case ALERTS_LIST_FAIL:
      return { loading: false, error: action.payload };
    case ALERTS_LIST_RESET:
      return { alerts: {} };
    default:
      return state;
  }
};
