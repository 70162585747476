import {
  QUESTIONNAIRES_DETAILS_FAIL,
  QUESTIONNAIRES_DETAILS_REQUEST,
  QUESTIONNAIRES_DETAILS_RESET,
  QUESTIONNAIRES_DETAILS_SUCCESS,
  QUESTIONNAIRES_LIST_FAIL,
  QUESTIONNAIRES_LIST_REQUEST,
  QUESTIONNAIRES_LIST_RESET,
  QUESTIONNAIRES_LIST_SUCCESS,
  QUESTIONNAIRES_ADD_FAIL,
  QUESTIONNAIRES_ADD_REQUEST,
  QUESTIONNAIRES_ADD_RESET,
  QUESTIONNAIRES_ADD_SUCCESS,
  QUESTIONNAIRES_GET_USERS_REQUEST,
  QUESTIONNAIRES_GET_USERS_SUCCESS,
  QUESTIONNAIRES_GET_USERS_FAIL,
  QUESTIONNAIRES_GET_USERS_RESET,
  QUESTIONNAIRES_GET_COMPANIES_REQUEST,
  QUESTIONNAIRES_GET_COMPANIES_SUCCESS,
  QUESTIONNAIRES_GET_COMPANIES_FAIL,
  QUESTIONNAIRES_GET_COMPANIES_RESET,
  QUESTIONNAIRES_GET_FILE_REQUEST,
  QUESTIONNAIRES_GET_FILE_SUCCESS,
  QUESTIONNAIRES_GET_FILE_FAIL,
  QUESTIONNAIRES_GET_FILE_RESET,
  QUESTIONNAIRES_ADD_ITEM_REQUEST,
  QUESTIONNAIRES_ADD_ITEM_SUCCESS,
  QUESTIONNAIRES_ADD_ITEM_FAIL,
  QUESTIONNAIRES_ADD_ITEM_RESET,
  QUESTIONNAIRES_RESPONSE_REQUEST,
  QUESTIONNAIRES_RESPONSE_SUCCESS,
  QUESTIONNAIRES_RESPONSE_FAIL,
  QUESTIONNAIRES_RESPONSE_RESET,
  QUESTIONNAIRES_ADD_FILE_REQUEST,
  QUESTIONNAIRES_ADD_FILE_SUCCESS,
  QUESTIONNAIRES_ADD_FILE_FAIL,
  QUESTIONNAIRES_ADD_FILE_RESET,
  QUESTIONNAIRES_DELETE_FILE_REQUEST,
  QUESTIONNAIRES_DELETE_FILE_SUCCESS,
  QUESTIONNAIRES_DELETE_FILE_FAIL,
  QUESTIONNAIRES_DELETE_FILE_RESET,
  QUESTIONNAIRES_DELETE_ITEM_REQUEST,
  QUESTIONNAIRES_DELETE_ITEM_SUCCESS,
  QUESTIONNAIRES_DELETE_ITEM_FAIL,
  QUESTIONNAIRES_DELETE_ITEM_RESET,
  QUESTIONNAIRES_FILTRADA_GET,
  QUESTIONNAIRES_FILTRADA_RESET,
  QUESTIONNAIRES_FILTRADA_DETAILS_GET,
  QUESTIONNAIRES_FILTRADA_DETAILS_RESET,
} from "../constants/questionnaires.const";

export const questionnaryAddReducer = (state = {}, action) => {
  switch (action.type) {
    case QUESTIONNAIRES_ADD_REQUEST:
      return { loading: true };
    case QUESTIONNAIRES_ADD_SUCCESS:
      return { loading: false, addResult: action.payload };
    case QUESTIONNAIRES_ADD_FAIL:
      return { loading: false, error: action.payload };
    case QUESTIONNAIRES_ADD_RESET:
      return {};
    default:
      return state;
  }
};

export const questionnaryListReducer = (
  state = { questionnaries: [] },
  action
) => {
  switch (action.type) {
    case QUESTIONNAIRES_LIST_REQUEST:
      return { loading: true };
    case QUESTIONNAIRES_LIST_SUCCESS:
      return { loading: false, questionnaries: action.payload };
    case QUESTIONNAIRES_LIST_FAIL:
      return { loading: false, error: action.payload };
    case QUESTIONNAIRES_LIST_RESET:
      return { questionnaries: [] };
    default:
      return state;
  }
};

export const questionnariesListFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case QUESTIONNAIRES_FILTRADA_GET:
      return {
        questionnaries: action.payload,
      };
    case QUESTIONNAIRES_FILTRADA_RESET:
      return {};

    default:
      return state;
  }
};

//LIST USERS FOR CREATE CUESTIONARIE
export const questionnaryListUsersReducer = (
  state = { users: [], companyUsers: [] },
  action
) => {
  switch (action.type) {
    case QUESTIONNAIRES_GET_USERS_REQUEST:
      return { loading: true };
    case QUESTIONNAIRES_GET_USERS_SUCCESS:
      return {
        loading: false,
        users: action.payload.users,
        companyUsers: action.payload.usersCompany,
      };
    case QUESTIONNAIRES_GET_USERS_FAIL:
      return { loading: false, error: action.payload };
    case QUESTIONNAIRES_GET_USERS_RESET:
      return { users: [], companyUsers: [] };
    default:
      return state;
  }
};
//LIST COMPANIES FOR CREATE CUESTIONARIE
export const questionnaryListCompaniesReducer = (
  state = { companies: [] },
  action
) => {
  switch (action.type) {
    case QUESTIONNAIRES_GET_COMPANIES_REQUEST:
      return { loading: true };
    case QUESTIONNAIRES_GET_COMPANIES_SUCCESS:
      return {
        loading: false,
        companies: action.payload,
      };
    case QUESTIONNAIRES_GET_COMPANIES_FAIL:
      return { loading: false, error: action.payload };
    case QUESTIONNAIRES_GET_COMPANIES_RESET:
      return { companies: [] };
    default:
      return state;
  }
};

export const questionnaryDetailsReducer = (
  state = { questionnary: {} },
  action
) => {
  switch (action.type) {
    case QUESTIONNAIRES_DETAILS_REQUEST:
      return { ...state, loading: true };
    case QUESTIONNAIRES_DETAILS_SUCCESS:
      return { loading: false, questionnary: action.payload };
    case QUESTIONNAIRES_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case QUESTIONNAIRES_DETAILS_RESET:
      return { questionnary: {} };
    default:
      return state;
  }
};

export const questionnariesDetailsFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case QUESTIONNAIRES_FILTRADA_DETAILS_GET:
      return {
        questions: action.payload,
      };
    case QUESTIONNAIRES_FILTRADA_DETAILS_RESET:
      return {};

    default:
      return state;
  }
};

export const getQuestionnaryFilesReducer = (state = { files: {} }, action) => {
  switch (action.type) {
    case QUESTIONNAIRES_GET_FILE_REQUEST:
      return { ...state, loading: true };
    case QUESTIONNAIRES_GET_FILE_SUCCESS:
      return { loading: false, files: action.payload };
    case QUESTIONNAIRES_GET_FILE_FAIL:
      return { loading: false, error: action.payload };
    case QUESTIONNAIRES_GET_FILE_RESET:
      return { files: {} };
    default:
      return state;
  }
};

export const questionnaryAddItemReducer = (state = {}, action) => {
  switch (action.type) {
    case QUESTIONNAIRES_ADD_ITEM_REQUEST:
      return { loading: true };
    case QUESTIONNAIRES_ADD_ITEM_SUCCESS:
      return { loading: false, addResult: action.payload };
    case QUESTIONNAIRES_ADD_ITEM_FAIL:
      return { loading: false, error: action.payload };
    case QUESTIONNAIRES_ADD_ITEM_RESET:
      return {};
    default:
      return state;
  }
};

export const questionnaryResponseReducer = (state = {}, action) => {
  switch (action.type) {
    case QUESTIONNAIRES_RESPONSE_REQUEST:
      return { loading: true };
    case QUESTIONNAIRES_RESPONSE_SUCCESS:
      return { loading: false, responseResult: action.payload };
    case QUESTIONNAIRES_RESPONSE_FAIL:
      return { loading: false, error: action.payload };
    case QUESTIONNAIRES_RESPONSE_RESET:
      return {};
    default:
      return state;
  }
};

export const questionnaryAddFileReducer = (state = {}, action) => {
  switch (action.type) {
    case QUESTIONNAIRES_ADD_FILE_REQUEST:
      return { loading: true };
    case QUESTIONNAIRES_ADD_FILE_SUCCESS:
      return { loading: false, addResult: action.payload };
    case QUESTIONNAIRES_ADD_FILE_FAIL:
      return { loading: false, error: action.payload };
    case QUESTIONNAIRES_ADD_FILE_RESET:
      return {};
    default:
      return state;
  }
};

export const questionnaryDeleteFileReducer = (state = {}, action) => {
  switch (action.type) {
    case QUESTIONNAIRES_DELETE_FILE_REQUEST:
      return { loading: true };
    case QUESTIONNAIRES_DELETE_FILE_SUCCESS:
      return { loading: false, deleteResult: action.payload };
    case QUESTIONNAIRES_DELETE_FILE_FAIL:
      return { loading: false, error: action.payload };
    case QUESTIONNAIRES_DELETE_FILE_RESET:
      return {};
    default:
      return state;
  }
};

export const questionnaryDeleteItemReducer = (state = {}, action) => {
  switch (action.type) {
    case QUESTIONNAIRES_DELETE_ITEM_REQUEST:
      return { loading: true };
    case QUESTIONNAIRES_DELETE_ITEM_SUCCESS:
      return { loading: false, deleteResult: action.payload };
    case QUESTIONNAIRES_DELETE_ITEM_FAIL:
      return { loading: false, error: action.payload };
    case QUESTIONNAIRES_DELETE_ITEM_RESET:
      return {};
    default:
      return state;
  }
};
