import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import "react-day-picker/lib/style.css";

//import components
import Header from "../../../layout/header/header.component";
import Loader from "../../../components/shared/loaders/spinner/sppinner.component";
import Alert from "../../../components/shared/alerts/material-alert/alert.component";
import Snackbar from "../../../components/shared/alerts/material-snack-bar/material-snack-bar.component";

//constant
import BREADCRUM_DATA from "../../../constants/breadcrumb.const";

//redux

import { useDispatch, useSelector } from "react-redux";
import { addLinks, listLabels } from "../../../redux/actions/searcher.actions";
import { logout } from "../../../redux/actions/user.actions";
import TagsComponent from "../../../components/shared/tags/tags.component";

const LinksAddPage = () => {
  const [name, setname] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [labelsSelected, setLabelsSelected] = useState([]);
  //console.log(labelsSelected);
  const [message, setMessage] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const linkAdd = useSelector((state) => state.linkAdd);
  const { loading, error, addResult } = linkAdd;

  const labelList = useSelector((state) => state.labelList);
  const { loading: loadingLabels, error: errorLabels, labels } = labelList;
  //console.log(userSelected);

  const filterLabels = (inputValue) => {
    return labels.filter((i) =>
      i.name.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseOptionsLabels = (inputValue) =>
    new Promise((resolve) => {
      resolve(filterLabels(inputValue));
    });

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else if (userInfo.role === "USUARIO") {
      dispatch(logout());
    } else {
      if (addResult) {
        navigate("/enlaces");
      }
      dispatch(listLabels());
    }
    /* if (addResult) {
      navigate("/empresas");
    } */
  }, [navigate, userInfo, dispatch, addResult]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name && description && url && labelsSelected.length > 0) {
      dispatch(addLinks(name, description, url, labelsSelected));
    } else {
      setMessage("Por favor llene todos los campos requeridos.");
    }
    //console.log(selectedDay.toISOString().slice(0, 10));
    //console.log(userSelected);
    //console.log(userCompanieSelected);
    //console.log(companieSelected);
    //console.log(name);
  };

  const handleAddTag = (e) => {
    const tag = labelsSelected.find((item) => item.id === e.id);
    if (!tag) {
      setLabelsSelected((labelsSelected) => [...labelsSelected, e]);
    }
  };

  const handleDeleteTag = (e, id) => {
    e.preventDefault();
    //console.log(id);
    const newTags = labelsSelected.filter((item) => item.id !== id);
    //console.log(newTags);
    setLabelsSelected(newTags);
  };

  return (
    <>
      {" "}
      <Header
        title="Crear Enlace"
        path="Crear Enlace"
        pathList={BREADCRUM_DATA.linksAdd}
      >
        {message && <Snackbar message="Por favor llene todos los campos." />}
        <button
          className="btn btn-success btn-icon float-right right_icon_toggle_btn"
          /* onClick={handleSubmit(onSubmit)} */
        >
          <i className="zmdi zmdi-check"></i>
        </button>
      </Header>
      {error && <Snackbar message={error} />}
      {loading && <Loader />}
      {errorLabels ? (
        <Alert message="Algo salió mal, por favor, intentelo más tarde." />
      ) : loadingLabels ? (
        <div className="text-center mt-5">
          <Loader />
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-md-12">
              <div className="card">
                <div className="header">
                  <h2>
                    Ingresar información del nuevo <strong>enlace</strong>
                  </h2>
                </div>
                <div className="body">
                  <div className="row clearfix">
                    <div className="col-lg-6 col-md-12 mt-3">
                      <h6>1. Ingresar un nombre para el enlace</h6>
                      <textarea
                        rows={3}
                        type="text"
                        className="form-control"
                        placeholder="Nombre"
                        value={name}
                        onChange={(e) => {
                          setname(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 mt-3">
                      <h6>2. Ingresar una descripción</h6>
                      <textarea
                        rows={3}
                        type="text"
                        className="form-control"
                        placeholder="Descripción"
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                      />
                    </div>

                    <div className="col-lg-12 col-md-12 mt-3">
                      <div className="header">
                        <h6>3. Ingrese una URL:</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="URL"
                          value={url}
                          onChange={(e) => {
                            setUrl(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 mt-3">
                      <h6>
                        4. Seleccionar una o varias etiquetas para el enlace
                      </h6>
                      <div className="form-group">
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          placeholder="Seleccione una opción"
                          loadOptions={promiseOptionsLabels}
                          getOptionLabel={(option) => `${option.name}`}
                          getOptionValue={(e) => e.id}
                          /*  onInputChange={(e) =>
                            setUserSelected(e.id ? e.id : "")
                          } */
                          onChange={(e) => handleAddTag(e)}
                        />
                      </div>
                    </div>
                    {labelsSelected.length === 0 ? (
                      <div></div>
                    ) : (
                      <div className="col-lg-12 col-md-12 mt-3">
                        <h6>Etiquetas Seleccionadas</h6>
                        <div className="form-group">
                          <TagsComponent
                            data={labelsSelected}
                            onDelete={handleDeleteTag}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <hr />
                  <div className="col-md-12">
                    <button className="btn btn-info" onClick={handleSubmit}>
                      <i className="zmdi zmdi-check mr-2"></i>
                      <strong style={{ fontSize: "16px" }}>Crear Enlace</strong>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LinksAddPage;
