const QUESTIONNAIRES_FILTER_OPTIONS = {
  filter: [
    {
      title: "Todas",
      action: "Todas",
    },
    {
      title: "Completados",
      action: "Completados",
    },
    {
      title: "Prioridad alta",
      action: "Prioridad alta",
    },
    {
      title: "Prioridad baja",
      action: "Prioridad baja",
    },
    {
      title: "Prioridad media",
      action: "Prioridad media",
    },
  ],
  sort: [
    {
      title: "Progreso",
      action: "progress",
    },

    {
      title: "Próximo a terminar",
      action: "deadline",
    },

    {
      title: "Supervisor",
      action: "supervisor",
    },

    {
      title: "Empresa",
      action: "company",
    },
  ],
};

export default QUESTIONNAIRES_FILTER_OPTIONS;
