import React from "react";

import LeftSideBar from "../left-side-bar/left-side-bar.component";
import RightSideBar from "../right-side-bar/right-side-bar.component";
//import Footer from "../footer/footer.component";

//import styles from "./skeleton.module.scss";
import { useSelector } from "react-redux";

const Skeleton = ({ children }) => {
  const navigation = useSelector((state) => state.navigation);
  const { sidebarOpened, rightSidebarOpened } = navigation;
  return (
    <div
      className={`theme-blush ${
        sidebarOpened === false ? "ls-toggle-menu" : ""
      } ${rightSidebarOpened === false ? "right_icon_toggle" : ""}`}
    >
      {/* <!-- Overlay For Sidebars --> */}
      <div className="overlay"></div>
      {/* MAIN SEARCH */}
      {/* <div id="search">
        <button
          id="close"
          type="button"
          className="close btn btn-primary btn-icon btn-icon-mini btn-round"
        >
          x
        </button>
        <form>
          <input type="search" value="" placeholder="Search..." />
          <button type="submit" className="btn btn-primary">
            Search
          </button>
        </form>
      </div> */}
      <LeftSideBar />
      <RightSideBar />
      <section className="content">
        <div className="body_scroll">{children}</div>
      </section>
    </div>
  );
};

export default Skeleton;
