import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useStateWithCallbackLazy } from "use-state-with-callback";
//import components
import Header from "../../layout/header/header.component";
//import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import SkeletonLoader from "../../components/shared/loaders/skeleton-loader/skeleton-loader.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";
import FilterListComponent from "../../components/shared/filters/filter-list/filter-list.component";
import PaginationComponent from "../../components/shared/pagination/pagination.component";
//import redux ;
import { useDispatch, useSelector } from "react-redux";
import { changeActiveSidebarItem } from "../../redux/actions/navigation.actions";
import {
  listRiskMaps,
  listRisksBySearching,
} from "../../redux/actions/risk-maps.actions";
import { RISK_MAPS_DETAILS_RESET } from "../../redux/constants/risk-maps.const";

//const
import RISK_MAPS_FILTER_OPTIONS from "../../constants/riskMapList.FilterOptions.const";

const RiskMapsListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [message] = useState(null);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  /*   const questionnaryAdd = useSelector((state) => state.questionnaryAdd);
  const { addResult } = questionnaryAdd; */
  const riskMapsList = useSelector((state) => state.riskMapsList);
  const { loading, error } = riskMapsList;

  const riskMapsListFilter = useSelector((state) => state.riskMapsListFilter);
  const { riskMaps } = riskMapsListFilter;

  const [tableDropdownOpen, setTableMenuOpen] = useState(false);
  const [tableDropdownOpenSort, setTableMenuOpenSort] = useState(false);
  const tableMenuOpen = () => {
    setTableMenuOpen(!tableDropdownOpen);
  };
  const tableSortMenuOpen = () => {
    setTableMenuOpenSort(!tableDropdownOpenSort);
  };

  const [secondTableCurrentPage, setSecondTableCurrentPage] = useState(0);
  const pageSize = 10;
  const secondTablePagesCount = riskMaps
    ? Math.ceil(riskMaps.length / pageSize)
    : 1;

  const setSecondTablePage = (e, index) => {
    e.preventDefault();
    setSecondTableCurrentPage(index);
  };

  const handleCambioEstado = (condition) => {};

  const handleSorting = (condition) => {};
  const [searchKey, setSearchKey] = useStateWithCallbackLazy("");
  const handleSearch = (codigo) => {
    setSearchKey(codigo, (current) => {
      dispatch(listRisksBySearching(current.trim()));
      setSecondTableCurrentPage(0);
    });
  };

  useEffect(() => {
    dispatch(changeActiveSidebarItem(location.pathname));
    if (!userInfo) {
      navigate("/login");
    } else {
      /*  if (addResult) {
        setMessage("CUESTIONARIO creado con éxito");
      } */

      dispatch({ type: RISK_MAPS_DETAILS_RESET });
      /*  dispatch({ type: QUESTIONNAIRES_DELETE_ITEM_RESET }); */
      dispatch(listRiskMaps());
    }
  }, [navigate, userInfo, dispatch, location]);
  return (
    <>
      <Header title="Lista de Mapas de Riesgo" path="Mapas de Riesgo">
        {/* {userInfo ? (
          userInfo.role !== "EMPRESA" ? (
            <Link
              to="/cuestionarios/agregar"
              className="btn btn-success btn-icon float-right "
            >
              <i className="zmdi zmdi-plus"></i>
            </Link>
          ) : null
        ) : null} */}
      </Header>
      {message && (
        <div className="mb-2 mt-2">
          <Alert message={message} severity="info" />
        </div>
      )}
      {error ? (
        <div className="mb-2 mt-2">
          <Alert message={error} />
        </div>
      ) : loading ? (
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <SkeletonLoader />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="card project_list">
                <div className="table-responsive">
                  <FilterListComponent
                    searchKey={searchKey}
                    handleSearch={handleSearch}
                    tableDropdownOpenSort={tableDropdownOpenSort}
                    tableSortMenuOpen={tableSortMenuOpen}
                    handleSorting={handleSorting}
                    tableDropdownOpen={tableDropdownOpen}
                    tableMenuOpen={tableMenuOpen}
                    handleCambioEstado={handleCambioEstado}
                    filterOptions={RISK_MAPS_FILTER_OPTIONS}
                  />
                  {riskMaps ? (
                    riskMaps.length === 0 ? (
                      <h5 className="text-center">
                        Todavía no existen mapas de riesgos registrados.
                      </h5>
                    ) : (
                      <>
                        {" "}
                        <table className="table table-hover c_table theme-color">
                          <thead>
                            <tr>
                              <th>Supervisor</th>
                              <th>Nombre</th>
                              <th>Empresa</th>
                              <th>Acción</th>
                            </tr>
                          </thead>

                          <tbody>
                            {riskMaps
                              .slice(
                                secondTableCurrentPage * pageSize,
                                (secondTableCurrentPage + 1) * pageSize
                              )
                              .map((riskMap, index) => (
                                <tr key={index}>
                                  <td>
                                    <Link
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="single-user-name"
                                      to={`/usuarios/${riskMap.user.id}`}
                                    >
                                      {riskMap.user.name}
                                      {" - "}
                                      {riskMap.user.lastname}
                                    </Link>
                                    <br />
                                    <small>{riskMap.user.email}</small>
                                  </td>
                                  <td>{riskMap.name}</td>
                                  <td>
                                    <Link
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="single-user-name"
                                      to={`/empresa/${
                                        riskMap.company && riskMap.company.id
                                      }`}
                                    >
                                      {riskMap.company && riskMap.company.name}
                                      {" - "}
                                      {riskMap.company && riskMap.company.ruc}
                                    </Link>
                                    <br />
                                    <small>
                                      {riskMap.company && riskMap.company.email}
                                    </small>
                                  </td>
                                  {/*  */}

                                  <td>
                                    <div className="icon-container">
                                      <>
                                        {/*   <Link
                                      to={`/mapa-de-riesgos/${riskMap.id}`}
                                      className="btn btn-info btn-sm"
                                      title="Ver Cuestionario"
                                    >
                                      <i className="zmdi zmdi-eye"></i>
                                    </Link> */}

                                        {/* <Link
                                        to={`/checklist/${questionnary.checklist.id}`}
                                        className="btn btn-warning btn-sm"
                                        title="Editar Checklist"
                                      >
                                        <i className="zmdi zmdi-edit"></i>
                                      </Link> */}

                                        <Link
                                          className="btn btn-primary btn-sm"
                                          title="Editar"
                                          to={`/mapa-de-riesgos/${riskMap.id}`}
                                        >
                                          <i className="zmdi zmdi-edit"></i>
                                        </Link>
                                        <Link
                                          className="btn btn-info btn-sm"
                                          title="Resultados"
                                          to={`/mapa-de-riesgos/resultados/${riskMap.id}`}
                                        >
                                          <i className="zmdi zmdi-chart"></i>
                                        </Link>
                                        <button
                                          className="btn btn-danger btn-sm"
                                          title="Eliminar"
                                          onClick={(e) => {}}
                                        >
                                          <i className="zmdi zmdi-delete"></i>
                                        </button>
                                      </>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        <div className="mt-4 text-center">
                          <PaginationComponent
                            tablecurrentPage={secondTableCurrentPage}
                            setTablecurrentPage={setSecondTablePage}
                            tablepagesCount={secondTablePagesCount}
                          />
                        </div>
                      </>
                    )
                  ) : (
                    <h5 className="text-center">
                      Todavía no existen mapas de riesgos registrados.
                    </h5>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RiskMapsListPage;
