import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import style from "./spinner.module.scss";

const Loader = ({ size = 60, color = "#c55b25" }) => {
  return (
    <ClipLoader
      className={style.r_loader}
      color={color}
      loading={true}
      css=""
      size={size}
    />
  );
};

export default Loader;
