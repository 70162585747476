import React from "react";

const FilesCard = ({
  file,
  hasDelete = false,
  handleDelete,
  isEditable,
  isWithBackground,
}) => {
  // console.log(file.createdAt);
  return (
    <div className="card" title={file.name}>
      <div
        className="file"
        style={{
          background: isWithBackground ? "rgba(236, 236, 236,0.4)" : null,
        }}
      >
        <div className="hover">
          {hasDelete && handleDelete && isEditable && (
            <button
              type="button"
              className="btn btn-icon btn-icon-mini btn-round btn-danger"
              onClick={(e) => handleDelete(e, file.id)}
            >
              <i className="zmdi zmdi-delete"></i>
            </button>
          )}
          <a
            style={{ color: "white", cursor: "pointer" }}
            href={file.url}
            target="_blank"
            rel="noreferrer"
            download
            className="btn btn-icon btn-icon-mini btn-round btn-success"
          >
            <i className="zmdi zmdi-download"></i>
          </a>
        </div>
        <div className="icon">
          {file.type.includes("xlsx") || file.type.includes("csv") ? (
            <i className="zmdi zmdi-chart pr-2"></i>
          ) : file.type.includes("document") ? (
            <i className="zmdi zmdi-file-text pr-2"></i>
          ) : file.type.includes("pdf") ? (
            <i className="zmdi zmdi-collection-pdf pr-2"></i>
          ) : file.type.includes("jpeg") | file.type.includes("png") ? (
            <i className="zmdi zmdi-collection-folder-image pr-2"></i>
          ) : (
            <i className="zmdi zmdi-file pr-2"></i>
          )}
        </div>
        <div className="file-name">
          <p className="m-b-5 text-muted">
            {file.name.length > 30 ? file.name.slice(0, 25) + "..." : file.name}
          </p>
          <small>
            Size: {file.size / 1000}KB{" "}
            <span className="date text-muted">
              {new Date(file.createdAt).toLocaleString("default", {
                month: "short",
              })}{" "}
              {new Date(file.createdAt).getDate()}
              {", "}
              {new Date(file.createdAt).toLocaleString("default", {
                year: "numeric",
              })}
            </span>
          </small>
        </div>
      </div>
    </div>
  );
};

export default FilesCard;
