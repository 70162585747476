export const STATES_COMPLAINT_LIST_REQUEST = "STATES_COMPLAINT_LIST_REQUEST";
export const STATES_COMPLAINT_LIST_SUCCESS = "STATES_COMPLAINT_LIST_SUCCESS";
export const STATES_COMPLAINT_LIST_FAIL = "STATES_COMPLAINT_LIST_FAIL";
export const STATES_COMPLAINT_LIST_RESET = "STATES_COMPLAINT_LIST_RESET";

export const STATES_COMPLAINT_DETAILS_REQUEST = "STATES_COMPLAINT_DETAILS_REQUEST";
export const STATES_COMPLAINT_DETAILS_SUCCESS = "STATES_COMPLAINT_DETAILS_SUCCESS";
export const STATES_COMPLAINT_DETAILS_FAIL = "STATES_COMPLAINT_DETAILS_FAIL";
export const STATES_COMPLAINT_DETAILS_RESET = "STATES_COMPLAINT_DETAILS_RESET";

export const STATES_COMPLAINT_DELETE_REQUEST = "STATES_COMPLAINT_DELETE_REQUEST";
export const STATES_COMPLAINT_DELETE_SUCCESS = "STATES_COMPLAINT_DELETE_SUCCESS";
export const STATES_COMPLAINT_DELETE_FAIL = "STATES_COMPLAINT_DELETE_FAIL";
export const STATES_COMPLAINT_DELETE_RESET = "STATES_COMPLAINT_DELETE_FAIL";

export const STATES_COMPLAINT_UPDATE_REQUEST = "STATES_COMPLAINT_UPDATE_REQUEST";
export const STATES_COMPLAINT_UPDATE_SUCCESS = "STATES_COMPLAINT_UPDATE_SUCCESS";
export const STATES_COMPLAINT_UPDATE_FAIL = "STATES_COMPLAINT_UPDATE_FAIL";
export const STATES_COMPLAINT_UPDATE_RESET = "STATES_COMPLAINT_UPDATE_RESET";

export const STATES_COMPLAINT_ADD_REQUEST = "STATES_COMPLAINT_ADD_REQUEST";
export const STATES_COMPLAINT_ADD_SUCCESS = "STATES_COMPLAINT_ADD_SUCCESS";
export const STATES_COMPLAINT_ADD_FAIL = "STATES_COMPLAINT_ADD_FAIL";
export const STATES_COMPLAINT_ADD_RESET = "STATES_COMPLAINT_ADD_RESET";
