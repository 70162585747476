import React from "react";
import { Link, useNavigate } from "react-router-dom";

//components
import Searcher from "../../components/searcher/searcher-pop-up";
import NotificationPopUp from "../../components/shared/alerts/alert-toggle/alert-toggle.component";

import { logout } from "../../redux/actions/user.actions";
import { useDispatch } from "react-redux";

import styles from "./right-side-bar.module.scss";

const RightSideBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutHandler = () => {
    if (window.confirm("Cerar Sesión ?")) {
      dispatch(logout());
      navigate("/login")
    }
  };
  return (
    <div className="navbar-right">
      <ul className="navbar-nav">



        
        <li className="dropdown">
          <button
            className={`dropdown-toggle ${styles.r_mega_menu}`}
            title="Notifications"
            data-toggle="dropdown"
          >
            <i
              className={`zmdi zmdi-notifications ${styles.r_mega_menu_icon}`}
            ></i>
            <div className="notify">
              <span className="heartbit"></span>
              <span className="point"></span>
            </div>
          </button>
          <ul className="dropdown-menu slideUp2">
            <li className="header">Alertas</li>
            <NotificationPopUp />
            <li className="footer">
              <Link to="/alertas">Ver todas las alertas</Link>
            </li>
          </ul>
        </li>



        <li className="dropdown">
          <button
            className={`dropdown-toggle ${styles.r_mega_menu}`}
            data-toggle="dropdown"
          >
            <i
              className={`zmdi zmdi-search-in-file ${styles.r_mega_menu_icon}`}
            ></i>
            <div className="notify">
              <span className="heartbit"></span>
              <span className="point"></span>
            </div>
          </button>
          <ul className="dropdown-menu slideUp2">
            <li className="header">Buscador</li>
            <li className="body">
              <ul className="menu tasks list-unstyled">
                <Searcher />
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <button
            className={styles.r_mega_menu}
            title="Cerrar Sesión"
            onClick={logoutHandler}
          >
            <i className={`zmdi zmdi-power ${styles.r_mega_menu_icon}`}></i>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default RightSideBar;
