import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useStateWithCallbackLazy } from "use-state-with-callback";

//import components
import Header from "../../../layout/header/header.component";
import Loader from "../../../components/shared/loaders/spinner/sppinner.component";
import SkeletonLoader from "../../../components/shared/loaders/skeleton-loader/skeleton-loader.component";
import Alert from "../../../components/shared/alerts/material-alert/alert.component";
import FilterListComponent from "../../../components/shared/filters/filter-list/filter-list.component";
import PaginationComponent from "../../../components/shared/pagination/pagination.component";

//import redux ;
import { useDispatch, useSelector } from "react-redux";
import { changeActiveSidebarItem } from "../../../redux/actions/navigation.actions";
import {
  listConducts,
  listConductsBySearching,
  deleteConduct as deleteConductAction,
} from "../../../redux/actions/conducts.actions";
import {
  CONDUCTS_ADD_RESET,
  CONDUCTS_UPDATE_RESET,
  CONDUCTS_DELETE_RESET,
  CONDUCTS_DETAILS_RESET,
} from "../../../redux/constants/conducts.const";

//const
import CONDUCTS_FILTER_OPTIONS from "../../../constants/conductsFilterOptions";
import BREADCRUMS from "../../../constants/breadcrumb.const";

const ConductsListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState(null);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const conductAdd = useSelector((state) => state.conductAdd);
  const { addResult } = conductAdd;
  const conductList = useSelector((state) => state.conductList);
  const { loading, error } = conductList;
  const conductListFiltered = useSelector((state) => state.conductListFiltered);
  const { conducts } = conductListFiltered;

  const conductDelete = useSelector((state) => state.conductDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    deleteConductResult,
  } = conductDelete;

  const [tableDropdownOpen, setTableMenuOpen] = useState(false);
  const [tableDropdownOpenSort, setTableMenuOpenSort] = useState(false);
  const tableMenuOpen = () => {
    setTableMenuOpen(!tableDropdownOpen);
  };
  const tableSortMenuOpen = () => {
    setTableMenuOpenSort(!tableDropdownOpenSort);
  };

  const [secondTableCurrentPage, setSecondTableCurrentPage] = useState(0);
  const pageSize = 10;
  const secondTablePagesCount = conducts
    ? Math.ceil(conducts.length / pageSize)
    : 1;

  const setSecondTablePage = (e, index) => {
    e.preventDefault();
    setSecondTableCurrentPage(index);
  };

  const handleCambioEstado = (condition) => {
    //dispatch(listQuestionnairesByCondition(condition));
    setSecondTableCurrentPage(0);
  };

  const handleSorting = (condition) => {
    //dispatch(listQuestionnairesBySorting(condition));
    setSecondTableCurrentPage(0);
  };
  const [searchKey, setSearchKey] = useStateWithCallbackLazy("");
  const handleSearch = (codigo) => {
    setSearchKey(codigo, (current) => {
      dispatch(listConductsBySearching(current.trim()));
      setSecondTableCurrentPage(0);
    });
  };

  useEffect(() => {
    dispatch(changeActiveSidebarItem(location.pathname));
    if (!userInfo) {
      navigate("/login");
    } else {
      if (addResult) {
        setMessage("Conducta creada con éxito");
      }

      dispatch({ type: CONDUCTS_DETAILS_RESET });
      dispatch({ type: CONDUCTS_UPDATE_RESET });
      dispatch({ type: CONDUCTS_ADD_RESET });
      dispatch({ type: CONDUCTS_DELETE_RESET });
      /*  dispatch({ type: QUESTIONNAIRES_DELETE_ITEM_RESET }); */
      dispatch(listConducts());
    }
  }, [navigate, userInfo, dispatch, location, addResult]);

  const handleDelete = (e, id) => {
    e.preventDefault();
    if (window.confirm("Esta acción no se puede deshacer.")) {
      dispatch(deleteConductAction(id));
    }
  };
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <>
      <button
        onClick={() => {
          handleGoBack();
        }}
        title="Regresar"
        className="btn mt-3   btn-icon float-left"
      >
        <i className="zmdi zmdi-arrow-left"></i>
      </button>
      <Header
        title="Lista de Conductas Generales"
        path="Lista de Conductas Generales"
        pathList={BREADCRUMS.conductList}
      >
        {userInfo ? (
          userInfo.role !== "EMPRESA" ? (
            <Link
              to="/mapa-de-riesgos/conductas/agregar"
              className="btn btn-success btn-icon float-right "
            >
              <i className="zmdi zmdi-plus"></i>
            </Link>
          ) : null
        ) : null}
      </Header>
      {errorDelete && (
        <div className="mb-2 mt-2">
          <Alert message={errorDelete} />
        </div>
      )}
      {loadingDelete && (
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card text-center">
                <Loader />
              </div>
            </div>
          </div>
        </div>
      )}
      {deleteConductResult && (
        <div className="mb-2 mt-2">
          <Alert message="Conducta eliminada exitosamente" severity="info" />
        </div>
      )}
      {message && (
        <div className="mb-2 mt-2">
          <Alert message={message} severity="info" />
        </div>
      )}
      {error ? (
        <div className="mb-2 mt-2">
          <Alert message={error} />
        </div>
      ) : loading ? (
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <SkeletonLoader />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="card project_list">
                <div className="table-responsive">
                  <FilterListComponent
                    searchKey={searchKey}
                    handleSearch={handleSearch}
                    tableDropdownOpenSort={tableDropdownOpenSort}
                    tableSortMenuOpen={tableSortMenuOpen}
                    handleSorting={handleSorting}
                    tableDropdownOpen={tableDropdownOpen}
                    tableMenuOpen={tableMenuOpen}
                    handleCambioEstado={handleCambioEstado}
                    filterOptions={CONDUCTS_FILTER_OPTIONS}
                  />

                  {conducts && conducts.length === 0 ? (
                    <h5 className="text-center">
                      No se ha encontrado conductas.
                    </h5>
                  ) : (
                    <>
                      <table className="table table-hover c_table theme-color">
                        <thead>
                          <tr>
                            <th>Tipología</th>
                            <th>Artículo</th>
                            <th>Tipo</th>
                            <th>Nombre</th>

                            <th>Acción</th>
                          </tr>
                        </thead>

                        <tbody>
                          {conducts && conducts.length === 0 ? (
                            <h5 className="text-center">
                              No se ha encontrado conductas.
                            </h5>
                          ) : (
                            conducts &&
                            conducts
                              .slice(
                                secondTableCurrentPage * pageSize,
                                (secondTableCurrentPage + 1) * pageSize
                              )
                              .map((conduct, index) => (
                                <tr key={index}>
                                  <div>
                                    {conduct.typology
                                      ? conduct.typology.name
                                      : "---"}
                                  </div>
                                  <td>{conduct.article && conduct.article}</td>
                                  <td>
                                    {conduct.type ? conduct.type.name : "--"}
                                  </td>

                                  <div>{conduct.name}</div>

                                  <td>
                                    <div className="icon-container">
                                      {/* <Link
                                        to={`/condutas/${conduct.id}`}
                                        className="btn btn-warning btn-sm"
                                        title="Editar"
                                      >
                                        <i className="zmdi zmdi-edit"></i>
                                      </Link> */}
                                      <button
                                        className="btn btn-danger btn-sm"
                                        title="Eliminar"
                                        onClick={(e) => {
                                          handleDelete(e, conduct.id);
                                        }}
                                      >
                                        <i className="zmdi zmdi-delete"></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))
                          )}
                        </tbody>
                      </table>
                      <div className="mt-4 text-center">
                        <PaginationComponent
                          tablecurrentPage={secondTableCurrentPage}
                          setTablecurrentPage={setSecondTablePage}
                          tablepagesCount={secondTablePagesCount}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConductsListPage;
