export const CATEGORIES_COMPANIES_LIST_REQUEST = "CATEGORIES_COMPANIES_LIST_REQUEST";
export const CATEGORIES_COMPANIES_LIST_SUCCESS = "CATEGORIES_COMPANIES_LIST_SUCCESS";
export const CATEGORIES_COMPANIES_LIST_FAIL = "CATEGORIES_COMPANIES_LIST_FAIL";
export const CATEGORIES_COMPANIES_LIST_RESET = "CATEGORIES_COMPANIES_LIST_RESET";

export const CATEGORIES_COMPANIES_DETAILS_REQUEST = "CATEGORIES_COMPANIES_DETAILS_REQUEST";
export const CATEGORIES_COMPANIES_DETAILS_SUCCESS = "CATEGORIES_COMPANIES_DETAILS_SUCCESS";
export const CATEGORIES_COMPANIES_DETAILS_FAIL = "CATEGORIES_COMPANIES_DETAILS_FAIL";
export const CATEGORIES_COMPANIES_DETAILS_RESET = "CATEGORIES_COMPANIES_DETAILS_RESET";

export const CATEGORIES_COMPANIES_DELETE_REQUEST = "CATEGORIES_COMPANIES_DELETE_REQUEST";
export const CATEGORIES_COMPANIES_DELETE_SUCCESS = "CATEGORIES_COMPANIES_DELETE_SUCCESS";
export const CATEGORIES_COMPANIES_DELETE_FAIL = "CATEGORIES_COMPANIES_DELETE_FAIL";
export const CATEGORIES_COMPANIES_DELETE_RESET = "CATEGORIES_COMPANIES_DELETE_FAIL";

export const CATEGORIES_COMPANIES_UPDATE_REQUEST = "CATEGORIES_COMPANIES_UPDATE_REQUEST";
export const CATEGORIES_COMPANIES_UPDATE_SUCCESS = "CATEGORIES_COMPANIES_UPDATE_SUCCESS";
export const CATEGORIES_COMPANIES_UPDATE_FAIL = "CATEGORIES_COMPANIES_UPDATE_FAIL";
export const CATEGORIES_COMPANIES_UPDATE_RESET = "CATEGORIES_COMPANIES_UPDATE_RESET";

export const CATEGORIES_COMPANIES_ADD_REQUEST = "CATEGORIES_COMPANIES_ADD_REQUEST";
export const CATEGORIES_COMPANIES_ADD_SUCCESS = "CATEGORIES_COMPANIES_ADD_SUCCESS";
export const CATEGORIES_COMPANIES_ADD_FAIL = "CATEGORIES_COMPANIES_ADD_FAIL";
export const CATEGORIES_COMPANIES_ADD_RESET = "CATEGORIES_COMPANIES_ADD_RESET";
