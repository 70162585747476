import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

//import components
import Header from "../../layout/header/header.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import SkeletonLoader from "../../components/shared/loaders/skeleton-loader/skeleton-loader.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";

//import redux ;
import { useDispatch, useSelector } from "react-redux";
import {
  listUsers,
  deleteUser as deleteUserAction,
} from "../../redux/actions/user.actions";
import { changeActiveSidebarItem } from "../../redux/actions/navigation.actions";
import {
  USER_DELETE_RESET,
  USER_REGISTER_RESET,
  USER_UPDATE_ADMIN_RESET,
  USER_DETAILS_RESET,
} from "../../redux/constants/user.const";

const UsersListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState(null);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const usersList = useSelector((state) => state.userList);
  const { loading, error, users } = usersList;
  const userDelete = useSelector((state) => state.userDelete);
  const userRegister = useSelector((state) => state.userRegister);
  const { register: registerResult } = userRegister;
  const userUpdateAdmin = useSelector((state) => state.userUpdateAdmin);
  const { userUpdateResult } = userUpdateAdmin;
  const { loading: loadingDelete, error: errorDelete, deleteUser } = userDelete;

  useEffect(() => {
    dispatch(changeActiveSidebarItem(location.pathname));
    if (!userInfo) {
      navigate("/login");
    } else {
      if (registerResult) {
        setMessage("Usuario creado con éxito");
      }
      if (userUpdateResult) {
        setMessage("Usuario actualizado con éxito");
      }
      dispatch({ type: USER_REGISTER_RESET });
      dispatch({ type: USER_UPDATE_ADMIN_RESET });
      dispatch({ type: USER_DELETE_RESET });
      dispatch({ type: USER_DETAILS_RESET });
      dispatch(listUsers());
    }
  }, [
    navigate,
    userInfo,
    dispatch,
    registerResult,
    userUpdateResult,
    location,
  ]);

  const handleDelete = (e, id) => {
    e.preventDefault();
    if (window.confirm("Esta acción no se puede deshacer.")) {
      dispatch(deleteUserAction(id));
    }
  };

  return (
    <>
      <Header title="Lista de Usuarios" path="Lista de Usuarios">
        <Link
          to="/usuarios/agregar"
          className="btn btn-success btn-icon float-right "
        >
          <i className="zmdi zmdi-plus"></i>
        </Link>
      </Header>
      {errorDelete && (
        <div className="mb-2 mt-2">
          <Alert message={errorDelete} />
        </div>
      )}
      {loadingDelete && (
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card text-center">
                <Loader />
              </div>
            </div>
          </div>
        </div>
      )}

      {deleteUser && (
        <div className="mb-2 mt-2">
          <Alert message="Usuario eliminado exitrosamente" severity="info" />
        </div>
      )}
      {message && (
        <div className="mb-2 mt-2">
          <Alert message={message} severity="info" />
        </div>
      )}
      {error ? (
        <div className="mb-2 mt-2">
          <Alert message={error} />
        </div>
      ) : loading ? (
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <SkeletonLoader />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-hover c_table theme-color">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Nombre</th>
                        <th>Correo</th>
                        <th>Teléfono</th>
                        <th>Rol</th>
                        <th>Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.length === 0 ? (
                        <h4>Todavía no se tienen usuarios registrados.</h4>
                      ) : (
                        users.map((user, index) => (
                          <tr key={index}>
                            <td>{user.id}</td>
                            <td>
                              <strong>{user.name}</strong>
                            </td>
                            <td>{user.email} </td>
                            <td>{user.phone}</td>
                            <td>{user.role.name} </td>
                            <td>
                              <Link
                                to={`/usuarios/${user.id}`}
                                className="btn btn-warning btn-sm"
                                title="Editar"
                              >
                                <i className="zmdi zmdi-edit"></i>
                              </Link>
                              <button
                                className="btn btn-danger btn-sm"
                                title="Eliminar"
                                onClick={(e) => {
                                  handleDelete(e, user.id);
                                }}
                              >
                                <i className="zmdi zmdi-delete"></i>
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UsersListPage;
