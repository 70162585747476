import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PDFViewer, Document, Page, View, Text, StyleSheet } from "@react-pdf/renderer";

//import components
import Header from "../../layout/header/header.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";

//redux import
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/user.actions";
import { getRiskMapReport } from "../../redux/actions/risk-maps.actions";

//constant
import BREADCRUM_DATA from "../../constants/breadcrumb.const";

const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'none'

const styles = StyleSheet.create({
  body: {
    paddingVertical: 20,
    paddingHorizontal: 40
  },
  table: { 
    display: "table", 
    width: "auto", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 0, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 
  }, 
  tableRow: { 
    margin: "auto", 
    flexDirection: "row",
  },
  tableHeader20: { 
    width: '25%',
    borderWidth: 1,
    borderTop: 0,
    borderRight: 0,
  },
  tableHeader25: { 
    width: '30%',
    borderWidth: 1,
    borderRight: 0,
    borderTop: 0,
  },
  tableHeader33: { 
    width: '33.333%',
    borderWidth: 1,
    borderBottom: 0,
    borderRight: 0,
  },
  tableHeader75: { 
    width: '70%',
    borderWidth: 1,
    borderTop: 0,
  },
  tableHeader50: { 
    width: '50%',
    borderWidth: 0, 
    borderLeftWidth: 0, 
    borderTopWidth: 0,
  },
  tableHeader60: { 
    width: '50%',
    borderWidth: 1, 
    borderRightWidth: 0,
    borderTop: 0,
  },
  tableHeader100: { 
    width: '100%',
    borderWidth: 0, 
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5, 
    fontSize: 10,
  },  
  tableCell: { 
    margin: 5, 
    fontSize: 10 
  }
});

const RiskMapsReportPage = () => {
	const navigate = useNavigate();
	const params = useParams();
	const dispatch = useDispatch();

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const riskMapReport = useSelector((state) => state.riskMapReport);

	const { loading, error, report } = riskMapReport;

	const handleBack = () => {
		navigate(-1);
	};

	useEffect(() => {
		if (!userInfo) {
			navigate("/login");
		} else if (userInfo.role === "USUARIO") {
			dispatch(logout());
		} else {
			dispatch(getRiskMapReport(params.id));
		}
	}, [navigate, userInfo, dispatch, params]);

  const getColor = (type) => {
    let color = '';
    switch (type) {
      case 'FUERA DE RANGO':
        color = '#C62828';
        break;
      case 'ALTO':
        color = '#EF6C00';
        break;
      case 'MEDIO':
        color = '#FF8F00';
        break;
      case 'BAJO':
        color = '#2E7D32';
        break;
      case 'MINIMO':
        color = '#01579B';
        break;
      case 'FUERA DE RIESGO':
        color = '#424242';
        break;
      default:
        color = '#000';
        break;
    }
    return color;
  }

	return (
		<>
			<button
				onClick={() => {
					handleBack();
				}}
				className="btn mt-3 btn-icon float-left "
			>
				<i className="zmdi zmdi-arrow-left"></i>
			</button>
			<Header
				title="Reporte"
				path="Reporte"
				pathList={BREADCRUM_DATA.riskMapReport}
			></Header>
			<div className="container-fluid" style={{ minHeight: "83vh" }}>
				<div className="row clearfix">
					<div className="col-md-12">
						{loading ? (
							<div className="text-center mt-4">
								<Loader />
							</div>
						) : error ? (
							<Alert message={error} />
						) : (
							report && (
								<PDFViewer
									style={{ width: "100%", height: "82vh" }}
								>
									<Document>
										<Page size={`A4`} style={styles.body}>
                      <View style={styles.table}>
                        {/* Date */}
                        <View style={{...styles.tableRow, borderWidth: 1}}> 
                          <View style={styles.tableHeader50}> 
                            <Text style={styles.tableCellHeader}>Reporte de riesgos encontrados</Text> 
                          </View>
                          <View style={styles.tableHeader50}> 
                            <Text style={styles.tableCellHeader}>{new Date(report.currentDate).toLocaleDateString()}</Text> 
                          </View>
                        </View>
                        {/* Company Name */}
                        <View style={{...styles.tableRow, paddingTop: 20}}> 
                          <View style={styles.tableHeader50}> 
                            <Text style={styles.tableCellHeader}>Nombre de la empresa</Text> 
                          </View>
                          <View style={styles.tableHeader50}> 
                            <Text style={styles.tableCellHeader}>{report.company.name}</Text> 
                          </View>
                        </View>
                        {/* Company RUC */}
                        <View style={styles.tableRow}> 
                          <View style={styles.tableHeader50}> 
                            <Text style={styles.tableCellHeader}>Identificación de la compañía</Text> 
                          </View>
                          <View style={styles.tableHeader50}> 
                            <Text style={styles.tableCellHeader}>{report.company.ruc}</Text> 
                          </View>
                        </View>
                        {/* Company Supervisor */}
                        <View style={styles.tableRow}> 
                          <View style={styles.tableHeader50}> 
                            <Text style={styles.tableCellHeader}>Nombre Compliance Office</Text> 
                          </View>
                          <View style={styles.tableHeader50}> 
                            <Text style={styles.tableCellHeader}>{report.supervisor.name} {report.supervisor.lastname}</Text> 
                          </View>
                        </View>
                      </View>
                      {/* Riesgos encontrados en el periodo */}
                      <View style={{...styles.table, paddingTop: 20}}>
                        <View style={{...styles.tableRow, borderWidth: 1}}>
                          <View style={styles.tableHeader100}> 
                            <Text style={{...styles.tableCellHeader, textAlign: 'center', textTransform: 'uppercase'}}>Riesgos encontrados en el periodo</Text> 
                          </View>
                        </View>
                        <View> 
                          {Object.keys(report.foundRisks).map((item) => 
                            <>
                              <View style={{...styles.tableHeader100}}> 
                                <Text style={{...styles.tableCell, color: getColor(item), fontSize: 12 }}>{item}</Text>
                              </View>
                              <View>
                                { !report.foundRisks[item]?.length ?
                                  <Text style={styles.tableCell}>No hay riesgos en esta categoría</Text> :
                                  report.foundRisks[item].map((risk) =>
                                  <>
                                    {/* Typology */}
                                    <View style={{...styles.tableRow, paddingTop: 10}}> 
                                      <View style={{...styles.tableHeader25, borderTop: 1}}> 
                                        <Text style={styles.tableCellHeader}>Tipología</Text> 
                                      </View>
                                      <View style={{...styles.tableHeader75, borderTop: 1}}> 
                                        <Text style={styles.tableCellHeader}>{risk.conduct.typology.name}</Text> 
                                      </View>
                                    </View>
                                    {/* Conduct name */}
                                    <View style={styles.tableRow}> 
                                      <View style={styles.tableHeader25}> 
                                        <Text style={styles.tableCellHeader}>Conducta</Text> 
                                      </View>
                                      <View style={styles.tableHeader75}> 
                                        <Text style={styles.tableCellHeader}>{risk.conduct.name}</Text> 
                                      </View>
                                    </View>
                                    {/* Article */}
                                    <View style={styles.tableRow}> 
                                      <View style={styles.tableHeader25}> 
                                        <Text style={styles.tableCellHeader}>Artìculo</Text> 
                                      </View>
                                      <View style={styles.tableHeader75}> 
                                        <Text style={styles.tableCellHeader}>{risk.conduct.article}</Text> 
                                      </View>
                                    </View>
                                    {/* Sum */}
                                    <View style={styles.tableRow}> 
                                      <View style={styles.tableHeader25}> 
                                        <Text style={styles.tableCellHeader}>Sumatoria</Text> 
                                      </View>
                                      <View style={styles.tableHeader75}> 
                                        <Text style={styles.tableCellHeader}>{risk.sum}</Text> 
                                      </View>
                                    </View>
                                    {/* Avg */}
                                    <View style={styles.tableRow}> 
                                      <View style={styles.tableHeader25}> 
                                        <Text style={styles.tableCellHeader}>Promedio</Text> 
                                      </View>
                                      <View style={styles.tableHeader75}> 
                                        <Text style={styles.tableCellHeader}>{risk.average}</Text> 
                                      </View>
                                    </View>
                                    {/* Round */}
                                    <View style={styles.tableRow}> 
                                      <View style={styles.tableHeader25}> 
                                        <Text style={styles.tableCellHeader}>Redondeo</Text> 
                                      </View>
                                      <View style={styles.tableHeader75}> 
                                        <Text style={styles.tableCellHeader}>{risk.round}</Text> 
                                      </View>
                                    </View>
                                    {/* RECOMENDACIONES, PAUTAS, INSTRUMENTOS APLICADOS A LA FECHA DEL REPORTE (UTILIZACIÓN DE INSTRUMENTOS TÉCNICOS, JURÍDICOS, ENTRE OTROS) */}
                                    {
                                      (risk.analysis || risk.recommendation || risk.plan) &&
                                      <View style={{...styles.tableRow, borderWidth: 1, borderTop: 0, paddingHorizontal: 30}}>
                                        <View style={styles.tableHeader100}> 
                                          <Text style={{...styles.tableCellHeader, textAlign: 'center', textTransform: 'uppercase'}}>RECOMENDACIONES, PAUTAS, INSTRUMENTOS APLICADOS A LA FECHA DEL REPORTE (UTILIZACIÓN DE INSTRUMENTOS TÉCNICOS, JURÍDICOS, ENTRE OTROS)</Text> 
                                        </View>
                                      </View>
                                    }

                                    {/* Análisis */}
                                    {
                                      risk.analysis &&
                                      <View style={styles.tableRow}> 
                                        <View style={styles.tableHeader25}> 
                                          <Text style={styles.tableCellHeader}>Análisis</Text> 
                                        </View>
                                        <View style={styles.tableHeader75}> 
                                          <Text style={styles.tableCellHeader}>{risk.analysis}</Text> 
                                        </View>
                                      </View>
                                    }
                                    {/* recommendation */}
                                    {
                                      risk.recommendation &&
                                      <View style={styles.tableRow}> 
                                        <View style={styles.tableHeader25}> 
                                          <Text style={styles.tableCellHeader}>Recomendación</Text> 
                                        </View>
                                        <View style={styles.tableHeader75}> 
                                          <Text style={styles.tableCellHeader}>{risk.recommendation}</Text> 
                                        </View>
                                      </View>
                                    }
                                    {/* control */}
                                    {
                                      risk.plan &&
                                      <View style={styles.tableRow}> 
                                        <View style={styles.tableHeader25}> 
                                          <Text style={styles.tableCellHeader}>Plan de Control</Text> 
                                        </View>
                                        <View style={styles.tableHeader75}> 
                                          <Text style={styles.tableCellHeader}>{risk.plan.existingControl}</Text> 
                                        </View>
                                      </View>
                                    }

                                    {/* Reconocimiento de novedades durante el periodo */}
                                    {
                                      risk.plan &&                
                                      <View style={{...styles.table}}>
                                        <View style={{...styles.tableRow, borderWidth: 1, borderTop: 0}}>
                                          <View style={styles.tableHeader100}> 
                                            <Text style={{...styles.tableCellHeader, textAlign: 'center', textTransform: 'uppercase'}}>Reconocimiento de novedades durante el periodo</Text> 
                                          </View>
                                        </View>
                                        <View style={styles.tableRow}> 
                                          <View style={styles.tableHeader25}> 
                                            <Text style={styles.tableCellHeader}>Rendimiento de evaluación</Text> 
                                          </View>
                                          <View style={styles.tableHeader75}> 
                                            <Text style={styles.tableCellHeader}>{risk.plan.valuationPerformance}</Text> 
                                          </View>
                                        </View>
                                        <View style={styles.tableRow}> 
                                          <View style={styles.tableHeader25}> 
                                            <Text style={styles.tableCellHeader}>Efectividad de evaluación</Text> 
                                          </View>
                                          <View style={styles.tableHeader75}> 
                                            <Text style={styles.tableCellHeader}>{risk.plan.effectivenessEvaluation}</Text> 
                                          </View>
                                        </View>
                                        <View style={styles.tableRow}> 
                                          <View style={styles.tableHeader25}> 
                                            <Text style={styles.tableCellHeader}>Vulnerabilidad</Text> 
                                          </View>
                                          <View style={styles.tableHeader75}> 
                                            <Text style={styles.tableCellHeader}>{risk.plan.vulnerability}</Text> 
                                          </View>
                                        </View>
                                      </View>
                                    }

                                    {/* Mapa de prioridades */}
                                    {
                                      risk.plan &&                                      
                                      <View style={{...styles.table}}>
                                        <View style={{...styles.tableRow, borderWidth: 1, borderTop: 0}}>
                                          <View style={styles.tableHeader100}> 
                                            <Text style={{...styles.tableCellHeader, textAlign: 'center', textTransform: 'uppercase'}}>Mapa de prioridades</Text> 
                                          </View>
                                        </View>
                                        <View style={styles.tableRow}> 
                                          <View style={{...styles.tableHeader60, textAlign: 'center'}}> 
                                            <Text style={styles.tableCellHeader}>Tareas</Text> 
                                          </View>
                                          <View style={{...styles.tableHeader20, textAlign: 'center'}}> 
                                            <Text style={styles.tableCellHeader}>Responsable</Text> 
                                          </View>
                                          <View style={{...styles.tableHeader20, textAlign: 'center', borderRight: 1}}> 
                                            <Text style={styles.tableCellHeader}>Plazo máximo de mejoras</Text> 
                                          </View>
                                        </View>
                                        <View style={styles.tableRow}> 
                                          <View style={styles.tableHeader60}> 
                                            <Text style={styles.tableCellHeader}>{risk.plan.existingControl}</Text> 
                                          </View>
                                          <View style={{...styles.tableHeader20, textAlign: 'center'}}> 
                                            <Text style={styles.tableCellHeader}>{risk.plan.headControl}</Text> 
                                          </View>
                                          <View style={{...styles.tableHeader20, borderRight: 1, textAlign: 'center'}}> 
                                            <Text style={styles.tableCellHeader}>{new Date(risk.plan.implementationDate).toLocaleDateString()}</Text> 
                                          </View>
                                        </View>
                                      </View>
                                    }

                                    <View style={{paddingTop: 20}}/>
                                  </>
                                )}
                              </View>
                            </>
                          )}
                        </View>                      
                      </View>
                      {/* Ëstadísticas */}
                      <View style={{...styles.table, paddingTop: 20}}>
                        <View style={{...styles.tableRow, borderWidth: 1, borderBottom: 0}}>
                          <View style={styles.tableHeader100}> 
                            <Text style={{...styles.tableCellHeader, textAlign: 'center', textTransform: 'uppercase'}}>Estadística de riesgos del periodo</Text> 
                          </View>
                        </View>
                        <View style={{...styles.tableRow}}>
                          <View style={styles.tableHeader33}>
                            <Text style={{...styles.tableCellHeader, textAlign: 'center'}}>Tipo</Text> 
                          </View>
                          <View style={styles.tableHeader33}>
                            <Text style={{...styles.tableCellHeader, textAlign: 'center'}}>Porcentaje</Text> 
                          </View>
                          <View style={{...styles.tableHeader33, borderRight: 1}}>
                            <Text style={{...styles.tableCellHeader, textAlign: 'center'}}>Total</Text> 
                          </View>
                        </View>
                        {
                          report.statitics &&
                          <>
                            <View style={{...styles.tableRow}}>
                              <View style={styles.tableHeader33}>
                                <Text style={{...styles.tableCellHeader, textAlign: 'center', color: getColor('FUERA DE RIESGO')}}>Fuera de riesgo</Text> 
                              </View>
                              <View style={styles.tableHeader33}>
                                <Text style={{...styles.tableCellHeader, textAlign: 'center'}}>{report.statitics.fueraDeRiesgo.avg} %</Text> 
                              </View>
                              <View style={{...styles.tableHeader33, borderRight: 1}}>
                                <Text style={{...styles.tableCellHeader, textAlign: 'center'}}>{report.statitics.fueraDeRiesgo.total}/{report.statitics.total}</Text> 
                              </View>
                            </View>
                            <View style={{...styles.tableRow}}>
                              <View style={styles.tableHeader33}>
                                <Text style={{...styles.tableCellHeader, textAlign: 'center', color: getColor('MINIMO')}}>Mínimo</Text> 
                              </View>
                              <View style={styles.tableHeader33}>
                                <Text style={{...styles.tableCellHeader, textAlign: 'center'}}>{report.statitics.minimo.avg} %</Text> 
                              </View>
                              <View style={{...styles.tableHeader33, borderRight: 1}}>
                                <Text style={{...styles.tableCellHeader, textAlign: 'center'}}>{report.statitics.minimo.total}/{report.statitics.total}</Text> 
                              </View>
                            </View>
                            <View style={{...styles.tableRow}}>
                              <View style={styles.tableHeader33}>
                                <Text style={{...styles.tableCellHeader, textAlign: 'center', color: getColor('BAJO')}}>Bajo</Text> 
                              </View>
                              <View style={styles.tableHeader33}>
                                <Text style={{...styles.tableCellHeader, textAlign: 'center'}}>{report.statitics.bajo.avg} %</Text> 
                              </View>
                              <View style={{...styles.tableHeader33, borderRight: 1}}>
                                <Text style={{...styles.tableCellHeader, textAlign: 'center'}}>{report.statitics.bajo.total}/{report.statitics.total}</Text> 
                              </View>
                            </View>
                            <View style={{...styles.tableRow}}>
                              <View style={styles.tableHeader33}>
                                <Text style={{...styles.tableCellHeader, textAlign: 'center', color: getColor('MEDIO')}}>Medio</Text> 
                              </View>
                              <View style={styles.tableHeader33}>
                                <Text style={{...styles.tableCellHeader, textAlign: 'center'}}>{report.statitics.medio.avg} %</Text> 
                              </View>
                              <View style={{...styles.tableHeader33, borderRight: 1}}>
                                <Text style={{...styles.tableCellHeader, textAlign: 'center'}}>{report.statitics.medio.total}/{report.statitics.total}</Text> 
                              </View>
                            </View>
                            <View style={{...styles.tableRow}}>
                              <View style={styles.tableHeader33}>
                                <Text style={{...styles.tableCellHeader, textAlign: 'center', color: getColor('ALTO')}}>Alto</Text> 
                              </View>
                              <View style={styles.tableHeader33}>
                                <Text style={{...styles.tableCellHeader, textAlign: 'center'}}>{report.statitics.alto.avg} %</Text> 
                              </View>
                              <View style={{...styles.tableHeader33, borderRight: 1}}>
                                <Text style={{...styles.tableCellHeader, textAlign: 'center'}}>{report.statitics.alto.total}/{report.statitics.total}</Text> 
                              </View>
                            </View>
                            <View style={{...styles.tableRow}}>
                              <View style={styles.tableHeader33}>
                                <Text style={{...styles.tableCellHeader, textAlign: 'center', color: getColor('FUERA DE RANGO')}}>Fuera de Rango</Text> 
                              </View>
                              <View style={styles.tableHeader33}>
                                <Text style={{...styles.tableCellHeader, textAlign: 'center'}}>{report.statitics.fueraDeRango.avg} %</Text> 
                              </View>
                              <View style={{...styles.tableHeader33, borderRight: 1}}>
                                <Text style={{...styles.tableCellHeader, textAlign: 'center'}}>{report.statitics.fueraDeRango.total}/{report.statitics.total}</Text> 
                              </View>
                            </View>
                            <View style={{...styles.tableRow, borderBottom: 1}}>
                              <View style={styles.tableHeader33}>
                                <Text style={{...styles.tableCellHeader, textAlign: 'center'}}>No respondido</Text> 
                              </View>
                              <View style={styles.tableHeader33}>
                                <Text style={{...styles.tableCellHeader, textAlign: 'center'}}>{report.statitics.noRespondido.avg} %</Text> 
                              </View>
                              <View style={{...styles.tableHeader33, borderRight: 1}}>
                                <Text style={{...styles.tableCellHeader, textAlign: 'center'}}>{report.statitics.noRespondido.total}/{report.statitics.total}</Text> 
                              </View>
                            </View>
                          </>
                        }
                        {/* Denuncias */}
                        <View style={{...styles.table, paddingTop: 20}}>
                          {
                            report.complaints.length ? report.complaints.map((complaint, index) => 
                            <>
                              {
                                index === 0 &&
                                <View style={{...styles.tableRow, borderWidth: 1, borderBottom: 0}}>
                                  <View style={{...styles.tableHeader50, borderRight: 1}}> 
                                    <Text style={{...styles.tableCellHeader, textAlign: 'center', textTransform: 'uppercase'}}>Riesgos del canal de denuncia</Text> 
                                  </View>
                                  <View style={styles.tableHeader50}> 
                                    <Text style={{...styles.tableCellHeader, textAlign: 'center', textTransform: 'uppercase'}}>Acciones a seguir</Text> 
                                  </View>
                                </View>
                              }
                              <View style={{...styles.tableRow, borderWidth: 1}}>
                                <View style={{...styles.tableHeader50, borderRight: 1}}> 
                                  <Text style={{...styles.tableCellHeader}}>{complaint.subject}: {complaint.description}</Text> 
                                </View>
                                <View style={styles.tableHeader50}> 
                                  <Text style={{...styles.tableCellHeader}}></Text> 
                                </View>
                              </View>
                            </>
                            ): null
                            
                          }
                        </View>
                        {/* Firma */}
                        <View style={{...styles.table, paddingTop: 20}}>
                          <View style={{...styles.tableRow, borderWidth: 1}}>
                            <View style={styles.tableHeader100}> 
                              <Text style={{...styles.tableCellHeader, textAlign: 'center', textTransform: 'uppercase'}}>Firma de constancia</Text> 
                            </View>
                          </View>
                          <View style={{...styles.tableRow, border: 1, borderTop: 0, paddingVertical: 20}}>
                            <View style={styles.tableHeader100}> 
                              <Text style={{...styles.tableCellHeader, textAlign: 'center', textTransform: 'uppercase'}}></Text> 
                            </View>
                          </View>
                          <View style={{...styles.tableRow, borderWidth: 1, borderTop: 0}}>
                            <View style={styles.tableHeader100}> 
                              <Text style={styles.tableCellHeader}>NOMBRE:</Text> 
                            </View>
                          </View>
                          <View style={{...styles.tableRow, borderWidth: 1, borderTop: 0}}>
                            <View style={styles.tableHeader100}> 
                              <Text style={styles.tableCellHeader}>CI:</Text> 
                            </View>
                          </View>
                          <View style={{...styles.tableRow, borderWidth: 1, borderTop: 0}}>
                            <View style={styles.tableHeader100}> 
                              <Text style={styles.tableCellHeader}>MAT:</Text> 
                            </View>
                          </View>
                        </View>
                      </View>
										</Page>
									</Document>
								</PDFViewer>
							)
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default RiskMapsReportPage;
