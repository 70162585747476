import React from "react";
import { Link } from "react-router-dom";
// import styles from "./header.module.scss";

//redux import
import { useDispatch } from "react-redux";
import {
  openSidebarRight,
  openSidebarMobile,
} from "../../redux/actions/navigation.actions";

const Header = ({ title, path, pathList = null, children }) => {
  const dispatch = useDispatch();
  return (
    <div className="block-header">
      <div className="row">
        <div className="col-lg-7 col-md-6 col-sm-12">
          <h2>{title}</h2>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">
                <i className="zmdi zmdi-home"></i> Rigori
              </Link>
            </li>

            {pathList ? (
              <>
                {pathList.map((item, index) => (
                  <li key={index} className={`breadcrumb-item `}>
                    {item.link ? (
                      <Link to={item.link}>{item.title}</Link>
                    ) : (
                      item.title
                    )}
                  </li>
                ))}
              </>
            ) : (
              <li className="breadcrumb-item ">{path}</li>
            )}
          </ul>
          <button
            className="btn btn-primary btn-icon mobile_menu"
            onClick={() => dispatch(openSidebarMobile())}
            type="button"
          >
            <i className="zmdi zmdi-sort-amount-desc"></i>
          </button>
        </div>

        <div className="col-lg-5 col-md-6 col-sm-12">
          <button
            className="btn btn-primary btn-icon float-right right_icon_toggle_btn"
            onClick={() => dispatch(openSidebarRight())}
            type="button"
          >
            <i className="zmdi zmdi-arrow-right"></i>
          </button>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Header;
