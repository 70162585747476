export const RISK_MAPS_LIST_REQUEST = "RISK_MAPS_LIST_REQUEST";
export const RISK_MAPS_LIST_SUCCESS = "RISK_MAPS_LIST_SUCCESS";
export const RISK_MAPS_LIST_FAIL = "RISK_MAPS_LIST_FAIL";
export const RISK_MAPS_LIST_RESET = "RISK_MAPS_LIST_RESET";

export const RISK_MAPS_DETAILS_REQUEST = "RISK_MAPS_DETAILS_REQUEST";
export const RISK_MAPS_DETAILS_SUCCESS = "RISK_MAPS_DETAILS_SUCCESS";
export const RISK_MAPS_DETAILS_FAIL = "RISK_MAPS_DETAILS_FAIL";
export const RISK_MAPS_DETAILS_RESET = "RISK_MAPS_DETAILS_RESET";

export const RISK_MAPS_RESPONSE_REQUEST = "RISK_MAPS_RESPONSE_REQUEST";
export const RISK_MAPS_RESPONSE_SUCCESS = "RISK_MAPS_RESPONSE_SUCCESS";
export const RISK_MAPS_RESPONSE_FAIL = "RISK_MAPS_RESPONSE_FAIL";
export const RISK_MAPS_RESPONSE_RESET = "RISK_MAPS_RESPONSE_RESET";

export const RISK_MAPS_FILTRADA_GET = "RISK_MAPS_FILTRADA_GET";
export const RISK_MAPS_FILTRADA_RESET = "RISK_MAPS_FILTRADA_RESET";

export const RISK_MAPS_FILTRADA_QUESTIONS_GET =
  "RISK_MAPS_FILTRADA_DETAILS_GET";
export const RISK_MAPS_FILTRADA_QUESTIONS_RESET =
  "RISK_MAPS_FILTRADA_DETAILS_RESET";

export const RISK_MAPS_DELETE_ITEM_REQUEST = "RISK_MAPS_DELETE_ITEM_REQUEST";
export const RISK_MAPS_DELETE_ITEM_SUCCESS = "RISK_MAPS_DELETE_ITEM_SUCCESS";
export const RISK_MAPS_DELETE_ITEM_FAIL = "RISK_MAPS_DELETE_ITEM_FAIL";
export const RISK_MAPS_DELETE_ITEM_RESET = "RISK_MAPS_DELETE_ITEM_FAIL";

export const RISK_MAPS_STATISTICS_REQUEST = "RISK_MAPS_STATISTICS_REQUEST";
export const RISK_MAPS_STATISTICS_SUCCESS = "RISK_MAPS_STATISTICS_SUCCESS";
export const RISK_MAPS_STATISTICS_FAIL = "RISK_MAPS_STATISTICS_FAIL";
export const RISK_MAPS_STATISTICS_RESET = "RISK_MAPS_STATISTICS_RESET";

export const RISK_MAPS_ADD_CONDUCT_REQUEST = "RISK_MAPS_ADD_CONDUCT_REQUEST";
export const RISK_MAPS_ADD_CONDUCT_SUCCESS = "RISK_MAPS_ADD_CONDUCT_SUCCESS";
export const RISK_MAPS_ADD_CONDUCT_FAIL = "RISK_MAPS_ADD_CONDUCT_FAIL";
export const RISK_MAPS_ADD_CONDUCT_RESET = "RISK_MAPS_ADD_CONDUCT_RESET";

export const RISK_MAPS_PLAN_ADD_REQUEST = "RISK_MAPS_PLAN_ADD_REQUEST";
export const RISK_MAPS_PLAN_ADD_SUCCESS = "RISK_MAPS_PLAN_ADD_SUCCESS";
export const RISK_MAPS_PLAN_ADD_FAIL = "RISK_MAPS_PLAN_ADD_FAIL";
export const RISK_MAPS_PLAN_ADD_RESET = "RISK_MAPS_PLAN_ADD_RESET";

export const RISK_MAPS_CONDUCTS_BY_SCALE_REQUEST = "RISK_MAPS_CONDUCTS_BY_SCALE_REQUEST";
export const RISK_MAPS_CONDUCTS_BY_SCALE_SUCCESS = "RISK_MAPS_CONDUCTS_BY_SCALE_SUCCESS";
export const RISK_MAPS_CONDUCTS_BY_SCALE_FAIL = "RISK_MAPS_CONDUCTS_BY_SCALE_FAIL";
export const RISK_MAPS_CONDUCTS_BY_SCALE_RESET = "RISK_MAPS_CONDUCTS_BY_SCALE_RESET";

export const RISK_MAPS_GENERATE_REPORT_REQUEST = "RISK_MAPS_GENERATE_REPORT_REQUEST";
export const RISK_MAPS_GENERATE_REPORT_SUCCESS = "RISK_MAPS_GENERATE_REPORT_SUCCESS";
export const RISK_MAPS_GENERATE_REPORT_FAIL = "RISK_MAPS_GENERATE_REPORT_FAIL";
export const RISK_MAPS_GENERATE_REPORT_RESET = "RISK_MAPS_GENERATE_REPORT_RESET";