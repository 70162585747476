const BREADCRUMS_PAGES = {
  userAdd: [
    {
      title: "Lista de usuarios",
      link: "/usuarios",
    },
    {
      title: "Agregar Usuario",
    },
  ],
  userUpdate: [
    {
      title: "Lista de usuarios",
      link: "/usuarios",
    },
    {
      title: "Actualizar Usuario",
    },
  ],
  areaCompanyAdd: [
    {
      title: "Lista de Áreas en la empresa",
      link: "/area",
    },
    {
      title: "Agregar Área",
    },
  ],
  areaCompanyUpdate: [
    {
      title: "Lista de Áreas en la empresa",
      link: "/area",
    },
    {
      title: "Actualizar Área",
    },
  ],
  categoryCompanyAdd: [
    {
      title: "Lista de Categorías",
      link: "/categorias",
    },
    {
      title: "Agregar Categorías",
    },
  ],
  categoryCompanyUpdate: [
    {
      title: "Lista de Categorías",
      link: "/categorias",
    },
    {
      title: "Actualizar Categoría",
    },
  ],
  companyAdd: [
    {
      title: "Lista de Empresas",
      link: "/empresas",
    },
    {
      title: "Agregar Empresas",
    },
  ],
  companyAdminAreas: [
    {
      title: "Lista de Empresas",
      link: "/empresas",
    },
    {
      title: "Administrar Áreas de la Empresa",
    },
  ],
  companyUpdate: [
    {
      title: "Lista de Empresas",
      link: "/empresas",
    },
    {
      title: "Actualizar Empresa",
    },
  ],
  complaintStateAdd: [
    {
      title: "Lista de estados",
      link: "/estados",
    },
    {
      title: "Crear estado para denuncia",
    },
  ],
  complaintStateUpdate: [
    {
      title: "Lista de estados",
      link: "/categorias",
    },
    {
      title: "Actualizar estado de denuncia",
    },
  ],
  questionnairesAdd: [
    {
      title: "Lista de Cuestionarios",
      link: "/cuestionarios",
    },
    {
      title: "Crear Cuestionario",
    },
  ],
  questionnairyDetail: [
    {
      title: "Lista de Cuestionarios",
      link: "/cuestionarios",
    },
    {
      title: " Cuestionario",
    },
  ],
  checklistDetail: [
    {
      title: "Lista de Cuestionarios y CheckList",
      link: "/cuestionarios",
    },
    {
      title: " CheckList",
    },
  ],
  complaintDetail: [
    {
      title: "Lista de Denuncias",
      link: "/denuncias",
    },
    {
      title: "Detalles",
    },
  ],
  linksAdd: [
    {
      title: "Lista de Enlaces",
      link: "/enlaces",
    },
    {
      title: "Crear Enlace",
    },
  ],
  riskMapDetail: [
    {
      title: "Lista de Mapas de Riesgos",
      link: "/mapa-de-riesgos",
    },
    {
      title: "Mapa de Riesgos",
    },
  ],
  typologyList: [
    {
      title: "Administrar Mapas de Riesgos",
      link: "/mapa-de-riesgos/administrar",
    },
    {
      title: "Tipologías",
    },
  ],
  conductTypesList: [
    {
      title: "Administrar Tipos de conductas",
      link: "/mapa-de-riesgos/administrar",
    },
    {
      title: "Tipos de Conductas",
    },
  ],
  conductList: [
    {
      title: "Administrar",
      link: "/mapa-de-riesgos/administrar",
    },
    {
      title: "Conductas",
    },
  ],
  conductAdd: [
    {
      title: "Administrar",
      link: "/mapa-de-riesgos/administrar",
    },
    {
      title: "Administrar conductas",
      link: "/mapa-de-riesgos/conductas",
    },
    {
      title: "Agregar Conductas",
    },
  ],
  riskMapAddConduct: [
    {
      title: "Lista de Mapas de Riesgos",
      link: "/mapa-de-riesgos",
    },

    {
      title: "Agregar Conducta",
    },
  ],
  riskMapReport: [
    {
      title: "Lista de Mapas de Riesgos",
      link: "/mapa-de-riesgos",
    },
    {
      title: "Mapas de Riesgos",
      link: `/mapa-de-riesgos`,
    },
    {
      title: "Reporte",
    },
  ],
};

export default BREADCRUMS_PAGES;
