import api from "../../api/rigori.api";

import {
  CONDUCTS_TYPE_DELETE_REQUEST,
  CONDUCTS_TYPE_DELETE_SUCCESS,
  CONDUCTS_TYPE_DELETE_FAIL,
  CONDUCTS_TYPE_LIST_FAIL,
  CONDUCTS_TYPE_LIST_REQUEST,
  CONDUCTS_TYPE_LIST_SUCCESS,
  CONDUCTS_TYPE_ADD_FAIL,
  CONDUCTS_TYPE_ADD_REQUEST,
  CONDUCTS_TYPE_ADD_SUCCESS,
  CONDUCTS_ADD_REQUEST,
  CONDUCTS_ADD_SUCCESS,
  CONDUCTS_ADD_FAIL,
  CONDUCTS_LIST_REQUEST,
  CONDUCTS_LIST_SUCCESS,
  CONDUCTS_FILTRADA_GET,
  CONDUCTS_LIST_FAIL,
  CONDUCTS_DETAILS_REQUEST,
  CONDUCTS_DETAILS_SUCCESS,
  CONDUCTS_DETAILS_FAIL,
  CONDUCTS_UPDATE_REQUEST,
  CONDUCTS_UPDATE_SUCCESS,
  CONDUCTS_UPDATE_FAIL,
  CONDUCTS_DELETE_SUCCESS,
  CONDUCTS_DELETE_FAIL,
  CONDUCTS_DELETE_REQUEST,
} from "../constants/conducts.const";

import { logout } from "./user.actions";

export const addConducts =
  (typologyId, article, typeId, name) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CONDUCTS_ADD_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await api.post(
        "/conducts",
        { typologyId, article, typeId, name },
        config
      );

      dispatch({
        type: CONDUCTS_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Unauthorized") {
        dispatch(logout());
      }
      dispatch({
        type: CONDUCTS_ADD_FAIL,
        payload: message,
      });
    }
  };

export const listConducts = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONDUCTS_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.get(`/conducts`, config);

    dispatch({
      type: CONDUCTS_LIST_SUCCESS,
      payload: data,
    });
    dispatch({
      type: CONDUCTS_FILTRADA_GET,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: CONDUCTS_LIST_FAIL,
      payload: message,
    });
  }
};

export const listConductsBySearching =
  (filter) => async (dispatch, getState) => {
    //const reservas = getState().reservas.reservas;
    const filterNormalized = filter.trim().toLowerCase();
    //console.log(reservas);
    var conductsFilter;
    if (filter === "") {
      conductsFilter = getState().conductList.conducts;
    } else {
      conductsFilter = getState().conductList.conducts.filter(function (
        element
      ) {
        return (
          element.name.toLowerCase().includes(filterNormalized) ||
          (element.article !== null &&
            element.article.toLowerCase().includes(filterNormalized)) ||
          (element.type !== null &&
            element.type.name.toLowerCase().includes(filterNormalized)) ||
          (element.typology !== null &&
            element.typology.name.toLowerCase().includes(filterNormalized))
        );
      });
    }

    dispatch({
      type: CONDUCTS_FILTRADA_GET,
      payload: conductsFilter,
    });
  };

export const getConductDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONDUCTS_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await api.get(`/conducts/${id}`, config);

    dispatch({
      type: CONDUCTS_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: CONDUCTS_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const updateConduct =
  (id, typologyId, article, typeId, name) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CONDUCTS_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await api.patch(
        `/conducts/${id}`,
        { typologyId, article, typeId, name },
        config
      );

      dispatch({
        type: CONDUCTS_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Unauthorized") {
        dispatch(logout());
      }
      dispatch({
        type: CONDUCTS_UPDATE_FAIL,
        payload: message,
      });
    }
  };

export const deleteConduct = (conductId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONDUCTS_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.delete(`/conducts/${conductId}`, config);
    //console.log(data);

    dispatch({
      type: CONDUCTS_DELETE_SUCCESS,
      payload: data,
    });
    dispatch(listConducts());
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: CONDUCTS_DELETE_FAIL,
      payload: message,
    });
  }
};

export const addConductType = (name) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONDUCTS_TYPE_ADD_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await api.post("/conduct-types", { name }, config);

    dispatch({
      type: CONDUCTS_TYPE_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: CONDUCTS_TYPE_ADD_FAIL,
      payload: message,
    });
  }
};

export const listConductType = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONDUCTS_TYPE_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.get(`/conduct-types`, config);

    dispatch({
      type: CONDUCTS_TYPE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: CONDUCTS_TYPE_LIST_FAIL,
      payload: message,
    });
  }
};

export const deleteConductType =
  (conducttypeId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CONDUCTS_TYPE_DELETE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await api.delete(
        `/conduct-types/${conducttypeId}`,
        config
      );
      //console.log(data);

      dispatch({
        type: CONDUCTS_TYPE_DELETE_SUCCESS,
        payload: data,
      });
      dispatch(listConductType());
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Unauthorized") {
        dispatch(logout());
      }
      dispatch({
        type: CONDUCTS_TYPE_DELETE_FAIL,
        payload: message,
      });
    }
  };
