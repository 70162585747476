import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//COMPONENTS IMPORTS
//import CardComponent from "../components/shared/cards/statistic-card-v1/statistic-card-v1.component";
import Loader from "../components/shared/loaders/spinner/sppinner.component";
import Alert from "../components/shared/alerts/material-alert/alert.component";
import Header from "../layout/header/header.component";
import DataCard from "../components/shared/cards/statistic-card-v2/statistics-card-v2.component";

//DATA IMPORTS
//import DATA from "../constants/staticsV1.const";

//redux imports
import { useSelector, useDispatch } from "react-redux";
import { changeActiveSidebarItem } from "../redux/actions/navigation.actions";
import { getGeneralStatistics } from "../redux/actions/statistics.actions";

const HomeScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  //console.log(location.pathname);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const statisticsGeneral = useSelector((state) => state.statisticsGeneral);
  const { loading, error, statistics } = statisticsGeneral;

  useEffect(() => {
    //navigation
    dispatch(changeActiveSidebarItem(location.pathname));
    if (!userInfo) {

      /*   navigate("/login"); */
      navigate("/denunciar/1");


    } else {
      if (userInfo.role === "EMPRESA") {
        navigate("/cuestionarios");
      } else {
        dispatch(getGeneralStatistics());
      }
    }
  }, [navigate, userInfo, dispatch, location]);

  return (
    <>
      <Header title="Panel" path="Panel Administrativo" />
      <div className="container-fluid">
        {loading ? (
          <div className="mt-5 text-center">
            <Loader />
          </div>
        ) : error ? (
          <div className="mt-5 text-center">
            <Alert message={error} />
          </div>
        ) : (
          statistics && (
            <div className="row justify-content-center">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <DataCard
                  bg="#000e2d"
                  name="Cuestionarios"
                  number={statistics.totalQuizzes}
                  link="/cuestionarios"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <DataCard
                  bg="#c55b26"
                  name="Total de Denuncias"
                  iconName="info"
                  number={statistics.totalComplaints}
                  link="/denuncias"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <DataCard
                  color="#000e2d"
                  name="Avance en Cuestionarios"
                  iconName="trending-up"
                  number={statistics.progressAverage ? Number(statistics.progressAverage.toFixed(2)) + " %" : '0 %'}
                  link="/cuestionarios"
                />
              </div>
            </div>
          )
        )}
        {/* <div className="row clearfix">
          {DATA.map((item, index) => (
            <div className="col-lg-3 col-md-6 col-sm-12" key={index}>
              <CardComponent data={item} />
            </div>
          ))}
        
        </div> */}
        {/* <div className="row clearfix">
          <div className="col-lg-12">
            <div className="card">
              <div className="header">
                <h2>
                  <strong>
                    <i className="zmdi zmdi-chart"></i> Reporte
                  </strong>
                  Proyectos
                </h2>
                <ul className="header-dropdown">
                  <li className="dropdown">
                    <Link
                      to=""
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="zmdi zmdi-more"></i>
                    </Link>
                    <ul className="dropdown-menu dropdown-menu-right slideUp">
                      <li>
                        <Link to="/">Editar</Link>
                      </li>
                      <li>
                        <Link to="/">Eliminar</Link>
                      </li>
                      <li>
                        <Link to="/">Imprimir</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="remove">
                    <a role="button" className="boxs-close">
                      <i className="zmdi zmdi-close"></i>
                    </a>
                  </li>
                </ul>
              </div> <div className="body mb-2">
                  <div className="row clearfix">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                      <div className="state_w1 mb-1 mt-1">
                        <div className="d-flex justify-content-between">
                          <div>
                            <h5>2,365</h5>
                            <span>
                              <i className="zmdi zmdi-balance"></i> Revenue
                            </span>
                          </div>
                          <div
                            className="sparkline"
                            data-type="bar"
                            data-width="97%"
                            data-height="55px"
                            data-bar-Width="3"
                            data-bar-Spacing="5"
                            data-bar-Color="#868e96"
                          >
                            5,2,3,7,6,4,8,1
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                      <div className="state_w1 mb-1 mt-1">
                        <div className="d-flex justify-content-between">
                          <div>
                            <h5>365</h5>
                            <span>
                              <i className="zmdi zmdi-turning-sign"></i> Returns
                            </span>
                          </div>
                          <div
                            className="sparkline"
                            data-type="bar"
                            data-width="97%"
                            data-height="55px"
                            data-bar-Width="3"
                            data-bar-Spacing="5"
                            data-bar-Color="#2bcbba"
                          >
                            8,2,6,5,1,4,4,3
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                      <div className="state_w1 mb-1 mt-1">
                        <div className="d-flex justify-content-between">
                          <div>
                            <h5>65</h5>
                            <span>
                              <i className="zmdi zmdi-alert-circle-o"></i>
                              Queries
                            </span>
                          </div>
                          <div
                            className="sparkline"
                            data-type="bar"
                            data-width="97%"
                            data-height="55px"
                            data-bar-Width="3"
                            data-bar-Spacing="5"
                            data-bar-Color="#82c885"
                          >
                            4,4,3,9,2,1,5,7
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                      <div className="state_w1 mb-1 mt-1">
                        <div className="d-flex justify-content-between">
                          <div>
                            <h5>2,055</h5>
                            <span>
                              <i className="zmdi zmdi-print"></i> Invoices
                            </span>
                          </div>
                          <div
                            className="sparkline"
                            data-type="bar"
                            data-width="97%"
                            data-height="55px"
                            data-bar-Width="3"
                            data-bar-Spacing="5"
                            data-bar-Color="#45aaf2"
                          >
                            7,5,3,8,4,6,2,9
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <div className="body">
                <div
                  id="chart-area-spline-sracked"
                  className="c3_chart d_sales"
                ></div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="row clearfix">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="card mcard_4">
              <div className="body">
                <ul className="header-dropdown list-unstyled">
                  <li className="dropdown">
                    <Link
                      to=""
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="zmdi zmdi-menu"></i>
                    </Link>
                    <ul className="dropdown-menu slideUp">
                      <li>
                        <Link to="">Edit</Link>
                      </li>
                      <li>
                        <Link to="">Delete</Link>
                      </li>
                      <li>
                        <Link to="">Report</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div className="img">
                  <img
                    src="assets/images/lg/avatar1.jpg"
                    className="rounded-circle"
                    alt="profile-image"
                  />
                </div>
                <div className="user">
                  <h5 className="mt-3 mb-1">Eliana Smith</h5>
                  <small className="text-muted">UI/UX Desiger</small>
                </div>
                <ul className="list-unstyled social-links">
                  <li>
                    <Link to="">
                      <i className="zmdi zmdi-dribbble"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <i className="zmdi zmdi-behance"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <i className="zmdi zmdi-pinterest"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="card w_data_1">
              <div className="body">
                <div className="w_icon pink">
                  <i className="zmdi zmdi-bug"></i>
                </div>
                <h4 className="mt-3 mb-0">12.1k</h4>
                <span className="text-muted">Bugs Fixed</span>
                <div className="w_description text-success">
                  <i className="zmdi zmdi-trending-up"></i>
                  <span>15.5%</span>
                </div>
              </div>
            </div>
            <div className="card w_data_1">
              <div className="body">
                <div className="w_icon cyan">
                  <i className="zmdi zmdi-ticket-star"></i>
                </div>
                <h4 className="mt-3 mb-1">01.8k</h4>
                <span className="text-muted">Submitted Tickers</span>
                <div className="w_description text-success">
                  <i className="zmdi zmdi-trending-up"></i>
                  <span>95.5%</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="card">
              <div className="body">
                <div className="chat-widget">
                  <ul className="list-unstyled">
                    <li className="left">
                      <img
                        src="assets/images/xs/avatar3.jpg"
                        className="rounded-circle"
                        alt=""
                      />
                      <ul className="list-unstyled chat_info">
                        <li>
                          <small>Frank 11:00AM</small>
                        </li>
                        <li>
                          <span className="message bg-blue">
                            Hello, Michael
                          </span>
                        </li>
                        <li>
                          <span className="message bg-blue">How are you!</span>
                        </li>
                      </ul>
                    </li>
                    <li className="right">
                      <ul className="list-unstyled chat_info">
                        <li>
                          <small>11:10AM</small>
                        </li>
                        <li>
                          <span className="message">Hello, Frank</span>
                        </li>
                      </ul>
                    </li>
                    <li className="right">
                      <ul className="list-unstyled chat_info">
                        <li>
                          <small>11:11AM</small>
                        </li>
                        <li>
                          <span className="message">
                            I'm fine what about you?
                          </span>
                        </li>
                      </ul>
                    </li>
                    <li className="left">
                      <img
                        src="assets/images/xs/avatar2.jpg"
                        className="rounded-circle"
                        alt=""
                      />
                      <ul className="list-unstyled chat_info">
                        <li>
                          <small>Gary 11:13AM</small>
                        </li>
                        <li>
                          <span className="message bg-indigo">
                            Hello, Michael and Frank
                          </span>
                        </li>
                      </ul>
                    </li>
                    <li className="left">
                      <img
                        src="assets/images/xs/avatar5.jpg"
                        className="rounded-circle"
                        alt=""
                      />
                      <ul className="list-unstyled chat_info">
                        <li>
                          <small>Hossein 11:14AM</small>
                        </li>
                        <li>
                          <span className="message bg-amber">Hello, team</span>
                        </li>
                        <li>
                          <span className="message bg-amber">
                            Please let me know your requirements.
                          </span>
                        </li>
                        <li>
                          <span className="message bg-amber">
                            How would like to connect with us?
                          </span>
                        </li>
                      </ul>
                    </li>
                    <li className="right">
                      <ul className="list-unstyled chat_info">
                        <li>
                          <small>11:15AM</small>
                        </li>
                        <li>
                          <span className="message">Hello, Hossein</span>
                        </li>
                        <li>
                          <span className="message">
                            Meeting on conference room at 12:00PM
                          </span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className="input-group mt-3">
                  <div className="input-group-prepend">
                    <button
                      className="btn btn-outline-secondary dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Add
                    </button>
                    <div className="dropdown-menu">
                      <Link className="dropdown-item" to="/">
                        Tim Hank
                      </Link>
                      <Link className="dropdown-item" to="/">
                        Hossein Shams
                      </Link>
                      <Link className="dropdown-item" to="/">
                        John Smith
                      </Link>
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter text here..."
                    aria-label="Text input with dropdown button"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i className="zmdi zmdi-mail-send"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="row clearfix">
          <div className="col-md-12 col-lg-8">
            <div className="card">
              <div className="header">
                <h2>
                  <strong>Visitors</strong> Statistics
                </h2>
                <ul className="header-dropdown">
                  <li className="dropdown">
                    <Link
                      to="/"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="zmdi zmdi-more"></i>
                    </Link>
                    <ul className="dropdown-menu dropdown-menu-right slideUp">
                      <li>
                        <Link to="/">Action</Link>
                      </li>
                      <li>
                        <Link to="/">Another action</Link>
                      </li>
                      <li>
                        <Link to="/">Something else</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="remove">
                    <a role="button" className="boxs-close">
                      <i className="zmdi zmdi-close"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="body">
                <div id="world-map-markers" className="jvector-map"></div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="card">
              <div className="header">
                <h2>
                  <strong>Distribution</strong>
                </h2>
                <ul className="header-dropdown">
                  <li className="dropdown">
                    <Link
                      to="/"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="zmdi zmdi-more"></i>
                    </Link>
                    <ul className="dropdown-menu dropdown-menu-right slideUp">
                      <li>
                        <Link to="/">Edit</Link>
                      </li>
                      <li>
                        <Link to="/">Delete</Link>
                      </li>
                      <li>
                        <Link to="/">Report</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="remove">
                    <a role="button" className="boxs-close">
                      <i className="zmdi zmdi-close"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="body text-center">
                <div id="chart-pie" className="c3_chart d_distribution"></div>
                <button className="btn btn-primary mt-4 mb-4">View More</button>
              </div>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="card">
              <div className="header">
                <h2>
                  <strong>Traffic</strong> Source
                </h2>
                <ul className="header-dropdown">
                  <li className="dropdown">
                    <Link
                      to="/"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="zmdi zmdi-more"></i>
                    </Link>
                    <ul className="dropdown-menu dropdown-menu-right slideUp">
                      <li>
                        <Link to="/">Edit</Link>
                      </li>
                      <li>
                        <Link to="/">Delete</Link>
                      </li>
                      <li>
                        <Link to="/">Report</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="remove">
                    <a role="button" className="boxs-close">
                      <i className="zmdi zmdi-close"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="body">
                <div className="row">
                  <div className="col-lg-8 col-md-6 col-sm-12">
                    <div
                      id="chart-area-step"
                      className="c3_chart d_traffic"
                    ></div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <span>
                      More than 30% percent of users come from direct links.
                      Check details page for more information.
                    </span>
                    <div className="progress mt-4">
                      <div
                        className="progress-bar l-amber"
                        role="progressbar"
                        aria-valuenow="45"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: "45%" }}
                      ></div>
                    </div>
                    <div className="d-flex bd-highlight mt-4">
                      <div className="flex-fill bd-highlight">
                        <h5 className="mb-0">
                          21,521 <i className="zmdi zmdi-long-arrow-up"></i>
                        </h5>
                        <small>Today</small>
                      </div>
                      <div className="flex-fill bd-highlight">
                        <h5 className="mb-0">
                          %12.35 <i className="zmdi zmdi-long-arrow-down"></i>
                        </h5>
                        <small>Last month %</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default HomeScreen;
