import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//import components
import Header from "../../layout/header/header.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";
//import SkeletonLoader from "../../components/shared/loaders/skeleton-loader/skeleton-loader.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";

//import redux ;
import { useDispatch, useSelector } from "react-redux";
import { listLinks } from "../../redux/actions/searcher.actions";

import { changeActiveSidebarItem } from "../../redux/actions/navigation.actions";

const SearcherPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  //const [message, setMessage] = useState(null);
  const [keysearch, setKeySearch] = useState("");
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const linkList = useSelector((state) => state.linkList);
  const { loading, error, links } = linkList;

  const handleSearch = (e) => {
    e.preventDefault();

    if (keysearch) {
      dispatch(listLinks(keysearch));
    }
  };

  useEffect(() => {
    dispatch(changeActiveSidebarItem(location.pathname));
    if (!userInfo) {
      navigate("/login");
    } else {
      /*  if (addResult) {
        setMessage("EMPRESA creada con éxito");
      }
      if (updateResult) {
        setMessage("Empresa actualizada con éxito");
      } */
      /*  dispatch({ type: COMPANIES_ADD_RESET });
      dispatch({ type: COMPANIES_UPDATE_RESET });
      dispatch({ type: COMPANIES_DELETE_RESET });
      dispatch({ type: COMPANIES_DETAILS_RESET });
      dispatch(listCompanies()); */
    }
  }, [navigate, userInfo, dispatch, location]);
  return (
    <>
      {" "}
      <Header title="Buscador" path="Buscar"></Header>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="body">
                <form>
                  <div className="input-group">
                    <button className=" buttonSearcher" onClick={handleSearch}>
                      <i className="zmdi zmdi-search"></i>
                    </button>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Buscar..."
                      value={keysearch}
                      onChange={(e) => {
                        setKeySearch(e.target.value);
                      }}
                    />
                  </div>
                </form>
              </div>
            </div>
            {loading ? (
              <div className="text-center mt-3">
                <Loader />
              </div>
            ) : error ? (
              <Alert message={error} />
            ) : links ? (
              links.length > 0 ? (
                <div className="table-responsive">
                  <table className="table table-hover mb-0 c_table search_page">
                    <tbody>
                      {links.map((link, index) => (
                        <tr key={index}>
                          <td className="max_ellipsis">
                            <h5 className="title">
                              <a
                                href={link.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {link.name}
                              </a>
                            </h5>
                            <a
                              className="link"
                              href={link.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {link.url}
                            </a>
                            <span className="details">{link.description}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="text-center">
                  <h6>
                    No existen resultados para su búsqueda, por favor inténtelo
                    nuevamente
                  </h6>
                </div>
              )
            ) : null}
          </div>

          {/*  <div className="col-md-12">
            <ul className="pagination pagination-primary m-t-20">
              <li className="page-item">
                <a className="page-link" href="javascript:void(0);">
                  Previous
                </a>
              </li>
              <li className="page-item active">
                <a className="page-link" href="javascript:void(0);">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="javascript:void(0);">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="javascript:void(0);">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="javascript:void(0);">
                  Next
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default SearcherPage;
