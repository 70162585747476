const RISKMAPS_QUESTIONS_FILTER_OPTIONS = {
  filter: [
    {
      title: "Todas",
      action: "Todas",
    },
    {
      title: "Analizadas",
      action: "answered",
    },
    {
      title: "No Analizadas",
      action: "notAnswered",
    },
    {
      title: "Riesgo alto",
      action: "highRisk",
    },
    {
      title: "Riesgo medio",
      action: "mediumRisk",
    },
    {
      title: "Riesgo bajo",
      action: "lowRisk",
    },
  ],
  sort: [],
};

export default RISKMAPS_QUESTIONS_FILTER_OPTIONS;
