export const COMPLAINTS_LIST_REQUEST = "COMPLAINTS_LIST_REQUEST";
export const COMPLAINTS_LIST_SUCCESS = "COMPLAINTS_LIST_SUCCESS";
export const COMPLAINTS_LIST_FAIL = "COMPLAINTS_LIST_FAIL";
export const COMPLAINTS_LIST_RESET = "COMPLAINTS_LIST_RESET";

export const COMPLAINTS_DETAILS_REQUEST = "COMPLAINTS_DETAILS_REQUEST";
export const COMPLAINTS_DETAILS_SUCCESS = "COMPLAINTS_DETAILS_SUCCESS";
export const COMPLAINTS_DETAILS_FAIL = "COMPLAINTS_DETAILS_FAIL";
export const COMPLAINTS_DETAILS_RESET = "COMPLAINTS_DETAILS_RESET";

export const COMPLAINTS_ADD_REQUEST = "COMPLAINTS_ADD_REQUEST";
export const COMPLAINTS_ADD_SUCCESS = "COMPLAINTS_ADD_SUCCESS";
export const COMPLAINTS_ADD_FAIL = "COMPLAINTS_ADD_FAIL";
export const COMPLAINTS_ADD_RESET = "COMPLAINTS_ADD_RESET";

export const COMPLAINTS_RATE_REQUEST = "COMPLAINTS_RATE_REQUEST";
export const COMPLAINTS_RATE_SUCCESS = "COMPLAINTS_RATE_SUCCESS";
export const COMPLAINTS_RATE_FAIL = "COMPLAINTS_RATE_FAIL";
export const COMPLAINTS_RATE_RESET = "COMPLAINTS_RATE_RESET";

export const COMPLAINTS_GET_EMAILS_REQUEST = "COMPLAINTS_GET_EMAILS_REQUEST";
export const COMPLAINTS_GET_EMAILS_SUCCESS = "COMPLAINTS_GET_EMAILS_SUCCESS";
export const COMPLAINTS_GET_EMAILS_FAIL = "COMPLAINTS_GET_EMAILS_FAIL";
export const COMPLAINTS_GET_EMAILS_RESET = "COMPLAINTS_GET_EMAILS_RESET";