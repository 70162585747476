export const TYPOLOGIES_LIST_REQUEST = "TYPOLOGIES_LIST_REQUEST";
export const TYPOLOGIES_LIST_SUCCESS = "TYPOLOGIES_LIST_SUCCESS";
export const TYPOLOGIES_LIST_FAIL = "TYPOLOGIES_LIST_FAIL";
export const TYPOLOGIES_LIST_RESET = "TYPOLOGIES_LIST_RESET";

export const TYPOLOGIES_DELETE_REQUEST = "TYPOLOGIES_DELETE_REQUEST";
export const TYPOLOGIES_DELETE_SUCCESS = "TYPOLOGIES_DELETE_SUCCESS";
export const TYPOLOGIES_DELETE_FAIL = "TYPOLOGIES_DELETE_FAIL";
export const TYPOLOGIES_DELETE_RESET = "TYPOLOGIES_DELETE_FAIL";

export const TYPOLOGIES_ADD_REQUEST = "TYPOLOGIES_ADD_REQUEST";
export const TYPOLOGIES_ADD_SUCCESS = "TYPOLOGIES_ADD_SUCCESS";
export const TYPOLOGIES_ADD_FAIL = "TYPOLOGIES_ADD_FAIL";
export const TYPOLOGIES_ADD_RESET = "TYPOLOGIES_ADD_RESET";

export const TYPOLOGIES_FILTRADA_GET = "TYPOLOGIES_FILTRADA_GET";
export const TYPOLOGIES_FILTRADA_RESET = "TYPOLOGIES_FILTRADA_RESET";
