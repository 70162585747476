import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

//import components
import Header from "../../layout/header/header.component";
import PieChart from "../../components/charts/pie/pie-chart.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";
import CardRiskMap from "../../components/shared/cards/card-risk-map/card-risk-map";

//redux import
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/user.actions";
import { getStatisticsRiskMaps } from "../../redux/actions/risk-maps.actions";

//constant
import BREADCRUM_DATA from "../../constants/breadcrumb.const";

const RiskMapsResultsPage = () => {
	const navigate = useNavigate();
	const params = useParams();
	const dispatch = useDispatch();
	const labels = ["FUERA DE RIESGO", "MINIMO", "BAJO", "MEDIO", "ALTO", "FUERA DE RANGO", "NO RESPONDIDO"];
	const backgroundColor = [
		"rgba(210, 215, 211, 0.8)",
		"rgba(135, 206, 250, 0.6)",
		"rgba(50, 205, 50, 0.5)",
		"rgba(255, 204, 0, 0.5)",
		"rgba(255, 160, 122, 0.9)",
		"rgba(255, 41, 46, 0.76)",
		"rgba(8, 23, 37, 0.6)",
	];
	let data = [];

	const riskMapGetStatistics = useSelector((state) => state.riskMapGetStatistics);

	const { loading: loadingDetails, error: errorDetails, stats } = riskMapGetStatistics;
	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	if (stats) {
		data = [
			stats.fueraDeRiesgo.total,
			stats.minimo.total,
			stats.bajo.total,
			stats.medio.total,
			stats.alto.total,
			stats.fueraDeRango.total,
			stats.noRespondido.total,
		];
	}

	useEffect(() => {
		if (!userInfo) {
			navigate("/login");
		} else if (userInfo.role === "USUARIO") {
			dispatch(logout());
		} else {
			dispatch(getStatisticsRiskMaps(params.id));
		}
	}, [navigate, userInfo, dispatch, params]);

	const handleBack = () => {
		navigate(-1);
	};

	return (
		<>
			<button
				onClick={() => {
					handleBack();
				}}
				className="btn mt-3 btn-icon float-left "
			>
				<i className="zmdi zmdi-arrow-left"></i>
			</button>
			<Header title="Resumen" path="Resumen" pathList={BREADCRUM_DATA.riskMapDetail}/>
			<div className="container-fluid">
				<div className="row clearfix">
					<div className="col-md-12">
						{loadingDetails ? (
							<div className="text-center mt-4">
								<Loader />
							</div>
						) : errorDetails ? (
							<Alert message={errorDetails} />
						) : (
							stats && (
								<>
									<div className="row">
                    <div className="col-md-12 text-center">
											<Link
												to={`/mapa-de-riesgos/reporte/${params.id}`}
												className="btn btn-danger float-right"
											>
                        Descargar informe
                        {` `}
												<i className="zmdi zmdi-collection-pdf"></i>
											</Link>
										</div>
									</div>
									<div className="text-center mb-2 mt-2">
										<h5>Resumen Mapa de Riesgos</h5>
									</div>
									<div className="row">
										<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
											<CardRiskMap bg={"bg-red"} name="TOTAL" number={stats.total} total={stats.total} />
										</div>
										<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
											<CardRiskMap
												name="FUERA DE RIESGO"
												number={stats.fueraDeRiesgo.total}
												porcentage={stats.fueraDeRiesgo.avg}
												total={stats.total}
											/>
										</div>
										<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
											<CardRiskMap
												bg={"bg-red"}
												name="MINIMO"
												number={stats.minimo.total}
												porcentage={stats.minimo.avg}
												total={stats.total}
											/>
										</div>
										<div className="col-lg-3 col-md-4  col-sm-6 col-xs-12">
											<CardRiskMap
												bg={"bg-red"}
												name="BAJO"
												number={stats.bajo.total}
												porcentage={stats.bajo.avg}
												total={stats.total}
											/>
										</div>
										<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
											<CardRiskMap
												bg={"bg-red"}
												name="MEDIO"
												number={stats.medio.total}
												porcentage={stats.medio.avg}
												total={stats.total}
											/>
										</div>
										<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
											<CardRiskMap
												bg={"bg-red"}
												name="ALTO"
												number={stats.alto.total}
												porcentage={stats.alto.avg}
												total={stats.total}
											/>
										</div>
										<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
											<CardRiskMap
												bg={"bg-red"}
												name="FUERA DE RANGO"
												number={stats.fueraDeRango.total}
												porcentage={stats.fueraDeRango.avg}
												total={stats.total}
											/>
										</div>
										<div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
											<CardRiskMap
												bg={"bg-red"}
												name="NO RESPONDIDO"
												number={stats.noRespondido.total}
												porcentage={stats.noRespondido.avg}
												total={stats.total}
											/>
										</div>
									</div>
									<div className="row justify-content-center">
										<div className="col-10">
											<PieChart
												label="Riesgos Empresa"
												labels={labels}
												backgroundColor={backgroundColor}
												dataChart={data}
											/>
										</div>
									</div>
								</>
							)
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default RiskMapsResultsPage;
