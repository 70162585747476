import api from "../../api/rigori.api";

import {
  LABELS_DELETE_REQUEST,
  LABELS_DELETE_SUCCESS,
  LABELS_DELETE_FAIL,
  LABELS_DETAILS_FAIL,
  LABELS_DETAILS_REQUEST,
  LABELS_DETAILS_SUCCESS,
  LABELS_LIST_FAIL,
  LABELS_LIST_REQUEST,
  LABELS_LIST_SUCCESS,
  LABELS_ADD_FAIL,
  LABELS_ADD_REQUEST,
  LABELS_ADD_SUCCESS,
  LABELS_UPDATE_FAIL,
  LABELS_UPDATE_REQUEST,
  LABELS_UPDATE_SUCCESS,
  LINKS_ADD_REQUEST,
  LINKS_ADD_SUCCESS,
  LINKS_ADD_FAIL,
  LINKS_LIST_FAIL,
  LINKS_LIST_SUCCESS,
  LINKS_LIST_REQUEST,
  LINKS_UPDATE_FAIL,
  LINKS_UPDATE_SUCCESS,
  LINKS_UPDATE_REQUEST,
  LINKS_DELETE_SUCCESS,
  LINKS_DELETE_FAIL,
  LINKS_DELETE_REQUEST,
  LINKS_DETAILS_REQUEST,
  LINKS_DETAILS_SUCCESS,
  LINKS_DETAILS_FAIL,
} from "../constants/searcher.const";

import { logout } from "./user.actions";

export const addLabels = (name) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LABELS_ADD_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await api.post("/tags", { name }, config);

    dispatch({
      type: LABELS_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: LABELS_ADD_FAIL,
      payload: message,
    });
  }
};

export const addLinks =
  (name, description, url, tags) => async (dispatch, getState) => {
    try {
      dispatch({
        type: LINKS_ADD_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await api.post(
        "/links",
        { name, description, url, tags },
        config
      );

      dispatch({
        type: LINKS_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Unauthorized") {
        dispatch(logout());
      }
      dispatch({
        type: LINKS_ADD_FAIL,
        payload: message,
      });
    }
  };

export const listLabels = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: LABELS_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.get(`/tags`, config);

    dispatch({
      type: LABELS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: LABELS_LIST_FAIL,
      payload: message,
    });
  }
};

export const listLinks =
  (keyword = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: LINKS_LIST_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await api.get(`/links?filter=${keyword}`, config);
      //console.log(data);
      dispatch({
        type: LINKS_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Unauthorized") {
        dispatch(logout());
      }
      dispatch({
        type: LINKS_LIST_FAIL,
        payload: message,
      });
    }
  };

export const updateLabel = (labelId, name) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LABELS_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.patch(`/tags/${labelId}`, { name }, config);

    dispatch({
      type: LABELS_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: LABELS_UPDATE_FAIL,
      payload: message,
    });
  }
};

export const updateLink =
  (linkId, name, description, url, tags) => async (dispatch, getState) => {
    try {
      dispatch({
        type: LINKS_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await api.patch(
        `/links/${linkId}`,
        { linkId, name, description, url, tags },
        config
      );

      dispatch({
        type: LINKS_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Unauthorized") {
        dispatch(logout());
      }
      dispatch({
        type: LINKS_UPDATE_FAIL,
        payload: message,
      });
    }
  };

export const deleteLabel = (labelId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LABELS_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.delete(`tags/${labelId}`, config);
    //console.log(data);

    dispatch({
      type: LABELS_DELETE_SUCCESS,
      payload: data,
    });
    dispatch(listLabels());
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: LABELS_DELETE_FAIL,
      payload: message,
    });
  }
};

export const deleteLink = (linkId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LINKS_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await api.delete(`links/${linkId}`, config);
    //console.log(data);

    dispatch({
      type: LINKS_DELETE_SUCCESS,
      payload: data,
    });
    dispatch(listLinks());
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Unauthorized") {
      dispatch(logout());
    }
    dispatch({
      type: LINKS_DELETE_FAIL,
      payload: message,
    });
  }
};

export const getLabelDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LABELS_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await api.get(`/tags/${id}`, config);

    dispatch({
      type: LABELS_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LABELS_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getLinkDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LINKS_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await api.get(`/links/${id}`, config);

    dispatch({
      type: LINKS_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LINKS_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
