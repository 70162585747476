import React, { useEffect, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

//import components
import Header from "../../layout/header/header.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";
import DropZoneUploader from "../../components/shared/uploaders/single-uploader/dropzone-uploader";
import SelectedFilesList from "../../components/files/selected-files-list/selected-files-list";

//redux import
import { useDispatch, useSelector } from "react-redux";
import { updateActionComplaint, getActionComplaintDetails, deleteActionFile } from "../../redux/actions/complaints-actions.actions";
import { listStatesComplaint } from "../../redux/actions/complaints-states.actions";

import { ACTIONS_COMPLAINT_DELETE_FILE_RESET } from "../../redux/constants/complaints-actions.const";

const ComplaintActionUpdatePage = () => {
	const navigate = useNavigate();
	const params = useParams();
	const dispatch = useDispatch();

	const stateUpdate = useSelector((state) => state.actionComplaintUpdate);
	const { loading, error, updateResult } = stateUpdate;
	const stateDetail = useSelector((state) => state.actionComplaintDetails);
	const { loading: loadingDetails, error: errorDetails, action } = stateDetail;
	const statesList = useSelector((state) => state.statesComplaintList);
	const { loading: loadingState, states } = statesList;
	const actionDelete = useSelector((state) => state.actionDeleteFile);
	const { loading: loadingDelete, error: errorDelete } = actionDelete;
	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;
	const [uploadFiles, setUploadedFiles] = useState([]);
	const onDrop = useCallback(
		(acceptedFiles) => {
			const files = acceptedFiles.map((file) => file);
			setUploadedFiles([].concat(...uploadFiles, files));
		},
		[uploadFiles]
	);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		mode: "onChange",
		/* reValidateMode: "onChange", */
		criteriaMode: "firstError",
		shouldFocusError: true,
	});

	const complaintActionUpdatePage = [
		{
			title: "Lista de denuncias",
			link: `/denuncias`,
		},
		{
			title: "Detalles",
			link: `/denuncia/${params.id}`,
		},
		{
			title: "Actualizar diligencia de denuncia",
		},
	];

	const onSubmit = (data) => {
		if (window.confirm("Desea actualizar la información ?")) {
			dispatch(updateActionComplaint(params.actionId, uploadFiles, data.title, data.description, params.id, data.actionStateId));
		}
	};

	const handleDeleteSingleFile = (e, file) => {
		e.preventDefault();
		setUploadedFiles(
			uploadFiles.filter(function (obj) {
				return obj.path !== file.path;
			})
		);
	};

	const handleDeleteFile = (fileId) => {
		if (window.confirm("Esta acción no se puede deshacer.")) {
			dispatch(deleteActionFile(params.actionId, fileId));
		}
	};

	useEffect(() => {
		if (!userInfo) {
			navigate("/login");
		} else {
			if (!action.description) {
				dispatch({ type: ACTIONS_COMPLAINT_DELETE_FILE_RESET });
				dispatch(getActionComplaintDetails(params.actionId));
			} else {
				if (action.name !== "undefined") {
					reset({
						description: action.description,
						actionStateId: action.actionState.id,
            title: action.title,
					});
				}
			}
		}
		if (updateResult) {
			navigate(`/denuncia/${params.id}`);
		}
		dispatch(listStatesComplaint());
	}, [navigate, updateResult, userInfo, dispatch, params, reset, action]);

	return (
		<>
			<Header title="Actualizar diligencia de denuncia" path="Actualizar diligencia de denuncia" pathList={complaintActionUpdatePage}>
				<button className="btn btn-success btn-icon float-right" onClick={handleSubmit(onSubmit)}>
					<i className="zmdi zmdi-check"></i>
				</button>
			</Header>
			<div className="container-fluid">
				<div className="row clearfix">
					<div className="col-md-12">
						<div className="card">
							<div className="header">
								<h2>
									Actualizar diligencia de <strong>denuncia</strong>
								</h2>
							</div>
							<div className="body">
								{error && (
									<div className="mb-3">
										<Alert message={error} />
									</div>
								)}
								{errorDelete && (
									<div className="mb-3">
										<Alert message={errorDelete} />
									</div>
								)}
								{(loading || loadingDelete) && (
									<div className="text-center mb-2">
										<Loader />
									</div>
								)}
								{errorDetails ? (
									<div className="mb-3">
										<Alert message={errorDetails} />
									</div>
								) : loadingDetails || loadingState ? (
									<div className="text-center mb-2">
										<Loader />
									</div>
								) : (
									<div className="row justify-content-center clearfix">
										<div className="col-md-7 pb-3">
											<div className="form-group pb-0">
												<label className="form_label">Estado de la diligencia </label>
												<select
													className="form-control show-tick"
													{...register("actionStateId", {
														required: true,
													})}
												>
													<option value="">Estado de diligencia</option>
													{states &&
														states.map((state) => (
															<option key={state.id} value={state.id}>
																{state.name}
															</option>
														))}
												</select>
												{errors.actionStateId?.type === "required" && (
													<span
														style={{
															color: "red",
															padding: "4px 2px",
														}}
													>
														El estado de la diligencia es requerido
													</span>
												)}
											</div>
										</div>
										<div className="col-md-7">
											<div className="form-group pb-0">
												<label className="form_label">Título de la diligencia: </label>
												<input
													type="text"
													className="form-control"
													{...register("title", {
														required: true,
														minLength: 5,
													})}
												/>
												{errors.title?.type === "required" && (
													<span style={{ color: "red", padding: "4px 2px" }}>El título de la diligencia es requerido.</span>
												)}
												{errors.title?.type === "minLength" && (
													<span style={{ color: "red" }}>El título debe tener al menos 5 caracteres.</span>
												)}
											</div>
										</div>
										<div className="col-md-7">
											<div className="form-group pb-0">
												<label className="form_label">Diligencias</label>
											</div>
										</div>
										<div className="col-md-7">
											<div className="form-group">
												<textarea
													style={{ border: errors.description ? "1px solid red" : "" }}
													type="text"
													rows={6}
													className="form-control"
													placeholder="Describa la acción"
													{...register("description", {
														required: true,
														minLength: 5,
													})}
												></textarea>
												{errors.description?.type === "required" && (
													<span style={{ color: "red", padding: "4px 2px" }}>La descripción de la acción es requerida.</span>
												)}
												{errors.description?.type === "minLength" && (
													<span style={{ color: "red" }}>La descripción debe tener al menos 5 caracteres.</span>
												)}
											</div>
										</div>
										<div className="col-md-7">
											<div className="form-group pb-0">
												<label className="form_label">Anexos</label>
											</div>
										</div>
										<div className="col-md-7">
											<div className="mb-3 justify-content-center">
												<DropZoneUploader onDrop={onDrop} />
												{uploadFiles.length > 0 ? (
													<>
														<SelectedFilesList uploadFiles={uploadFiles} handleDeleteSingleFile={handleDeleteSingleFile} />
													</>
												) : null}
											</div>
										</div>
										<div className="col-md-7">
											<button className="btn btn-success" onClick={handleSubmit(onSubmit)}>
												Actualizar acción de denuncia
											</button>
										</div>
										<div className="col-12 col-md-11 content file_manager">
											<div className="card">
												{action.files && action.files.length > 0 && (
													<div className="header">
														<h2>archivos adjuntos de la acción</h2>
													</div>
												)}
												<div className="row pt-3">
													{action.files ? (
														action.files.length > 0 ? (
															<>
																{action.files.map((file, index) => (
																	<div className="col-lg-3 col-md-4 col-sm-12" key={index}>
																		{/* <FileCardComponent file={file} /> */}
																		<div className="card">
																			<div className="file">
																				<div className="hover">
																					<button
																						onClick={() => handleDeleteFile(file.id)}
																						type="button"
																						className="btn btn-icon btn-icon-mini btn-round btn-danger"
																					>
																						<i className="zmdi zmdi-delete"></i>
																					</button>
																				</div>
																				<div className="icon">
																					<i className="zmdi zmdi-file-text"></i>
																				</div>
																				<div className="file-name">
																					<a href={file.url} target="_blank" rel="noopener noreferrer" className="m-b-5 text-muted">
																						{file.name.length > 40 ? file.name.slice(0, 25) + `... ${file.type}` : `${file.name}`}
																					</a>
																					<small>
																						{file.size / 100} KB
																						<span className="date text-muted">{new Date(file.createdAt).toLocaleDateString()}</span>
																					</small>
																				</div>
																			</div>
																		</div>
																	</div>
																))}
															</>
														) : (
															<div className="col-12 text-center">
																<h6> No existen archivos adjuntos en esta acción.</h6>
															</div>
														)
													) : (
														<div className="col-12 text-center">
															<h6> No existen archivos adjuntos en esta acción.</h6>
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ComplaintActionUpdatePage;
