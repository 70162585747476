import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

//import components
import Header from "../../layout/header/header.component";
import ProfileCard from "../../components/shared/cards/profile-card/profile-card.component";
//import LoaderContentUser from "../../components/shared/loaders/skeleton-user-loader/skeleton-user-loader.component";
import Alert from "../../components/shared/alerts/material-alert/alert.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";

//redux import
import { useSelector, useDispatch } from "react-redux";
import { getUserDetails, updateUser } from "../../redux/actions/user.actions";

const UserUpdatePage = () => {
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userUpdate = useSelector((state) => state.userUpdate);
  const {
    loading: userUpdateLoading,
    error: userUpdateError,
    userUpdateResult,
  } = userUpdate;

  const {
    register,
    handleSubmit,

    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    /* reValidateMode: "onChange", */
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  const onSubmit = (data) => {
    //console.log(data);
    dispatch(
      updateUser(
        userInfo.id,
        data.name,
        data.lastname,
        data.email,
        data.phone,
        data.username
      )
    );
    setMessage(true);
    /*   addUser(
          data.name,
          data.lastname,
          data.email,
          data.phone,
          data.username,
          data.password,
          data.role
        ) */
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else {
      dispatch(getUserDetails(userInfo.id));
      if (user) {
        if (user.name !== "undefined") {
          reset({
            name: user.name,
            lastname: user.lastname,
            email: user.email,
            username: user.username,
            phone: user.phone,
          });
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, userInfo, dispatch, reset]);

  return (
    <>
      <Header title="Perfil" path="Perfil" />
      <div className="container-fluid">
        {userUpdateError && message && <Alert message={userUpdateError} />}
        {userUpdateLoading && message && (
          <div className="col-lg-12 col-md-12">
            <div className="text-center">
              {" "}
              <Loader />
            </div>
          </div>
        )}
        {userUpdateResult && message && (
          <Alert message="Perfil Actualizado con éxito" severity="info" />
        )}
        <div className="row clearfix">
          {error ? (
            <Alert message={error} />
          ) : loading ? (
            <div className="col-lg-12 col-md-12">
              <div className="text-center">
                {" "}
                <Loader />
              </div>
            </div>
          ) : (
            <>
              <div className="col-lg-4 col-md-12">
                <ProfileCard
                  name={user.name}
                  lastname={user.lastname}
                  email={user.email}
                  username={user.username}
                  phone={user.phone}
                  role={user.role ? user.role.name : "No definido"}
                />
              </div>
              <div className="col-lg-8 col-md-12 ">
                {/*  <div className="card mb-1">
                  <div className="header">
                    <h2>
                      Cambiar <strong>Contraseña</strong>
                    </h2>
                  </div>
                  <div className="body">
                    <div className="row">
                      <div className="col-lg-4 col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Contraseña Actual"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12">
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Nueva Contraseña"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12">
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Repetir Contraseña"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <button className="btn btn-success">Guardar Cambios</button>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="card">
                  <div className="header">
                    <h2>
                      Detalles del <strong>Perfil</strong>
                    </h2>
                  </div>
                  <div className="body">
                    <div className="row clearfix">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <label className="form_label">Nombre:</label>
                          <input
                            style={{
                              border: errors.name ? "1px solid red" : "",
                            }}
                            type="text"
                            className="form-control"
                            placeholder="Nombre"
                            {...register("name", {
                              required: true,
                              minLength: 2,
                            })}
                          />
                          {errors.name?.type === "required" && (
                            <span style={{ color: "red", padding: "4px 2px" }}>
                              El nombre es requerido.
                            </span>
                          )}
                          {errors.name?.type === "minLength" && (
                            <span style={{ color: "red" }}>
                              El nombre debe tener al menos dos caracteres.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <label className="form_label">Apellido:</label>
                          <input
                            style={{
                              border: errors.lastname ? "1px solid red" : "",
                            }}
                            type="text"
                            className="form-control"
                            placeholder="Apellido"
                            {...register("lastname", {
                              required: true,
                              minLength: 2,
                            })}
                          />
                          {errors.lastname?.type === "required" && (
                            <span style={{ color: "red", padding: "4px 2px" }}>
                              El apellido es requerido.
                            </span>
                          )}
                          {errors.lastname?.type === "minLength" && (
                            <span style={{ color: "red" }}>
                              El apellido debe tener al menos dos caracteres.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12">
                        <div className="form-group">
                          <label className="form_label">Teléfono</label>
                          <input
                            style={{
                              border: errors.phone ? "1px solid red" : "",
                            }}
                            type="tel"
                            className="form-control"
                            placeholder="Teléfono"
                            {...register("phone", {
                              required: true,

                              pattern: /[0][0-9]{9}/,
                            })}
                          />
                          {errors.phone?.type === "required" && (
                            <span style={{ color: "red" }}>
                              El teléfono es requerido.
                            </span>
                          )}
                          {errors.phone?.type === "pattern" && (
                            <span style={{ color: "red" }}>
                              El número debe comenzar con 0 y tener 10 dígitos.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12">
                        <div className="form-group">
                          <label className="form_label">Correo:</label>
                          <input
                            style={{
                              border: errors.email ? "1px solid red" : "",
                            }}
                            type="email"
                            className="form-control"
                            placeholder="Correo"
                            {...register("email", {
                              required: true,

                              pattern: /\S+@\S+\.\S+/,
                            })}
                          />
                          {errors.email?.type === "required" && (
                            <span style={{ color: "red" }}>
                              El correo es requerido.
                            </span>
                          )}
                          {errors.email?.type === "pattern" && (
                            <span style={{ color: "red" }}>
                              El valor ingresado no coincide con el formato de
                              correo electrónico
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12">
                        <div className="form-group">
                          <label className="form_label">Nombre Usuario:</label>
                          <input
                            style={{
                              border: errors.username ? "1px solid red" : "",
                            }}
                            type="text"
                            className="form-control"
                            placeholder="Nombre Usuario"
                            {...register("username", {
                              required: true,
                              minLength: 3,
                            })}
                          />
                          {errors.username?.type === "required" && (
                            <span style={{ color: "red", padding: "4px 2px" }}>
                              El nombre de usuario es requerido.
                            </span>
                          )}
                          {errors.username?.type === "minLength" && (
                            <span style={{ color: "red" }}>
                              El nombre de usuario debe tener al menos dos
                              caracteres.
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <button
                          className="btn btn-success"
                          onClick={handleSubmit(onSubmit)}
                        >
                          Guardar Cambios
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default UserUpdatePage;